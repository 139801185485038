import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmLanguageEntity } from 'app/@core/data/app-home.service';
import { DmLanguageService } from '../dm-language.service';

@Component({
  selector: 'ngx-dm-language-create',
  templateUrl: './dm-language-create.component.html',
  styleUrls: ['./dm-language-create.component.scss'],
})
export class DmLanguageCreateComponent implements OnInit {
  constructor(
    private service: DmLanguageService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<DmLanguageCreateComponent>
  ) {}

  ngOnInit(): void {}

  target: DmLanguageEntity = new DmLanguageEntity();

  create(frm: NgForm) {
    this.target.trangthai = 0;
    this.service.insert(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess('Thêm mới thành công');
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
