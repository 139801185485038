import { Injectable } from '@angular/core';
import {
  ApiResponse,
  AppHomeApiService,
  DmLBVModelApiResponse,
  DmLoaibaivietEntity,
  DmLoaibaivietEntityApiResponse,
  MasterModelIEnumerablePageModelViewApiResponse,
  PageModel
} from '../../../@core/data/app-home.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class DmLoaiBaiVietService {
  constructor(private api: AppHomeApiService) {}

  typeAttach(): number {
    return 1;
  }

  paging(body: PageModel | undefined): Observable<MasterModelIEnumerablePageModelViewApiResponse> {
    return this.api.appAdminApiDmloaibaivietPaging(body);
  }

  insert(body: DmLoaibaivietEntity | undefined): Observable<ApiResponse> {
    return this.api.appAdminApiDmloaibaivietInsert(body);
  }

  selectOne(key: number): Observable<DmLoaibaivietEntityApiResponse> {
    return this.api.appAdminApiDmloaibaivietSelectOne(key);
  }

  update(entity: DmLoaibaivietEntity): Observable<ApiResponse> {
    return this.api.appAdminApiDmloaibaivietUpdate(entity);
  }

  delete(id: number): Observable<ApiResponse> {
    return this.api.appAdminApiDmloaibaivietDelete(id);
  }

  insertLBV(body: DmLoaibaivietEntity | undefined): Observable<DmLBVModelApiResponse> {
    return this.api.appAdminApiDmloaibaivietCreateLBV(body);
  }

  updateLBV(body: DmLoaibaivietEntity | undefined): Observable<DmLBVModelApiResponse> {
    return this.api.appAdminApiDmloaibaivietUpdateLBV(body);
  }
}
