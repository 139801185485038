import { PAGE_SIZE, SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from '../../file/file.service';
import { PageModel } from '../../../../@core/data/app-gis.service';

@Component({
  selector: 'ngx-fileselect-import',
  templateUrl: './fileselect-import.component.html',
  styleUrls: ['./fileselect-import.component.scss'],
})
export class FileSelectImportComponent implements OnInit {
  constructor(
    private fileService: FileService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<FileSelectImportComponent>,
    @Inject(MAT_DIALOG_DATA) private meta: { fileType: string }
  ) {}

  target: any = [];
  pageModel: PageModel = new PageModel({
    pageSize: PAGE_SIZE,
    condition: null,
    currentPage: 1,
    search: null,
  });
  parentId : number;
  folder = [];
  ngOnInit(): void {
    this.loadFolder();
  }
  loadFolder() {
    this.fileService.getFolder(undefined).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.folder = this.folderTree(result.data, null);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
  resetPage() {
    this.pageModel.currentPage = 1;
    this.pageModel.search = undefined;
    this.searchPage();
  }
  searchPage(){
    this.pageModel.currentPage = 1;
    this.loadFiles(this.parentId);
  }
  loadFiles(parentId) {
    this.pageModel.condition = this.meta.fileType
    this.fileService.PagingFile(parentId, this.pageModel).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
        this.parentId = parentId;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
  
  folderTree(data, parentid) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].parentID === parentid) {
        result.push({
          name: data[i].name,
          id: data[i].id,
          items: this.folderTree(data, data[i].id),
        });
      }
    }
    return result;
  }
  filterByFolder(evt) {
    this.pageModel.currentPage = 1;
    this.loadFiles(evt.id);
  }
  selectFile(id: number, name: string) {
    this.dialogRef.close({ id: id, name: name });
  }
  byteToSize(bytes) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
