import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { ApiResponse, CreateOrUpdateDataModel, FileParameter } from '../../../../@core/data/app-gis.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'ngx-map-add',
  templateUrl: './map-image.component.html',
  styleUrls: ['./map-image.component.scss']
})
export class MapImageComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private fe: any,
    private dataService: DataService,
    public dialogRef: MatDialogRef<MapImageComponent>,
    private readonly httpClient: HttpClient
  ) { }

  fields: any[] = [];
  fieldsName: any[] = [];
  lstAll: any[] = [];
  lnglat: any;
  fileToUpload: FileList | null = null;
  LstImgPath: any[] = [];
  selectedFiles: FileParameter[] = [];
  lstFileIdDelete: any[] = [];
  handleFileInput(files: FileList) {
    this.fileToUpload = files;
  }
  ngOnInit(): void {
  }
  chooseFile(files: FileParameter[]) {
    if (files.length === 0) {
      return;
    }
    this.selectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      const fileParameter: FileParameter = {
        data: files[i],
        fileName: files[i]["name"],
      };
      this.selectedFiles.push(fileParameter);
    }
  }
  create() {
    const id = this.fe.fe.properties.id;
    const table = this.fe.fe.layer.metadata.tablename;
    if (this.selectedFiles.length > 0) {
      this.dataService.uploadFile(this.selectedFiles, id, table, table, 'image').subscribe(res => {
        if (res.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess();
          this.dialogRef.close(true);
        }

      });
    }
  }
}
