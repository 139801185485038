import { Component } from '@angular/core';

@Component({
    selector: 'ngx-icon-reply',
    templateUrl: './icon-reply.component.html',
    styleUrls: ['./icon-reply.component.scss']
})
export class IconReplyComponent {
    constructor() {
    }
}
