import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import {} from '@nebular/auth';

import { MenuComponent } from '../../@theme/pages/menu/menu.component';
import { routes as configAppRoutes } from './config-app/config-app-routing.module';

export const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      { path: 'config-app', children: configAppRoutes , data: {type: null}}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppQTHTRoutingModule {}
