import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { SysAppService } from '../config-app.service';
import { SysappEntity } from 'app/@core/data/app-qtht.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'ngx-config-app-detail',
  templateUrl: './config-app-detail.component.html',
  styleUrls: ['./config-app-detail.component.scss']
})
export class SysAppDetailComponent implements OnInit {

  constructor(
    private sysAppService: SysAppService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private idThiDuaKhenThuong: {id: number},
    public dialogRef: MatDialogRef<SysAppDetailComponent>
  ) {
  }

  target: SysappEntity = new SysappEntity();

  ngOnInit(): void {
    this.loadData(this.idThiDuaKhenThuong.id);
  }

  loadData(id: number) {
    this.sysAppService.selectOne(id).subscribe(result => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
