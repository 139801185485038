import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PAGE_SIZE, SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { PageModel } from 'app/@core/data/app-qtht.service';
import { FilesManagerService } from '../files-manager.service';

@Component({
  selector: 'ngx-files-manager-index',
  templateUrl: './files-manager-index.component.html',
  styleUrls: ['./files-manager-index.component.scss'],
})
export class FilesManagerIndexComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: FilesManagerService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}
  public ngOnInit(): void {
  }

}
