import { NgModule } from '@angular/core';
import {
  NbDirectionality, NbTreeGridDataService, NbTreeGridDataSourceBuilder, NbTreeGridFilterService,
  NbTreeGridService, NbTreeGridSortService
} from '@nebular/theme';

import { AppQTHTRoutingModule } from './app-qtht-routing.module';
import { SysAppModule } from './config-app/config-app.module';

@NgModule({
  imports: [
    AppQTHTRoutingModule,
    SysAppModule
  ],
  providers: [
    NbTreeGridDataSourceBuilder,
    NbTreeGridFilterService,
    NbTreeGridSortService,
    NbTreeGridService,
    NbTreeGridDataService,
    NbDirectionality,
  ],
  declarations: [],
})
export class AppQTHTModule { }
