import { PAGE_SIZE, SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MapAdminService } from '../mapadmin.service';
import {
  ObjectIEnumerablePageModelView,
  ObjectIEnumerablePageModelViewApiResponse,
  PageModel,
  SysappEntity,
} from 'app/@core/data/app-qtht.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { MapUserModel, SysmapEntity } from 'app/@core/data/app-gis.service';
import { UserAppService } from '../../services/user-app.service';
import { TokenService } from 'app/@core/token.service';

@Component({
  selector: 'ngx-mapadmin-permission-addusers',
  templateUrl: './mapadmin-permission-addusers.component.html',
  styleUrls: ['./mapadmin-permission-addusers.component.scss'],
})
export class MapAdminPermissionAddUserComponent implements OnInit {
  constructor(
    private userAppService: UserAppService,
    private mapService: MapAdminService,
    private tokenService: TokenService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private MapAdminItem: { id: number; lstUserName: any[] },
    public dialogRef: MatDialogRef<MapAdminPermissionAddUserComponent>
  ) {}

  target: SysmapEntity = new SysmapEntity();
  users: ObjectIEnumerablePageModelView[] = [];
  pageModel: PageModel = new PageModel({
    pageSize: 100000000,
    condition: null,
    currentPage: 1,
    search: null,
  });

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.tokenService.getUserInfo().subscribe((u) => {
      this.userAppService.selectAll().subscribe((result) => {
      });
    });
    this.userAppService.paging(this.pageModel).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        const dic = {};
        for (let i = 0; i < this.MapAdminItem.lstUserName.length; i++) {
          dic[this.MapAdminItem.lstUserName[i].userName] = 1;
        }
        const data = result.data.data;
        for (let i = 0; i < data.length; i++) {
          if (dic[data[i].username] || data[i].appid !== 21) continue;
          this.users.push(data[i]);
        }
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
  selectUser(id, username) {
    const model = new MapUserModel();
    model.userId = id;
    model.userName = username;
    model.mapId = this.MapAdminItem.id;
    this.mapService.addPermission(model).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  capNhat() {
    this.dialogRef.close(true);
  }
}
