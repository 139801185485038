import { DUPLICATE_RESPONE } from '../../../../@core/customs/constants';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { FileService } from '../file.service';
import { DatasetService } from '../../dataset/dataset.service';
import { TokenService } from '../../../../@core/token.service';
import { FolderInsertOrUpdateModel } from '../../../../@core/data/app-gis.service';

@Component({
  selector: 'ngx-folder-create',
  templateUrl: './folder-create.component.html',
  styleUrls: ['./folder-create.component.scss'],
})
export class FolderCreateComponent implements OnInit {
  constructor(
    private folderService: FileService,
    private commonService: CommonService,
    private datasetService: DatasetService,
    private tokenService: TokenService,
    @Inject(MAT_DIALOG_DATA) private folder: { parentID: number, checkUnit: boolean },
    public dialogRef: MatDialogRef<FolderCreateComponent>
  ) {}
  folders = [];
  selected = [];
  selectedItem: any;
  parentId: any;
  target: FolderInsertOrUpdateModel = new FolderInsertOrUpdateModel();
  user: any;
  lstUnit: any[] = [];
  checkUnit: boolean = false;
  ngOnInit(): void {
    this.checkUnit = this.folder.checkUnit;
    this.getUnit();
    this.loadFolder();
  }
  getUnit(){
    this.datasetService.getAllUnit().subscribe(res => {
        this.lstUnit = res.data;
    });
  }
  loadFolder() {
    this.selected = [];
    this.folderService.getFolder(undefined).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.folders = this.folderTree(result.data, null);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
  folderTree(data, parentid) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      const obj = {
        name: data[i].name,
        id: data[i].id,
      };
      if (data[i].parentID === parentid) {
        result.push({
          name: data[i].name,
          id: data[i].id,
          items: this.folderTree(data, data[i].id),
        });
      }
    }
    return result;
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  create(frm: NgForm) {
    if (this.selectedItem) {
      this.target.parentID = this.selectedItem.id;
    }

    this.target.name = this.target.name.trim();
    this.folderService.createFolder(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(result.data);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
