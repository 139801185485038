import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { FeatureclassService } from '../featureclass.service';
import { SysfeatureclassEntity } from '../../../../@core/data/app-gis.service';
import { DatasetService } from '../../dataset/dataset.service';

@Component({
  selector: 'ngx-featureclass-create',
  templateUrl: './featureclass-create.component.html',
  styleUrls: ['./featureclass-create.component.scss'],
})
export class FeatureclassCreateComponent implements OnInit {
  constructor(
    private featureclassService: FeatureclassService,
    private commonService: CommonService,
    private datasetService: DatasetService,
    @Inject(MAT_DIALOG_DATA) private dataset: { datasetid: number,checkUnit:boolean  },
    public dialogRef: MatDialogRef<FeatureclassCreateComponent>
  ) {}

  target: SysfeatureclassEntity = new SysfeatureclassEntity();
  lstUnit: any[] = [];
  checkUnit: boolean = false;
  ngOnInit(): void {
    this.checkUnit = this.dataset.checkUnit;
    this.getUnit();
    this.target.prj = '105453';
  }
  getUnit(){
    this.datasetService.getAllUnit().subscribe(res => {
        this.lstUnit = res.data;
    });
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  create(frm: NgForm) {
    this.target.name = this.target.name.trim();
    this.target.datasetid = this.dataset.datasetid;
    this.featureclassService.create(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
