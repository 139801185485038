import { DUPLICATE_RESPONE } from '../../../../@core/customs/constants';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { SysAppService } from '../config-app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { SysappEntity } from '../../../../@core/data/app-qtht.service';


@Component({
  selector: 'ngx-config-app-create',
  templateUrl: './config-app-create.component.html',
  styleUrls: ['./config-app-create.component.scss']
})
export class SysAppCreateComponent implements OnInit {

  constructor(
    private sysAppService: SysAppService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private testData: {testData: string, unitcode: string},
    public dialogRef: MatDialogRef<SysAppCreateComponent>
  ) {
  }

  target: SysappEntity = new SysappEntity();

  ngOnInit(): void {
    this.target.unitcode = this.testData.unitcode
    this.target.trangthai = 1;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  create(frm: NgForm) {
    this.target.tenapp = this.target.tenapp.trim();
    this.sysAppService.create(this.target).subscribe(result => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else if (result.code === DUPLICATE_RESPONE) {
        this.commonService.toastrWarning('Tên App đã tồn tại trên hệ thống !');
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
