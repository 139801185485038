<form #frmFeature="ngForm" (ngSubmit)="update(frmFeature)">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">Chỉnh sửa lớp dữ liệu</div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Tên lớp dữ liệu</label>
            <input type="text" class="form-control" name="name" [(ngModel)]="target.name" required maxlength="50" />
          </div>
        </div>
        <div class="col-lg-12">
            <div class="form-group">
              <label class="col-form-label">Chủ đề dữ liệu</label>
              <kendo-dropdowntree
                placeholder="Chọn chủ đề"
                kendoDropDownTreeExpandable
                [kendoDropDownTreeHierarchyBinding]="datasets"
                textField="name"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedItem"
                valueField="id"
                childrenField="items"
                class="furniture"
              >
              </kendo-dropdowntree>
            </div>
          </div>
        <div class="col-lg-12" *ngIf="checkUnit === true">
          <div class="form-group">
            <label class="col-form-label">Đơn vị quản lý</label>
            <ng-select
              [items]="lstUnit"
              bindValue="unitcode"
              bindLabel="unitname"
              [(ngModel)]="target.unitCode"
              name="unitcode"
              placeholder="Chọn đơn vị"
              appendTo="Body"
            ></ng-select>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label">Mô tả</label>
            <textarea type="text" class="form-control" name="description" [(ngModel)]="target.description"></textarea>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group" style="margin-top: 10px">
            <label class="control-label">Trạng thái</label>
            <div class="input-group">
              <input
                type="radio"
                style="margin-top: auto; margin-bottom: auto; width: 20px; height: 20px"
                [value]="1"
                [(ngModel)]="target.status"
                id="status1"
                name="status"
              />&nbsp;
              <label for="status1" style="margin: 10px 10px 10px 0px">Sử dụng </label>
              <br />
              <input
                type="radio"
                style="margin-top: auto; margin-bottom: auto; width: 20px; height: 20px"
                [value]="0"
                [(ngModel)]="target.status"
                id="status2"
                name="status"
              />&nbsp;
              <label for="status2" style="margin: 10px 10px 10px 0px">Không sử dụng </label>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button type="submit" class="btn btn-primary" [disabled]="frmFeature.invalid">Lưu</button>
          &nbsp;
          <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
