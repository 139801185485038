<nb-card>
  <nb-card-header class="nb-card-header">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
        <div class="input-group">
         
          <label style="flex: auto">Danh sách trường dữ liệu</label>
              &nbsp;&nbsp;
              <input
                type="text"
                class="form-control"
                placeholder="Nhập để tìm kiếm trường"
                aria-label="Nhập để tìm kiếm trường"
                aria-describedby="basic-addon2"
                [(ngModel)]="pageModel.search"
                (ngModelChange)="searchField($event)"
              />


          <button
          class="btn btn-primary mr-2"
          type="button"
          (click)="openCreateDialog()"
        >
          <i class="fas fa-plus fa-sm"></i>&nbsp;Thêm mới
        </button>

        <button
        class="btn btn-danger"
        type="button"
        (click)="closeDialog()"
      >
        <i class="fas fa-close fa-sm"></i>&nbsp;Đóng
      </button>
        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th class="text-center" style="width: 50px;">STT</th>
            <th>Tên trường</th>
            <th>Nhãn hiển thị</th>
            <th>Kiểu dữ liệu</th>
            <th>Độ rộng</th>
            <th>Thứ tự hiển thị</th>
            <th>Trạng thái</th>
            <th class="text-center" style="width: 150px;">Hành động</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of target; let i = index">
            <td class="text-center">
              {{i + 1}}
            </td>
            <td>{{ item.fieldname }}</td>
            <td>{{ item.name }}</td>
            <td>{{ renderDataType(item.datatype) }}</td>
            <td>{{ item.datalength }}</td>
            <td>{{ item.show }}</td>
            <td>
              <mat-slide-toggle [color]="'primary'" [checked]="setCheckedForToggle(item.status)" [disabled]="false"
              (change)="changeStatus(item)">
            </mat-slide-toggle>
            </td>
            <td class="text-center">
              <button title="Sửa thông tin" class="btn" (click)="editItem(item.id, item.fieldname)"><i class="fa fa-edit"></i></button>
              <button title="Xóa trường dữ liệu" class="btn" (click)="deleteItem(item.id, item.name, item.fieldname)"><i class="fa fa-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </nb-card-body>
</nb-card>

