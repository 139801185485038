<form>
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-10 col-md-10 col-xs-10 col-lg-10">Danh sách dữ liệu {{ featureclassName }}</div>
        <div class="col-sm-2 col-md-2 col-xs-2 col-lg-2" style="display: flex;">
          <ngx-btn-add data-toggle="tooltip" title="Thêm mới" (click)="openCreate()"></ngx-btn-add>&nbsp;
          <ngx-btn-refresh (click)="SearchModel.text = null;SearchModel.status = null; refresh();">
          </ngx-btn-refresh>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row p-2" style="border-bottom: 1px solid #edf1f7; padding: 5px 20px !important">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 p-0">

          <div class="input-group">
            <div style="width: 200px; margin-right: 15px;">
              <ng-select style="width: 200px;" [items]="options" bindValue="value" bindLabel="name"
                (change)="sortByStatus();" [(ngModel)]="status" name="status"
                placeholder="Lọc theo trạng thái"></ng-select>
            </div>
            &nbsp;
            <ng-select [items]="lstDVHCCH" bindValue="madonvi" bindLabel="ten" [(ngModel)]="maHuyen" name="maHuyen"
              placeholder="Chọn đơn vị hành chính cấp huyện" style="margin: 0 auto;width: 200px"
              (change)="changeHuyenbyXa()">
            </ng-select>
            &nbsp;
            <ng-select [items]="lstDVHCCXFill" bindValue="madonvi" bindLabel="ten" [(ngModel)]="maXa" name="maXa"
              placeholder="Chọn đơn vị hành chính cấp xã" style="margin: 0 auto;width: 200px" (change)="loadHuyenXa()">
            </ng-select>
            &nbsp;
            <ng-select style="width: 200px; margin-right: 15px;" [items]="fields" bindValue="fieldname" bindLabel="name"
              name="status" placeholder="Chọn trường muốn tìm" [(ngModel)]="field"></ng-select>
            <label class="ml-1 mt-2 pr-1">Tìm theo từ khóa:</label>
            <input type="text" class="form-control" placeholder="Tìm kiếm theo từ khóa" [(ngModel)]="textSearch"
              [ngModelOptions]="{ standalone: true }" (keyup.enter)="onSearchTableAttribute()" />
            <button class="btn btn-primary" type="button" title="Tìm kiếm" (click)="onSearchTableAttribute()">
              <i class="fas fa-search fa-sm"></i>&nbsp;
            </button>
            &nbsp;
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 p-0">
          <table class="table table-bordered table-hover m-0">
            <thead>
              <tr>
                <th class="text-center" style="width: 50px">STT</th>
                <th *ngFor="let item of fields; let i = index">{{ item.name }}</th>
                <th class="text-center" style="width: 50px">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of target; let i = index">
                <td class="text-center">
                  {{ i + stt + 1 }}
                </td>
                <td *ngFor="let index of fields; let j = index">
                  <span
                    *ngIf="index.name.toLowerCase() !== 'trạng thái' && index.name.toLowerCase() !== 'status' && index.fieldname.toLowerCase() !== 'mahuyen' && index.fieldname.toLowerCase() !== 'maxa' && index.name.toLowerCase() !== 'giới tính'">
                    {{item[index.fieldname.toLowerCase()]}}
                  </span>
                  <span *ngIf="index.fieldname.toLowerCase() === 'mahuyen'">
                    {{ getTenDVH(item[index.fieldname.toLowerCase()])}}
                  </span>
                  <span *ngIf="index.fieldname.toLowerCase() === 'maxa'">
                    {{ getTenDVX(item[index.fieldname.toLowerCase()])}}
                  </span>
                  <span *ngIf="index.name.toLowerCase() === 'trạng thái' || index.name.toLowerCase() === 'status'">
                    <mat-slide-toggle [color]="'primary'"
                      [checked]="setCheckedForToggle(item[index.fieldname.toLowerCase()])" [disabled]="false"
                      (change)="changeStatus(i)">
                    </mat-slide-toggle>
                  </span>
                </td>
                <td class="text-center" style="padding: unset" style="width:95px">
                  <button style="padding: 5px !important" title="Xem chi tiết" class="btn btn-sm"
                    (click)="viewFeature(i)">
                    <i class="fa fa-eye"></i>
                  </button>
                  <button style="padding: 5px !important" title="Sửa thông tin" class="btn btn-sm"
                    (click)="editFeature(i)">
                    <i class="fa fa-edit"></i>
                  </button>
                  <button style="padding: 5px !important" title="Xóa đối tượng" class="btn btn-sm"
                    (click)="deleteFeature(i)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row col" style="margin-top: 10px;">
          <div *ngIf="targetNew.data.recordsCount > 0">
            <div style="float: left;">
              Tổng số bản ghi: {{targetNew.data.recordsCount}}
            </div>
            <div style="float: right;">
              <ngx-pagination (pageChange)="SearchModel.page = $event;loadDataNew()"
                (pageSize)="SearchModel.limit = $event;loadDataNew()" [currentPage]="SearchModel.page"
                [totalPages]="targetNew.data.totalPage">
              </ngx-pagination>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>