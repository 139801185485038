import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
  styles: [
    `
      kendo-treeview {
        width: 200px;
      }
    `,
  ],
})
export class PermissionComponent implements OnInit {
  public expandedKeys: any[] = ['0', '1'];

  public checkedKeys: any[] = ['0_1'];

  public data: any[] = [
    {
      text: 'Furniture',
      items: [{ text: 'Tables & Chairs' }, { text: 'Sofas' }, { text: 'Occasional Furniture' }],
    },
    {
      text: 'Decor',
      items: [{ text: 'Bed Linen' }, { text: 'Curtains & Blinds' }, { text: 'Carpets' }],
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
