import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { TintucSukienService } from '../tintuc-sukien.service';

@Component({
  selector: 'ngx-tintuc-sukien-delete',
  templateUrl: './tintuc-sukien-delete.component.html',
  styleUrls: ['./tintuc-sukien-delete.component.scss']
})
export class TintucSukienDeleteComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private service: TintucSukienService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number, title: string },
    private dialogRef: MatDialogRef<TintucSukienDeleteComponent>
  ) { }

  title: string;

  ngOnInit(): void {
    this.title = this.data.title;
  }

  delete() {
    this.service.deleteWithFile(this.data.id).subscribe(res => {
      if (res.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess('Xoá dữ liệu thành công.');
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(res.message);
        this.dialogRef.close(false);
      }
    });
  }
}
