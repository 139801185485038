import { Component, Inject, OnInit } from '@angular/core';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { PAGE_SIZE, SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CountDataModel, DataSearchModel, DataSetModel } from '../../../../@core/data/app-gis.service';
import { DatasetService } from '../../dataset/dataset.service';
import { FeatureclassService } from '../../featureclass/featureclass.service';
import { DataService } from '../../services/data.service';
import { Workbook } from 'exceljs';
import { DatePipe } from '@angular/common';
import * as fs from 'file-saver';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldService } from '../../featureclass/field.service';
@Component({
  selector: 'ngx-view-data-thong-ke',
  templateUrl: './view-data-thong-ke.component.html',
  styleUrls: ['./view-data-thong-ke.component.scss'],
})
export class ViewDataThongKeComponent implements OnInit {
  constructor(
    private datasetService: DatasetService,
    private commonService: CommonService,
    private dataService: DataService,
    private featureclassService: FeatureclassService,
    @Inject(MAT_DIALOG_DATA) private feature: { tableName: string; lstId: string; textSearch: string; field: string; month: any; year: any; maDVHCCX: string; maDVHCCH: string },
    public dialogRef: MatDialogRef<ViewDataThongKeComponent>,
    private fieldService: FieldService,
  ) { }

  SearchModel: DataSearchModel = new DataSearchModel({
    tableName: null,
    text: null,
    page: 1,
    limit: PAGE_SIZE,
    status: null,
    fieldSelect: null,
    dvhcch: null,
    dvhccx: null,
    lstId: null
  });
  target: any[] = [];
  fields: any[] = [];
  targetNew: any = {
    data: [],
  };
  lstDVHCCH: any[] = [];
  lstDVHCCX: any[] = [];
  stt:any;
  ngOnInit(): void {
    this.loadData();
    this.fieldService.getAllByTableName(this.feature.tableName).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.fields = result.data;
      }
    });
  }
  loadData() {
    this.SearchModel.tableName = this.feature.tableName;
    this.SearchModel.dvhcch = this.feature.maDVHCCH;
    this.SearchModel.dvhccx = this.feature.maDVHCCX;
    this.SearchModel.fieldSelect = this.feature.field;
    this.SearchModel.text = this.feature.textSearch;
    this.SearchModel.startDate = this.feature.month;
    this.SearchModel.endDate = this.feature.year;
    // this.SearchModel.lstId = this.feature.lstId === '' ? this.feature.lstId = 'null' : this.feature.lstId;
    this.dataService.PaingData(this.SearchModel).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.targetNew = result;
        this.target = JSON.parse(this.targetNew.data.data);
        this.stt = (this.SearchModel.page - 1 ) * this.SearchModel.limit;
      } else {
        this.commonService.toastrDanger(result.message);
      }

    });

  }
  setCheckedForToggle(status: number) {
    if (status === 1) {
      return true;
    } else {
      return false;
    }
  }
  loadDVHC() {
    this.dataService.getDataByTableName('dvhccx').subscribe((result) => {
      this.lstDVHCCX = JSON.parse(result.data);
    });
    this.dataService.getDataByTableName('dvhcch').subscribe((res) => {
      this.lstDVHCCH = JSON.parse(res.data);
    })
  }
  getTenDVX(unit) {
    const name = this.lstDVHCCX.filter(x => x.madonvi === unit);
    return name.length > 0 ? name[0].ten : unit
  }
  getTenDVH(unit) {
    const name = this.lstDVHCCH.filter(x => x.madonvi === unit);
    return name.length > 0 ? name[0].ten : unit
  }
}
