import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { PAGE_SIZE, SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { PageModel } from 'app/@core/data/app-qtht.service';
import { DmLanguageCreateComponent } from '../dm-language-create/dm-language-create.component';
import { DmLanguageDeleteComponent } from '../dm-language-delete/dm-language-delete.component';
import { DmLanguageDetailComponent } from '../dm-language-detail/dm-language-detail.component';
import { DmLanguageUpdateComponent } from '../dm-language-update/dm-language-update.component';
import { DmLanguageService } from '../dm-language.service';

@Component({
  selector: 'ngx-dm-language-index',
  templateUrl: './dm-language-index.component.html',
  styleUrls: ['./dm-language-index.component.scss'],
})
export class DmLanguageIndexComponent implements OnInit {
  constructor(
    private service: DmLanguageService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  target: any = {
    data: [],
  };
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
  });

  loadData() {
    this.service.paging(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
  type: string;
  openCreate() {
    this.dialog
      .open(
        DmLanguageCreateComponent,
        this.commonService.configDialog('50%', { lsttype: this.type })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) this.search();
      });
  }

  search() {
    this.searchModel.currentPage = 1;
    this.loadData();
  }

  updateData(id: number) {
    this.dialog
      .open(
        DmLanguageUpdateComponent,
        this.commonService.configDialog('50%', { id: id })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res === true) {
          this.loadData();
        }
      });
  }

  deleteData(id: number, ten: string) {
    this.dialog
      .open(
        DmLanguageDeleteComponent,
        this.commonService.configDialog('50%', { id: id, ten: ten })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res === true) {
          this.loadData();
        }
      });
  }

  detailData(id: number) {
    this.dialog
      .open(
        DmLanguageDetailComponent,
        this.commonService.configDialog('50%', { id: id })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res === true) {
          this.loadData();
        }
      });
  }
}
