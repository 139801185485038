<form #frm="ngForm" (ngSubmit)="create()">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          Thêm trường dữ liệu
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Tên trường dữ liệu</label>
            <input type="text" class="form-control" name="fieldname" [(ngModel)]="target.fieldname" required
              maxlength="11" #fieldname="ngModel" />
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Nhãn hiển thị</label>
            <input type="text" class="form-control" name="name" [(ngModel)]="target.name" required maxlength="50" />
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Kiểu dữ liệu</label>
            <select class="form-control" aria-label="Kiểu dữ liệu" [(ngModel)]="target.datatype"
              [ngModelOptions]="{standalone: true}">
              <option value="nvarchar2">Chuỗi ký tự</option>
              <option value="number">Số nguyên</option>
              <option value="float">Số thực</option>
              <option value="date">Ngày giờ</option>
            </select>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Độ rộng</label>
            <input type="number" placeholder="Độ rộng trường" class="form-control" name="datalength"
              [(ngModel)]="target.datalength" required value="255" />
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Thứ tự hiển thị</label>
            <input type="number" placeholder="Thứ tự hiển thị, giá trị 0 nếu không hiển thị" class="form-control"
              name="show" [(ngModel)]="target.show" required value="255" />
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button type="submit" class="btn btn-primary" [disabled]="frm.invalid">
            Lưu
          </button>
          &nbsp;
          <button type="button" class="btn btn-outline-secondary" mat-dialog-close>
            Đóng
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>