import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { FeatureclassService } from '../../featureclass/featureclass.service';
import { MapAdminService } from '../mapadmin.service';
import { FeatureclassDetailModel, SysfeatureclassEntity } from '../../../../@core/data/app-gis.service';

@Component({
  selector: 'ngx-mapadmin-update',
  templateUrl: './source-update.component.html',
  styleUrls: ['./source-update.component.scss'],
})
export class SourceUpdateComponent implements OnInit {
  constructor(
    private mapService: MapAdminService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number, data: any, isEdit: boolean },
    public dialogRef: MatDialogRef<SourceUpdateComponent>,
    private featureclassService: FeatureclassService
  ) { }

  target: FeatureclassDetailModel[];
  tableName: string;
  sourceMap: string;
  baseUrl = environment.APP_GIS_URL;
  ngOnInit(): void {
    this.sourceMap = 'api';
    if (this.data.isEdit === true) {
      this.tableName = this.data.data.name;
      if (this.data.data.value.data.toLowerCase().includes("api")) {
        this.sourceMap = 'api';
      } else {
        this.sourceMap = 'json';
      }
    }
    this.loadata();

  }

  loadata() {
    this.featureclassService.getFeatureclasssByMap().subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
        if (this.data.isEdit !== true) {
          this.tableName = this.target[0].tableName;
        }
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  update() {
    this.mapService.selectOne(this.data.id).subscribe(res => {
      const mapModal = res.data;

      const newConfig = JSON.parse(mapModal.config);
      if (this.data.isEdit === true) {
        const output = {};
        for (const key in newConfig.sources) {
          if (key !== this.data.data.name) {
            output[key] = newConfig.sources[key];
          }
        }
        newConfig.sources = output;
      }
      if (newConfig.sources[this.tableName]) {
        this.commonService.toastrWarning('Sources ' + this.tableName + ' đã tồn tại')
      } else {
        newConfig.sources[this.tableName] = {
          type: 'geojson',
          data:
            this.sourceMap === 'json'
              ? `GeoJson/${this.tableName}.json`
              : `${this.baseUrl}/App/gis/api/Data/${this.tableName}/data?format=geojson`,
        };
        mapModal.config = JSON.stringify(newConfig);

        this.mapService.updateCustomMap(mapModal).subscribe((res) => {
          if (res.code === SUCCESS_RESPONE) {
            this.commonService.toastrSuccess('Thành công!');
            this.loadata();
            this.dialogRef.close(true);
          } else {
            this.commonService.toastrDanger(res.message);
          }
        });
      }
    })
  }
}
