import { Injectable } from '@angular/core';
import {
  AppHomeApiService,
  MasterModelIEnumerablePageModelViewApiResponse,
  ObjectIEnumerableApiResponse,
  PageModel,
} from 'app/@core/data/app-home.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class FilesManagerService {
  constructor(private api: AppHomeApiService) {}

  typeAttach(): number {
    return 1;
  }
}
