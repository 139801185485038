import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE, SUCCESS_NOTICE, PAGE_SIZE } from '../../../../@core/customs/constants';
import { SysAppService } from '../config-app.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SysAppCreateComponent } from '../config-app-create/config-app-create.component';
import { SysAppDetailComponent } from '../config-app-detail/config-app-detail.component';
import { SysAppUpdateComponent } from '../config-app-update/config-app-update.component';
import { TokenService } from '../../../../@core/token.service';
import { PageModel, SysappEntity } from '../../../../@core/data/app-qtht.service';

@Component({
  selector: 'ngx-config-app-index',
  templateUrl: './config-app-index.component.html',
  styleUrls: ['./config-app-index.component.scss']
})
export class SysAppIndexComponent implements OnInit {
  constructor(
    private sysAppService: SysAppService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private tokenService: TokenService
  ) { }

  pageModel: PageModel = new PageModel({
    pageSize: PAGE_SIZE,
    condition: null,
    currentPage: 1,
    search: null,
    unitcode: null,
  });

  target: any = {
    data: []
  };
  users: any;
  ngOnInit(): void {
    this.tokenService.getUserInfo().subscribe((result) => {
      this.users = result;
    });
    this.pageModel.unitcode = this.users.UnitCode;
    this.loadData();
  }

  loadData() {
    this.sysAppService.paging(this.pageModel).subscribe(result => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  changeStatus(item: SysappEntity) {
    item.fields = undefined;
    item.isNew = false;
    item.trangthai = item.trangthai === 1 ? 0 : 1;
    this.sysAppService.update(item).subscribe(result => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.loadData();
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  setCheckedForToggle(status: number) {
    if (status === 1) {
      return true;
    } else {
      return false;
    }
  }

  searchPage() {
    this.pageModel.search = this.pageModel.search;
    this.loadData();
  }

  resetPage() {
    this.pageModel.search = null;
    this.searchPage();
  }

  openCreateDialog() {
    this.dialog.open(SysAppCreateComponent, this.commonService.configDialog('40%', { testData: 'data', unitcode: this.users.UnitCode }))
      .afterClosed().subscribe(result => {
        if (result === true) {
          this.loadData();
        }
      });
  }

  detailItem(id: number) {
    this.dialog.open(SysAppDetailComponent, this.commonService.configDialog('40%', { id: id })).afterClosed().subscribe(() => { });
  }

  editItem(id: number) {
    this.dialog.open(SysAppUpdateComponent, this.commonService.configDialog('40%', { id: id })).afterClosed().subscribe(result => {
      if (result === true) {
        this.loadData();
      }
    });
  }

  deleteItem(id: number, maSysApp: string) {
    if (confirm(`Xác nhận xóa chức năng hệ thống với mã ${maSysApp} ?`)) {
      this.sysAppService.delete(id).subscribe(result => {
        if (result.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess();
          this.loadData();
        } else {
          this.commonService.toastrDanger(result.message);
        }
      });
    }
  }


}
