import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmQuangcaoService } from '../dm-quangcao.service';

@Component({
  selector: 'ngx-dm-quangcao-delete',
  templateUrl: './dm-quangcao-delete.component.html',
  styleUrls: ['./dm-quangcao-delete.component.scss'],
})
export class DmQuangcaoDeleteComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: DmQuangcaoService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number; title: string },
    private dialogRef: MatDialogRef<DmQuangcaoDeleteComponent>
  ) {}

  title: string;

  ngOnInit(): void {
    this.title = this.data.title;
  }

  delete() {
    this.service.delete(this.data.id).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess('Xoá dữ liệu thành công.');
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(res.message);
        this.dialogRef.close(false);
      }
    });
  }
}
