<form (ngSubmit)="update()">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div style="display: flex; align-items: center; justify-content: space-between">
        <div class="">Thêm nhóm cho các lớp</div>
        <div style="display: flex; justify-content: flex-end; gap: 10px"></div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <!-- <div class="col-lg-12">
          <ng-select
            style="width: 300px"
            name="parentId"
            placeholder="Chọn group cha"
            style="margin: 0 auto; margin-top: 20px"
            [(ngModel)]="parentId"
            required
          ></ng-select>
          
        </div> -->
        <div class="col-lg-12">
          <div class="form-group"
            style="display: flex; justify-content: space-between; align-items: center; margin-top: 7px">
            <label style="display: block; min-width: 200px;" class="col-form-label required">Chọn nhóm cha( Nếu không
              chọn nhóm này sẽ là nhóm cha)</label>
            <ng-select style="width: 100%;" [items]="target" bindValue="id" bindLabel="name" [(ngModel)]="parentId"
              name="tableName" placeholder="Chọn Nhóm"></ng-select>
          </div>
          <div class="form-group">
            <label class="col-form-label required">Tên nhóm</label>
            <input type="text" class="form-control" name="name" [(ngModel)]="name" required maxlength="50" />
          </div>
        </div>
        <div class="col-lg-12">
          <label class="col-form-label">Hiển thị nhóm ngoài bản đồ</label>
          <div class="input-group">
            <input type="radio" style="margin-top: auto; margin-bottom: auto; width: 20px; height: 20px" [value]="1"
              [(ngModel)]="check" id="status1" name="check" />
            &nbsp;
            <label for="status1" style="margin: 10px 10px 10px 0px">Có</label>
            <input type="radio" style="margin-top: auto; margin-bottom: auto; width: 20px; height: 20px" [value]="0"
              [(ngModel)]="check" id="status2" name="check" />
            &nbsp;
            <label for="status2" style="margin: 10px 10px 10px 0px">Không</label>
          </div>
        </div>

      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button type="submit" class="btn btn-primary">Lưu</button>
          &nbsp;
          <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>