import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmChucVuService } from '../dm-chuc-vu.service';

@Component({
  selector: 'ngx-dm-chuc-vu-delete',
  templateUrl: './dm-chuc-vu-delete.component.html',
  styleUrls: ['./dm-chuc-vu-delete.component.scss'],
})
export class DmChucVuDeleteComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: DmChucVuService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number; ten: string },
    public dialogRef: MatDialogRef<DmChucVuDeleteComponent>
  ) {}
  tenchucvu: string;
  ngOnInit(): void {
    this.tenchucvu = this.data.ten;
  }

  delete() {
    this.service.delete(this.data.id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess('Xoá dữ liệu thành công');
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
        this.dialogRef.close(false);
      }
    });
  }
}
