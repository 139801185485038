import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { ChangePasswordService } from './change-password.service';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private commonService: CommonService,
    private changePasswordService: ChangePasswordService
  ) {}

  target = {
    oldpassword: '',
    newpassword: '',
    newpasswordagain: ''
  };
  hideOrShowOldPass: boolean = false;
  hideOrShowNewPass: boolean = false;
  hideOrShowNewPassAgain: boolean = false;

  ngOnInit(): void {}

  changePassword() {
    this.changePasswordService.changePassword(this.target.oldpassword, this.target.newpassword).subscribe(rs => {
      if (rs.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(rs.message);
      }
    });
  }
}
