<div class="header-container" *ngIf="showHeader">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="" (click)="navigateHome()">Quản trị hệ thống CSDL Chuyên ngành</a>
  </div>
</div>
<div class="header-container" *ngIf="!showHeader">
  <div class="logo-container">
    <span style="font-size: 24px;font-weight:bold;">Khai thác bản đồ</span>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
  <!-- <ngx-layout-direction-switcher class="direction-switcher"></ngx-layout-direction-switcher> -->
</div>

<div class="header-container" *ngIf="showHeader">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.FullName"
        [picture]="user?.picture" (click)="selectToolbar()">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
