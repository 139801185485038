import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuComponent } from 'app/@theme/pages/menu/menu.component';
import { routes as TintucSukienRoutes } from './tintuc-sukien/tintuc-sukien-routing.module';
import { routes as FilesManagerRoutes } from './files-manager/files-manager-routing.module';
import { routes as DmLoaiBaiVietRoutes } from './dmLoai-bai-viet/dm-loai-bai-viet-routing.module';
import { routes as DmChucVuRoutingRoutes } from './dmChuc-vu/dm-chuc-vu-routing.module';
import { routes as dmLienketRoutes } from './dm-lienket/dm-lienket-routing.module';
import { routes as DmTacGiaRoutes } from './dmTacgia/dm-tac-gia-routing.module';
import { routes as DmQuangcaoRoutes } from './dm-quangcao/dm-quangcao-routing.module';
import { routes as DmTienTrinhRoutes } from './dmTien-trinh/dm-tien-trinh-routing.module';
import { routes as DmLanguageRoutes } from './dmLanguage/dm-language-routing.module';
import { routes as DmLienHeRoutes } from './dm-Lien-he/dm-lien-he-routing.module';
export const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      { path: 'tintuc-sukien', children: TintucSukienRoutes, data: { type: '01' } },
      { path: 'album', children: TintucSukienRoutes, data: { type: '02' } },
      { path: 'dau-tu', children: TintucSukienRoutes, data: { type: '03' } },
      { path: 'vanhoa-lichsu', children: TintucSukienRoutes, data: { type: '04' } },
      { path: 'danhlam-thangcanh', children: TintucSukienRoutes, data: { type: '05' } },
      { path: 'hethong-chinhtri', children: TintucSukienRoutes, data: { type: '06' } },
      { path: 'lien-ket', children: dmLienketRoutes, data: { type: '07' } },
      { path: 'quang-cao', children: DmQuangcaoRoutes, data: { type: '08' } },
      { path: 'file-manager', children: FilesManagerRoutes, data: { type: null } },
      { path: 'dmLoai-bai-viet', children: DmLoaiBaiVietRoutes, data: { type: null } },
      { path: 'dmChuc-vu', children: DmChucVuRoutingRoutes, data: { type: null } },
      { path: 'dmTac-gia', children: DmTacGiaRoutes, data: { type: null } },
      { path: 'dmTien-trinh', children: DmTienTrinhRoutes, data: { type: null } },
      { path: 'dmLanguage', children: DmLanguageRoutes, data: { type: null } },
      { path: 'dmLienhe', children: DmLienHeRoutes, data: { type: null } },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebAdminRoutingModule {}
