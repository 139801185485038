import {
  ApiResponse,
  AppQthtApiService,
  ChucVuSelectModelIEnumerableApiResponse,
  InsertUpdateModel,
  ObjectApiResponse,
  ObjectIEnumerableApiResponse,
  ObjectIEnumerablePageModelViewApiResponse,
  PageModel,
  SelectListUserGroup,
  SysGroupSelectByAppidModelListApiResponse,
  SysuserEntity,
  SysusermenuEntity,
  UpdateUserModel,
  UserDetailModelApiResponse,
  UserInsertViewModelApiResponse,
  UserUpdateDetailViewModelApiResponse,
} from '../../../@core/data/app-qtht.service';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAppService {
  constructor(private api: AppQthtApiService) {}

  paging(page: PageModel): Observable<ObjectIEnumerablePageModelViewApiResponse> {
    return this.api.appQthtApiSysUserPaging(page);
  }
  selectAll(): Observable<ObjectIEnumerableApiResponse> {
    return this.api.appQthtApiSysUserSelectAll();
  }
  updateStatus(body: SysuserEntity): Observable<ApiResponse> {
    return this.api.appQthtApiSysUserUpdate(body);
  }

  update(body: UpdateUserModel): Observable<ApiResponse> {
    return this.api.appQthtApiSysUserUpdateCustomUser(body);
  }

  delete(key: number): Observable<ApiResponse> {
    return this.api.appQthtApiSysUserDeleteCustomUser(key);
  }

  Selectformupdatedetailbyid(key: number): Observable<UserUpdateDetailViewModelApiResponse> {
    return this.api.appQthtApiSysUserSelectFormUpdateDetailById(key);
  }
  Selectforminsertbyid(): Observable<UserInsertViewModelApiResponse> {
    return this.api.appQthtApiSysUserSelectFormInsertById();
  }
  Insert(body: InsertUpdateModel): Observable<ApiResponse> {
    return this.api.appQthtApiSysUserInsertUserAndUserGroup(body);
  }
  UpdateUserGroup(body: SelectListUserGroup): Observable<ApiResponse> {
    return this.api.appQthtApiSysUserUpdateUserGroup(body);
  }

  selectByAppId(key: string, userId: number): Observable<SysGroupSelectByAppidModelListApiResponse> {
    return this.api.appQthtApiSysUserSelectByAppId(key, userId);
  }

  updateUserMenuFunction(body: SysusermenuEntity[]): Observable<ApiResponse> {
    return this.api.appQthtApiSysUserUpdateUserMenuFunction(body);
  }

  selectAllSysApp(): Observable<ObjectIEnumerableApiResponse> {
    return this.api.appQthtApiSysUserSelectAllSysApp();
  }

  getChucVuListByUnitCode(unitcode: string | undefined): Observable<ChucVuSelectModelIEnumerableApiResponse> {
    return this.api.appQthtApiDmChucVuGetChucVuListByUnitcode(unitcode);
  }

  getPhongBanListByUnitcode(unitcode: string | undefined): Observable<ChucVuSelectModelIEnumerableApiResponse> {
    return this.api.appQthtApiDmPhongBanGetPhongBanListByUnitcode(unitcode);
  }

  getSysUnitListByDinhDanhApp(dinhDanhApp: string | undefined): Observable<ChucVuSelectModelIEnumerableApiResponse> {
    return this.api.appQthtApiSysUnitGetSysUnitListByDinhDanhApp(dinhDanhApp);
  }

  getDetails(userId: number): Observable<UserDetailModelApiResponse> {
    return this.api.appQthtApiSysUserGetDetails(userId);
  }

  getInformationsForForm(id: number | undefined): Observable<ObjectApiResponse> {
    return this.api.appQthtApiSysUserGetInformationsForForm(id);
  }
}
