import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { DatasetService } from '../dataset.service';
import { SysappEntity } from 'app/@core/data/app-qtht.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ngx-dataset-detail',
  templateUrl: './dataset-detail.component.html',
  styleUrls: ['./dataset-detail.component.scss'],
})
export class DatasetDetailComponent implements OnInit {
  constructor(
    private sysAppService: DatasetService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private idThiDuaKhenThuong: { id: number },
    public dialogRef: MatDialogRef<DatasetDetailComponent>
  ) {}

  target: SysappEntity = new SysappEntity();

  ngOnInit(): void {
    this.loadData(this.idThiDuaKhenThuong.id);
  }

  loadData(id: number) {
    this.sysAppService.selectOne(id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
