import { MenuService } from 'app/@theme/pages/menu/menu.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../@core/auth.service';
import { TokenService } from '../../../@core/token.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonService, SearchService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ngx-header-dau-tu',
  styleUrls: ['./header-dau-tu.component.scss'],
  templateUrl: './header-dau-tu.component.html',
})
export class HeaderDauTuComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  search = new SearchService();
  dataOfSelect: string = null;
  lstDuAn: any[] = [];

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Thông tin tài khoản' }, { title: 'Đăng xuất' } ];

  selectDuAn: string = null;

  constructor(private sidebarService: NbSidebarService,
              private menuService: MenuService,
              private themeService: NbThemeService,
              private tokenService: TokenService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: AuthService,
              private commonService: CommonService,
              private spinner: NgxSpinnerService,
              private router: Router) {
  }

  ngOnInit() {
    this.menuService.getDanhSachDuAn().subscribe(result => {
      if (result && result.data && result.data.length !== 0) {
        this.lstDuAn = result.data;
        this.selectDuAn = this.tokenService.getProjectInvest();
      } else {
        this.menuService.selectAllDanhSachDuAn().subscribe(dataDuAn => {
          if (dataDuAn.code === SUCCESS_RESPONE) {
            this.lstDuAn = dataDuAn.data;
            this.menuService.setDanhSachDuAn(dataDuAn);
            this.selectDuAn = this.tokenService.getProjectInvest();
          }
        });
      }
    });

    this.currentTheme = this.themeService.currentTheme;
    this.tokenService.getUserInfo().subscribe((users: any) => this.user = users);
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  selectProjectInvest(maduan) {
    if (maduan) {
      this.tokenService.saveProjectInvest(maduan).subscribe(result => {
        if (result && result.code && result.code === SUCCESS_RESPONE) {
          this.reloadCurrentRoute();
        } else {
          this.commonService.toastrDanger('Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại');
          this.authService.logout();
          this.router.navigate([ '/login' ]);
        }
      });
    }
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  selectToolbar() {
    const menuService = new NbMenuService();
    let status = true;
    menuService.onItemClick().subscribe((menu) => {
      switch (menu.item.title) {
        case 'Đăng xuất':
          if (status) {
            this.authService.logout();
            this.router.navigate([ '/login' ]);
            status = false;
          }
          break;
        case 'Thông tin tài khoản':
          // if (status) {
          //   const data = {
          //     userId: this.userInfo.id,
          //     username: this.userInfo.username,
          //   };
          //   this.openDialog('updateUser', data);
          //   status = false;
          // }
          break;
      }
    });
  }

  navigateHome() {
    this.router.navigate['/gis/map'];
  }
}
