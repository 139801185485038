import { Injectable } from '@angular/core';
import {
  AppHomeApiService,
  DmLienketEntity,
  DmLienketEntityApiResponse,
  DmLienketVmIEnumerablePageModelViewApiResponse,
  TintucSukienEntityProcessInsertFileModel,
} from 'app/@core/data/app-home.service';
import {
  ApiResponse,
  ObjectIEnumerableApiResponse,
  ObjectIEnumerablePageModelViewApiResponse,
  PageModel,
} from 'app/@core/data/app-qtht.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DmLienketService {
  constructor(private api: AppHomeApiService) {}

  insert(model: DmLienketEntity): Observable<ApiResponse> {
    return this.api.appAdminApiDmLienKetInsert(model);
  }
  update(model: DmLienketEntity): Observable<ApiResponse> {
    return this.api.appAdminApiDmLienKetUpdate(model);
  }
  delete(key: number): Observable<ApiResponse> {
    return this.api.appAdminApiDmLienKetDelete(key);
  }

  SelectAll(): Observable<ObjectIEnumerableApiResponse> {
    return this.api.appAdminApiDmLienKetSelectAll();
  }
  SelectOne(key: number): Observable<DmLienketEntityApiResponse> {
    return this.api.appAdminApiDmLienKetSelectOne(key);
  }
  pagingLienket(
    body: PageModel | undefined
  ): Observable<DmLienketVmIEnumerablePageModelViewApiResponse> {
    return this.api.appAdminApiDmLienKetPagingLienKet(body);
  }


}
