
import { SysAppIndexComponent } from './config-app-index/config-app-index.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../../@theme/theme.module';
import { FormsModule } from '@angular/forms';
import { SysAppCreateComponent } from './config-app-create/config-app-create.component';
import { SysAppDetailComponent } from './config-app-detail/config-app-detail.component';
import { SysAppUpdateComponent } from './config-app-update/config-app-update.component';
import {MatSelectModule} from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NbTreeGridModule } from '@nebular/theme';
@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ThemeModule,
        MatSelectModule,
        MatSlideToggleModule,
        NbTreeGridModule
    ],
    declarations: [
        SysAppDetailComponent,
        SysAppUpdateComponent,
        SysAppIndexComponent,
        SysAppCreateComponent
    ]
})
export class SysAppModule {}
