import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE, PAGE_SIZE, SUCCESS_NOTICE } from '../../../../@core/customs/constants';
import { FeatureclassService } from '../featureclass.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeatureclassCreateComponent } from '../featureclass-create/featureclass-create.component';
import { FeatureclassUpdateComponent } from '../featureclass-update/featureclass-update.component';
import { DatasetService } from '../../dataset/dataset.service';
import { FieldIndexComponent } from '../field-index/field-index.component';
import { FeatureclassImportComponent } from '../featureclass-import/featureclass-import.component';
import { FeatureclassExportComponent } from '../featureclass-export/featureclass-export.component';
import { FeatureclassViewDataComponent } from '../featureclass-viewdata/featureclass-viewdata.component';
import { FeatureclassViewLogComponent } from '../featureclass-viewlog/featureclass-viewlog.component';
import { ExportDataModel, PageModel } from '../../../../@core/data/app-gis.service';
import { TokenService } from '../../../../@core/token.service';
import { DialogConfirmComponent } from '../../../../@theme/components/template/dialog-confirm/dialog-confirm.component';
import { ShareComponent } from '../Share-component/share.component';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'ngx-featureclass-index',
  templateUrl: './featureclass-index.component.html',
  styles: ['.furniture { width: 250px; }'],
  styleUrls: ['./featureclass-index.component.scss'],
})
export class FeatureclassIndexComponent implements OnInit {
  constructor(
    private featureclassService: FeatureclassService,
    private datasetService: DatasetService,
    private commonService: CommonService,
    private tokenService: TokenService,
    private dialog: MatDialog,
    private readonly httpClient: HttpClient,
  ) { }

  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
  });
  datasets = [];
  datasetsSession = [];
  target: any = [];
  dataFirst: any = [];
  datasetSelect: any;
  textSearch: any;
  checkUnit: boolean;
  checkPhongBan: boolean;
  ngOnInit(): void {
    this.tokenService.getUserInfo().subscribe((users: any) => {
      if (users.UnitCode === '9999') {
        this.checkUnit = true;
        this.checkPhongBan = false;
      } else {
        this.checkPhongBan = users.PhongBan ? true : false;
      }
    });
    this.loadData();
    this.loadDataSet();
  }
  loadDataSet() {
    this.datasetService.getDataset().subscribe((ds) => {
      if (ds.code === SUCCESS_RESPONE) {
        this.datasetsSession = ds.data;
      } else {
        this.commonService.toastrDanger(ds.message);
      }
    });
  }
  loadData() {
    this.datasetService.getAll().subscribe((ds) => {
      if (ds.code === SUCCESS_RESPONE) {
        this.datasets = this.dataSetTree(ds.data, null);
      } else {
        this.commonService.toastrDanger(ds.message);
      }
    });
    if (this.idDataSet === null) {
      this.getFeatures(undefined);
    } else {
      this.getFeatures(this.idDataSet)
    }

  }
  share(item) {
    this.dialog
      .open(ShareComponent, this.commonService.configDialog('40%', { item: item, type: 'FeatureClass' }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  getFeatures(datasetId) {
    this.textSearch = '';
    this.featureclassService.pagingF(this.searchModel, datasetId).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
        this.dataFirst = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
  search() {
    this.searchModel.currentPage = 1;
    this.loadData();
  }
  getDataSetName(datasetId) {
    const name = this.datasetsSession.filter(x => x.id === datasetId);
    return name.length > 0 ? name[0].name : datasetId;
  }
  dataSetTree(data, parentid) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].parentId === parentid) {
        result.push({
          name: data[i].name,
          id: data[i].id,
          items: this.dataSetTree(data, data[i].id),
        });
      }
    }
    return result;
  }
  idDataSet: any;
  filterByDataset(evt) {
    if (!evt) {
      this.commonService.toastrDanger('Vui lòng chọn chủ đề dữ liệu');
      this.getFeatures(undefined);
      this.datasetSelect = null;
      this.idDataSet = null;
    } else {
      this.getFeatures(evt.id);
      this.idDataSet = evt.id
      this.datasetSelect = evt;
    }
  }
  addFeatureClass() {
    if (!this.datasetSelect) {
      this.commonService.toastrDanger('Vui lòng chọn chủ đề dữ liệu');
      return;
    }
    this.dialog
      .open(FeatureclassCreateComponent, this.commonService.configDialog('40%', { datasetid: this.datasetSelect.id, checkUnit: this.checkUnit }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }

  addField(id: number) {
    const DIALOG_CONFIG = new MatDialogConfig();
    DIALOG_CONFIG.disableClose = true;
    DIALOG_CONFIG.width = '100%';
    DIALOG_CONFIG.height = 'auto';
    DIALOG_CONFIG.maxWidth = '100%';
    DIALOG_CONFIG.maxHeight = '100%';
    DIALOG_CONFIG.data = { featureid: id };
    this.dialog
      .open(FieldIndexComponent, DIALOG_CONFIG)
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  editItem(id: number) {
    this.dialog
      .open(FeatureclassUpdateComponent, this.commonService.configDialog('40%', { id: id, checkUnit: this.checkUnit }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }

  deleteItem(id: number, name: string) {

    this.dialog.open(DialogConfirmComponent, this.commonService.configDialog('30%', {
      title: 'Xác nhận',
      message: `Bạn có chắc chắn xóa <br><b>${name}</b> ?`,
      color: 'text-warning',
      type: 1,
      icon: 'exclamation-triangle',
      isAppend: true
    })).afterClosed().subscribe(info => {
      if (info) {
        this.featureclassService.delete(id).subscribe(res => {
          if (res.code === SUCCESS_RESPONE) {
            this.commonService.toastrSuccess(SUCCESS_NOTICE);
            this.loadData();
          } else {
            this.commonService.toastrDanger(res.message);
          }
        });
      }
    });
  }
  exportTemplate(id: number, tablename: string, name: string) {
    const ex = new ExportDataModel();
    ex.featureclassId = id;
    ex.tableName = tablename;
    const date = Date();
    const CurrDate = new ConvertDatePipe().transform(date);

    const req = new HttpRequest('POST', `${environment.APP_GIS_URL}/App/gis/api/Data/ExportTemplate`, ex, {
      responseType: 'blob',
    });
    this.httpClient
      .request<Blob>(req)
      .pipe()
      .subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            let result = event.body;
            this.blobToFile(
              result,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              CurrDate + '-' + ex.tableName + '.xlsx'
            );
          }
        },
        (error) => {
          throw error;
        }
      );
  }

  blobToFile(data: any, type: string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  importData(id: number, tablename: string) {
    this.dialog
      .open(FeatureclassImportComponent, this.commonService.configDialog('80%', { id: id, tablename: tablename }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }

  exportData(id: number, tablename: string) {
    this.dialog
      .open(FeatureclassExportComponent, this.commonService.configDialog('50%', { id: id, tablename: tablename }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  viewData(id: number, tablename: string, name: string) {
    this.dialog
      .open(FeatureclassViewDataComponent, this.commonService.configDialog('100%', { id: id, tablename: tablename, name: name }))
      .afterClosed()
      .subscribe((result) => { });
  }
  viewLog(id: number, tablename: string) {
    this.dialog
      .open(FeatureclassViewLogComponent, this.commonService.configDialog('100%', { id: id, tablename: tablename }))
      .afterClosed()
      .subscribe((result) => { });
  }
  truncateData(id: number, tablename: string) {
    if (confirm(`Xác nhận xóa toàn bộ dữ liệu của lớp ${name} ?`)) {
      this.featureclassService.truncate(id).subscribe((result) => {
        if (result.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess();
          this.loadData();
        } else {
          this.commonService.toastrDanger(result.message);
        }
      });
    }
  }
}
