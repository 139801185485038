import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmTienTrinhService } from '../dm-tien-trinh.service';

@Component({
  selector: 'ngx-dm-tien-trinh-delete',
  templateUrl: './dm-tien-trinh-delete.component.html',
  styleUrls: ['./dm-tien-trinh-delete.component.scss'],
})
export class DmTienTrinhDeleteComponent implements OnInit {
  constructor(
    private service: DmTienTrinhService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number; name: string },
    private dialogRef: MatDialogRef<DmTienTrinhDeleteComponent>
  ) {}
  name: string;
  ngOnInit(): void {
    this.name = this.data.name;
  }

  delete() {
    this.service.delete(this.data.id).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess('Xoá dữ liệu thành công.');
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
}
