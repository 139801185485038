import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DmChucVuIndexComponent } from './dm-chuc-vu-index/dm-chuc-vu-index.component';

export const routes: Routes = [
  {
      path: '',
      children: [
          { path: '', component: DmChucVuIndexComponent }
      ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DmChucVuRoutingModule { }
