<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <h5 class="mb-0">Quản trị thư mục/tệp</h5>
            </div>
          </div>
         
          <br />
          <div class="row">
            <div class="col-md-4 col-sm-12 col-xs-12 col-lg-4">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                  <div class="input-group">
                    <button
                      class="btn btn-primary mr-1"
                      type="button"
                      title="Thêm thư mục"
                      (click)="addFolder()"
                    >
                    <i class="fas fa-plus fa-sm"></i>
                    </button>
                    <button
                      class="btn btn-outline-secondary mr-1"
                      type="button" title="Sửa tệp được chọn"
                      (click)="editFolder()"
                    >
                    <i class="fas fa-edit fa-sm"></i>
                    </button>
                    <button
                      class="btn btn-outline-secondary mr-1"
                      type="button" title="Sao chép thư mục"
                      (click)="copyFolder()"
                    >
                    <i class="fas fa-copy fa-sm"></i>
                    </button>
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      title="Xóa tệp được chọn"
                      (click)="deleteFolder()"
                    >
                    <i class="fas fa-trash fa-sm"></i>
                    </button>
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      title="Chia sẻ tệp cho phòng ban"
                      (click)="share()"
                      *ngIf="!checkPhongBan"
                    >
                    <i class="fa-solid fa-share"></i>
                    </button>
                  </div>
                </div>
              </div>
              <kendo-treelist
              [kendoTreeListFlatBinding]="data"
              #directive="kendoTreeListFlatBinding"
              kendoTreeListExpandable
              kendoTreeListSelectable
              [selectable]="settings"
              [(selectedItems)]="selected"
              idField="id"
              parentIdField="parentID"
              (cellClick)="changeFolder($event)"
              >
              <kendo-treelist-column
                [expandable]="true"
                field="name"
                title="Thư mục"
              >
              </kendo-treelist-column>
              </kendo-treelist>
            </div>
            <div class="col-md-8 col-sm-12 col-xs-12 col-lg-8">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                  <div class="input-group">
                    <button
                      class="btn btn-primary"
                      type="button"
                      (click)="uploadFile()"
                    >
                      <i class="fas fa-plus fa-sm"></i>&nbsp;Tải tệp lên
                    </button>
                    &nbsp;&nbsp;
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Tìm kiếm tên tệp"
                      aria-label="Tìm kiếm tên tệp"
                      aria-describedby="basic-addon2"
                      [(ngModel)]="pageModel.search"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-info" type="button" (click)="searchPage()">
                        <i class="fas fa-search fa-sm"></i>&nbsp;Tìm kiếm
                      </button>
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        (click)="resetPage()"
                      >
                        <i class="fas fa-sync-alt fa-sm"></i>&nbsp;Làm mới
                      </button>
                      <button class="btn btn-outline-secondary" type="button">
                        <i class="fas fa-reply fa-sm"></i>&nbsp;Quay lại
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th class="text-center" style="width: 50px;">STT</th>
                    <th>Tên tệp</th>
                    <th>Kích thước</th>
                    <!-- <th>Thời gian</th> -->
                    <th class="text-center" style="width: 115px;">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of target.data; let i = index">
                    <td class="text-center">
                      {{ i +1 }}
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ byteToSize(item.length) }}</td>
                    <!-- <td>{{ item.createdate }}</td> -->
                    <td class="text-center">
                      <button style="padding:5px !important;" title="Sửa tên tệp" class="btn btn-sm" (click)="editItem(item.id, item.name)"><i class="fa fa-edit"></i></button>
                      <button style="padding:5px !important;" title="Xóa tệp dữ liệu" class="btn btn-sm" (click)="deleteItem(item.id, item.name)"><i class="fa fa-trash"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="target?.recordsCount > 0" class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                    <div style="float: left;">
                        Tổng số bản ghi: {{target?.recordsCount}}
                    </div>
                    <div style="float: right;">
                        <ngx-pagination (pageChange)="pageModel.currentPage = $event;search(this.parentId)" (pageSize)="pageModel.pageSize = $event;search(this.parentId)" [currentPage]="pageModel.currentPage" [totalPages]="target.totalPage">
                        </ngx-pagination>
                    </div>
                </div>
            </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>