<div class="row" style="width: 100%;height: 100%;">
  <div class="col-md-12 selectTop" *ngIf="this.UrlParam.includes('/gis')">
    <ng-select style="width: 300px;" [items]="lstMap" bindValue="id" bindLabel="name" (change)="ChangeMap()"
      [(ngModel)]="mapId" name="mapId" placeholder="Chọn Map"></ng-select>
  </div>

  <div class="col-md-12 all">

    <div class="sqh-hdr d-flex justify-content-center">
      <div class="sqh-hdr__wrap">
        <div class="hdr-lg">
          <a href="/">
            <img src="/assets/images/banner/LOGOCB.png" alt="Guland" style="width: 50px;">
          </a>
          <a href="/">
            <span>NỀN TẢNG BẢN ĐỒ TỈNH CAO BẰNG</span>
          </a>

        </div>
        <div class="hdr-mn__it">
          <a class="hdr-mn__lk " (click)="sideBar()">
            <span class="mn-lk-ic">
              <svg viewBox="0 0 24 24">
                <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"></path>
              </svg>
            </span>
          </a>
        </div>
        <div class="hdr-mn">
          <h6 style="font-weight: bold;">Lĩnh vực {{title}}</h6>
        </div>
      </div>

    </div>
    <!-- <div class="sdb-picker-map__btns d-flex justify-content-center">
      <ul id="TabNav-SqhSearch" class="nav nav-tabs nav-sqh" role="tablist">
        <li class="nav-item"><a class="nav-link" role="tab" data-toggle="tab" aria-selected="false">Khu vực</a></li>
        <li class="nav-item"><a class="nav-link" role="tab" data-toggle="tab" id="dia-diem-search" aria-selected="false"
            (click)="OpenSearch('dia-diem-search')">Địa điểm</a></li>
        <li class="nav-item"><a class="nav-link" id="goc-ranh-search" role="tab" data-toggle="tab" aria-selected="true"
            (click)="OpenSearch('goc-ranh-search')">Góc ranh</a></li>
        <li class="nav-item"><a class="nav-link" id="to-thua-search" role="tab" data-toggle="tab" aria-selected="true"
            (click)="OpenSearch('to-thua-search')">Tìm kiếm</a></li>
      </ul>
    </div> -->
    <div id="map" style="height:100%;">
      <div *ngIf="contentMeasure !== ''" class="tienich">
        <div class="input-group" style="background-color: #fff;">
          <label class="mt-1 ml-1 p-1" [innerHTML]="contentMeasure"></label>
        </div>
      </div>
      <div class="soluongdoituong">
        <div class="input-group" style="background-color: #fff;">
          <label class="mt-1 ml-1 p-1" [innerHTML]="contentNumberCount"></label>
        </div>
      </div>
      <div class="parent-container">
        <div *ngFor="let field of targetAll; let i = index">
          <kendo-window [id]="'lam-' + i" class="child-window" [top]="390" [left]="0" [width]="1000"
            *ngIf="showTableAttribute" (stateChange)="onStateChange(i,$event)" title="{{field.name}}" class="p-0"
            [keepContent]="true" (close)="onCloseTableAttribute(field.tableName)">
            <div class="row p-2" style="border-bottom: 1px solid #edf1f7;padding: 5px 20px !important;width: 100%;">
              <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 p-0">
                <div class="input-group">
                  <label class="ml-1 mt-2 pr-1">Tìm theo từ khóa:</label>
                  <input type="text" class="form-control" placeholder="Tìm kiếm theo từ khóa" [(ngModel)]="textSearch"
                    (keyup.enter)="onSearchTableAttribute()">&nbsp;
                  <button class="btn btn-primary" type="button" title="Tìm kiếm" (click)="onSearchTableAttribute()">
                    <i class="fas fa-search fa-sm"></i>&nbsp;
                  </button>
                  &nbsp;
                  <ng-select [items]="lstDVHCCH" bindValue="madonvi" bindLabel="ten" [(ngModel)]="maHuyenDsDt"
                    name="maHuyen" placeholder="Chọn đơn vị hành chính cấp huyện" style="margin: 0 auto;width: 200px"
                    (change)="changeHuyenbyXa()">
                  </ng-select>
                  &nbsp;
                  <ng-select [items]="lstDVHCCXDSL" bindValue="madonvi" bindLabel="ten" [(ngModel)]="maXaDsDt"
                    name="maXa" placeholder="Chọn đơn vị hành chính cấp xã" style="margin: 0 auto;width: 200px"
                    (change)="loadHuyenXa()">
                  </ng-select>
                  &nbsp;
                  <ngx-btn-refresh (click)="SearchModel.text = null;SearchModel.status = null; search();">
                  </ngx-btn-refresh>
                  <button class="btn" type="button" title="Xuất Excel" (click)="export('excel')">
                    <i class="fas fa-file-excel fa-sm"></i>&nbsp;
                  </button>
                  <button class="btn" type="button" title="Thêm mới" (click)="openCre()">
                    <i class="fas fa-plus-circle fa-sm"></i>&nbsp;
                  </button>
                  <button class="btn" type="button" title="Xuất Shapefile" (click)="export('shp')">
                    <i class="fas fa-file fa-sm"></i>&nbsp;
                  </button>
                </div>

              </div>
            </div>
            <div style="height: 64%;overflow-y:auto;width: 100%;">
              <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12" class="height">
                <table class="table table-bordered table-hover m-0">
                  <thead>
                    <tr>
                      <th class="text-center" style="width: 50px;">STT</th>
                      <th *ngFor="let item of field.fields; let i = index">{{item.name}}</th>
                      <th style="width:100px;text-align: center;">#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of field.data; let i = index">
                      <td class="text-center">
                        {{ i + 1 }}
                      </td>
                      <td *ngFor="let index of field.fields;">
                        {{item[index.fieldname.toLowerCase()]}}
                      </td>
                      <td style="text-align: center;">
                        <button style="padding:5px !important;" title="Xem thông tin đối tượng" class="btn btn-sm"
                          (click)="viewDetail(item)"><i class="fa fa-eye"></i></button>
                        <button style="padding:5px !important;" title="Xem đối tượng trên bản đồ" class="btn btn-sm"
                          (click)="zoomTo(item)"><i class="fa fa-map-marker"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row col" style="margin-top: 10px;">
              <div *ngIf="targetNew.data.recordsCount > 0">
                <div style="float: left;">
                  Tổng số bản ghi: {{targetNew.data.recordsCount}}
                </div>
                <div style="float: right;">
                  <ngx-pagination (pageChange)="SearchModel.page = $event;loadDataNew()"
                    (pageSize)="SearchModel.limit = $event;loadDataNew()" [currentPage]="SearchModel.page"
                    [totalPages]="targetNew.data.totalPage">
                  </ngx-pagination>
                </div>
              </div>
            </div>
          </kendo-window>
        </div>
      </div>
      <!-- <div class="draggable" (mousedown)="onMouseDown($event)">
        <div *ngIf="showTableAttribute" class="tableattribute p-0" id="full">
          <div class="row p-2" style="border-bottom: 1px solid #edf1f7 ;width: 100%;padding: 5px 20px !important;">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 p-0">
              <div class="input-group">
                <label style="flex: auto; font-size: 1.5em;padding-top:10px;font-weight: bold;">Danh sách đối tượng
                  của
                  {{this.name}}</label>
                <button class="btn" type="button" title="Đóng" (click)="onCloseTableAttribute()">
                  <i class="fas fa-times fa-sm"></i>&nbsp;
                </button>
                <button class="btn" type="button" title="Full screen" (click)="openFullscreen()">
                  <i class="fas fa-expand"></i>&nbsp;
                </button>
              </div>
            </div>
          </div>
          <div class="row p-2" style="border-bottom: 1px solid #edf1f7;padding: 5px 20px !important;width: 100%;">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 p-0">
              <div class="input-group">
                <label class="mt-2 pr-1">Tìm theo</label>
                <select class="form-control" (change)="chonTimKiemKhongGian($event.target.value)">
                  <option selected>Không lựa chọn</option>
                  <option value="radius">Bán kính</option>
                  <option value="polygon">Vùng bất kỳ</option>
                </select>
                <label class="ml-1 mt-2 pr-1">Tìm theo từ khóa:</label>
                <input type="text" class="form-control" placeholder="Tìm kiếm theo từ khóa" [(ngModel)]="textSearch"
                  (keyup.enter)="onSearchTableAttribute()">&nbsp;
                <button class="btn btn-primary" type="button" title="Tìm kiếm" (click)="onSearchTableAttribute()">
                  <i class="fas fa-search fa-sm"></i>&nbsp;
                </button>
                <ngx-btn-refresh (click)="SearchModel.text = null;SearchModel.status = null; search();">
                </ngx-btn-refresh>
                <button class="btn" type="button" title="Xuất Excel" (click)="export('excel')">
                  <i class="fas fa-file-excel fa-sm"></i>&nbsp;
                </button>
                <button class="btn" type="button" title="Thêm mới" (click)="openCre()">
                  <i class="fas fa-plus-circle fa-sm"></i>&nbsp;
                </button>
                <button class="btn" type="button" title="Xuất Shapefile" (click)="export('shp')">
                  <i class="fas fa-file fa-sm"></i>&nbsp;
                </button>

              </div>

            </div>
          </div>
          <div style="height: 64%;overflow-y:auto;width: 100%;" class="row" id="table">
            <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12" id="height" class="height">
              <table class="table table-bordered table-hover m-0">
                <thead>
                  <tr>
                    <th class="text-center" style="width: 50px;">STT</th>
                    <th *ngFor="let item of fields; let i = index">{{item.name}}</th>
                    <th style="width:100px;text-align: center;">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of target; let i = index">
                    <td class="text-center">
                      {{ i + 1 }}
                    </td>
                    <td *ngFor="let index of fields;">
                      {{item[index.fieldname.toLowerCase()]}}
                    </td>
                    <td style="text-align: center;">
                      <button style="padding:5px !important;" title="Xem thông tin đối tượng" class="btn btn-sm"
                        (click)="viewDetail(item)"><i class="fa fa-eye"></i></button>
                      <button style="padding:5px !important;" title="Xem đối tượng trên bản đồ" class="btn btn-sm"
                        (click)="zoomTo(item)"><i class="fa fa-map-marker"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row col" style="margin-top: 10px;">
              <div *ngIf="targetNew.data.recordsCount > 0">
                <div style="float: left;">
                  Tổng số bản ghi: {{targetNew.data.recordsCount}}
                </div>
                <div style="float: right;">
                  <ngx-pagination (pageChange)="SearchModel.page = $event;loadDataNew()"
                    (pageSize)="SearchModel.limit = $event;loadDataNew()" [currentPage]="SearchModel.page"
                    [totalPages]="targetNew.data.totalPage">
                  </ngx-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="base-map base-map-visible" id="control-basemap">
        <div class="p-2">
          <div class="d-flex align-items-center px-1" *ngFor="let item of baseLayer; let i = index">
            <div class="d-flex flex-column flex-grow-1">
              <a href="javascript:;" (click)="batTatBanDoNen(item.id)"
                class="text-dark-75 text-hover-primary font-weight-bold font-size-lg">{{item.name}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div style="height: 350px" *ngIf="chart" class="tableattributeCharts p-0">
      <div class="row p-2" style="border-bottom: 1px solid #edf1f7; width: 100%; padding: 5px 20px !important">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 p-0">
          <div class="input-group">
            <label style="flex: auto; font-size: 1.5em; padding-top: 10px; font-weight: bold">
              {{namChart}}
            </label>
            <ng-select *ngIf="checkFilter" [items]="lstDVHCCHBD" bindValue="madonvi" bindLabel="ten"
              [(ngModel)]="maHuyenBD" name="maHuyenBD" placeholder="Chọn đơn vị hành chính cấp huyện"
              style="margin: 0 auto;width: 200px" (change)="loadXaBieuDo()"></ng-select>
            &nbsp;
            &nbsp;
            <ng-select *ngIf="lstDVHCCXBD.length > 0" [items]="lstDVHCCXBD" bindValue="madonvi" bindLabel="ten"
              [(ngModel)]="maXaBD" name="maXaBD" placeholder="Chọn đơn vị hành chính cấp xã"
              style="margin: 0 auto;width: 200px " (change)="filterByXaBieuDo()" change></ng-select>
            &nbsp;
            &nbsp;
            <ng-select *ngIf="checkYear" [items]="lstYear" bindValue="value" bindLabel="key" [(ngModel)]="yearValue"
              name="yearValue" placeholder="Chọn năm" style="margin: 0 auto;width: 150px " (change)="chosseYear()"
              change></ng-select>
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            <button class="btn" type="button" title="Đóng" (click)="closeCharts()">
              <i class="fas fa-times fa-sm"></i>&nbsp;
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="checkMapId === 361">

        <kendo-chart *ngIf="bieuDoName === 'bieudogiatrisxnn'">
          <kendo-chart-series-defaults type="column">
            <kendo-chart-series-defaults-labels format="{0} Tr.đồng"> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="groupedData.tongDanso"> </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam"> </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart>
        <kendo-chart *ngIf="bieuDoName === 'bieudogiatrisxln'">
          <kendo-chart-series-defaults type="column">
            <kendo-chart-series-defaults-labels format="{0} Tr.đồng"> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="groupedData.tongDanso" type="column"> </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam"> </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart>
        <kendo-chart *ngIf="bieuDoName === 'bieudogiatrisxlts'">
          <kendo-chart-series-defaults type="column">
            <kendo-chart-series-defaults-labels format="{0} Tr.đồng"> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="groupedData.tongDanso" type="column"> </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam"> </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart>

        <div
          *ngIf="bieuDoName === 'bieudoccgiatrisxnn' || bieuDoName === 'bieudoccgiatrisxln' || bieuDoName === 'bieudoccgiatrisxts'"
          style="text-align: center;">
          <span *ngIf="dataChartPie.length === 0"> Không có dữ liệu</span>
        </div>
        <kendo-chart *ngIf="bieuDoName === 'bieudoccgiatrisxnn'">
          <kendo-chart-legend position="bottom"></kendo-chart-legend>
          <kendo-chart-series-defaults>
            <kendo-chart-series-defaults-labels> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="dataChartPie" type="pie" field="value" categoryField="ten"
              [labels]="{ visible: true, content: labelContent1 }"> </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
        <kendo-chart *ngIf="bieuDoName === 'bieudoccgiatrisxln'">
          <kendo-chart-legend position="bottom"></kendo-chart-legend>
          <kendo-chart-series-defaults>
            <kendo-chart-series-defaults-labels> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="dataChartPie" type="pie" field="value" categoryField="ten"
              [labels]="{ visible: true, content: labelContent1 }"> </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
        <kendo-chart *ngIf="bieuDoName === 'bieudoccgiatrisxts'">
          <kendo-chart-legend position="bottom"></kendo-chart-legend>
          <kendo-chart-series-defaults>
            <kendo-chart-series-defaults-labels> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="dataChartPie" type="pie" field="value" categoryField="ten"
              [labels]="{ visible: true, content: labelContent1 }"> </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
      </div>
      <div *ngIf="checkMapId === 390">
        <kendo-chart *ngIf="bieuDoName !== 'bddstheokv'">
          <kendo-chart-series-defaults type="column">
            <kendo-chart-series-defaults-labels [format]="0"> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="groupedData.tongDanso"> </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam"> </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart>
        <kendo-chart *ngIf="bieuDoName === 'bddstheokv'">
          <kendo-chart-series-defaults type="column">
            <kendo-chart-series-defaults-labels [format]="0"> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="groupedData.tongDanso" type="bar"> </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam"> </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart>
      </div>
      <div *ngIf="checkMapId === 391">
        <div style="text-align: center;"><span *ngIf="dataChartPie.length === 0"> Không có dữ liệu</span></div>
        <kendo-chart *ngIf="bieuDoName === 'BIEUDOCHUYENDETSXCN'">
          <kendo-chart-series-defaults type="column">
            <kendo-chart-series-defaults-labels [format]="0"> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="groupedData.tongDanso"> </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam"> </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart>
        <kendo-chart *ngIf="bieuDoName === 'bieudochisosanxuatcn'">
          <kendo-chart-legend position="bottom"></kendo-chart-legend>
          <kendo-chart-series-defaults>
            <kendo-chart-series-defaults-labels> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="dataChartPie" type="pie" field="value" categoryField="ten"
              [labels]="{ visible: true, content: labelContent }"> </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
        <kendo-chart *ngIf="bieuDoName === 'bieudotongsocososanx'">
          <kendo-chart-series-defaults type="column">
            <kendo-chart-series-defaults-labels format="{0} Cơ sở"> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam">
            </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
          <kendo-chart-series>
            <kendo-chart-series-item type="column" [data]="groupedData.tongDanso">
            </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
        <kendo-chart *ngIf="bieuDoName === 'bieudosllaodongvsngk'">
          <kendo-chart-series>
            <kendo-chart-series-item [data]="groupedData.tongDanso" type="bar"> </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam">
              <kendo-chart-category-axis-item-label>
                <kendo-chart-category-axis-item-label-content [font]="{ size: '8px' }">
                </kendo-chart-category-axis-item-label-content>
              </kendo-chart-category-axis-item-label>
            </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
          <kendo-chart-tooltip [shared]="true"></kendo-chart-tooltip>
          <kendo-chart-legend position="bottom"></kendo-chart-legend>
        </kendo-chart>
      </div>
      <div *ngIf="checkMapId === 394">
        <kendo-chart
          *ngIf="bieuDoName === 'bieudomamnon' || bieuDoName === 'bieudophothong' || bieuDoName === 'bieudothuongxuyen' || bieuDoName === 'bieudotrungcap' || bieuDoName === 'bieudodaihoc' || bieuDoName === 'bieudogiaoducdaotao'">
          <kendo-chart-series-defaults type="column">
            <kendo-chart-series-defaults-labels [format]="0"> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="groupedData.tongDanso"> </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam"> </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart>
      </div>
      <div *ngIf="checkMapId === 393">
        <kendo-chart
          *ngIf="bieuDoName === 'bieudokhambenh' || bieuDoName === 'bieudogiuongbenh' || bieuDoName === 'bieudonhanluc' || bieuDoName === 'bieudoantoan' || bieuDoName === 'bieudongansach' || bieuDoName === 'bieudoduoc'">
          <kendo-chart-series-defaults type="column">
            <kendo-chart-series-defaults-labels [format]="0"> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="groupedData.tongDanso"> </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam"> </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart>
      </div>
      <div *ngIf="checkMapId === 392">
        <kendo-chart>
          <kendo-chart-series-defaults type="column">
            <kendo-chart-series-defaults-labels [format]="loadLabelTN(bieuDoName)">
            </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>

          <kendo-chart-series>
            <kendo-chart-series-item [data]="groupedData.tongDanso" type="bar"> </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam"> </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart>
      </div>
      <div *ngIf="checkMapId === 69">
        <kendo-chart
          *ngIf="bieuDoName === 'bieudothethao' || bieuDoName === 'bieudodisan' || bieuDoName === 'bieudohoatdongdulich' || bieuDoName === 'bieudonanglucdulich' || bieuDoName === 'bieudovaitrodulich' || bieuDoName === 'bieudothuvien' || bieuDoName === 'bieudonghethuat' || bieuDoName === 'bieudodienanh' || bieuDoName === 'bieudosunghiepvanhoa' || bieuDoName === 'bieudomythuat'">
          <kendo-chart-series-defaults type="column">
            <kendo-chart-series-defaults-labels [format]="0"> </kendo-chart-series-defaults-labels>
          </kendo-chart-series-defaults>
          <kendo-chart-series>
            <kendo-chart-series-item [data]="groupedData.tongDanso"> </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="groupedData.nam"> </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart>
      </div>
    </div>
    <div class="styleBieuDo bieudo-visible" id="control-bieudo">
      <ng-select style="width: 300px" [items]="listBieuDoChil" bindValue="tablename" bindLabel="name"
        [(ngModel)]="bieuDoName" name="maHuyen" placeholder="Chọn biểu đồ" style="margin: 0 auto"
        (change)="LoadDataBieuDo()"></ng-select>
    </div>
    <div class="sdb-content-picker" id="toggle-side-menu">
      <div class="sdb-picker-menu-logo">
        <!-- <a href="/">
        <img src="/assets/images/banner/LOGOCB.png" alt="Cao Bằng" style="width: 50px;">
        <span>NỀN TẢNG BẢN ĐỒ</span>
      </a> -->
        <!-- <a style="color: white ;margin-left: 15px" routerLink="/app-home/tintuc">Tin tức dữ liệu chuyên ngành</a> -->
        <div style="margin-top: 15px;">
          <div *ngIf="!isLogin; else notLogin" style="text-align: center;">
            <a style="width: 50%;"><button type="button" (click)="LogToAdmin()" class="btn btn-light"
                style="margin-right: 10px">Đăng
                nhập</button></a>
            <a style="width: 50%;"><button type="button" class="btn btn-light" style="margin-right: 10px">Đăng
                ký</button></a>
          </div>
          <ng-template #notLogin>
            <div style="text-align: center;">
              <a style="width: 50%;"><button type="button" class="btn btn-light"
                  style="margin-right: 10px;color: blue;font-weight: bold;" (click)="LogToAdmin()">Quản
                  trị</button></a>
              <a style="width: 50%;"><button type="button" class="btn btn-light" style="margin-right: 10px"
                  (click)="LogOut()">Đăng
                  xuất</button></a>
            </div>

          </ng-template>
        </div>
      </div>
      <div class="layer">
        <kendo-treeview [nodes]="arr_layer" textField="text" class="treelayer" childrenField="items" [filterable]="true"
          kendoTreeViewHierarchyBinding kendoTreeViewExpandable checkBy="value"
          [(checkedKeys)]="arrCheckedKeys_ActiveLayer" [isChecked]="isChecked_KendoTree" [expandBy]="'value'"
          [(expandedKeys)]="arrExpandedKeys_KendoTree"
          [kendoTreeViewCheckable]="{checkChildren: true,checkParents: true,checkOnClick: false}"
          (checkedChange)="checkedChange_KendoTree($event)">
          <ng-template kendoTreeViewNodeTemplate let-dataItem>
            <span (click)="viewData(dataItem)" style="cursor: pointer;">
              <span class="icon-treeview k-icon" *ngIf="dataItem.items.length > 0"><i style="color: #3366ff;"
                  class="fas fa-folder"></i></span>
              <span class="icon-treeview k-icon" *ngIf="dataItem.items.length === 0"><a href="javascript:;"
                  title="Xem dữ liệu"><i class="fas fa-table"></i></a></span>
              {{ dataItem.text }}
            </span>
          </ng-template>
        </kendo-treeview>
      </div>

    </div>
    <div class="l-sdb-form " id="create-check-plan-content-side">
      <div class="container">
        <div class="row justify-content-center" style="width: 100%;">
          <div class="col-12">
            <div class="closeSide" (click)="closeSideBar()">
              <i class="fa fa-window-close"></i>
            </div>
          </div>
          <div class="col-12">
            <div class="l-sdb-form__wrap">
              <div class="l-sdb-form__top">
                <div class="col-lg-12 bt">
                  <div class="row sbAll">
                    <div class="dataSide">
                      <div>
                        <h5 style="font-weight: bold;">{{obName}}</h5>
                      </div>
                      <div *ngFor="let item of listData">
                        <h6> <span style="font-weight: bold;">{{item.name}}</span> : {{item.value}}</h6>
                      </div>
                      <div *ngIf="listData.length <= 0">
                        <h5 style="color: red;">Không có dữ liệu</h5>
                      </div>
                    </div>
                    <div class="form-mbtn-space">
                      <div class="form-mbtn">
                        <div class="options" style="display: flex">
                          <a href="{{href}}" target="_blank" class=" btn-sm  btn-outline btndd">
                            <i class="fas fa-directions  mr-2"></i>
                            <span> Đường đi</span></a>
                          <a id="btn-add-video" (click)="openCreate()" class=" btn-sm  btn-outline btndd"><i
                              class="fa fa-upload mr-2"></i> Tạo địa
                            điểm</a>
                          <a id="btn-add-video" (click)="openCreateImage()" class=" btn-sm  btn-outline btndd"><i
                              class="fa fa-upload mr-2"></i> Thêm ảnh & video</a>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="c-form-bds">
                      <div class="c-form-bds__top">
                        <h3 class="c-form-bds__tle">Hình ảnh &amp; Video</h3>
                      </div>
                      <div class="c-form-bds__group">

                        <div id="demo" class="carousel slide" data-bs-ride="carousel" *ngIf="LstImgPath.length > 0"
                          data-pause="false">

                          <!-- The slideshow/carousel -->
                          <div class="carousel-inner">
                            <div *ngFor="let items of LstImgPath">
                              <div class="carousel-item active" *ngIf="items.id === imageId">
                                <img src="data:image/jpeg;base64,{{items.filepath}}"
                                  style="width: 100%; height: 235px; padding: 5px;" alt="IMG" class="d-block w-100" />
                              </div>
                              <div class="carousel-item" *ngIf="items.id !== imageId">
                                <img src="data:image/jpeg;base64,{{items.filepath}}"
                                  style="width: 100%;height: 235px; padding: 5px;" alt="IMG" class="d-block w-100" />
                              </div>
                            </div>
                          </div>


                          <!-- Left and right controls/icons -->
                          <button class="carousel-control-prev" type="button" data-bs-target="#demo"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon"></span>
                          </button>
                          <button class="carousel-control-next" type="button" data-bs-target="#demo"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon"></span>
                          </button>
                        </div>

                        <div id="demo1" class="carousel slide" data-bs-ride="carousel" *ngIf="LstVideo.length > 0">

                          <!-- The slideshow/carousel -->
                          <div class="carousel-inner">
                            <div *ngFor="let items of LstVideo">
                              <!-- <div *ngIf="items"> -->
                              <div class="carousel-item active" *ngIf="items.id === videoId">
                                <video [src]="items.path" height="200" width="100%" controls></video> <br />
                              </div>
                              <div class="carousel-item" *ngIf="items.id !== videoId">
                                <video [src]="items.path" height="200" width="100%" controls></video> <br />
                              </div>
                              <!-- </div> -->
                            </div>
                          </div>


                          <!-- Left and right controls/icons -->
                          <button class="carousel-control-prev" type="button" data-bs-target="#demo1"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon"></span>
                          </button>
                          <button class="carousel-control-next" type="button" data-bs-target="#demo1"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div id="TabContent-SqhSearch-3" class="tab-pane fade active show" role="tabpanel" *ngIf="show">
      <div class="sdb-picker-map__search-form">
        <div class="c-form-bds" data-select2-id="select2-data-4138-mc9v">
          <div class="c-form-bds__htext">
            <i>Tìm kiếm dữ liệu</i>
          </div>
          <hr>
        </div>
        <form>
          <div class="row" style="width: 100%;">

            <div class="col-lg-6">
              <div class="form-group">
                <label class="col-form-label required">Nhập từ khoá</label>
                <input type="text" class="form-control" name="diaDanh" [(ngModel)]="textSearch" required maxlength="255"
                  aria-required="true" />
              </div>
            </div>
            <!-- <div class="col-lg-6">
              <div class="form-group">
                <label class="col-form-label required">Số thửa</label>
                <input type="text" class="form-control" name="soThua" [(ngModel)]="soThua" required maxlength="255"
                  aria-required="true" />
              </div>
            </div> -->
            <div class="col-lg-6">
              <div class="form-group">
                <label class="col-form-label">Chọn đối tượng tìm kiếm</label>
                <ng-select [items]="lstLayer" bindValue="tableName" bindLabel="name" name="tableName"
                  placeholder="Chọn đối tượng" [(ngModel)]="tableName" appendTo="Body"></ng-select>
              </div>
            </div>

            <div class="form-group-submit">

              <button class="btn btn-primary btn-submit" type="submit" (click)="SearchData()">
                <i class="fa fa-search" aria-hidden="true"></i>
                <span>Tìm kiếm</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div id="TabContent-SqhSearch-2" class="tab-pane1 fade active show" role="tabpanel" *ngIf="show2">
      <div class="c-form-bds__group">
        <form id="form-ranh">
          <input type="hidden" name="type" value="form-ranh">
          <div class="form-group">
            <label class="form-label">Quét tọa độ góc ranh từ ảnh chụp</label>
          </div>
          <hr>
          <div class="form-upload-boundary">
            <div class="form-upload-boundary__image">
              <div class="form-upload-boundary__image-wrap">
                <div class="cropper-container cropper-bg" touch-action="none" style="width: 200px; height: 100px;">
                  <div class="cropper-wrap-box">
                    <div class="cropper-canvas"
                      style="width: 169.014px; height: 100px; transform: translateX(15.493px);">
                      <img src="https://guland.vn/bds/img/vi-du-toa-do-goc-ranh.webp" alt="The image to crop"
                        class="cropper-hide" style="width: 169.014px; height: 100px; transform: none;">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-upload-boundary__action">
              <label class="btn btn-outline-secondary">
                <input type="file" value="" class="d-none" id="BoundaryUpload-Input" name="image">
                <i class="fa fa-camera mr-2"></i>Chọn ảnh
              </label>
              <a href="#" id="scan" class="btn btn-outline-primary"><i class="fa-regular fa-barcode-read mr-2">
                </i>Quét tọa độ</a>
            </div>
          </div>
          <hr>
          <div class="form-group">
            <label class="form-label">Điền toạ độ góc ranh trên sổ đỏ hoặc sổ
              hồng</label>
            <div class="form-add-codn">
              <div class="form-add-codn__rows" id="form-add-points">
                <div class="row align-items-center row--thead">
                  <div class="col">
                    <div class="input-th">Toạ độ X</div>
                  </div>
                  <div class="col">
                    <div class="input-th">Toạ độ Y</div>
                  </div>
                </div>
                <div class="row align-items-center" id="clone-point" style="display: none">
                  <div class="col">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text cnt-point">1</span>
                      </div>
                      <input type="text" name="x[]" class="form-control x-point" placeholder="xxxxxxx.xx">
                      <input type="text" name="y[]" class="form-control y-point" placeholder="yyyyyyy.yy">
                      <div class="input-group-append">
                        <button class="btn btn-danger btn-delete-point" type="button">Xóa
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="points-input">
                  <div class="row align-items-center row-points">
                    <div class="col">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">1</span>
                        </div>
                        <input type="text" class="form-control" placeholder="xx, xxxxxxx" name="x[]">
                        <input type="text" class="form-control" placeholder="yy, yyyyyyy" name="y[]">
                        <div class="input-group-append">
                          <button class="btn btn-danger btn-delete-point" type="button">Xóa
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-add-codn__btns">
                <button class="btn btn-outline-primary btn-add-point"><i class="fa-solid fa-plus mr-2"></i>Thêm tọa độ
                  góc
                </button>
              </div>
            </div>
          </div>
          <hr>
          <div class="form-group" data-select2-id="select2-data-583-btul">
            <label class="form-label">Chọn đối tượng</label>
            <ng-select [items]="lstLayer" bindValue="tableName" bindLabel="name" name="tableName"
              placeholder="Chọn đối tượng" [(ngModel)]="tableNameSr" appendTo="Body"></ng-select>
          </div>
          <div class="form-group-submit" style="margin-bottom: 15px;">
            <button class="btn btn-primary btn-submit" type="submit">
              <i class="fa fa-search" aria-hidden="true"></i>
              <span>Tìm kiếm</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div id="TabContent-SqhSearch-1" class=" pane2 fade active show" role="tabpanel" *ngIf="show1">
      <div class="sdb-picker-map__search-form">
        <form id="form-lat-lng">
          <input type="hidden" name="type" value="form-lat-lng">
          <div class="c-form-bds">
            <div class="c-form-bds__group">
              <div class="form-group">
                <label class="form-label">Tìm bằng toạ độ vị trí Google map của
                  <span class="text-red">(*)</span></label>
                <input type="text" value="" name="query_place" class="form-control"
                  placeholder="Ví dụ: 21.0265250552213, 105.822266629795" [(ngModel)]="coor" name="coor">
              </div>
              <div class="form-group-submit">
                <button class="btn btn-primary btn-submit" type="button" data-form="form-lat-lng" id="search-lat-lng"
                  (click)="searchCoor()">
                  <i class="fa fa-search" aria-hidden="true"></i>
                  <span>Tìm địa điểm</span>
                </button>
              </div>
              <!-- <div class="form-group form-input" style="margin-top: 15px">
                <label class="form-label">hoặc tìm bằng số nhà</label>
                <input type="text" class="form-control search-text" placeholder="Ví dụ: Số 36 ngõ 34 đường An Bình Phát"
                  id="">
              </div> -->
            </div>
          </div>
        </form>

        <hr>
        <div class="form-group-tut">
          <img src="/assets/images/banner/huong-dan-lay-toa-do.jpg">
        </div>
      </div>
    </div>
  </div>
</div>