<nb-card>
  <nb-card-header class="nb-card-header">
    <h3 style="padding: 10px">Danh sách</h3>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
        <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 p-0">
        <table class="table table-bordered table-hover m-0">
          <thead>
            <tr>
              <th class="text-center" style="width: 50px">STT</th>
              <th *ngFor="let item of fields; let i = index">{{ item.name }}</th>
              <th class="text-center" style="width: 50px">Hành động</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of target; let i = index">
              <td class="text-center">
                {{ i + stt + 1 }}
              </td>
              <td *ngFor="let index of fields; let j = index">
                <span
                  *ngIf="index.name.toLowerCase() !== 'trạng thái' && index.name.toLowerCase() !== 'status' && index.fieldname.toLowerCase() !== 'mahuyen' && index.fieldname.toLowerCase() !== 'maxa' && index.name.toLowerCase() !== 'giới tính'">
                  {{item[index.fieldname.toLowerCase()]}}
                </span>
                <span *ngIf="index.fieldname.toLowerCase() === 'mahuyen'">
                  {{ getTenDVH(item[index.fieldname.toLowerCase()])}}
                </span>
                <span *ngIf="index.fieldname.toLowerCase() === 'maxa'">
                  {{ getTenDVX(item[index.fieldname.toLowerCase()])}}
                </span>
                <span *ngIf="index.name.toLowerCase() === 'trạng thái' || index.name.toLowerCase() === 'status'">
                  <mat-slide-toggle [color]="'primary'"
                    [checked]="setCheckedForToggle(item[index.fieldname.toLowerCase()])" [disabled]="true">
                  </mat-slide-toggle>
                </span>
              </td>
              <!-- <td class="text-center" style="padding: unset" style="width:95px">
                <button style="padding: 5px !important" title="Xem chi tiết" class="btn btn-sm"
                  (click)="viewFeature(i)">
                  <i class="fa fa-eye"></i>
                </button>
                <button style="padding: 5px !important" title="Sửa thông tin" class="btn btn-sm"
                  (click)="editFeature(i)">
                  <i class="fa fa-edit"></i>
                </button>
                <button style="padding: 5px !important" title="Xóa đối tượng" class="btn btn-sm"
                  (click)="deleteFeature(i)">
                  <i class="fa fa-trash"></i>
                </button>
              </td> -->
            </tr>

          </tbody>
        </table>
        <div *ngIf="targetNew.data.recordsCount === 0" style="text-align: center; padding: 20px;">
          Không có dữ liệu...
        </div>
      </div>
      <div class="row col" style="margin-top: 10px;">
        <div *ngIf="targetNew.data.recordsCount > 0">
          <div style="float: left;">
            Tổng số bản ghi: {{targetNew.data.recordsCount}}
          </div>
          <div style="float: right;">
            <ngx-pagination (pageChange)="SearchModel.page = $event;loadData()"
              (pageSize)="SearchModel.limit = $event;loadData()" [currentPage]="SearchModel.page"
              [totalPages]="targetNew.data.totalPage">
            </ngx-pagination>
          </div>
        </div>
      </div>
    </div>


  </nb-card-body>
</nb-card>