<form>
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          Nhật ký tích hợp dữ liệu bảng {{feature.tablename}}
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
        <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 p-0">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="text-center" style="width: 50px;">STT</th>
                <th>Bắt đầu</th>
                <th>Kết thúc</th>
                <th>Trạng thái</th>
                <th>Số bản ghi</th>
                <th>Thông tin</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of target; let i = index">
                <td class="text-center">
                  {{ i+1 }}
                </td>
                <td>{{convertDate(item.starttime)}}</td>
                <td>{{convertDate(item.endtime) }}</td>
                <td>{{formatStatus(item.status) }}</td>
                <td>{{ item.count }}</td>
                <td style="white-space:normal">{{ item.message }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button
            type="button"
            class="btn btn-outline-secondary"
            mat-dialog-close
          >
            Đóng
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>