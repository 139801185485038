<form>
    <nb-card>
        <nb-card-header class="nb-card-header">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    Chia sẻ dữ liệu cho người dùng
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
          
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
              <label class="col-form-label mr-2">Danh sách người dùng</label>
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th class="text-center" style="width: 50px;">STT</th>
                    <th>Người dùng</th>
                    <th class="text-center" style="width: 150px;">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of users; let i = index">
                    <td class="text-center">
                      {{ i + 1 }}
                    </td>
                    <td>{{ item.username }}</td>
                    <td class="text-center">
                      <button title="Chọn người dùng" 
                      class="btn btn-sm" (click)="selectUser(item.userid, item.username)"><i class="fa fa-check"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </nb-card-body>
        <nb-card-footer class="text-right">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
                    <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
                </div>
            </div>
        </nb-card-footer>
    </nb-card>
</form>
