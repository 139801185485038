<form #frm="ngForm" (ngSubmit)="create(frm)">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          Nhập dữ liệu
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Nguồn dữ liệu</label>
            <select class="form-control" aria-label="Định dạng nguồn dữ liệu" [(ngModel)]="exportTye"
            (change)="onChonNguonDuLieu($event.target.value)"
            [ngModelOptions]="{standalone: true}">
              <option value="shp">Shapefile</option>
              <option value="excel">Excel</option>
              <option value="csv">CSV</option>
            </select>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Đường dẫn tệp dữ liệu</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                name="name"
                placeholder="{{placeHolder}}"
                disabled
                [(ngModel)]="target.pathFile"
                required
                maxlength="255"
              />
              <button
                class="btn btn-primary"
                type="button"
                (click)="chonTepDuLieu()"
              >
                <i class="fas fa-plus fa-check"></i>&nbsp;Chọn tệp dữ liệu
              </button>
            </div>
            
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Hệ quy chiếu</label>
            <select class="form-control" aria-label="Hệ quy chiếu" [(ngModel)]="target.prj"
            [ngModelOptions]="{standalone: true}">
              <option value='4326'>WGS84</option>
              <option value='103003'>VN2000, 103°0, 3</option>
              <option value='103006'>VN2000, 103°0, 6</option>
              <option value='104003'>VN2000, 104°0, 3</option>
              <option value='104006'>VN2000, 104°0, 6</option>
              <option value='104303'>VN2000, 104°30, 3</option>
              <option value='104306'>VN2000, 104°30, 6</option>
              <option value='104453'>VN2000, 104°45, 3</option>
              <option value='104456'>VN2000, 104°45, 3</option>
              <option value='105003'>VN2000, 105°0, 3</option>
              <option value='105006'>VN2000, 105°0, 6</option>
              <option value='105303'>VN2000, 105°30, 3</option>
              <option value='105306'>VN2000, 105°30, 6</option>
              <option value='105453'>VN2000, 105°45, 3</option>
              <option value='105456'>VN2000, 105°45, 6</option>
              <option value='106003'>VN2000, 106°0, 3</option>
              <option value='106006'>VN2000, 106°0, 6</option>
              <option value='106153'>VN2000, 106°15, 3</option>
              <option value='106156'>VN2000, 106°15, 6</option>
              <option value='106303'>VN2000, 106°30, 3</option>
              <option value='106306'>VN2000, 106°30, 6</option>
              <option value='107003'>VN2000, 107°0, 3</option>
              <option value='107006'>VN2000, 107°0, 6</option>
              <option value='107153'>VN2000, 107°15, 3</option>
              <option value='107156'>VN2000, 107°15, 6</option>
              <option value='107303'>VN2000, 107°30, 3</option>
              <option value='107306'>VN2000, 107°30, 6</option>
              <option value='107453'>VN2000, 107°45, 3</option>
              <option value='107456'>VN2000, 107°45, 6</option>
              <option value='108003'>VN2000, 108°0, 3</option>
              <option value='108006'>VN2000, 108°0, 6</option>
              <option value='108153'>VN2000, 108°15, 3</option>
              <option value='108156'>VN2000, 108°15, 6</option>
              <option value='108303'>VN2000, 108°30, 3</option>
              <option value='108306'>VN2000, 108°30, 6</option>
            </select>
          </div>
        </div>
        <div class="col-lg-12">
            <div class="form-group">
              <label class="col-form-label required">Ánh xạ trường dữ liệu</label>
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th class="text-center" style="width: 50px;">STT</th>
                    <th>Trường từ tệp</th>
                    <th>Trường đích</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of fields; let i = index">
                    <td class="text-center">
                      {{ i +1 }}
                    </td>
                    <td class="text-center">
                      <select id="field-import-{{item.fieldDestination}}" class="form-control">
                       <option *ngFor="let f of fieldsFile">{{f}}</option>
                      </select>
                    <td>
                      {{item.fieldDestination}}
                    </td>
                  </tr>
                </tbody>
              </table>
              
            </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="frm.invalid"
          >
            Nhập dữ liệu
          </button>
          &nbsp;
          <button
            type="button"
            class="btn btn-outline-secondary"
            mat-dialog-close
          >
            Đóng
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
