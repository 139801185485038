import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE, SUCCESS_NOTICE, PAGE_SIZE } from '../../../../@core/customs/constants';
import { FieldService } from '../field.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FieldCreateComponent } from '../field-create/field-create.component';
import { FieldDetailComponent } from '../field-detail/field-detail.component';
import { FieldUpdateComponent } from '../field-update/field-update.component';
import { DatasetService } from '../../dataset/dataset.service';
import { PageModel } from '../../../../@core/data/app-gis.service';
import { DataService } from '../../services/data.service';
import { DialogConfirmComponent } from '../../../../@theme/components/template/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'ngx-field-index',
  templateUrl: './field-index.component.html',
  styles: ['.furniture { width: 250px; }'],
  styleUrls: ['./field-index.component.scss'],
})
export class FieldIndexComponent implements OnInit {
  constructor(
    private featureclassService: FieldService,
    private commonService: CommonService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) private featureClass: { featureid: number },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FieldIndexComponent>
  ) {}

  pageModel: PageModel = new PageModel({
    pageSize: PAGE_SIZE,
    condition: null,
    currentPage: 1,
    search: null,
  });
  datasets = [];
  target: any = [];
  first: any = [];

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.featureclassService.getAll(this.featureClass.featureid).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
        this.first = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  searchField(evt) {
    const text = this.pageModel.search;
    if (!text || text === '') this.target = this.first;
    else {
      this.target = this.first.filter(function (e) {
        return (
          e.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
          e.fieldname.toLowerCase().indexOf(text.toLowerCase()) > -1
        );
      });
    }
  }
  setCheckedForToggle(status: number) {
    if (status === 1) {
      return true;
    } else {
      return false;
    }
  }
  changeStatus(item: any) {
    const tableName = 'sysfield';
    const id = item.id;
    const status = item.status;
    this.dataService.ChangeStatus(tableName,status,id).subscribe(res => {
      this.loadData();
    });
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  openCreateDialog() {
    this.dialog
      .open(FieldCreateComponent, this.commonService.configDialog('50%', { featureClass: this.featureClass }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }

  editItem(id: number, fieldname: string) {
    if (['id', 'shape'].indexOf(fieldname.toLocaleLowerCase()) > -1) {
      alert('Không thể sửa trường hệ thống');
    } else
      this.dialog
        .open(FieldUpdateComponent, this.commonService.configDialog('50%', { id: id }))
        .afterClosed()
        .subscribe((result) => {
          if (result === true) {
            this.loadData();
          }
        });
  }

  deleteItem(id: number, name: string, fieldname: string) {
    if (['id', 'shape'].indexOf(fieldname.toLocaleLowerCase()) > -1) {
      alert('Không thể xóa trường hệ thống');
    } else {
      this.dialog.open(DialogConfirmComponent, this.commonService.configDialog('30%', {
        title: 'Xác nhận',
        message: `Bạn có chắc chắn xóa ?`,
        color: 'text-warning',
        type: 1,
        icon: 'exclamation-triangle',
        isAppend: true
      })).afterClosed().subscribe(info => {
        if (info) {
          this.featureclassService.delete(id).subscribe(res => {
            if (res.code === SUCCESS_RESPONE) {
              this.commonService.toastrSuccess(SUCCESS_NOTICE);
              this.loadData();
            } else {
              this.commonService.toastrDanger(res.message);
            }
          });
        }
      });
    }
  }
  renderDataType(type) {
    switch (type) {
      default:
        return 'Chuỗi ký tự';
      case 'number':
        return 'Số nguyên';
      case 'float':
        return 'Số thực';
      case 'date':
        return 'Ngày giờ';
    }
  }
}
