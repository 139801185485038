import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmTacgiumEntity } from 'app/@core/data/app-home.service';
import { DmTacGiaService } from '../dm-tac-gia.service';

@Component({
  selector: 'ngx-dm-tac-gia-detail',
  templateUrl: './dm-tac-gia-detail.component.html',
  styleUrls: ['./dm-tac-gia-detail.component.scss'],
})
export class DmTacGiaDetailComponent implements OnInit {
  constructor(
    private service: DmTacGiaService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number }
  ) {}

  ngOnInit(): void {
    this.loadData(this.data.id);
  }

  target: DmTacgiumEntity = new DmTacgiumEntity();

  loadData(id: number) {
    this.service.selectOne(id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
