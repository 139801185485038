import { Injectable } from '@angular/core';
import {
  ApiResponse,
  AppHomeApiService,
  DmLienheEntity,
  DmLienheEntityApiResponse,
  ObjectIEnumerablePageModelViewApiResponse,
  PageModel,
} from 'app/@core/data/app-home.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DmLienHeService {
  constructor(private api: AppHomeApiService) {}

  typeAttach(): number {
    return 1;
  }

  paging(body: PageModel | undefined): Observable<ObjectIEnumerablePageModelViewApiResponse> {
    return this.api.appAdminApiDmLienHePaging(body);
  }

  insert(body: DmLienheEntity | undefined): Observable<ApiResponse> {
    return this.api.appAdminApiDmLienHeInsert(body);
  }

  selectOne(key: number): Observable<DmLienheEntityApiResponse> {
    return this.api.appAdminApiDmLienHeSelectOne(key);
  }

  update(entity: DmLienheEntity): Observable<ApiResponse> {
    return this.api.appAdminApiDmLienHeUpdate(entity);
  }

  delete(id: number): Observable<ApiResponse> {
    return this.api.appAdminApiDmLienHeDelete(id);
  }
}
