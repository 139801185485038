import { Injectable } from '@angular/core';
import {
  AppHomeApiService,
  DmLanguageEntity,
  DmLanguageEntityApiResponse,
  MasterModelIEnumerablePageModelViewApiResponse,
} from 'app/@core/data/app-home.service';
import { ApiResponse, PageModel } from 'app/@core/data/app-qtht.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DmLanguageService {
  constructor(private api: AppHomeApiService) {}

  paging(
    body: PageModel | undefined
  ): Observable<MasterModelIEnumerablePageModelViewApiResponse> {
    return this.api.appAdminApiDmLanguagePaging(body);
  }

  insert(entity: DmLanguageEntity | undefined): Observable<ApiResponse> {
    return this.api.appAdminApiDmLanguageInsert(entity);
  }

  update(entity: DmLanguageEntity | undefined): Observable<ApiResponse> {
    return this.api.appAdminApiDmLanguageUpdate(entity);
  }

  selectOne(id: number): Observable<DmLanguageEntityApiResponse> {
    return this.api.appAdminApiDmLanguageSelectOne(id);
  }

  delete(id: number): Observable<ApiResponse> {
    return this.api.appAdminApiDmLanguageDelete(id);
  }
}
