import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { MapAdminService } from '../../mapadmin/mapadmin.service';
import * as turf from '@turf/turf';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxDirections from '@mapbox/mapbox-gl-directions';
import { FieldService } from '../../featureclass/field.service';
import { CheckedState } from '@progress/kendo-angular-treeview';
import { filter as _filter, forEach as _forEach, clone as _ } from 'lodash';
import { DataService } from '../../services/data.service';
import { stringify as GeoJSONToWKT, parse as WKTToGeoJSON } from 'wellknown';
import { MapCreateComponent } from '../map-create/map-create.component';
import mapboxgl from 'mapbox-gl';
import { environment } from '../../../../../environments/environment';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { PAGE_SIZE, SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { DataSearchModel, ExportDataModel } from '../../../../@core/data/app-gis.service';
import { MapAddComponent } from '../map-add/map-add.component';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxElevationControl from '@watergis/mapbox-gl-elevation';
import { MapImageComponent } from '../map-image/map-image.component';
import { AuthService } from '../../../../@core/auth.service';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { MapboxExportControl, Size, PageOrientation, Format, DPI } from '@watergis/mapbox-gl-export';
import { WindowCloseResult, WindowRef, WindowService, WindowState } from '@progress/kendo-angular-dialog';

declare const mapboxgl: any;
declare var MapboxCircle: any;
declare var watergis: any;
declare var MapboxDirections: any;
@Component({
  selector: 'ngx-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  /* #region  Variable */
  arrCheckedKeys_ActiveLayer: any[] = [];
  allParentNodes_KendoTree = [];
  arrExpandedKeys_KendoTree = [];
  arr_layer: any[] = [];
  drawControl: any;
  contentMeasure: string = '';
  contentNumberCount: string = '';
  showTableAttribute: boolean = false;
  textSearch: string;
  fields: any[] = [];
  target: any[] = [];
  drawCicle: any;
  tableName: string = '';
  polygon: string = '';
  baseLayer: any[] = [];
  listTable: any[] = [];
  tableSelect: '';
  public layers: any[] = [];
  listFieldName: any[] = [];
  public map: any;
  test: any[] = [];
  textSearchMap: string = '';
  HOST = environment.APP_GIS_URL;
  lstDVHCCH: any[] = [];
  lstDVHCCX: any[] = [];
  checkTable: string;
  dataDraw = {
    type: 'FeatureCollection',
    features: [],
  };
  dataPointPreview = {
    type: 'FeatureCollection',
    features: [],
  };
  href: string;
  fe: any;
  lstLayer: any[] = [];
  name: string;
  coordinateDraw = [];
  listeners: any;
  popupDraw: any;
  drawing: boolean = false; // trạng thái đang vẽ
  marker: any;
  isDrawing = false;
  area: any;
  imageId: string;
  videoId: string;
  popupMenuContext: any;
  isTraCuuTTPolygon = false;
  radius = 1;
  currentPage = 1;
  listData: any[] = [];
  lstMap: any[] = [];
  mapId: number;
  totalPage: number;
  popup: any;
  isShowDirection: boolean = false;
  isShowSearchBar: boolean = false;
  isShowElevation: boolean = false;
  id: number;
  UrlParam: string;
  lstFiles: any[] = [];
  LstImgPath: any[] = [];
  LstVideo: any[] = [];
  point: any;
  show: boolean = false;
  show2: boolean = false;
  show1: boolean = false;
  show4: boolean = false;
  isLogin: boolean = false;
  routeLink: string;
  elem;
  title: string;
  obName: any;
  targetNew: any = {
    data: [],
  };

  /* #endregion */

  constructor(
    private mapService: MapAdminService,
    private commonService: CommonService,
    private fieldService: FieldService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private readonly httpClient: HttpClient,
    private windowService: WindowService
  ) { }

  SearchModel: DataSearchModel = new DataSearchModel({
    tableName: null,
    text: null,
    page: 1,
    limit: PAGE_SIZE,
    status: null,
    dvhcch: "",
    dvhccx: "",
  });
  checkMapId: any;
  listBieuDoChil: any[] = [];
  listBieuDo: any[] = [];
  maHuyenDsDt: string;
  maXaDsDt: string;
  ngOnInit(): void {
    this.loadDVHC();
    this.isLogin = localStorage.getItem('access_token') !== null;
    if (localStorage.getItem('curAppId') === '1') {
      this.routeLink = '#/app-home-admin/tintuc-sukien';
    } else {
      this.routeLink = '#/gis/dataset';
    }
    this.UrlParam = this.route.snapshot['_routerState'].url;
    this.checkMapId = Number(this.route.snapshot.paramMap.get('id'));
    this.getMap();
    this.elem = document.documentElement;
    this.loadDmBieuDo();
    setTimeout(() => {
      this.loadViewData();
    }, 1000);

  }



  loadViewData() {
    // Lấy dữ liệu từ localStorage
    const storedData = sessionStorage.getItem('mapData');
    if (storedData) {
      const myObject = JSON.parse(storedData);
      console.log(myObject);
    }

  }

  loadDmBieuDo() {
    this.mapService.SelectAllDmBieuDo().subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.listBieuDo = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  checkYear: boolean = false;
  lstYear: any[] = [];
  yearValue: string;
  bieuDoName: any;
  LoadDataBieuDo() {
    this.chart = false;
    this.checkYear = false;
    this.yearValue = null;
    this.maHuyenBD = null;
    this.maXaBD = null;
    if (this.bieuDoName) {
      const name = this.bieuDoName;
      this.getDataChart(name);
    }
  }
  namChart: string;
  dataChart: any[] = [];
  categoryChartB: any[] = [];
  groupedData = {
    nam: [],
    tongDanso: [],
  };
  closeCharts() {
    this.maHuyenBD = null;
    this.maXaBD = null;
    this.dataChart = [];
    this.categoryChartB = [];
    this.chart = false;
    this.bieuDoName = null;
  }
  getDataChart(table) {
    this.namChart = this.listBieuDo.filter((x) => x.tablename === table)[0].name;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    let filter = '';
    if (
      table === 'BIEUDOCHUYENDETSXCN' ||
      table === 'bieudochisosanxuatcn' ||
      table === 'bieudotongsocososanx' ||
      table === 'bieudosllaodongvsngk' ||
      table === 'bddstheokv' ||
      table === 'bddsnam' ||
      table === 'bddstheotuoi' ||
      table === 'bdbdds' ||
      table === 'bdhd' ||
      table === 'bddstheolaodong' ||
      table === 'bieudomamnon' ||
      table === 'bieudophothong' ||
      table === 'bieudothuongxuyen' ||
      table === 'bieudotrungcap' ||
      table === 'bieudodaihoc' ||
      table === 'bieudogiaoducdaotao' ||
      table === 'bieudokhambenh' ||
      table === 'bieudogiuongbenh' ||
      table === 'bieudonhanluc' ||
      table === 'bieudoantoan' ||
      table === 'bieudongansach' ||
      table === 'bieudoduoc' ||
      table === 'bieudogiatrisxnn' ||
      table === 'bieudogiatrisxln' ||
      table === 'bieudogiatrisxlts' ||
      table === 'bieudoccgiatrisxnn' ||
      table === 'bieudoccgiatrisxln' ||
      table === 'bieudoccgiatrisxts' ||
      table === 'bdsltknctc' ||
      table === 'bdsltkctst' ||
      table === 'bdsltkctch' ||
      table === 'bdsltkcttttm' ||
      table === 'bdsltkctgia' ||
      table === 'bieudothethao' ||
      table === 'bieudodisan' ||
      table === 'bieudohoatdongdulich' ||
      table === 'bieudonanglucdulich' ||
      table === 'bieudovaitrodulich' ||
      table === 'bieudothuvien' ||
      table === 'bieudonghethuat' ||
      table === 'bieudodienanh' ||
      table === 'bieudosunghiepvanhoa' ||
      table === 'bieudomythuat' ||
      table === 'bddstheotpkt'
    ) {
      this.lstYear = [];
      const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json';
      this.httpClient.get(url).subscribe((res) => {
        var result = Object.assign(res);
        if (result.length > 0) {
          result.forEach((element) => {
            if (!this.lstYear.some((item) => item.value === element.nam.toString())) {
              const key = 'Năm ' + element.nam.toString();
              this.lstYear.push({ key: key, value: element.nam.toString() });
            }
          });
          this.lstYear = this.lstYear.sort((a, b) => b.value - a.value);
          this.checkYear = true;
          // const currentYear = new Date().getFullYear();
          // if (this.lstYear.filter((x) => x.value === currentYear).length > 0) {
          //   filter = " nam = '" + currentYear + "'";
          // } else {
          // filter = " nam = '" + this.lstYear[0].value + "'";
          // }
          // this.yearValue = currentYear.toString();
          switch (table) {
            case 'BIEUDOCHUYENDETSXCN':
              this.loadBieuDoSanXuatCongNghiep(table, filter);
              break;
            case 'bieudochisosanxuatcn':
              this.loadBieuDoChiSoGiaTriSanXuatCongNghiep(table, filter);
              break;
            case 'bieudotongsocososanx':
              this.loadBieuDoTongCoSoSanXuatCongNghiep(table, filter);
              break;
            case 'bieudosllaodongvsngk':
              this.loadBieuDoSLLaoDongVsNgk(table, filter);
              break;
            case 'bieudogiatrisxnn':
              this.loadBieuDoGiaTriSanXuatNongNghiep(table, filter);
              break;
            case 'bieudogiatrisxln':
              this.loadBieuDoGiaTriSanXuatLamNghiep(table, filter);
              break;
            case 'bieudogiatrisxlts':
              this.loadBieuDoGiaTriSanXuatThuySan(table, filter);
              break;
            case 'bieudoccgiatrisxnn':
              this.loadBieuDoCoCauGiaTriSanXuatNongNghiep(table, filter);
              break;
            case 'bieudoccgiatrisxln':
              this.loadBieuDoCoCauGiaTriSanXuatLamNghiep(table, filter);
              break;
            case 'bieudoccgiatrisxts':
              this.loadBieuDoCoCauGiaTriSanXuatThuySan(table, filter);
              break;
            case 'bdsltknctc':
              this.loadBieuDoThongKeChiTieuCho(table, filter);
              break;
            case 'bdsltkctst':
              this.loadBieuDoThongKeChiTieuSieuThi(table, filter);
              break;
            case 'bdsltkcttttm':
              this.loadBieuDoThongKeChiTieuTrungTamThuongMai(table, filter);
              break;
            case 'bdsltkctch':
              this.loadBieuDoThongKeChiTieuCuaHang(table, filter);
              break;
            case 'bdsltkctgia':
              this.loadBieuDoThongKeChiTieuGia(table, filter);
              break;
            case 'bddsnam':
              this.loadBieuDoDanSoNam(table, filter);
              break;
            case 'bddstheotuoi':
              this.loadBieuDoDanSoTuoi(table, filter);
              break;
            case 'bdbdds':
              this.loadBieuDoDanSoNam(table, filter);
              break;
            case 'bdhd':
              this.loadBieuHoDan(table, filter);
              break;
            case 'bddstheokv':
              this.loadBieuDoDSTheoKV(table, filter);
              break;
            case 'bddstheolaodong':
              this.loadBieuDoDSTheoLaoDong(table, filter);
              break;
            case 'bddstheotpkt':
              this.loadBieuDoDSTheoTPKT(table, filter);
              break;
            case 'bieudomamnon':
              this.loadBieuDoMamNon(table, filter);
              break;
            case 'bieudophothong':
              this.loadBieuDoPhoThong(table, filter);
              break;
            case 'bieudothuongxuyen':
              this.loadBieuDoThuongXuyen(table, filter);
              break;
            case 'bieudotrungcap':
              this.loadBieuDoTrungCap(table, filter);
              break;
            case 'bieudodaihoc':
              this.loadBieuDoDaiHoc(table, filter);
              break;
            case 'bieudogiaoducdaotao':
              this.loadBieuDoGiaoDucDaoTao(table, filter);
              break;
            case 'bieudokhambenh':
              this.loadBieuDoKhamBenh(table, filter);
              break;
            case 'bieudogiuongbenh':
              this.loadBieuDoGiuongBenh(table, filter);
              break;
            case 'bieudonhanluc':
              this.loadBieuDoNhanLuc(table, filter);
              break;
            case 'bieudoantoan':
              this.loadBieuDoAnToan(table, filter);
              break;
            case 'bieudongansach':
              this.loadBieuDoNganSach(table, filter);
              break;
            case 'bieudoduoc':
              this.loadBieuDoDuoc(table, filter);
              break;
            case 'bieudothethao':
              this.loadBieuDoTheThao(table, filter);
              break;
            case 'bieudodisan':
              this.loadBieuDoDiSan(table, filter);
              break;
            case 'bieudohoatdongdulich':
              this.loadBieuDoHoatDongDuLich(table, filter);
              break;
            case 'bieudonanglucdulich':
              this.loadBieuDoNangLucDuLich(table, filter);
              break;
            case 'bieudovaitrodulich':
              this.loadBieuDoVaiTroDuLich(table, filter);
              break;
            case 'bieudothuvien':
              this.loadBieuDoThuVien(table, filter);
              break;
            case 'bieudonghethuat':
              this.loadBieuDoNgheThuat(table, filter);
              break;
            case 'bieudodienanh':
              this.loadBieuDoDienAnh(table, filter);
              break;
            case 'bieudosunghiepvanhoa':
              this.loadBieuDoSuNghiepVanHoa(table, filter);
              break;
            case 'bieudomythuat':
              this.loadBieuDoMyThuat(table, filter);
              break;
            default:
            // default statements
          }
        }
      });
    }
  }

  checkFilter: boolean = false;
  chart: boolean = false;
  loadBieuDoSanXuatCongNghiep(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.giatrisx)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }
  dataChartPie: any;
  loadBieuDoChiSoGiaTriSanXuatCongNghiep(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.giatrisx)));
        this.groupedData.tongDanso.push(sum);
      });
      const newArray = Object.keys(this.groupedData.nam).map((key, index) => {
        const ten = this.groupedData.nam[key];
        const value = this.groupedData.tongDanso[index]; // Thay thế giá trị này bằng giá trị thực tế bạn muốn sử dụng.
        return { ten, value };
      });
      this.dataChartPie = newArray;
      if (result.length === 0) {
        this.dataChartPie = [];
      }
      this.chart = true;
    });
  }
  public labelContent(args: any): string {
    return `${args.category} Chỉ số giá trị sản xuất: ${Number(args.dataItem.value)} %`;
  }

  loadBieuDoGiaTriSanXuatNongNghiep(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.nam)) {
          this.groupedData.nam.push(element.nam);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.nam === element);
        let sum = 0;
        lst.forEach((a) => (sum = Number(a.giatrisx)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoGiaTriSanXuatLamNghiep(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.nam)) {
          this.groupedData.nam.push(element.nam);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.nam === element);
        let sum = 0;
        lst.forEach((a) => (sum = Number(a.giatrisx)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }
  loadBieuDoGiaTriSanXuatThuySan(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.nam)) {
          this.groupedData.nam.push(element.nam);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.nam === element);
        let sum = 0;
        lst.forEach((a) => (sum = Number(a.giatrisx)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }
  totalValue: any;
  loadBieuDoCoCauGiaTriSanXuatNongNghiep(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      let sumAll = 0;
      result.forEach((element) => {
        sumAll += Number(element.giatrisx);
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.giatrisx)));
        lst.forEach((b) => this.groupedData.tongDanso.push((b.giatrisx / sumAll * 100).toFixed(2)));
        const newArray = Object.keys(this.groupedData.nam).map((key, index) => {
          const ten = this.groupedData.nam[key];
          const value = this.groupedData.tongDanso[index]; // Thay thế giá trị này bằng giá trị thực tế bạn muốn sử dụng.
          return { ten, value };
        });
        this.dataChartPie = newArray;
      });
      if (result.length === 0) {
        this.dataChartPie = [];
      }
      this.chart = true;
    });
  }
  public labelContent1(args: any): string {
    return `${args.category} Cơ cấu giá trị sản xuất: ${Number(args.dataItem.value)} %`;
  }

  loadBieuDoCoCauGiaTriSanXuatLamNghiep(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      let sumAll = 0;
      result.forEach((element) => {
        sumAll += Number(element.giatrisx);
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.giatrisx)));
        lst.forEach((b) => this.groupedData.tongDanso.push((b.giatrisx / sumAll * 100).toFixed(2)));
        const newArray = Object.keys(this.groupedData.nam).map((key, index) => {
          const ten = this.groupedData.nam[key];
          const value = this.groupedData.tongDanso[index]; // Thay thế giá trị này bằng giá trị thực tế bạn muốn sử dụng.
          return { ten, value };
        });
        this.dataChartPie = newArray;
      });
      if (result.length === 0) {
        this.dataChartPie = [];
      }
      this.chart = true;
    });
  }

  loadBieuDoCoCauGiaTriSanXuatThuySan(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      let sumAll = 0;
      result.forEach((element) => {
        sumAll += Number(element.giatrisx);
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.giatrisx)));
        lst.forEach((b) => this.groupedData.tongDanso.push((b.giatrisx / sumAll * 100).toFixed(2)));
        const newArray = Object.keys(this.groupedData.nam).map((key, index) => {
          const ten = this.groupedData.nam[key];
          const value = this.groupedData.tongDanso[index]; // Thay thế giá trị này bằng giá trị thực tế bạn muốn sử dụng.
          return { ten, value };
        });
        this.dataChartPie = newArray;
      });
      if (result.length === 0) {
        this.dataChartPie = [];
      }
      this.chart = true;
    });
  }

  loadBieuDoThongKeChiTieuCho(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        let name = '';
        if (this.maHuyenBD) {
          name = this.lstDVHCCX.filter((x) => x.madonvi === element.maxa)[0].ten;
        } else {
          name = this.lstDVHCCH.filter((x) => x.madonvi === element.mahuyen)[0].ten;
        }
        if (!this.groupedData.nam.includes(name)) {
          this.groupedData.nam.push(name);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        let ma = '';
        let lst = [];
        if (this.maHuyenBD) {
          ma = this.lstDVHCCX.filter((x) => x.ten === element)[0].madonvi;
          lst = result.filter((x) => x.maxa === ma);
        } else {
          ma = this.lstDVHCCH.filter((x) => x.ten === element)[0].madonvi;
          lst = result.filter((x) => x.mahuyen === ma);
        }
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.socho)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoThongKeChiTieuSieuThi(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoThongKeChiTieuTrungTamThuongMai(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum = Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoThongKeChiTieuCuaHang(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        let name = '';
        if (this.maHuyenBD) {
          name = this.lstDVHCCX.filter((x) => x.madonvi === element.maxa)[0].ten;
        } else {
          name = this.lstDVHCCH.filter((x) => x.madonvi === element.mahuyen)[0].ten;
        }
        if (!this.groupedData.nam.includes(name)) {
          this.groupedData.nam.push(name);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        let ma = '';
        let lst = [];
        if (this.maHuyenBD) {
          ma = this.lstDVHCCX.filter((x) => x.ten === element)[0].madonvi;
          lst = result.filter((x) => x.maxa === ma);
        } else {
          ma = this.lstDVHCCH.filter((x) => x.ten === element)[0].madonvi;
          lst = result.filter((x) => x.mahuyen === ma);
        }
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoThongKeChiTieuGia(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.nganhhang)) {
          this.groupedData.nam.push(element.nganhhang);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.nganhhang === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.chiso)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadLabelTN(bieuDoName) {
    if (bieuDoName === 'bdsltknctc') return '0 : Chợ'
    if (bieuDoName === 'bdsltkctst') return '0 : Siêu thị'
    if (bieuDoName === 'bdsltkctgia') return '0 : Chỉ số CPI'
    if (bieuDoName === 'bdsltkctch') return '0 : Cửa hàng'
    return '0'
  }

  loadBieuDoTongCoSoSanXuatCongNghiep(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.nam)) {
          this.groupedData.nam.push(element.nam);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.nam === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.tongsocoso)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoSLLaoDongVsNgk(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.sllaodong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoDSTheoKV(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.tenkv)) {
          this.groupedData.nam.push(element.tenkv);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.tenkv === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.danso)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }
  loadBieuDoDSTheoLaoDong(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.nam)) {
          this.groupedData.nam.push(element.nam);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.nam === element);
        let sum = 0;
        lst.forEach((a) => (sum = Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }
  loadBieuDoDSTheoTPKT(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum = Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }
  loadBieuHoDan(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.nam)) {
          this.groupedData.nam.push(element.nam);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.nam === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soho)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
      console.log(this.groupedData);
    });
  }
  loadBieuDoDanSoTuoi(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.dotuoi)) {
          this.groupedData.nam.push(element.dotuoi);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.dotuoi === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.tongso)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }
  loadBieuDoDanSoNam(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.nam)) {
          this.groupedData.nam.push(element.nam);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.nam === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.danso)));
        this.groupedData.tongDanso.push(sum);
      });

      this.chart = true;
    });
  }

  loadBieuDoMamNon(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoPhoThong(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoThuongXuyen(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoTrungCap(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoDaiHoc(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoGiaoDucDaoTao(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoKhamBenh(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoGiuongBenh(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoNhanLuc(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoAnToan(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoNganSach(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoDuoc(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoTheThao(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoDiSan(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoHoatDongDuLich(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoNangLucDuLich(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoVaiTroDuLich(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoThuVien(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoNgheThuat(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoDienAnh(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoSuNghiepVanHoa(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  loadBieuDoMyThuat(table, filter) {
    this.checkFilter = true;
    this.groupedData.nam = [];
    this.groupedData.tongDanso = [];
    const url = environment.APP_GIS_URL + '/App/Gis/api/Data/' + table + '/data?format=json&filter=' + filter;
    this.httpClient.get(url).subscribe((res) => {
      var result = Object.assign(res);
      result.forEach((element) => {
        if (!this.groupedData.nam.includes(element.ten)) {
          this.groupedData.nam.push(element.ten);
        }
      });
      this.groupedData.nam = this.groupedData.nam.sort((a, b) => a - b);
      this.groupedData.nam.forEach((element) => {
        const lst = result.filter((x) => x.ten === element);
        let sum = 0;
        lst.forEach((a) => (sum += Number(a.soluong)));
        this.groupedData.tongDanso.push(sum);
      });
      this.chart = true;
    });
  }

  maHuyenBD: any;
  maXaBD: any;
  chosseYear() {
    this.chart = false;
    let filter = '';
    if (this.yearValue) {
      filter = ' nam = ' + this.yearValue;
    }
    else {
      const currentYear = new Date().getFullYear();
      filter = ' nam = ' + currentYear;
    }
    if (this.maHuyenBD) {
      filter += ' and mahuyen = ' + this.maHuyenBD;
    }
    if (this.maXaBD) {
      filter += ' and maXa = ' + this.maXaBD;
    }
    if (this.bieuDoName === 'BIEUDOCHUYENDETSXCN') {
      this.loadBieuDoSanXuatCongNghiep(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudochisosanxuatcn') {
      this.loadBieuDoChiSoGiaTriSanXuatCongNghiep(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudotongsocososanx') {
      this.loadBieuDoTongCoSoSanXuatCongNghiep(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudosllaodongvsngk') {
      this.loadBieuDoSLLaoDongVsNgk(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudomamnon') {
      this.loadBieuDoMamNon(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudophothong') {
      this.loadBieuDoPhoThong(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudothuongxuyen') {
      this.loadBieuDoThuongXuyen(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudotrungcap') {
      this.loadBieuDoTrungCap(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudodaihoc') {
      this.loadBieuDoDaiHoc(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudogiaoducdaotao') {
      this.loadBieuDoGiaoDucDaoTao(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudokhambenh') {
      this.loadBieuDoKhamBenh(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudogiuongbenh') {
      this.loadBieuDoGiuongBenh(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudonhanluc') {
      this.loadBieuDoNhanLuc(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudoantoan') {
      this.loadBieuDoAnToan(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudongansach') {
      this.loadBieuDoNganSach(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudoduoc') {
      this.loadBieuDoDuoc(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudothethao') {
      this.loadBieuDoTheThao(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudodisan') {
      this.loadBieuDoDiSan(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudohoatdongdulich') {
      this.loadBieuDoHoatDongDuLich(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudonanglucdulich') {
      this.loadBieuDoNangLucDuLich(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudovaitrodulich') {
      this.loadBieuDoVaiTroDuLich(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudothuvien') {
      this.loadBieuDoVaiTroDuLich(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudonghethuat') {
      this.loadBieuDoNgheThuat(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudodienanh') {
      this.loadBieuDoDienAnh(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudosunghiepvanhoa') {
      this.loadBieuDoSuNghiepVanHoa(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudomythuat') {
      this.loadBieuDoMyThuat(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudogiatrisxnn') {
      this.loadBieuDoGiaTriSanXuatNongNghiep(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudogiatrisxln') {
      this.loadBieuDoGiaTriSanXuatLamNghiep(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudogiatrisxlts') {
      this.loadBieuDoGiaTriSanXuatThuySan(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudoccgiatrisxnn') {
      this.loadBieuDoCoCauGiaTriSanXuatNongNghiep(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudoccgiatrisxln') {
      this.loadBieuDoCoCauGiaTriSanXuatLamNghiep(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bieudoccgiatrisxts') {
      this.loadBieuDoCoCauGiaTriSanXuatThuySan(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bdsltknctc') {
      this.loadBieuDoThongKeChiTieuCho(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bdsltkctst') {
      this.loadBieuDoThongKeChiTieuSieuThi(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bdsltkctst') {
      this.loadBieuDoThongKeChiTieuTrungTamThuongMai(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bdsltkctch') {
      this.loadBieuDoThongKeChiTieuCuaHang(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bdsltkctgia') {
      this.loadBieuDoThongKeChiTieuGia(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bddsnam') {
      this.loadBieuDoDanSoNam(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bddstheokv') {
      this.loadBieuDoDSTheoKV(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bddstheotuoi') {
      this.loadBieuDoDanSoTuoi(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bddsnam' || this.bieuDoName === 'bdbdds') {
      this.loadBieuDoDanSoNam(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bdhd') {
      this.loadBieuHoDan(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bddstheolaodong') {
      this.loadBieuDoDSTheoLaoDong(this.bieuDoName, filter);
    }
    if (this.bieuDoName === 'bddstheotpkt') {
      this.loadBieuDoDSTheoTPKT(this.bieuDoName, filter);
    }
  }

  loadXaBieuDo() {
    this.maXaBD = null;
    let filter = '';
    if (this.maHuyenBD) {
      this.chart = false;
      this.lstDVHCCXBD = [];
      this.dataService.getDataByTableName('dvhccx').subscribe((res) => {
        this.lstDVHCCXBD = JSON.parse(res.data);
        this.lstDVHCCXBD = this.lstDVHCCXBD.filter((x) => x.macha === this.maHuyenBD);
      });
      if (
        this.bieuDoName === 'bddstheotuoi' ||
        this.bieuDoName === 'bddstheotpkt' ||
        this.bieuDoName === 'bddstheold' ||
        this.bieuDoName === 'bieudochisosanxuatcn' ||
        this.bieuDoName === 'bdtscssxcn' ||
        this.bieuDoName === 'bdldvsnk' ||
        this.bieuDoName === 'bddsnam' ||
        this.bieuDoName === 'bdbdds' ||
        this.bieuDoName === 'bdhd' ||
        this.bieuDoName === 'bddstheolaodong' ||
        this.bieuDoName === 'bddstheokv' ||
        this.bieuDoName === 'bdgtnongnghiep' ||
        this.bieuDoName === 'bdslgtsxln' ||
        this.bieuDoName === 'bdslccgtsxnn' ||
        this.bieuDoName === 'bdslccgtsxln' ||
        this.bieuDoName === 'bdsltknctc' ||
        this.bieuDoName === 'bdsltkctch' ||
        this.bieuDoName === 'bdsltkctgia' ||
        this.bieuDoName === 'BIEUDOCHUYENDETSXCN' ||
        this.bieuDoName === 'bieudochisosanxuatcn' ||
        this.bieuDoName === 'bieudotongsocososanx' ||
        this.bieuDoName === 'bieudosllaodongvsngk' ||
        this.bieuDoName === 'bieudogiatrisxnn' ||
        this.bieuDoName === 'bieudogiatrisxln' ||
        this.bieuDoName === 'bieudoccgiatrisxnn' ||
        this.bieuDoName === 'bieudoccgiatrisxln' ||
        this.bieuDoName === 'bieudogiatrisxlts' ||
        this.bieuDoName === 'bieudoccgiatrisxts' ||
        this.bieuDoName === 'bdsltkctst' ||
        this.bieuDoName === 'bddstheotpkt' ||
        this.bieuDoName === 'bieudomamnon' ||
        this.bieuDoName === 'bieudophothong' ||
        this.bieuDoName === 'bieudothuongxuyen' ||
        this.bieuDoName === 'bieudotrungcap' ||
        this.bieuDoName === 'bieudodaihoc' ||
        this.bieuDoName === 'bieudogiaoducdaotao' ||
        this.bieuDoName === 'bieudokhambenh' ||
        this.bieuDoName === 'bieudoduoc' ||
        this.bieuDoName === 'bieudogiuongbenh' ||
        this.bieuDoName === 'bieudonhanluc' ||
        this.bieuDoName === 'bieudongansach' ||
        this.bieuDoName === 'bieudoantoan' ||
        this.bieuDoName === 'bieudothethao' ||
        this.bieuDoName === 'bieudodisan' ||
        this.bieuDoName === 'bieudohoatdongdulich' ||
        this.bieuDoName === 'bieudonanglucdulich' ||
        this.bieuDoName === 'bieudovaitrodulich' ||
        this.bieuDoName === 'bieudothuvien' ||
        this.bieuDoName === 'bieudonghethuat' ||
        this.bieuDoName === 'bieudodienanh' ||
        this.bieuDoName === 'bieudosunghiepvanhoa' ||
        this.bieuDoName === 'bieudomythuat'
      ) {
        const currentYear = new Date().getFullYear();
        const year = this.yearValue ? this.yearValue : currentYear;
        filter = ' nam = ' + year + ' and mahuyen = ' + this.maHuyenBD;
      }
    } else {
      this.lstDVHCCX = [];
      this.chart = false;
      if (
        this.bieuDoName === 'bddstheotuoi' ||
        this.bieuDoName === 'bddstheotpkt' ||
        this.bieuDoName === 'bddstheold' ||
        this.bieuDoName === 'bdcssxcn' ||
        this.bieuDoName === 'bdtscssxcn' ||
        this.bieuDoName === 'bdldvsnk' ||
        this.bieuDoName === 'bddsnam' ||
        this.bieuDoName === 'bdbdds' ||
        this.bieuDoName === 'bdhd' ||
        this.bieuDoName === 'bddstheolaodong' ||
        this.bieuDoName === 'bddstheokv' ||
        this.bieuDoName === 'bdgtnongnghiep' ||
        this.bieuDoName === 'bdslgtsxln' ||
        this.bieuDoName === 'bdslccgtsxnn' ||
        this.bieuDoName === 'bdslccgtsxln' ||
        this.bieuDoName === 'bdsltknctc' ||
        this.bieuDoName === 'bdsltkctch' ||
        this.bieuDoName === 'bdsltkctgia' ||
        this.bieuDoName === 'BIEUDOCHUYENDETSXCN' ||
        this.bieuDoName === 'bieudochisosanxuatcn' ||
        this.bieuDoName === 'bieudotongsocososanx' ||
        this.bieuDoName === 'loadBieuDoSLLaoDongVsNgk' ||
        this.bieuDoName === 'bieudogiatrisxnn' ||
        this.bieuDoName === 'bieudogiatrisxln' ||
        this.bieuDoName === 'bieudogiatrisxlts' ||
        this.bieuDoName === 'bieudoccgiatrisxnn' ||
        this.bieuDoName === 'bieudoccgiatrisxln' ||
        this.bieuDoName === 'bieudoccgiatrisxts' ||
        this.bieuDoName === 'bdsltkctst' ||
        this.bieuDoName === 'bddstheotpkt' ||
        this.bieuDoName === 'bieudomamnon' ||
        this.bieuDoName === 'bieudophothong' ||
        this.bieuDoName === 'bieudothuongxuyen' ||
        this.bieuDoName === 'bieudotrungcap' ||
        this.bieuDoName === 'bieudodaihoc' ||
        this.bieuDoName === 'bieudogiaoducdaotao' ||
        this.bieuDoName === 'bieudokhambenh' ||
        this.bieuDoName === 'bieudoduoc' ||
        this.bieuDoName === 'bieudogiuongbenh' ||
        this.bieuDoName === 'bieudonhanluc' ||
        this.bieuDoName === 'bieudongansach' ||
        this.bieuDoName === 'bieudoantoan' ||
        this.bieuDoName === 'bieudothethao' ||
        this.bieuDoName === 'bieudodisan' ||
        this.bieuDoName === 'bieudohoatdongdulich' ||
        this.bieuDoName === 'bieudonanglucdulich' ||
        this.bieuDoName === 'bieudovaitrodulich' ||
        this.bieuDoName === 'bieudothuvien' ||
        this.bieuDoName === 'bieudonghethuat' ||
        this.bieuDoName === 'bieudodienanh' ||
        this.bieuDoName === 'bieudosunghiepvanhoa' ||
        this.bieuDoName === 'bieudomythuat'
      ) {
        const currentYear = new Date().getFullYear();
        const year = this.yearValue ? this.yearValue : currentYear;
        filter = ' nam = ' + year;
      }
    }
    if (this.bieuDoName === 'bddsnam' || this.bieuDoName === 'bdbdds') {
      this.loadBieuDoDanSoNam(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bdhodan') {
      this.loadBieuHoDan(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'BIEUDOCHUYENDETSXCN') {
      this.loadBieuDoSanXuatCongNghiep(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bddstheotuoi') {
      this.loadBieuDoDanSoTuoi(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bddstheokv') {
      this.loadBieuDoDSTheoKV(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bdsltknctc') {
      this.loadBieuDoThongKeChiTieuCho(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bdsltkctch') {
      this.loadBieuDoThongKeChiTieuCuaHang(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bdsltkctgia') {
      this.loadBieuDoThongKeChiTieuGia(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudochisosanxuatcn') {
      this.loadBieuDoChiSoGiaTriSanXuatCongNghiep(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudotongsocososanx') {
      this.loadBieuDoTongCoSoSanXuatCongNghiep(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudosllaodongvsngk') {
      this.loadBieuDoSLLaoDongVsNgk(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudogiatrisxnn') {
      this.loadBieuDoGiaTriSanXuatNongNghiep(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudogiatrisxln') {
      this.loadBieuDoGiaTriSanXuatLamNghiep(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudogiatrisxlts') {
      this.loadBieuDoGiaTriSanXuatThuySan(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudoccgiatrisxnn') {
      this.loadBieuDoCoCauGiaTriSanXuatNongNghiep(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudoccgiatrisxln') {
      this.loadBieuDoCoCauGiaTriSanXuatLamNghiep(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudoccgiatrisxts') {
      this.loadBieuDoCoCauGiaTriSanXuatThuySan(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bdsltkctst') {
      this.loadBieuDoThongKeChiTieuSieuThi(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bdhd') {
      this.loadBieuHoDan(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bddstheolaodong') {
      this.loadBieuDoDSTheoLaoDong(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bddstheotpkt') {
      this.loadBieuDoDSTheoTPKT(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudomamnon') {
      this.loadBieuDoMamNon(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudophothong') {
      this.loadBieuDoPhoThong(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudothuongxuyen') {
      this.loadBieuDoThuongXuyen(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudotrungcap') {
      this.loadBieuDoTrungCap(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudodaihoc') {
      this.loadBieuDoDaiHoc(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudogiaoducdaotao') {
      this.loadBieuDoGiaoDucDaoTao(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudokhambenh') {
      this.loadBieuDoKhamBenh(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudoduoc') {
      this.loadBieuDoDuoc(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudogiuongbenh') {
      this.loadBieuDoGiuongBenh(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudonhanluc') {
      this.loadBieuDoNhanLuc(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudongansach') {
      this.loadBieuDoNganSach(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudoantoan') {
      this.loadBieuDoAnToan(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudothethao') {
      this.loadBieuDoTheThao(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudodisan') {
      this.loadBieuDoDiSan(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudohoatdongdulich') {
      this.loadBieuDoHoatDongDuLich(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudonanglucdulich') {
      this.loadBieuDoNangLucDuLich(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudovaitrodulich') {
      this.loadBieuDoVaiTroDuLich(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudothuvien') {
      this.loadBieuDoThuVien(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudonghethuat') {
      this.loadBieuDoNgheThuat(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudodienanh') {
      this.loadBieuDoDienAnh(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudosunghiepvanhoa') {
      this.loadBieuDoSuNghiepVanHoa(this.bieuDoName, filter);
    } else if (this.bieuDoName === 'bieudomythuat') {
      this.loadBieuDoMyThuat(this.bieuDoName, filter);
    }
  }

  filterByXaBieuDo() {
    this.chart = false;
    if (this.maXaBD) {
      if (
        this.bieuDoName === 'bddstheotuoi' ||
        this.bieuDoName === 'bdsltknctc' ||
        this.bieuDoName === 'bdsltkctgia' ||
        this.bieuDoName === 'bddstheokv' ||
        this.bieuDoName === 'bddsnam' ||
        this.bieuDoName === 'bddstheotuoi' ||
        this.bieuDoName === 'bdbdds' ||
        this.bieuDoName === 'bdhd' ||
        this.bieuDoName === 'bddstheolaodong' ||
        this.bieuDoName === 'BIEUDOCHUYENDETSXCN' ||
        this.bieuDoName === 'bieudochisosanxuatcn' ||
        this.bieuDoName === 'bieudotongsocososanx' ||
        this.bieuDoName === 'bieudosllaodongvsngk' ||
        this.bieuDoName === 'bieudogiatrisxnn' ||
        this.bieuDoName === 'bieudogiatrisxln' ||
        this.bieuDoName === 'bieudogiatrisxlts' ||
        this.bieuDoName === 'bieudoccgiatrisxnn' ||
        this.bieuDoName === 'bieudoccgiatrisxln' ||
        this.bieuDoName === 'bieudoccgiatrisxts' ||
        this.bieuDoName === 'bdsltkctst' ||
        this.bieuDoName === 'bddstheotpkt' ||
        this.bieuDoName === 'bieudomamnon' ||
        this.bieuDoName === 'bieudophothong' ||
        this.bieuDoName === 'bieudothuongxuyen' ||
        this.bieuDoName === 'bieudotrungcap' ||
        this.bieuDoName === 'bieudodaihoc' ||
        this.bieuDoName === 'bieudogiaoducdaotao' ||
        this.bieuDoName === 'bieudokhambenh' ||
        this.bieuDoName === 'bieudoduoc' ||
        this.bieuDoName === 'bieudogiuongbenh' ||
        this.bieuDoName === 'bieudonhanluc' ||
        this.bieuDoName === 'bieudongansach' ||
        this.bieuDoName === 'bieudoantoan' ||
        this.bieuDoName === 'bieudothethao' ||
        this.bieuDoName === 'bieudodisan' ||
        this.bieuDoName === 'bieudohoatdongdulich' ||
        this.bieuDoName === 'bieudonanglucdulich' ||
        this.bieuDoName === 'bieudovaitrodulich' ||
        this.bieuDoName === 'bieudothuvien' ||
        this.bieuDoName === 'bieudonghethuat' ||
        this.bieuDoName === 'bieudodienanh' ||
        this.bieuDoName === 'bieudosunghiepvanhoa' ||
        this.bieuDoName === 'bieudomythuat'
      ) {
        this.chart = false;
        const currentYear = new Date().getFullYear();
        const year = this.yearValue ? this.yearValue : currentYear;
        const filter = ' nam = ' + year + ' and mahuyen = ' + this.maHuyenBD + ' and maxa = ' + this.maXaBD;
        if (this.bieuDoName === 'bddstheotuoi') {
          this.loadBieuDoDanSoTuoi(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bdhodan') {
          this.loadBieuHoDan(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'BIEUDOCHUYENDETSXCN') {
          this.loadBieuDoSanXuatCongNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bdsltknctc') {
          this.loadBieuDoThongKeChiTieuCho(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bdsltkctgia') {
          this.loadBieuDoThongKeChiTieuGia(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudochisosanxuatcn') {
          this.loadBieuDoChiSoGiaTriSanXuatCongNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudotongsocososanx') {
          this.loadBieuDoTongCoSoSanXuatCongNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudosllaodongvsngk') {
          this.loadBieuDoSLLaoDongVsNgk(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudogiatrisxnn') {
          this.loadBieuDoGiaTriSanXuatNongNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudogiatrisxln') {
          this.loadBieuDoGiaTriSanXuatLamNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudogiatrisxlts') {
          this.loadBieuDoGiaTriSanXuatThuySan(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudoccgiatrisxnn') {
          this.loadBieuDoCoCauGiaTriSanXuatNongNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudoccgiatrisxln') {
          this.loadBieuDoCoCauGiaTriSanXuatLamNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudoccgiatrisxts') {
          this.loadBieuDoCoCauGiaTriSanXuatThuySan(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bdsltkctst') {
          this.loadBieuDoThongKeChiTieuSieuThi(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bddsnam' || this.bieuDoName === 'bdbdds') {
          this.loadBieuDoDanSoNam(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bdhd') {
          this.loadBieuHoDan(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bddstheolaodong') {
          this.loadBieuDoDSTheoLaoDong(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bddstheotpkt') {
          this.loadBieuDoDSTheoTPKT(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudomamnon') {
          this.loadBieuDoMamNon(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudophothong') {
          this.loadBieuDoPhoThong(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudothuongxuyen') {
          this.loadBieuDoThuongXuyen(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudotrungcap') {
          this.loadBieuDoTrungCap(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudodaihoc') {
          this.loadBieuDoDaiHoc(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudogiaoducdaotao') {
          this.loadBieuDoGiaoDucDaoTao(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudokhambenh') {
          this.loadBieuDoKhamBenh(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudoduoc') {
          this.loadBieuDoDuoc(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudogiuongbenh') {
          this.loadBieuDoGiuongBenh(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudonhanluc') {
          this.loadBieuDoNhanLuc(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudongansach') {
          this.loadBieuDoNganSach(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudoantoan') {
          this.loadBieuDoAnToan(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudothethao') {
          this.loadBieuDoTheThao(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudodisan') {
          this.loadBieuDoDiSan(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudohoatdongdulich') {
          this.loadBieuDoHoatDongDuLich(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudonanglucdulich') {
          this.loadBieuDoNangLucDuLich(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudovaitrodulich') {
          this.loadBieuDoVaiTroDuLich(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudothuvien') {
          this.loadBieuDoThuVien(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudonghethuat') {
          this.loadBieuDoNgheThuat(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudodienanh') {
          this.loadBieuDoDienAnh(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudosunghiepvanhoa') {
          this.loadBieuDoSuNghiepVanHoa(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudomythuat') {
          this.loadBieuDoMyThuat(this.bieuDoName, filter);
        }
      }
    } else {
      if (
        this.bieuDoName === 'bdsltknctc' ||
        this.bieuDoName === 'bddstheotpkt' ||
        this.bieuDoName === 'bdcssxcn' ||
        this.bieuDoName === 'bddstheold' ||
        this.bieuDoName === 'bdtscssxcn' ||
        this.bieuDoName === 'bdldvsnk' ||
        this.bieuDoName === 'bddstheokv' ||
        this.bieuDoName === 'bddsnam' ||
        this.bieuDoName === 'bddstheotuoi' ||
        this.bieuDoName === 'bdbdds' ||
        this.bieuDoName === 'bdhd' ||
        this.bieuDoName === 'bddstheolaodong' ||
        this.bieuDoName === 'bdgtnongnghiep' ||
        this.bieuDoName === 'bdslccgtsxnn' ||
        this.bieuDoName === 'bdslccgtsxln' ||
        this.bieuDoName === 'bdsltkctch' ||
        this.bieuDoName === 'bdsltkctgia' ||
        this.bieuDoName === 'BIEUDOCHUYENDETSXCN' ||
        this.bieuDoName === 'bieudochisosanxuatcn' ||
        this.bieuDoName === 'bieudotongsocososanx' ||
        this.bieuDoName === 'bieudogiatrisxnn' ||
        this.bieuDoName === 'bieudogiatrisxln' ||
        this.bieuDoName === 'bieudogiatrisxlts' ||
        this.bieuDoName === 'bieudoccgiatrisxnn' ||
        this.bieuDoName === 'bieudoccgiatrisxln' ||
        this.bieuDoName === 'bieudoccgiatrisxts' ||
        this.bieuDoName === 'bdsltkctst' ||
        this.bieuDoName === 'bddstheotpkt' ||
        this.bieuDoName === 'bieudomamnon' ||
        this.bieuDoName === 'bieudophothong' ||
        this.bieuDoName === 'bieudothuongxuyen' ||
        this.bieuDoName === 'bieudotrungcap' ||
        this.bieuDoName === 'bieudodaihoc' ||
        this.bieuDoName === 'bieudogiaoducdaotao' ||
        this.bieuDoName === 'bieudokhambenh' ||
        this.bieuDoName === 'bieudoduoc' ||
        this.bieuDoName === 'bieudogiuongbenh' ||
        this.bieuDoName === 'bieudonhanluc' ||
        this.bieuDoName === 'bieudongansach' ||
        this.bieuDoName === 'bieudoantoan' ||
        this.bieuDoName === 'bieudothethao' ||
        this.bieuDoName === 'bieudodisan' ||
        this.bieuDoName === 'bieudohoatdongdulich' ||
        this.bieuDoName === 'bieudonanglucdulich' ||
        this.bieuDoName === 'bieudovaitrodulich' ||
        this.bieuDoName === 'bieudothuvien' ||
        this.bieuDoName === 'bieudonghethuat' ||
        this.bieuDoName === 'bieudodienanh' ||
        this.bieuDoName === 'bieudosunghiepvanhoa' ||
        this.bieuDoName === 'bieudomythuat'
      ) {
        const currentYear = new Date().getFullYear();
        const year = this.yearValue ? this.yearValue : currentYear;
        const filter = ' nam = ' + year + ' and mahuyen = ' + this.maHuyenBD;
        if (this.bieuDoName === 'bdsltknctc') {
          this.loadBieuDoThongKeChiTieuCho(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bdsltkctch') {
          this.loadBieuDoThongKeChiTieuCuaHang(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bdsltkctgia') {
          this.loadBieuDoThongKeChiTieuGia(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'BIEUDOCHUYENDETSXCN') {
          this.loadBieuDoSanXuatCongNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudochisosanxuatcn') {
          this.loadBieuDoChiSoGiaTriSanXuatCongNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudotongsocososanx') {
          this.loadBieuDoTongCoSoSanXuatCongNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudogiatrisxnn') {
          this.loadBieuDoGiaTriSanXuatNongNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudogiatrisxln') {
          this.loadBieuDoGiaTriSanXuatLamNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudogiatrisxlts') {
          this.loadBieuDoGiaTriSanXuatThuySan(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudoccgiatrisxnn') {
          this.loadBieuDoCoCauGiaTriSanXuatNongNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudoccgiatrisxln') {
          this.loadBieuDoCoCauGiaTriSanXuatLamNghiep(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudoccgiatrisxts') {
          this.loadBieuDoCoCauGiaTriSanXuatThuySan(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bdsltkctst') {
          this.loadBieuDoThongKeChiTieuSieuThi(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bddsnam' || this.bieuDoName === 'bdbdds') {
          this.loadBieuDoDanSoNam(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bddstheotuoi') {
          this.loadBieuDoDanSoTuoi(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bdhd') {
          this.loadBieuHoDan(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bddstheolaodong') {
          this.loadBieuDoDSTheoLaoDong(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bddstheotpkt') {
          this.loadBieuDoDSTheoTPKT(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudomamnon') {
          this.loadBieuDoMamNon(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudophothong') {
          this.loadBieuDoPhoThong(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudothuongxuyen') {
          this.loadBieuDoThuongXuyen(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudotrungcap') {
          this.loadBieuDoTrungCap(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudodaihoc') {
          this.loadBieuDoDaiHoc(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudogiaoducdaotao') {
          this.loadBieuDoGiaoDucDaoTao(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudokhambenh') {
          this.loadBieuDoKhamBenh(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudoduoc') {
          this.loadBieuDoDuoc(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudogiuongbenh') {
          this.loadBieuDoGiuongBenh(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudonhanluc') {
          this.loadBieuDoNhanLuc(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudongansach') {
          this.loadBieuDoNganSach(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudoantoan') {
          this.loadBieuDoAnToan(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudothethao') {
          this.loadBieuDoTheThao(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudodisan') {
          this.loadBieuDoDiSan(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudohoatdongdulich') {
          this.loadBieuDoHoatDongDuLich(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudonanglucdulich') {
          this.loadBieuDoNangLucDuLich(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudovaitrodulich') {
          this.loadBieuDoVaiTroDuLich(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudothuvien') {
          this.loadBieuDoThuVien(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudonghethuat') {
          this.loadBieuDoNgheThuat(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudodienanh') {
          this.loadBieuDoDienAnh(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudosunghiepvanhoa') {
          this.loadBieuDoSuNghiepVanHoa(this.bieuDoName, filter);
        } else if (this.bieuDoName === 'bieudomythuat') {
          this.loadBieuDoMyThuat(this.bieuDoName, filter);
        }
      }
    }
  }

  openMap() {
    this.mapService.selectMap().subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.mapId = result.data.id;
        this.createmap(result);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
  ChangeMap() {
    this.mapService.selectOne(this.mapId).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.map.remove();
        this.createmap(result);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  createmap(result) {
    const me = this;
    this.title = result.data.name;
    let style;
    try {
      style = JSON.parse(result.data.config);
    } catch (e) {
      alert('Không có dữ liệu, Map sẽ đưa về map mặc định!');
      this.openMap();
    }
    mapboxgl.accessToken = environment.TOKENMAPBOX;

    this.map = new mapboxgl.Map({
      container: 'map',
      style: style,
      zoom: 9.5,
      center: [106.004103, 22.786696]
    });

    // this.map.addControl(new MapboxLanguage({
    //   language: 'vi',
    // }));

    /* #region  Searc, elevation, direction init */
    let elevation = new MapboxElevationControl('https://wasac.github.io/rw-terrain/tiles/{z}/{x}/{y}.png', {
      font: [''],
      fontSize: 12,
      fontHalo: 1,
      mainColor: '',
      haloColor: '',
      tileSize: 256,
      units: '',
    });
    let geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });
    let directions = new MapboxDirections({
      accessToken: environment.TOKENMAPBOX,
      unit: 'metric',
      profile: 'mapbox/cycling',
      options: {
        language: 'vi',
      },
    });

    let ctrlElevation = new Button({
      className: 'btn-ctl-group',
      icon: 'fas fa-circle-sort',
      tooltip: 'Tính độ cao',
    });

    let ctrlSearch = new Button({
      className: 'btn-ctl-group',
      icon: 'fas fa-magnifying-glass',
      tooltip: 'Tìm địa chỉ',
    });

    let ctrlDirection = new Button({
      className: 'btn-ctl-group',
      icon: 'fas fa-route',
      tooltip: 'Chỉ đường',
    });

    let ctrlRerfesth = new Button({
      className: 'btn-ctl-group',
      icon: 'fas fa-sync',
      tooltip: 'Tải lại bản đồ',
    });

    ctrlRerfesth.on('click', () => {
      window.location.reload();
    })

    ctrlElevation.on('click', () => {
      if (!me.isShowElevation) {
        me.map.addControl(elevation, 'top-left');
        me.isShowElevation = true;
      } else {
        me.map.removeControl(elevation);
        me.isShowElevation = false;
      }
    });

    ctrlSearch.on('click', () => {
      if (!me.isShowSearchBar) {
        me.map.addControl(geocoder, 'top-left');
        me.isShowSearchBar = true;
      } else {
        me.map.removeControl(geocoder);
        me.isShowSearchBar = false;
      }
    });

    ctrlDirection.on('click', () => {
      if (!me.isShowDirection) {
        me.map.addControl(directions, 'top-left');
        me.isShowDirection = true;
      } else {
        me.map.removeControl(directions);
        me.isShowDirection = false;
      }
    });

    this.map.addControl(ctrlDirection, 'bottom-left');
    this.map.addControl(ctrlSearch, 'bottom-left');
    this.map.addControl(ctrlRerfesth, 'bottom-left');
    // this.map.addControl(ctrlElevation, "bottom-right");
    /* #endregion */
    let geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
    });

    this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');
    this.map.addControl(geolocate, 'top-left');
    this.map.addControl(new mapboxgl.FullscreenControl(), 'top-left');

    this.commonService.showSpinner();

    this.map.on('render', () => {
      const features = this.map.queryRenderedFeatures();
      me.contentNumberCount = 'Đối tượng: ' + features.length;
    });

    this.popup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: false,
    });

    this.map.once('load', (e) => {
      this.baseLayer = [];

      const obj_config = JSON.parse(result.data.config);
      const arr_groupLayer = obj_config.groups;
      const arr_layers = obj_config.layers;
      const arr_Active = [];
      /* #region  Set image to Map */

      obj_config?.listIcon.forEach((element) => {
        this.map.loadImage('FIcon/' + element.key, (error, image) => {
          if (error) throw error;
          this.map.addImage(element.value, image, { sdf: true });
        });
      });

      /* #endregion */
      for (let i = 0; i < arr_layers.length; i++) {
        const layer = arr_layers[i];
        if ((layer.metadata && layer.metadata.type === 'baselayer') || layer.type === 'raster') continue;
        let layerName = layer.id;
        if (layer.metadata && layer.metadata.name) layerName = layer.metadata.name;
        const layerStyle: any = {
          id: layer.id,
          name: layerName,
          'source-layer': layer['source'] !== undefined ? layer['source'] : null,
          visibility: me.map.getLayoutProperty(layer.id, 'visibility'),
          parentId: layer.metadata.groupId,
          tableName: layer.metadata.tablename,
          color: layer['paint']['icon-color'],
          type: layer.type,
          display: layer.display,
        };
        arr_groupLayer.push(layerStyle);
        this.lstLayer.push(layerStyle);
        if (me.map.getLayoutProperty(layer.id, 'visibility') !== 'none') arr_Active.push(layer.id);
      }
      me.arr_layer = me.createTree(arr_groupLayer, 'parentId', 'id', null, 'items', [
        {
          target: 'value',
          targetFunction(item) {
            return item.id;
          },
        },
        {
          target: 'text',
          targetFunction(item) {
            return item.name;
          },
        },
      ]);
      me.arrCheckedKeys_ActiveLayer = arr_Active;
      me.GetAllParentTextProperties_KendoTree(me.arr_layer);
      me.arrExpandedKeys_KendoTree = me.allParentNodes_KendoTree.slice();

      this.commonService.hideSpinner();
      me.layers = this.map.getStyle().layers;

      const controlExtend = new Button({
        className: 'btn-ctl-group',
        icon: 'fas fa-globe',
        tooltip: 'Xem toàn cảnh bản đồ',
      });
      this.map.addControl(controlExtend, 'bottom-left');
      controlExtend.on('click', () => {
        this.map.flyTo({
          center: style.center,
          essential: true,
          zoom: style.zoom,
        });
      });
      for (let i = 0; i < style.layers.length; i++) {
        if (style.layers[i].metadata.type === 'baselayer') {
          this.baseLayer.push({
            id: style.layers[i].id,
            name: style.layers[i].metadata.name,
            hide: style.layers[i].layout && style.layers[i].layout.visibility === 'none',
          });
        }
        if (style.layers[i].metadata && style.layers[i].metadata.table) {
          this.listTable.push({
            value: style.layers[i].metadata.table,
            text: style.layers[i].metadata.name,
          });
        }
      }

      if (this.baseLayer.length > 0) {
        const btnBaseMap = new Button({
          className: 'btn-ctl-group',
          icon: 'fas fa-layer-group',
          tooltip: 'Chọn bản đồ nền',
        });
        this.map.addControl(btnBaseMap, 'bottom-left');
        btnBaseMap.on('click', function () {
          document.getElementById('control-basemap').classList.toggle('base-map-visible');
        });
      }

      // let _export = new watergis.MapboxExportControl({
      //   PageSize: watergis.Size.A3,
      //   PageOrientation: watergis.PageOrientation.Portrait,
      //   Format: watergis.Format.PNG,
      //   DPI: watergis.DPI[96],
      //   Crosshair: true,
      //   PrintableArea: true,
      //   Local: 'vi',
      // });

      // this.map.addControl(_export, 'top-left');

      this.map.addControl(
        new MapboxExportControl({
          PageSize: Size.A3,
          PageOrientation: PageOrientation.Portrait,
          Format: Format.PNG,
          DPI: DPI[96],
          Crosshair: true,
          PrintableArea: true,
          Local: 'vi',
        }),
        'top-left'
      );
      const Draw = new MapboxDraw({
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
          polygon: true,
          line_string: true,
          trash: true,
        },
        defaultMode: 'simple_select',
      });
      this.map.addControl(Draw, 'top-left');
      me.drawControl = Draw;
      this.map.on('draw.create', updateArea);
      this.map.on('draw.delete', updateArea);
      this.map.on('draw.update', updateArea);
      this.map.on('draw.render', updateArea);
      function updateArea() {
        const data = Draw.getAll();
        if (data.features.length > 0) {
          if (me.drawControl.getMode() === 'draw_polygon') {
            const area = turf.area(data.features[data.features.length - 1]);
            // Restrict the area to 2 decimal points.
            const rounded_area = Math.round(area * 100) / 100;
            me.contentMeasure = `Diện tích ${rounded_area} m<sup>2</sup>`;
          } else if (me.drawControl.getMode() === 'draw_line_string') {
            const along = turf.length(data);
            // Restrict the area to 2 decimal points.
            const rounded_area = Math.round(along * 100) / 100;
            // console.log(rounded_area);
            me.contentMeasure = `Chiều dài ${rounded_area} km`;
          }
        } else {
          me.contentMeasure = '';
        }
      }
    });

    if (this.checkMapId === 391 || this.checkMapId === 394 || this.checkMapId === 361 || this.checkMapId === 392 || this.checkMapId === 390 || this.checkMapId === 393 || this.checkMapId === 69) {
      const bieuDoControl = new Button({
        className: 'btn-ctl-group',
        icon: 'fas fa-chart-bar',
        tooltip: 'Biểu đồ',
      });
      this.map.addControl(bieuDoControl, 'top-left');
      bieuDoControl.on('click', function () {
        me.listBieuDoChil = me.listBieuDo.filter((x) => x.parentid === me.checkMapId.toString());
        document.getElementById('control-bieudo').classList.toggle('bieudo-visible');
      });
    }

    this.map.on('mouseup', (e) => {
      if (e.originalEvent.button === 2) {
        this.openPopup(e, this.popup);
      }
    });

    this.map.on('click', (e) => {
      if (me.drawControl.getMode() === 'draw_polygon' || me.drawControl.getMode() === 'draw_line_string') {
        if (this.marker) this.marker.remove();
      } else {
        this.addMarker(e.lngLat);
      }
      if (this.popup.isOpen()) this.popup.remove();
      this.clearLayerFeature();

      this.area = e.lngLat;
      this.href = 'https://www.google.com/maps/dir/?api=1&destination=' + e.lngLat.lat + ',' + e.lngLat.lng;
      let features = this.map.queryRenderedFeatures(e.point);
      let fe = features[0];
      let id;
      try {
        id = fe.properties.id;
      } catch (e) {
        return;
      }
      this.tableName = fe.layer.metadata.tablename;

      if (fe.geometry.type !== 'Point') {
        this.map.addSource('vertor-source', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: fe.geometry,
          },
        });
      }
      if (fe.layer.type === 'fill') {
        this.map.addLayer({
          id: 'vertor-layer',
          type: 'fill',
          source: 'vertor-source',
          layout: {},
          paint: {
            'fill-color': '#FF3333',
            'fill-opacity': 0.6,
            'fill-outline-color': 'red',
          },
        });
      }
      if (fe.layer.type === 'line') {
        this.map.addLayer({
          id: 'vertor-layer',
          type: 'line',
          source: 'vertor-source',
          layout: {},
          paint: {
            'line-color': '#0000FF',
            'line-width': 2,
          },
        });
      }
      this.openSideBar();
      this.loadDataSideBar(e.point);
      this.point = e.point;
    });
    me.map = this.map;
  }
  clearLayerFeature() {
    const t = this.map;
    t.getLayer('vertor-layer') && (t.removeLayer('vertor-layer'), t.removeSource('vertor-source'));
  }
  addMarker(e) {
    this.marker && this.marker.remove(),
      (this.marker = new mapboxgl.Marker({
        color: '#ff0000',
        draggable: !1,
      })
        .setLngLat(e)
        .addTo(this.map));
  }
  loadDataSideBar(e) {
    const features = this.map.queryRenderedFeatures(e);
    if (features[0].source !== 'vertor-source') {
      this.fe = features[0];
    } else {
      this.fe = features[1];
    }
    const pro = this.fe.properties;
    this.obName = this.fe.layer.metadata.name;
    const tableName = this.fe.layer.metadata.tablename;
    this.getFiles(tableName, pro.id);
    if (this.checkTable === null || this.checkTable === undefined) {
      this.checkTable = tableName;
      this.getFields(tableName, pro);
    } else {
      if (this.checkTable !== tableName) {
        this.checkTable = tableName;
        this.getFields(tableName, pro);
      } else {
        this.getData(this.listFieldName, pro);
      }
    }
  }
  getFields(tableName, pro) {
    this.fieldService.getAllByTableName(tableName).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.listData = [];
        this.listFieldName = res.data;
        res.data.forEach((element) => {
          for (const attr in pro) {
            if (
              pro[attr] !== 'null' &&
              attr !== 'status' &&
              attr !== 'id' &&
              attr !== 'createddate' &&
              pro[attr] !== null &&
              element.fieldname.toLowerCase() === attr
            ) {
              this.listData.push({
                key: attr,
                value: pro[attr],
                name: element.name,
              });
            }
          }
        });
        if (this.listData.length > 0) this.openSideBar();
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
  getData(data, pro) {
    this.listData = [];
    data.forEach((element) => {
      for (const attr in pro) {
        if (
          pro[attr] !== 'null' &&
          attr !== 'status' &&
          attr !== 'id' &&
          attr !== 'createddate' &&
          pro[attr] !== null &&
          element.fieldname.toLowerCase() === attr
        ) {
          this.listData.push({
            key: attr,
            value: pro[attr],
            name: element.name,
          });
        }
      }
    });
    if (this.listData.length > 0) this.openSideBar();
  }
  openSideBar() {
    const elem = document.getElementById('create-check-plan-content-side');
    const collapsed = elem.classList.add('sdb-content-pickerrr');
    const ele = document.getElementById('toggle-side-menu');
    ele.classList.remove('sdb-content-pickerr');
  }
  closeSideBar() {
    const elem = document.getElementById('create-check-plan-content-side');
    const collapsed = elem.classList.remove('sdb-content-pickerrr');
    if (this.marker) this.marker.remove();
    this.clearLayerFeature();
  }
  openPopup(e, popup) {
    if (this.marker) this.marker.remove();
    this.clearLayerFeature();
    if (this.isTraCuuTTPolygon) return;
    if (this.drawControl.getMode() !== 'simple_select') return;
    const features = this.map.queryRenderedFeatures(e.point);
    const fe = features[0];
    if (!fe) return;
    if (fe.layer.source === 'mapbox-gl-draw-hot') return;
    const pro = fe.properties;
    const tableName = fe.layer.metadata.tablename;
    let html = '';
    this.fieldService.getAllByTableName(tableName).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        res.data.forEach((element) => {
          if (element) {
            for (const attr in pro) {
              if (
                pro[attr] !== 'null' &&
                pro[attr] !== null &&
                element.fieldname.toLowerCase() === attr.toLowerCase()
              ) {
                html += '<span>' + element.name + ': ' + pro[attr] + '</span><br/>';
              }
            }
          }
        });
        if (html) popup.setLngLat(e.lngLat).setHTML(html).addTo(this.map);
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  getFiles(tableName: string, IdData: number) {
    this.dataService.getFiles(tableName, IdData).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        console.log(res)
        this.LstVideo = [];
        this.LstImgPath = [];
        if (res.data.listImage.length > 0) {
          this.LstImgPath = res.data.listImage;
          this.imageId = res.data.listImage[0].id;
          console.log(this.imageId)
        }
        if (res.data.listVideo.length > 0) {
          this.videoId = res.data.listVideo[0].id;
          res.data.listVideo.forEach((element) => {
            this.LstVideo.push({ id: element.id, path: 'Video/' + element.tablename + '/' + element.filename });
          });
        }
      }
    });
  }
  lstDVHCCHBD: any[] = [];
  lstDVHCCXBD: any[] = [];
  lstDVHCCXDSL: any[] = [];
  loadDVHC() {
    this.dataService.getDataByTableName('dvhccx').subscribe((result) => {
      this.lstDVHCCX = JSON.parse(result.data);
    });
    this.dataService.getDataByTableName('dvhcch').subscribe((res) => {
      this.lstDVHCCH = JSON.parse(res.data);
      this.lstDVHCCHBD = JSON.parse(res.data);;
    });
  }
  openFullscreen() {
    const elem = document.getElementById('full');
    const collapsed = elem.classList.toggle('fullscreen');
    const ele = document.getElementById('table');
    ele.classList.toggle('tableShow');
    const height = document.getElementById('height');
    height.classList.toggle('tableShow');
  }
  openCreate() {
    this.dialog
      .open(
        MapCreateComponent,
        this.commonService.configDialog('60%', {
          area: this.area,
          DVHCCX: this.lstDVHCCX,
          DVHCCH: this.lstDVHCCH,
        })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
        }
      });
  }
  openCre() {
    this.dialog
      .open(
        MapAddComponent,
        this.commonService.configDialog('60%', {
          tableName: this.tableName,
          DVHCCX: this.lstDVHCCX,
          DVHCCH: this.lstDVHCCH,
        })
      )
      .afterClosed()
      .subscribe((ressult) => {
        if (ressult === true) {
        }
      });
  }
  viewData(item) {
    this.textSearch = null;
    this.SearchModel.text = null;
    this.tableName = item.data.tableName;
    this.fieldService.getName(this.tableName).subscribe((res) => {
      this.name = res;
    });
    this.SearchModel.page = 1;
    this.getField();
    setTimeout(() => {
      this.loadDataNew();
    }, 500);
    // const myObject = item;
    // const link = `#app-home/mapdetail/${this.checkMapId}`;
    // // Lưu trữ dữ liệu trong localStorage
    // sessionStorage.setItem('mapData', JSON.stringify(myObject));
    // // Mở tab mới
    // const newTab = window.open(link, '_blank');
  }

  // chonTimKiemKhongGian(type) {
  //   if (type === 'radius') {
  //     const center = this.map.getCenter();
  //     this.drawCicle = new MapboxCircle({ lat: center.lat, lng: center.lng }, 25000, {
  //       editable: true,
  //       fillColor: '#29AB87',
  //     }).addTo(this.map);
  //   } else if (type === 'polygon') {
  //     this.onActiveDraw();
  //     this.isTraCuuTTPolygon = true;
  //     if (this.drawCicle) this.drawCicle.remove();
  //     this.drawCicle = null;
  //   } else {
  //     this.isTraCuuTTPolygon = false;
  //     if (this.drawCicle) this.drawCicle.remove();
  //     this.drawCicle = null;
  //     this.onCloseDraw();
  //   }
  // }

  targetAll: any[] = [];
  dataAll: any[] = [];
  loadDataNew() {
    this.SearchModel.tableName = this.tableName;
    this.dataService.PaingData(this.SearchModel).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.targetNew = result;
        this.target = JSON.parse(this.targetNew.data.data);
        // this.dataAll = this.dataAll.filter((x) => x.tableName !== this.tableName);
        // this.dataAll.push({ data: this.target, tableName: this.tableName });
        this.targetAll = this.targetAll.filter((item) => item.tableName !== this.tableName);
        this.targetAll.push({ tableName: this.tableName, fields: this.fields, name: this.name, data: this.target });
        if (this.target.length <= 0 || this.target === null) {
          this.commonService.toastrDanger('Đối tượng ' + this.name + ' không có dữ liệu');
        } else {
          this.showTableAttribute = true;
        }
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  changeHuyenbyXa() {
    this.dataService.getDataByTableName('dvhccx').subscribe((result) => {
      this.lstDVHCCXDSL = JSON.parse(result.data);
      this.lstDVHCCXDSL = this.lstDVHCCXDSL.filter((x) => x.macha === this.maHuyenDsDt);
    });
    this.SearchModel.dvhcch = this.maHuyenDsDt;
    this.SearchModel.dvhccx = this.maXaDsDt;
    this.loadDataNew();
  }

  loadHuyenXa() {
    this.SearchModel.dvhcch = this.maHuyenDsDt;
    this.SearchModel.dvhccx = this.maXaDsDt;
    this.loadDataNew();
  }

  onOpenMap() { }
  reloadMap() {
    location.reload();
  }
  search() {
    this.textSearch = '';
    this.SearchModel.page = 1;
    this.loadDataNew();
  }
  clearFormSearch() {
    this.textSearch = '';
  }
  blobToFile(data: any, type: string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  export(typeFile: string) {
    const date = Date();
    const CurrDate = new ConvertDatePipe().transform(date);
    const ex = new ExportDataModel();
    ex.featureclassId = 0;
    ex.tableName = this.tableName;
    ex.fileType = typeFile;
    ex.prj = '105453';
    ex.fields = [];
    ex.filter = 'status = 1 ';
    const url = `${environment.APP_GIS_URL}/App/Gis/api/Data/export`;
    const req = new HttpRequest('POST', url, ex, { responseType: 'blob' });

    this.httpClient
      .request<Blob>(req)
      .pipe()
      .subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            let result = event.body;
            if (typeFile === 'excel') {
              this.blobToFile(
                result,
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                CurrDate + '-' + ex.tableName + '.xlsx'
              );
            } else if (typeFile === 'shp') {
              this.blobToFile(result, 'application/zip', ex.tableName + '.zip');
            }
          }
        },
        (error) => {
          throw error;
        }
      );
  }

  viewDetail(index) {
    const elem = document.getElementById('full');
    if (elem) {
      const collapsed = elem.classList.remove('fullscreen');
    }
    if (this.marker) this.marker.remove();
    const shape = index.shape;
    const geometry = WKTToGeoJSON(shape);
    let coor = geometry.coordinates[0];

    let html = '';

    const tableName = this.tableName;
    this.fieldService.getName(tableName).subscribe((res) => {
      this.name = res;
    });
    this.dataService.getFieldsByTableName(tableName).subscribe((result) => {
      result.data.forEach((element) => {
        for (const attr in index) {
          if (
            attr !== 'shape' &&
            attr !== 'id' &&
            index[attr] !== 'null' &&
            index[attr] !== null &&
            element.fieldname.toLowerCase() === attr.toLowerCase()
          ) {
            html += '<span>' + element.name + ': ' + index[attr] + '</span><br/>';
          }
        }
      });
      if (html) new mapboxgl.Popup().setLngLat(coor).setHTML(html).addTo(this.map);
    });

    if (geometry.type === 'Point') {
      coor = geometry.coordinates;
      this.map.flyTo({
        center: coor,
        essential: true,
        duration: 5000,
        zoom: 15,
      });
    } else {
      this.zoomTo(index);
    }
  }
  zoomTo(index) {
    const elem = document.getElementById('full');
    if (elem) {
      const collapsed = elem.classList.remove('fullscreen');
    }
    this.clearLayerFeature();
    const shape = index.shape;
    const geometry = WKTToGeoJSON(shape);
    if (geometry.type === 'Point') {
      this.addMarker(geometry.coordinates);
    }
    const geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: geometry,
        },
      ],
    };
    if (geometry.type !== 'Point') {
      this.map.addSource('vertor-source', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: geometry,
        },
      });
    }
    if (geometry.type === 'Polygon') {
      this.map.addLayer({
        id: 'vertor-layer',
        type: 'fill',
        source: 'vertor-source',
        layout: {},
        paint: {
          'fill-color': '#FF3333',
          'fill-opacity': 0.6,
          'fill-outline-color': 'red',
        },
      });
    }
    if (geometry.type === 'LineString') {
      this.map.addLayer({
        id: 'vertor-layer',
        type: 'line',
        source: 'vertor-source',
        layout: {},
        paint: {
          'line-color': '#0000FF',
          'line-width': 2,
        },
      });
    }
    const bbox = turf.bbox(geojson);
    this.map.fitBounds(
      [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ],
      {
        padding: 20,
      }
    );
  }
  // Vẽ vùng trên bản đồ
  onActiveDraw() {
    this.onCloseDraw();
    this.isDrawing = true;
    this.map.doubleClickZoom.disable();
    const markerHeight = 40;
    const markerRadius = 40;
    const linearOffset = 25;
    this.popupDraw = new mapboxgl.Popup({
      offset: {
        top: [0, 20],
        'top-left': [0, 0],
        'top-right': [0, 0],
        bottom: [0, -markerHeight],
        'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
        'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
        left: [markerRadius, (markerHeight - markerRadius) * -1],
        right: [-markerRadius, (markerHeight - markerRadius) * -1],
      },
      anchor: 'left',
      closeButton: false,
      closeOnClick: false,
    }).addTo(this.map);
    this.map.getCanvas().style.cursor = 'pointer';
    const me = this;
    // Mouse move
    me.listeners['mouseMovePolygon'] = me.mouseMovePolygon.bind(me);
    me.map.on('mousemove', me.listeners['mouseMovePolygon']);
    // Click
    me.listeners['mouseClickPolygon'] = me.mouseClickPolygon.bind(me);
    me.map.on('click', me.listeners['mouseClickPolygon']);
    // dblClick
    me.listeners['mouseDoubleClickPolygon'] = me.mouseDoubleClickPolygon.bind(me);
    me.map.on('dblclick', me.listeners['mouseDoubleClickPolygon']);
    // Mouse down
    me.listeners['mouseDownPolygon'] = me.mouseDownPolygon.bind(me);
    me.map.on('mousedown', me.listeners['mouseDownPolygon']);
    // Mouse up
    me.listeners['mouseUpPolygon'] = me.mouseUpPolygon.bind(me);
    me.map.on('mouseup', me.listeners['mouseUpPolygon']);
  }

  mouseMovePolygon(e: any) {
    this.addPointDraw(e.lngLat.lng, e.lngLat.lat);
    this.popupDraw.setLngLat([e.lngLat.lng, e.lngLat.lat]).setHTML('<span>Nhấn 2 lần vào bản đồ để kết thúc</span>');
    if (this.coordinateDraw && this.coordinateDraw.length > 0) {
      const newFeaLine: any = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      };
      const newFeaPoly: any = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [[]],
        },
      };
      let coors = _(this.coordinateDraw);
      if (!coors) {
        coors = [];
      }
      coors.push([e.lngLat.lng, e.lngLat.lat]);
      const fcoor = _(coors);
      fcoor.push(_(coors[0]));
      newFeaPoly.geometry.coordinates = [fcoor];
      newFeaLine.geometry.coordinates = coors;
      if (this.map.getLayer('draw-poly-preview')) {
        this.map.getSource('draw-poly-preview').setData(newFeaPoly);
      } else {
        this.map.addSource('draw-poly-preview', {
          type: 'geojson',
          data: newFeaPoly,
        });
        this.map.addLayer({
          id: 'draw-poly-preview',
          type: 'fill',
          source: 'draw-poly-preview',
          paint: {
            'fill-opacity': 0.1,
            'fill-color': '#0000ff',
          },
        });
      }
      if (this.map.getLayer('draw-line-preview')) {
        this.map.getSource('draw-line-preview').setData(newFeaLine);
      } else {
        this.map.addSource('draw-line-preview', {
          type: 'geojson',
          data: newFeaLine,
        });
        this.map.addLayer({
          id: 'draw-line-preview',
          type: 'line',
          source: 'draw-line-preview',
          paint: {
            'line-color': '#0000ff',
            'line-width': 2,
            'line-dasharray': [2.6, 2.6],
          },
        });
      }
    }
  }
  sideBar() {
    const elem = document.getElementById('toggle-side-menu');
    const collapsed = elem.classList.toggle('sdb-content-pickerr');
    const ele = document.getElementById('create-check-plan-content-side');
    ele.classList.remove('sdb-content-pickerrr');
    if (this.marker) {
      this.marker.remove();
      this.clearLayerFeature();
    }
  }
  OpenSearch(id) {
    if (id === 'to-thua-search') {
      const elem = document.getElementById(id);
      const a = elem.classList.toggle('activee');
      const ele = document.getElementById('goc-ranh-search');
      ele.classList.remove('activee');
      const el = document.getElementById('dia-diem-search');
      el.classList.remove('activee');
      if (a) this.show = true;
      else this.show = false;
      this.show2 = false;
      this.show1 = false;
    }
    if (id === 'goc-ranh-search') {
      const elem = document.getElementById(id);
      const a = elem.classList.toggle('activee');
      const ele = document.getElementById('to-thua-search');
      ele.classList.remove('activee');
      const el = document.getElementById('dia-diem-search');
      el.classList.remove('activee');
      if (a) this.show2 = true;
      else this.show2 = false;
      this.show = false;
      this.show1 = false;
    }
    if (id === 'dia-diem-search') {
      const elem = document.getElementById(id);
      const a = elem.classList.toggle('activee');
      const ele = document.getElementById('goc-ranh-search');
      ele.classList.remove('activee');
      const el = document.getElementById('to-thua-search');
      el.classList.remove('activee');
      if (a) this.show1 = true;
      else this.show1 = false;
      this.show = false;
      this.show2 = false;
    }
  }
  mouseClickPolygon(e: any) {
    if (this.isDrawing === true) {
      this.coordinateDraw.push([e.lngLat.lng, e.lngLat.lat]);
      this.drawing = true;
    }
  }

  getMap() {
    if (this.UrlParam.includes('/app-home')) {
      const id = Number(this.route.snapshot.paramMap.get('id'));
      this.mapService.selectOne(id).subscribe((result1) => {
        if (result1.code === SUCCESS_RESPONE) {
          this.createmap(result1);
        } else {
          this.commonService.toastrDanger(result1.message);
        }
      });
    }
    if (this.UrlParam.includes('/gis') === true) {
      this.dataService.getMap().subscribe((result) => {
        if (result.code === SUCCESS_RESPONE) {
          this.lstMap = result.data;
          if (this.lstMap && this.lstMap.length > 0) {
            this.mapId = this.lstMap[0].id;
            this.openMap();
            this.ChangeMap();
          } else {
            this.openMap();
          }
        }
      });
    }
  }
  SearchData() {
    this.SearchModel.text = this.textSearch;
    this.getField();
    this.loadDataNew();
    this.showTableAttribute = true;
    if (this.showTableAttribute) {
      this.show = false;
      this.show2 = false;
      const ele = document.getElementById('to-thua-search');
      ele.classList.remove('activee');
    }
  }
  fieldAll: any[] = [];
  getField() {
    this.fieldService.getAllByTableName(this.tableName).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.fields = result.data;
        // this.fieldAll = this.fieldAll.filter((x) => x.tableName !== this.tableName && x.name !== this.name);
        // this.fieldAll.push({ tableName: this.tableName, fields: this.fields, name: this.name, data: this.targetAll });
      } else {
        this.target = null;
        this.showTableAttribute = false;
      }
    });
  }
  LogToAdmin() {
    window.open(this.routeLink, '_blank');
  }
  LogOut() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
  mouseDoubleClickPolygon(e: any) {
    let feature = null;
    const fcoor = _(this.coordinateDraw);
    fcoor.push(_(this.coordinateDraw[0]));
    feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [fcoor],
      },
      properties: {},
    };
    if (feature) this.dataDraw.features.push(feature);
    if (this.isTraCuuTTPolygon) {
      const wktPolygon = GeoJSONToWKT(this.dataDraw.features[0]);
      this.polygon = wktPolygon;
    }
    // this.isDrawing = false;
    // this.layerDraw();
    // this.clearAllListener();
    this.popupDraw.remove();
    this.coordinateDraw = [];
    this.dataDraw.features = [];
  }
  mouseDownPolygon(e: any) { }

  mouseUpPolygon(e: any) { }

  layerDraw() {
    if (this.map.getSource('draw-source')) {
      this.map.getSource('draw-source').setData(this.dataDraw);
    } else {
      this.map.addSource('draw-source', {
        type: 'geojson',
        data: this.dataDraw,
      });
      this.map.addLayer({
        id: 'draw-poly',
        type: 'fill',
        source: 'draw-source',
        paint: {
          'fill-color': '#0000ff',
          'fill-opacity': 0.1,
          'fill-outline-color': '#0000ff',
        },
        filter: ['==', '$type', 'Polygon'],
      });
      this.map.addLayer({
        id: 'draw-poly-outline',
        type: 'line',
        source: 'draw-source',
        paint: {
          'line-color': '#0000ff',
          'line-width': 2,
        },
        filter: ['==', '$type', 'Polygon'],
      });
      this.map.addLayer({
        id: 'draw-line',
        type: 'line',
        source: 'draw-source',
        paint: {
          'line-color': '#0000ff',
          'line-width': 2,
        },
        filter: ['==', '$type', 'LineString'],
      });
    }
  }

  addPointDraw(lng: any, lat: any) {
    const geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lng, lat],
          },
        },
      ],
    };
    if (this.map.getLayer('point-cursor')) {
      this.map.getSource('point-cursor').setData(geojson);
    } else {
      this.map.addSource('point-cursor', {
        type: 'geojson',
        data: geojson,
      });
      this.map.addLayer({
        id: 'point-cursor',
        type: 'circle',
        source: 'point-cursor',
        paint: {
          'circle-radius': 6,
          'circle-color': '#ffc500',
          'circle-opacity': 0.5,
        },
      });
    }
  }

  onCloseDraw() {
    this.dataDraw.features = [];
    this.dataPointPreview.features = [];
    this.coordinateDraw = [];
    this.isDrawing = false;
    this.map.getCanvas().style.cursor = '';
    this.clearAllListener();
    this.clearAllPopup();
    this.clearLayerDrawCurrent();
    this.clearLayerDraw();
    this.listeners = {};
  }

  clearAllListener() {
    for (const evt in this.listeners) {
      if (evt) {
        this.map.off('mousemove', this.listeners[evt]);
        this.map.off('click', this.listeners[evt]);
        this.map.off('dblclick', this.listeners[evt]);
        this.map.off('mousedown', this.listeners[evt]);
      }
    }
  }

  clearAllPopup() {
    if (this.popupDraw) this.popupDraw.remove();
  }

  clearLayerDrawCurrent() {
    if (this.map.getLayer('draw-line-preview')) {
      this.map.removeLayer('draw-line-preview');
      this.map.removeSource('draw-line-preview');
    }
    if (this.map.getLayer('draw-poly-preview')) {
      this.map.removeLayer('draw-poly-preview');
      this.map.removeSource('draw-poly-preview');
    }
    if (this.map.getLayer('point-cursor')) {
      this.map.removeLayer('point-cursor');
      this.map.removeSource('point-cursor');
    }
  }

  clearLayerDraw() {
    if (this.map.getSource('draw-source')) {
      this.map.removeLayer('draw-poly');
      this.map.removeLayer('draw-poly-outline');
      this.map.removeLayer('draw-line');
      this.map.removeSource('draw-source');
    }
  }

  onShowMenuContext(coordinate: any, nativeElement: any) {
    const markerHeight = 40,
      markerRadius = 40,
      linearOffset = 25;

    const popupOffsets = {
      top: [0, 20],
      'top-left': [0, 0],
      'top-right': [0, -20],
      // 'bottom': [0, -markerHeight],
      // 'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
      // 'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
      // 'left': [20, 10],
      right: [-markerRadius, (markerHeight - markerRadius) * -1],
    };
    if (this.popupMenuContext) this.popupMenuContext.remove();
    this.popupMenuContext = new mapboxgl.Popup({
      offset: popupOffsets,
      anchor: 'left',
      className: 'popup-hover',
      closeButton: false,
      closeOnMove: true,
    })
      .setMaxWidth('300px')
      .setLngLat(coordinate)
      .setDOMContent(nativeElement)
      .addTo(this.map);
  }

  onCloseMenuContext() {
    if (this.popupMenuContext) this.popupMenuContext.remove();
  }
  openCreateImage() {
    this.dialog
      .open(
        MapImageComponent,
        this.commonService.configDialog('60%', {
          fe: this.fe,
        })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadDataSideBar(this.point);
        }
      });
  }
  visibleLayer(item, e) {
    const checked = e.target.checked;
    this.map.setLayoutProperty(item.id, 'visibility', e.target.checked ? 'visible' : 'none');
  }
  GetAllParentTextProperties_KendoTree(items: Array<any>) {
    items.forEach((i) => {
      if (i.items) {
        this.allParentNodes_KendoTree.push(i.value);
        this.GetAllParentTextProperties_KendoTree(i.items);
      }
    });
  }
  public isChecked_KendoTree = (dataItem: any, index: string): CheckedState => {
    if (this.containsItem_KendoTree(dataItem)) {
      return 'checked';
    }

    if (this.isIndeterminate_KendoTree(dataItem.items)) {
      return 'indeterminate'; //// 'checked';
    }

    return 'none';
  };

  private containsItem_KendoTree(item: any): boolean {
    return this.arrCheckedKeys_ActiveLayer.indexOf(item['value']) > -1;
  }

  private isIndeterminate_KendoTree(items: any[] = []): boolean {
    let idx = 0;
    let item;

    while ((item = items[idx])) {
      if (this.isIndeterminate_KendoTree(item.items) || this.containsItem_KendoTree(item)) {
        return true;
      }

      idx += 1;
    }

    return false;
  }

  onSearchTableAttribute() {
    if (this.drawCicle) {
      this.polygon = this.drawCicle ? GeoJSONToWKT(this.drawCicle._getCircleGeoJSON().features[0]) : '';
    }
    this.SearchModel.text = this.textSearch;
    this.SearchModel.dvhcch = this.maHuyenDsDt;
    this.SearchModel.dvhccx = this.maXaDsDt;
    this.SearchModel.page = 1;
    this.loadDataNew();
  }

  public onStateChange(index: any, state: string): void {
    const elem = document.getElementById('lam-' + index);
    const windowWidth = 310;
    if (elem) {
      if (state === 'minimized') {
        elem.classList.add('fullscreen1');
        elem.style.top = '700px';
        elem.style.left = `${index * windowWidth + 50}px`;
      } else {
        elem.classList.remove('fullscreen1');
      }
    }
  }

  onCloseTableAttribute(tableName) {
    this.targetAll = this.targetAll.filter((item) => item.tableName !== tableName);
  }

  createTree(array: any[], parentIdProperty, idProperty, parentIdValue, childrenProperty: string, fieldMappings): any {
    const tree = [];

    const nodes = _filter(array, [parentIdProperty, parentIdValue]);

    _forEach(nodes, (node) => {
      const newNode = {
        data: node,
      };

      this.mapFields(node, newNode, fieldMappings);

      newNode[childrenProperty] = this.createTree(
        array,
        parentIdProperty,
        idProperty,
        node[idProperty],
        childrenProperty,
        fieldMappings
      );

      tree.push(newNode);
    });

    return tree;
  }

  mapFields(node, newNode, fieldMappings): void {
    _forEach(fieldMappings, (fieldMapping) => {
      if (!fieldMapping['target']) {
        return;
      }

      if (fieldMapping.hasOwnProperty('value')) {
        newNode[fieldMapping['target']] = fieldMapping['value'];
      } else if (fieldMapping['source']) {
        newNode[fieldMapping['target']] = node[fieldMapping['source']];
      } else if (fieldMapping['targetFunction']) {
        newNode[fieldMapping['target']] = fieldMapping['targetFunction'](node);
      }
    });
  }
  checkedChange_KendoTree(evt) {
    this.visibleOrNoneLayerMap_KendoTree(evt);
  }

  visibleOrNoneLayerMap_KendoTree(objTree) {
    const obj_item = objTree.item.dataItem.data;
    if (obj_item && obj_item.hasOwnProperty('visibility')) {
      if (obj_item.visibility !== 'none' && !this.containsItem_KendoTree(objTree.item.dataItem)) {
        obj_item.visibility = 'none';
        this.map.setLayoutProperty(obj_item.id, 'visibility', 'none');
      } else {
        obj_item.visibility = 'visible';
        this.map.setLayoutProperty(obj_item.id, 'visibility', 'visible');
        if (obj_item.type === 'symbol') {
          this.loadIcon(obj_item['source-layer']);
        }
      }
    }

    if (objTree.children && objTree.children.length > 0) {
      for (let i = 0; i < objTree.children.length; i++) {
        this.visibleOrNoneLayerMap_KendoTree(objTree.children[i]);
      }
    }
  }
  batTatBanDoNen(id) {
    for (let i = 0; i < this.baseLayer.length; i++) {
      this.map.setLayoutProperty(this.baseLayer[i].id, 'visibility', 'none');
    }
    if (id === 'nono') {
      this.map.setLayoutProperty(id, 'visibility', 'none');
    } else {
      this.map.setLayoutProperty(id, 'visibility', 'visible');
    }
  }

  loadIcon(lstTable) {
    this.mapService.getIcon(lstTable).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        const icons = res.data;
        icons.forEach((icon) => {
          if (
            icon.iconName !== null &&
            icon.iconName !== undefined &&
            icon.iconName !== 'undefined' &&
            icon.iconName !== 'null'
          ) {
            this.map.loadImage(`${environment.APP_GIS_URL}/SysFileManager/Icon/${icon.iconName}`, (err, image) => {  // deploy BG dùng
              //this.map.loadImage(`${environment.FileGis}/SysFileManager/Icon/${icon.iconName}`, (err, image) => {
              // chạy deploy BT
              if (image !== undefined && image !== null && icon.iconName !== 'undefined' && icon.iconName !== 'null') {
                this.map.addImage(icon.iconName, image);
              }
            });
          }
        });
      }
    });
  }
}
export class Button extends mapboxgl.Evented {
  constructor(options) {
    super(options);
    this.options = options ? options : {};
    if (!this.options.icon) this.options.icon = 'fa fa-flickr';
  }

  /**
   * @description Register a control on the map and give it a chance to register event listeners and resources.
   * This method is callde by Map#addControl internally.
   * @param {Map} map the Map this  wcontrolill be added to.
   * @returns {HTMLElement}  The control's container element. This should be created by the
   * control and returned by onAdd without being attached to the DOM: the map will insert the control's element into the DOM as necessary.
   */
  onAdd(map) {
    this._map = map;
    const me = this; // might use this later
    this._div = document.createElement('div');
    this._div.title = this.options.tooltip !== undefined ? this.options.tooltip : 'Button click';
    this._div.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    if (this.options.className) this._div.className += ' ' + this.options.className;

    this._buttonCls = 'mapboxgl-button';
    if (this.options.buttonCls) this._buttonCls = this.options.buttonCls;

    this._div.style.fontSize = '14px';

    const input = this.createLayerInputToggle();
    this._div.appendChild(input);
    /**
     * @description Fired when click button.
     */
    this._div.addEventListener('click', function (e) {
      me.fire('click', me);
    });
    return this._div;
  }

  /**
   * @description Unregister a control on the map and give it a chance to detach event listeners and resources.
   * This method is called by Map#removeControl internally.
   * @param {Map} map the Map this control will be removed from.
   * @returns {undefined}  there is no required return value for this method.
   */
  onRemove(map) {
    this._map = map;
    this._div.parentNode.removeChild(this._div);
    this._map = undefined;
  }

  /**
   * @private
   * @description Create layer input
   */
  createLayerInputToggle() {
    const button = document.createElement('button');
    const icon = document.createElement('i');
    icon.className = this.options.icon;
    button.className = this._buttonCls;
    button.appendChild(icon);
    return button;
  }
}
