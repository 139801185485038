import { Routes } from '@angular/router';
import { SysAppCreateComponent } from './config-app-create/config-app-create.component';
import { SysAppIndexComponent } from './config-app-index/config-app-index.component';

export const routes: Routes = [
    {
        path: '',
        // canActivate: [AuthGuard],
        // data: { roles: ['Admin', 'Company'] },
        children: [
            { path: '', component: SysAppIndexComponent },
            { path: 'create', component: SysAppCreateComponent },
          ],
    },
];
