<form #frm="ngForm">
    <nb-card>
        <nb-card-header class="nb-card-header">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    <label>{{title}}</label>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    <kendo-stepper [steps]="ListTientrinh" stepType="label" [(currentStep)]="currentStep" [linear]="false">
                        <ng-template kendoStepperStepTemplate let-step>
                            <div class="custom-step">
                                <span class="{{ step.icon }}"></span>
                            </div>
                            <span class="step-label">{{ step.tentientrinh }}</span>
                        </ng-template>
                    </kendo-stepper>
                </div>
            </div>
            <br/>
            <div class="row">
                <div class="col-sm-5 col-md-5 col-xs-5 col-lg-5">
                    <div class="form-group">
                        <label class="control-label required">Loại bài viết</label>
                        <ng-select [items]="ListLoaibv" bindValue="ma" bindLabel="ten" [(ngModel)]="target.loaibaiviet" #loaibaiviet="ngModel" name="loaibaiviet" placeholder="Chọn Loại bài viết" required>
                        </ng-select>
                        <ng-container *ngIf="loaibaiviet.errors && (loaibaiviet.dirty || loaibaiviet.touched)">
                            <div [hidden]="!loaibaiviet.errors.required">
                                <label class="text-danger">Loại bài viết bắt buộc chọn.</label>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-sm-3 col-md-3 col-xs-3 col-lg-3">
                    <div class="form-group">
                        <label class="control-label">Ngày lập</label>
                        <input type="date" class="form-control" [(ngModel)]="target.ngayxuatban" name="ngayxuatban">
                    </div>
                </div>
                <div class="col-sm-2 col-md-2 col-xs-2 col-lg-2">
                    <div class="form-group">
                        <label class="control-label">Cài đặt tin nổi bật</label>
                        <div class="input-group">
                            <label style="margin: 10px 10px 10px 0px;">Tin nổi bật</label>
                            <input type="checkbox" style="margin-top: auto;margin-bottom: auto; width: 20px; height: 20px;" [(ngModel)]="tinnoibat" [value]="tinnoibat" name="tinnoibat">
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 col-md-2 col-xs-2 col-lg-2">
                    <div class="form-group">
                        <label class="control-label">Thời gian (ngày)</label>
                        <input type="number" class="form-control" [(ngModel)]="target.ngaynoibat" name="ngaynoibat">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6 col-xs-6 col-lg-6">
                    <div class="form-group">
                        <label class="control-label required">Tiêu đề</label>
                        <textarea rows="1" type="text" class="form-control" [(ngModel)]="target.tieude" name="tieude" maxlength="500" #tieude="ngModel" required placeholder="Nhập tiêu đề"></textarea>
                        <ng-container *ngIf="tieude.errors && (tieude.dirty || tieude.touched)">
                            <div [hidden]="!tieude.errors.required">
                                <label class="text-danger">Tiêu đề là bắt buộc.</label>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-xs-6 col-lg-6">
                    <div class="form-group">
                        <label class="control-label">Ảnh/Video</label>
                        <div class="input-group">
                            <ngx-btn-file (change)="chooseFile($event.target.files)" [multiple]="true" name="file" [accept]="'image/jpg, image/jpeg, image/png, video/mp4'">
                            </ngx-btn-file>
                            &nbsp;&nbsp;
                            <ul *ngIf="selectedFiles && selectedFiles.length > 0" style="list-style:none;display:inherit">
                                <li class="file-x" *ngFor="let file of selectedFiles">
                                    <ngx-icon-delete (click)="deleteFile(file)"></ngx-icon-delete>&nbsp; {{file.fileName}}&nbsp;
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    <div class="form-group">
                        <label class="control-label ">Tóm tắt nội dung tin bài</label>
                        <textarea rows="3" type="text" class="form-control" [(ngModel)]="target.noidungtomtat" name="noidungtomtat" maxlength="255" #noidungtomtat="ngModel" placeholder="Nhập tóm tắt nội dung tin bài"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    <label class="control-label ">Nội dung</label>
                    <kendo-editor name="noidung" [(ngModel)]="target.noidung" style="min-height: 300px;">
                        <kendo-toolbar>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                            <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                            <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                            <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                        </kendo-toolbar>
                    </kendo-editor>
                </div>
            </div>
            <br/>
            <div class="row">
                <div class="col-sm-7 col-md-7 col-xs-7 col-lg-7">
                    <div class="form-group">
                        <label class="control-label">Cài đặt bình luận</label>
                        <div class="input-group">
                            <input type="radio" style="margin-top: auto;margin-bottom: auto; width: 20px; height: 20px;" value="1" [(ngModel)]="target.binhluan" id="binhluan1" name="binhluan">&nbsp;
                            <label for="binhluan1" style="margin: 10px 10px 10px 0px;">Mở (Người dùng với quyền "Gửi bình luận" sẽ được phép bình luận)</label>
                            <br/>
                            <input type="radio" style="margin-top: auto;margin-bottom: auto; width: 20px; height: 20px;" value="0" [(ngModel)]="target.binhluan" id="binhluan2" name="binhluan">&nbsp;
                            <label for="binhluan2" style="margin: 10px 10px 10px 0px;">Đóng (Người dùng không thể gửi bình luận)</label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 col-xs-4 col-lg-4">
                    <div class="form-group">
                        <label class="control-label required">Ngôn ngữ</label>
                        <ng-select [items]="ListLanguage" bindValue="ma" dropdownPosition="top" [virtualScroll]="true" bindLabel="ten" [(ngModel)]="target.language" #language="ngModel" name="language" placeholder="Chọn ngôn ngữ" required>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5 col-md-5 col-xs-5 col-lg-5">
                    <div class="form-group">
                        <label class="control-label">Thông tin tác giả</label>
                        <ng-select [items]="ListTacgia" bindValue="matacgia" dropdownPosition="top" [virtualScroll]="true" bindLabel="tentacgia" [(ngModel)]="target.tacgia" #tacgia="ngModel" name="tacgia" placeholder="Chọn tác giả">
                        </ng-select>
                    </div>
                </div>
                <div class="col-sm-3 col-md-3 col-xs-3 col-lg-3">
                    <div class="form-group">
                        <label class="control-label">Thời gian hẹn giờ xuất bản</label>
                        <input type="datetime-local" class="form-control" [(ngModel)]="target.timedangtai" name="timedangtai">
                    </div>
                </div>
                <div class="col-sm-3 col-md-3 col-xs-3 col-lg-3">
                    <div class="form-group">
                        <label class="control-label required">Tiến trình</label>
                        <ng-select [items]="ListTientrinh" bindValue="matientrinh" dropdownPosition="top" [virtualScroll]="true" bindLabel="tentientrinh" [(ngModel)]="target.tientrinh" #tientrinh="ngModel" name="tientrinh" placeholder="Chọn tiến trình" required>
                        </ng-select>
                        <ng-container *ngIf="tientrinh.errors && (tientrinh.dirty || tientrinh.touched)">
                            <div [hidden]="!tientrinh.errors.required">
                                <label class="text-danger">Vui lòng cập nhật tiến trình trước khi lưu.</label>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer class="text-end">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
                    <ngx-btn-save (click)="create();" [disabled]="frm.invalid"></ngx-btn-save>&nbsp;
                    <!-- <button class="btn btn-success" type="button" [disabled]="frm.invalid" (click)="publish();"><i class="fa-solid fa-upload"></i>&nbsp;Lưu và Xuất bản</button>&nbsp; -->
                    <ngx-btn-close mat-dialog-close></ngx-btn-close>
                </div>
            </div>
        </nb-card-footer>
    </nb-card>
</form>
