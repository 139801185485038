import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MapAdminService } from '../mapadmin.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { ApiResponse, MapInsertOrUpdateModel, SysmapEntity } from '../../../../@core/data/app-gis.service';
import { DatasetService } from '../../dataset/dataset.service';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { DataService } from '../../services/data.service';
import { DialogConfirmComponent } from '../../../../@theme/components/template/dialog-confirm/dialog-confirm.component';
import { SourceUpdateComponent } from '../source-add/source-update.component';
import { LayerUpdateComponent } from '../layer-add/layer-update.component';
import { groupsmanagerComponent } from '../groups-manager/groups-manager.component';

@Component({
  selector: 'ngx-mapadmin-update',
  templateUrl: './mapadmin-update.component.html',
  styleUrls: ['./mapadmin-update.component.scss'],
})
export class MapAdminUpdateComponent implements OnInit {
  constructor(
    private mapService: MapAdminService,
    private commonService: CommonService,
    private datasetService: DatasetService,
    @Inject(MAT_DIALOG_DATA) private MapAdminItem: { id: number, checkUnit: boolean },
    public dialogRef: MatDialogRef<MapAdminUpdateComponent>,
    private dialog: MatDialog,
    private dataService: DataService,
    private httpClient: HttpClient
  ) { }
  fileToUpload: FileList | null = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files;
  }
  lstFile: any[] = [];
  target: SysmapEntity = new SysmapEntity();
  checkUnit: boolean;
  lstUnit: any[] = [];
  sources: any[] = [];
  Layers: any[] = [];
  Groups: any[] = [];
  ngOnInit(): void {
    this.checkUnit = this.MapAdminItem.checkUnit;
    this.loadData();
    this.getUnit();
    this.getFiles();
  }
  getUnit() {
    this.datasetService.getAllUnit().subscribe(res => {
      this.lstUnit = res.data;
    });
  }
  getFiles() {
    this.dataService.getFile(this.MapAdminItem.id).subscribe((result) => {
      if (result.code = SUCCESS_RESPONE) {
        this.lstFile = result.data;
      }
    });
  }
  deleteFile(item: any) {
    this.dataService.deleteFile(item).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.getFiles();
      }
    });
  }
  loadData() {
    this.mapService.selectOne(this.MapAdminItem.id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
        this.loadSource(this.target.config);
        if (this.target.status === null || this.target.status === '') this.target.status = 'private';
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  jsonParser(string) {
    try {
      return JSON.parse(string);
    } catch (ex: any) {
      return false;
    }
  }

  loadSource(data) {
    this.sources = [];
    const sources = JSON.parse(data).sources;
    for (const keys in sources) {
      const source = sources[keys];
      const key = keys;
      this.sources.push({ name: key, value: source });
    }
    this.Layers = JSON.parse(data).layers;
    this.Groups = JSON.parse(data).groups;
  }

  getGroupName(item) {
    const name = this.Groups.filter(x => x.id === item)[0]?.name;
    if (item > 0) return name;
    return '';
  }
  deleteSource(sourceName) {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('30%', {
          title: 'Xác nhận',
          message: `Bạn có chắc chắn xóa source này?`,
          color: 'text-warning',
          type: 1,
          icon: 'exclamation-triangle',
          isAppend: true,
        })
      )
      .afterClosed()
      .subscribe((info) => {
        if (info) {
          const config = JSON.parse(this.target.config);
          const source = JSON.parse(this.target.config).sources;
          const output = {};
          for (const key in source) {
            if (key !== sourceName) {
              output[key] = source[key];
            }
          }
          config['sources'] = output;
          this.target.config = JSON.stringify(config);
          this.mapService.updateCustomMap(this.target).subscribe((res) => {
            if (res.code === SUCCESS_RESPONE) {
              this.loadData();
              this.commonService.toastrSuccess('Thành công!');
            } else {
              this.commonService.toastrDanger(res.message);
            }
          });
        }
      });
  }

  deleteGroup(item) {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('30%', {
          title: 'Xác nhận',
          message: `Bạn có chắc chắn xóa group` + item.name + ` ?`,
          color: 'text-warning',
          type: 1,
          icon: 'exclamation-triangle',
          isAppend: true,
        })
      )
      .afterClosed()
      .subscribe((info) => {
        if (info) {
          const config = JSON.parse(this.target.config);
          let groups = JSON.parse(this.target.config).groups;
          groups = groups.filter(x => x.name !== item.name);
          config.groups = groups;
          this.target.config = JSON.stringify(config);
          this.mapService.updateCustomMap(this.target).subscribe((res) => {
            if (res.code === SUCCESS_RESPONE) {
              this.loadData();
              this.commonService.toastrSuccess('Thành công!');
            } else {
              this.commonService.toastrDanger(res.message);
            }
          });
        }
      });
  }

  deleteLayer(featureClassId) {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('30%', {
          title: 'Xác nhận',
          message: `Bạn có chắc chắn xóa layer này?`,
          color: 'text-warning',
          type: 1,
          icon: 'exclamation-triangle',
          isAppend: true,
        })
      )
      .afterClosed()
      .subscribe((info) => {
        if (info) {
          const config = JSON.parse(this.target.config);
          const data = config.layers.filter((x) => x.featureClassId !== featureClassId);

          config['layers'] = data;
          this.target.config = JSON.stringify(config);
          this.mapService.updateCustomMap(this.target).subscribe((res) => {
            if (res.code === SUCCESS_RESPONE) {
              this.loadData();
              this.commonService.toastrSuccess('Thành công!');
            } else {
              this.commonService.toastrDanger(res.message);
            }
          });
        }
      });
  }
  updateLayer(item) {
    this.dialog
      .open(
        LayerUpdateComponent,
        this.commonService.configDialog('50%', { data: this.target, item: item, isEdit: true })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.loadData();
        }
      });
  }
  updateSource(item) {
    this.dialog
      .open(
        SourceUpdateComponent,
        this.commonService.configDialog('50%', { data: item, id: this.target.id, isEdit: true })
      )
      .afterClosed()
      .subscribe((result) => {
        this.loadData();
      });
  }

  updateGroup(item) {
    this.dialog
      .open(
        groupsmanagerComponent,
        this.commonService.configDialog('50%', { data: item, id: this.target.id, isEdit: true })
      )
      .afterClosed()
      .subscribe((result) => {
        this.loadData();
      });
  }
  openAddSourceDialog() {
    this.dialog
      .open(SourceUpdateComponent, this.commonService.configDialog('50%', { id: this.target.id, isEdit: false }))
      .afterClosed()
      .subscribe((result) => {
        this.loadData();
      });
  }
  openAddGroupsDialog() {
    this.dialog
      .open(groupsmanagerComponent, this.commonService.configDialog('50%', { data: null, id: this.target.id, isEdit: false }))
      .afterClosed()
      .subscribe((result) => {
        this.loadData();
      });
  }
  openAddLayerDialog() {
    this.dialog
      .open(LayerUpdateComponent, this.commonService.configDialog('50%', { data: this.target }))
      .afterClosed()
      .subscribe((result) => {
        this.loadData();
      });
  }


  closeDialog() {
    this.dialogRef.close(false);
  }

  // update(frm: NgForm) {
  //   this.mapService.updateCustomMap(this.target).subscribe((result) => {
  //     if (result.code === SUCCESS_RESPONE) {
  //       this.commonService.toastrSuccess();
  //       this.dialogRef.close(true);
  //     } else {
  //       this.commonService.toastrDanger(result.message);
  //     }
  //   });
  // }

  update(frm: NgForm) {
    let _body = new MapInsertOrUpdateModel();
    _body.name = this.target.name.trim();
    _body.id = this.target.id;
    _body.config = this.target.config;
    _body.status = this.target.status;
    _body.unitCode = this.target.unitcode;
    const formData = new FormData();
    if (this.fileToUpload) {
      for (let i = 0; i < this.fileToUpload.length; i++) {
        formData.append('Files', this.fileToUpload[i]);
      }
    }
    formData.append('model', JSON.stringify(_body));
    const _headers: HttpHeaders = new HttpHeaders({
      //TODO: must contain boundary
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
      // "Accept": "application/json"
      'Accept': '*/*',
    });
    const req = new HttpRequest(
      'POST',
      `${environment.APP_GIS_URL}/App/Gis/api/Map/update`,
      formData,
      { headers: _headers }
    );

    this.httpClient.request<ApiResponse>(req).pipe().subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          let result = event.body;
          if (result.code === SUCCESS_RESPONE) {
            this.commonService.toastrSuccess();
            this.dialogRef.close(true);
          } else {
            this.commonService.toastrDanger(result.message);
          }
        }
      },
      (error) => {
        throw error;
      }
    );
  }
}
