import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  AppGISApiService,
  SyssettingEntity,
  SyssettingEntityApiResponse,
  ObjectIEnumerablePageModelViewApiResponse,
  PageModel,
} from 'app/@core/data/app-gis.service';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(
    private api: AppGISApiService // private logger: LoggerService
  ) {}

  paging(page: PageModel): Observable<ObjectIEnumerablePageModelViewApiResponse> {
    return this.api.appGisApiSettingPaging(page);
  }

  create(target: SyssettingEntity): Observable<ApiResponse> {
    return this.api.appGisApiSettingInsertCustom(target);
  }

  update(entity: SyssettingEntity): Observable<ApiResponse> {
    return this.api.appGisApiSettingUpdateCustom(entity);
  }

  delete(key: number): Observable<ApiResponse> {
    return this.api.appGisApiSettingDelete(key);
  }

  selectOne(key: number): Observable<SyssettingEntityApiResponse> {
    return this.api.appGisApiSettingSelecOneCustom(key);
  }
}
