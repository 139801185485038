import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  AppGISApiService,
  SysfieldEntity,
  SysfieldEntityApiResponse,
  ObjectIEnumerablePageModelViewApiResponse,
  PageModel,
  FieldDetailModelIEnumerableApiResponse,
} from '../../../@core/data/app-gis.service';

@Injectable({
  providedIn: 'root',
})
export class FieldService {
  constructor(
    private api: AppGISApiService // private logger: LoggerService
  ) {}

  paging(page: PageModel): Observable<ObjectIEnumerablePageModelViewApiResponse> {
    return this.api.appGisApiFieldPaging(page);
  }
  getAll(featureclassId: number | undefined): Observable<FieldDetailModelIEnumerableApiResponse> {
    return this.api.appGisApiFieldGetFields(featureclassId);
  }

  getAllByTableName(tablename: string | undefined): Observable<FieldDetailModelIEnumerableApiResponse> {
    return this.api.appGisApiFieldGetFieldsByTableName(tablename);
  }
  GetFieldsForInsertOrUpdate(tablename: string | undefined): Observable<FieldDetailModelIEnumerableApiResponse> {
    return this.api.appGisApiFieldGetFieldsForInsertOrUpdate(tablename);
  }
  create(target: SysfieldEntity): Observable<ApiResponse> {
    return this.api.appGisApiFieldInsertCustom(target);
  }

  update(entity: SysfieldEntity): Observable<ApiResponse> {
    return this.api.appGisApiFieldUpdateCustom(entity);
  }

  delete(key: number): Observable<ApiResponse> {
    return this.api.appGisApiFieldDeleteCustom(key);
  }

  selectOne(key: number): Observable<SysfieldEntityApiResponse> {
    return this.api.appGisApiFieldSelecOneCustom(key);
  }
  getName(tablename: string): Observable<string> {
    return this.api.appGisApiDataGetName(tablename);
  }
  
}
