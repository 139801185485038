<form>
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 d-flex">
          <div class="input-group">
            <label style="flex: auto">Cập nhật dữ liệu</label>
            <button class="btn btn-primary" type="button" (click)="update()">
              <i class="fas fa-plus fa-sm"></i>&nbsp;Cập nhật
            </button>
            <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
          </div>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <!-- <input type="file" id="file" multiple (change)="handleFileInput($event.target.files)" class="form-control"> -->
            <ngx-btn-file (change)="chooseFile($event.target.files)" [multiple]="true" name="file" [accept]="'image/jpg, image/jpeg, image/png, video/mp4'">
            </ngx-btn-file>
            &nbsp;&nbsp;
            <div>
              <span *ngIf="selectedFiles && selectedFiles.length > 0" class="files">
                Danh sách file :
                <span class="file-x" *ngFor="let file of selectedFiles">
                  {{file.fileName}}&nbsp;<ngx-icon-delete (click)="deleteFile(file)"></ngx-icon-delete>&nbsp;
                </span>
              </span>
              <span *ngIf="selectedFiles.length === 0"><i>Không có file đính kèm</i></span>
            </div>
          </div>

        </div>
      </div>
      <div class="row" *ngFor="let t of fields">
        <div class="col-lg-12">
          <div class="form-group"
            *ngIf="   t.fieldName.toLowerCase() !== 'gioitinh' && t.fieldName.toLowerCase() !== 'status'  && t.fieldName.toLowerCase() !== 'createddate'  && t.fieldName.toLowerCase() !== 'maxa' && t.fieldName.toLowerCase() !== 'mahuyen' && t.fieldName.toLowerCase() !== 'matinh' ">
            <label class="col-form-label">{{ t.label }}</label>
            <input type="text" id="{{ t.fieldName }}-update" class="form-control" value="{{ t.value }}" />
          </div>
          
          <div *ngIf="t.fieldName.toLowerCase() === 'matinh'">
            <label class="col-form-label">Tên tỉnh</label>
            <input type="text" id="{{ t.x }}-create" class="form-control" readonly="true" [(ngModel)]="MDVHCT" name="matinh"/>
          </div>
          <div *ngIf="t.fieldName.toLowerCase() === 'mahuyen'">
            <label class="col-form-label">Tên đơn vị hành chính cấp huyện</label>
            <ng-select [items]="lstDVHCCH" bindValue="madonvi" [(ngModel)]="MDVHCCH" bindLabel="ten" (change)="ChangeHuyen()" name="MDVHCCH" placeholder="Chọn đơn vị hành chính cấp huyện" appendTo="body"></ng-select>
          </div>
          <div *ngIf="t.fieldName.toLowerCase() === 'maxa' && MDVHCCH">
            <label class="col-form-label">Tên đơn vị hành chính cấp xã</label>
            <ng-select [items]="lstDVHCCX" bindValue="madonvi" [(ngModel)]="MDVHCCX" bindLabel="ten"  name="MDVHCCX" placeholder="Chọn đơn vị hành chính cấp xã" appendTo="body"></ng-select>
          </div>
          <div class="form-group" *ngIf=" t.fieldName.toLowerCase() === 'gioitinh'">
            <label class="col-form-label">Giới tính</label>
            <ng-select [items]="options" bindValue="value" [(ngModel)]="gender" bindLabel="name" name="gender"
              placeholder="Chọn giới tính" appendTo="body"></ng-select>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</form>