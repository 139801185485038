import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  AppGISApiService,
  SysfeatureclassEntityApiResponse,
  ListFileModelIEnumerableApiResponse,
  FolderInsertOrUpdateModelIEnumerableApiResponse,
  FolderInsertOrUpdateModel,
  FolderInsertOrUpdateModelApiResponse,
  StringIEnumerableApiResponse,
  FileUpdateModel,
  ListFileModelIEnumerablePageModelViewApiResponse,
  PageModel
} from '../../../@core/data/app-gis.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private api: AppGISApiService // private logger: LoggerService
  ) {}

  delete(key: number): Observable<ApiResponse> {
    return this.api.appGisApiFileDeleteFile(key);
  }
  selectOne(key: number): Observable<SysfeatureclassEntityApiResponse> {
    return this.api.appGisApiFeatureclassSelecOneCustom(key);
  }
  getFile(parentId: number | undefined, name: string | undefined): Observable<ListFileModelIEnumerableApiResponse> {
    return this.api.appGisApiFileGetFiles(parentId, name);
  }
  PagingFile(parentId: number | undefined, body: PageModel): Observable<ListFileModelIEnumerablePageModelViewApiResponse> {
    return this.api.appGisApiFilePaingFiles(parentId, body);
  }
  getColumns(key: number | undefined): Observable<StringIEnumerableApiResponse> {
    return this.api.appGisApiFileGetColumns(key);
  }
  updateFile(key: number, body: FileUpdateModel | undefined): Observable<ApiResponse> {
    return this.api.appGisApiFileUpdateFile(key, body);
  }
  createFolder(body: FolderInsertOrUpdateModel | undefined): Observable<FolderInsertOrUpdateModelApiResponse> {
    return this.api.appGisApiFolderCreate(body);
  }
  updateFolder(body: FolderInsertOrUpdateModel | undefined): Observable<FolderInsertOrUpdateModelApiResponse> {
    return this.api.appGisApiFolderUpdate(body);
  }
  deleteFolder(key: number | undefined): Observable<ApiResponse> {
    return this.api.appGisApiFolderDelete(key);
  }
  getFolder(parentId: number | undefined): Observable<FolderInsertOrUpdateModelIEnumerableApiResponse> {
    return this.api.appGisApiFolderGet(parentId);
  }
}
