<div class="main">
  <nb-card>
    <div class="content">
      <nb-card-header class="nb-card-header">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 col-xl-12">
           Liên Hệ
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 col-xl-12">
              <div class="input-group">
                  <input type="text" class="form-control" placeholder="Nhập nội dung tìm kiếm" style="height: auto;" [(ngModel)]="searchModel.search" (keydown.enter)="search();" name="seach">&nbsp;
                  <ngx-btn-search (click)="search();"></ngx-btn-search>&nbsp;
                  <ngx-btn-refresh (click)="searchModel.search = '';searchModel.status = null; search();">
                  </ngx-btn-refresh>
              </div>
          </div>
      </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 col-xl-12">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th class="text-center align-middle" style="width:2%;">STT</th>
                      <th class="text-center align-middle" >Tên người gửi</th>
                      <th class="text-center align-middle" >Tiêu đề</th>
                      <th class="text-center align-middle" >Điện thoại</th>
                      <th class="text-center align-middle" >Ngày gửi</th>
                      <th class="text-center align-middle" style="width:10%;">Trạng thái</th>
                      <th class="text-center align-middle" style="width:13%;">Hành động</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of target.data;let i=index">
                      <td class="text-center align-middle">{{i+1}}</td>
                      <td class="text-left align-middle" style="padding-left:25px;">{{item.tennguoigui}}</td>
                      <td class="text-left align-middle" style="padding-left:25px;">{{item.tieude}}</td>
                      <td class="text-left align-middle" style="padding-left:25px;">{{item.dienthoai}}</td>
                      <td class="text-center align-middle">{{item.time | date: 'dd/MM/yyyy'}}</td>
                      <td class="text-center align-middle">{{item.congkhai ? "Sử dụng" : "Không sử dụng"}}</td>
                      <td class="text-center align-middle">
                        <ngx-icon-info class="icon" data-toggle="tooltip" title="Xem chi tiết" (click)="detailData(item.id);">
                        </ngx-icon-info>
                        <ng-container>
                            <ngx-icon-edit class="icon" data-toggle="tooltip" title="Cập nhật" (click)="updateData(item.id);">
                            </ngx-icon-edit>
                            <ngx-icon-delete class="icon" data-toggle="tooltip" title="Xoá" (click)="deleteData(item.id, item.tieude);">
                            </ngx-icon-delete>
                        </ng-container>
                        <ngx-icon-reply class="icon" data-toggle="tooltip" title="Thu hồi" *ngIf="item.status === 2" (click)="recoverData(item);">
                        </ngx-icon-reply>
                        <ngx-icon-ellipsis class="icon" *ngIf="item.status === 2 && item.isGovernment" (click)="viewListShare(item.lstShareUnit);"></ngx-icon-ellipsis>
                    </td>
                    </tr>
                    <tr *ngIf="target?.data.length === 0">
                      <td colspan="7">Không có dữ liệu.</td>
                  </tr>
                  </tbody>
                </table>
          </div>
        </div>
        <div *ngIf="target?.recordsCount > 0" class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
              <div style="float: left;">
                  Tổng số bản ghi: {{target?.recordsCount}}
              </div>
              <div style="float: right;">
                  <ngx-pagination (pageChange)="searchModel.currentPage = $event;loadData()" (pageSize)="searchModel.pageSize = $event;loadData()" [currentPage]="searchModel.currentPage" [totalPages]="target.totalPage">
                  </ngx-pagination>
              </div>
          </div>
      </div>
      </nb-card-body>
    </div>
  </nb-card>
</div>


