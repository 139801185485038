<nb-card>
  <nb-card-header class="nb-card-header">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 d-flex">
        <div class="input-group">
          <label style="flex: auto">Danh mục chủ đề dữ liệu</label>
          <button class="btn btn-outline-secondary" type="button" data-toggle="tooltip" title="Tải xuống excel"
            (click)="export()">
            <i class="fas fa-download"></i>&nbsp;Kết xuất
          </button>&nbsp;&nbsp;
          <!-- <button type="button" (click)="exportToWord()" class="btn btn-outline-secondary" title="Tải xuống word"><i
              class="fas fa-download" data-toggle="tooltip" title="Tải xuống word"></i>
            Kết xuất Word
          </button>&nbsp;&nbsp; -->
          <button class="btn btn-primary" type="button" (click)="openCreateDialog(null)">
            <i class="fas fa-plus fa-sm"></i>&nbsp;Thêm mới
          </button>
        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <!-- <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
          <div class="input-group">
            <button
              class="btn btn-primary"
              type="button"
              (click)="openCreateDialog(null)"
            >
              <i class="fas fa-plus fa-sm"></i>&nbsp;Thêm mới
            </button>
            &nbsp;&nbsp;
            <input
              type="text"
              class="form-control"
              placeholder="Nhập nội dung tìm kiếm"
              aria-label="Nhập nội dung tìm kiếm"
              aria-describedby="basic-addon2"
              [(ngModel)]="pageModel.search"
            />
            <div class="input-group-append">
              <button class="btn btn-info" type="button" (click)="searchPage()">
                <i class="fas fa-search fa-sm"></i>&nbsp;Tìm kiếm
              </button>
              <button
                class="btn btn-outline-secondary"
                type="button"
                (click)="resetPage()"
              >
                <i class="fas fa-sync-alt fa-sm"></i>&nbsp;Làm mới
              </button>
              <button class="btn btn-outline-secondary" type="button">
                <i class="fas fa-reply fa-sm"></i>&nbsp;Quay lại
              </button>
            </div>
          </div>
        </div>
      </div>
      <br /> -->
    <div class="row">
      <kendo-treelist [kendoTreeListFlatBinding]="data" kendoTreeListExpandable idField="id" parentId="parentId"
        [filterable]="true" (add)="addHandler($event, null)" (edit)="editHandler($event)"
        (remove)="removeHandler($event)">
        <kendo-treelist-column [expandable]="true" field="name" title="Tên chủ đề" [width]="250">
          <ng-template kendoTreeListFilterCellTemplate let-filter let-column="column">
            <kendo-treelist-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false">
            </kendo-treelist-string-filter-cell>
          </ng-template>
        </kendo-treelist-column>
        <kendo-treelist-column field="description" title="Mô tả mục đích, phạm vi, nội dung của từng cơ sở dữ liệu"
          [filterable]="true" [width]="180"><ng-template kendoTreeListFilterCellTemplate let-filter let-column="column">
            <kendo-treelist-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false">
            </kendo-treelist-string-filter-cell>
          </ng-template>
        </kendo-treelist-column>
        <kendo-treelist-column field="mechanism" title="Cơ chế thu thập, cập nhật, nguồn dữ liệu" [filterable]="true"
          [width]="180"><ng-template kendoTreeListFilterCellTemplate let-filter let-column="column">
            <kendo-treelist-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false">
            </kendo-treelist-string-filter-cell>
          </ng-template>
        </kendo-treelist-column>
        <kendo-treelist-column field="category" title="Hạng mục dữ liệu" [filterable]="true" [width]="180">
          <ng-template kendoTreeListFilterCellTemplate let-filter let-column="column">
            <kendo-treelist-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false">
            </kendo-treelist-string-filter-cell>
          </ng-template>
        </kendo-treelist-column>
        <kendo-treelist-column field="note" title="Ghi chú" [filterable]="true" [width]="180">
          <ng-template kendoTreeListFilterCellTemplate let-filter let-column="column">
            <kendo-treelist-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false">
            </kendo-treelist-string-filter-cell>
          </ng-template>
        </kendo-treelist-column>
        <kendo-treelist-command-column title="Hành động" width="160">
          <ng-template kendoTreeListCellTemplate let-isNew="isNew" let-cellContext="cellContext" let-dataItem>
            <div style="display: flex;">
              <!-- "Add Child" command directive, will not be visible in edit mode -->
              <button [kendoTreeListAddCommand]="cellContext" class="btn" title="Thêm chủ đề con" width="35"
                style="min-width:35px;"><i class="far fa-plus-square"></i></button>

              <!-- "Edit" command directive, will not be visible in edit mode -->
              <button [kendoTreeListEditCommand]="cellContext" class="btn" width="35" style="min-width:35px;"
                title="Sửa thông tin"><i class="far fa-edit"></i></button>

              <!-- "Remove" command directive, will not be visible in edit mode -->
              <button [kendoTreeListRemoveCommand]="cellContext" title="Xóa" width="35" class="btn"
                style="min-width:35px;"><i class="fas fa-trash"></i></button>
                <button title="Chia sẻ cho phòng ban" *ngIf="!checkPhongBan" width="35" class="btn" style="min-width:35px;"><i class="fa-solid fa-share"
                  data-toggle="tooltip" title="Chia sẻ cho phòng ban" (click)="share(dataItem)"></i></button>
              <button title="Tải xuống word" width="35" class="btn" style="min-width:35px;"><i class="fas fa-download"
                  data-toggle="tooltip" title="Tải xuống word" (click)="exportToWord(dataItem.id)"></i></button>
            </div>
          </ng-template>
        </kendo-treelist-command-column>
      </kendo-treelist>
    </div>
  </nb-card-body>
</nb-card>