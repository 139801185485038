<form #frm="ngForm" (ngSubmit)="create(frm)">
  <nb-card>
      <nb-card-header class="nb-card-header">
          <div class="row">
              <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                  THÊM MỚI CHỨC VỤ
              </div>
          </div>
      </nb-card-header>
      <nb-card-body>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
              <div class="form-group">
                  <label class="control-label">Mã chức vụ</label>
                  <div class="input-group">
                    <input  type="text" class="form-control" name="machucvu" maxlength="500" [(ngModel)]="target.machucvu" #machucvu="ngModel"  required placeholder="Nhập mã chức vụ" />
                </div>
              </div>
          </div>
          <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
              <div class="form-group">
                  <label class="control-label">Tên chức vụ</label>
                  <div class="input-group">
                    <input  type="text" class="form-control" name="tenchucvu" maxlength="500" [(ngModel)]="target.tenchucvu" #tenchucvu="ngModel"  required placeholder="Nhập tên chức vụ" />
                </div>
              </div>
          </div>
          </div>
      </nb-card-body>
      <nb-card-footer class="text-end">
          <div class="row">
              <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
                  <ngx-btn-submit  [disabled]="frm.invalid"></ngx-btn-submit>&nbsp;
                  <!-- <button class="btn btn-success" type="button" [disabled]="frm.invalid" (click)="publish();"><i class="fa-solid fa-upload"></i>&nbsp;Lưu và Xuất bản</button>&nbsp; -->
                  <ngx-btn-close mat-dialog-close></ngx-btn-close>
              </div>
          </div>
      </nb-card-footer>
  </nb-card>
</form>
