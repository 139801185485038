import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FileService } from '../file.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { FolderInsertOrUpdateModel } from '../../../../@core/data/app-gis.service';
import { DatasetService } from '../../dataset/dataset.service';

@Component({
  selector: 'ngx-folder-update',
  templateUrl: './folder-update.component.html',
  styleUrls: ['./folder-update.component.scss'],
})
export class FolderUpdateComponent implements OnInit {
  constructor(
    private folderService: FileService,
    private commonService: CommonService,
    private datasetService: DatasetService,
    @Inject(MAT_DIALOG_DATA) private folderItem: { folderSL: FolderInsertOrUpdateModel, checkUnit: boolean },
    public dialogRef: MatDialogRef<FolderUpdateComponent>
  ) {}
  folders = [];
  selected = [];
  selectedItem: any;
  parentId: any;
  target: FolderInsertOrUpdateModel = new FolderInsertOrUpdateModel();
  checkUnit: boolean;
  lstUnit: any[] = [];
  ngOnInit(): void {
    this.checkUnit = this.folderItem.checkUnit;
    this.loadFolder();
    this.loadData();
    this.getUnit();
  }
  getUnit(){
    this.datasetService.getAllUnit().subscribe(res => {
        this.lstUnit = res.data;
    });
  }
  loadFolder() {
    this.folderService.getFolder(undefined).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.folders = this.folderTree(result.data, null);
      } else {
        this.commonService.toastrDanger(result.message);
      }
      this.loadData();
    });
  }
  folderTree(data, parentid) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      const obj = {
        name: data[i].name,
        id: data[i].id,
      };
      if (data[i].parentID === parentid) {
        result.push({
          name: data[i].name,
          id: data[i].id,
          items: this.folderTree(data, data[i].id),
        });
      }
      if (data[i].id === this.folderItem.folderSL.parentID) this.selectedItem = obj;
    }
    return result;
  }
  loadData() {
    this.target.id = this.folderItem.folderSL.id;
    this.target.name = this.folderItem.folderSL.name;
    this.target.parentID = this.folderItem.folderSL.parentID;
    this.target.unitCode = this.folderItem.folderSL.unitCode;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  update(frm: NgForm) {
    if (this.selectedItem && this.selectedItem.id === this.target.id) {
      this.commonService.toastrDanger('Không cập nhật được theo thư mục trùng nhau');
      return;
    }

    if (this.selectedItem) this.target.parentID = this.selectedItem.id;
    this.folderService.updateFolder(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(result);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
