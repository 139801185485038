<form #frm="ngForm">
    <nb-card>
        <nb-card-header class="nb-card-header">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    Chi tiết danh mục chủ đề
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
          <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                  <div class="form-group">
                      <label class="col-form-label required">Tên chức năng</label>
                      <input type="text" class="form-control" name="tenapp" [(ngModel)]="target.tenapp" required maxlength="50" disabled>
                  </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                  <div class="form-group">
                      <label class="col-form-label required">Mô tả</label>
                      <input type="text" class="form-control" name="mota" [(ngModel)]="target.mota" required maxlength="250" disabled>
                  </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                  <div class="form-group">
                      <label class="col-form-label">Trạng thái</label>
                      <select class="form-control" name="trangthai" [(ngModel)]="target.trangthai" disabled>
                        <option value="" selected="selected">Chọn trạng thái</option>
                        <option value="0">Không sử dụng</option>
                        <option value="1">Sử dụng</option>
                        </select>
                  </div>
              </div>
          </div>
      </nb-card-body>
        <nb-card-footer class="text-right">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
                    <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
                </div>
            </div>
        </nb-card-footer>
    </nb-card>
</form>
