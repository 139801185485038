import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DmTacGiaIndexComponent } from './dm-tac-gia-index/dm-tac-gia-index.component';

export const routes: Routes = [
  {
    path: '',
    children: [{ path: '', component: DmTacGiaIndexComponent }],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DmTacGiaRoutingModule {}
