<nb-card >
  <nb-card-header class="nb-card-header">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 col-xl-12">
        <p><strong>DANH MỤC LIÊN KẾT</strong></p>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 col-xl-12">
        <div class="input-group">
          <ngx-btn-add
            data-toggle="tooltip"
            title="Thêm mới"
            (click)="openCreate()"
          ></ngx-btn-add
          >&nbsp;
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div
        class="col-sm-12 col-md-12 col-xs-12 col-lg-12"
        style="overflow: auto"
      >
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th class="text-center align-middle" style="width: 60px">Mã</th>
              <th class="text-center align-middle" style="min-width: 150px">
                Tên
              </th>
              <th class="text-center align-middle" style="min-width: 300px">
                Link web
              </th>
              <th class="text-center align-middle" style="min-width: 300px">
                Ảnh đính kèm
              </th>
              <th class="text-center align-middle" style="width: 100px">
                Hành động
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of target.data; let i = index">
              <td class="text-left align-middle">{{ item.ma }}</td>
              <td class="text-left align-middle">{{ item.ten }}</td>
              <td class="text-left align-middle">{{ item.linkWeb }}</td>
              <td class="text-left align-middle">{{ item.img }}</td>
              <td class="text-center align-middle">
                <ngx-icon-info
                  class="icon"
                  data-toggle="tooltip"
                  title="Xem chi tiết"
                  (click)="updateData(item.id, false)"
                >
                </ngx-icon-info>
                <ng-container>
                  <ngx-icon-edit
                    class="icon"
                    data-toggle="tooltip"
                    title="Cập nhật"
                    (click)="updateData(item.id, true)"
                  >
                  </ngx-icon-edit>
                  <ngx-icon-delete
                    class="icon"
                    data-toggle="tooltip"
                    title="Xoá"
                    (click)="deleteData(item.id, item.ten)"
                  >
                  </ngx-icon-delete>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="target?.recordsCount > 0" class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
        <div style="float: left">
          Tổng số bản ghi: {{ target?.recordsCount }}
        </div>
        <div style="float: right">
          <ngx-pagination
            (pageChange)="searchModel.currentPage = $event; loadData()"
            (pageSize)="searchModel.pageSize = $event; loadData()"
            [currentPage]="searchModel.currentPage"
            [totalPages]="target.totalPage"
          >
          </ngx-pagination>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
