import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService, ConvertDatePipe } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { SysuserEntity } from 'app/@core/data/app-qtht.service';
import { UserInfoService } from './user-info.service';

@Component({
  selector: 'ngx-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private userInfoService: UserInfoService,
    @Inject(MAT_DIALOG_DATA) private userInfoItem: {data: any}
  ) { }

  target: SysuserEntity = new SysuserEntity();

  listGioiTinh = [
    {
      value: 1,
      text: 'Nam'
    },
    {
      value: 0,
      text: 'Nữ'
    }
  ];

  loadData() {
    this.userInfoService.seletOne(this.userInfoItem.data.userId).subscribe(result => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
    this.target.ngaysinh = new ConvertDatePipe().transform(this.target.ngaysinh);
  }

}
