import { DUPLICATE_RESPONE } from '../../../../@core/customs/constants';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { SysappEntity } from 'app/@core/data/app-qtht.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { FeatureclassService } from '../featureclass.service';
import {
  DataSearchModel,
  ExportDataModel,
  SysfeatureclassEntity,
  SysimportLogEntity,
} from 'app/@core/data/app-gis.service';
import { DataService } from '../../services/data.service';
import { FieldService } from '../field.service';
import { FeatureclassUpdateDataComponent } from '../featureclass-updatedata/featureclass-updatedata.component';

@Component({
  selector: 'ngx-featureclass-viewlog',
  templateUrl: './featureclass-viewlog.component.html',
  styleUrls: ['./featureclass-viewlog.component.scss'],
})
export class FeatureclassViewLogComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private fieldService: FieldService,
    private commonService: CommonService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public feature: { id: number; tablename: string },
    public dialogRef: MatDialogRef<FeatureclassViewLogComponent>
  ) {}
  target: SysimportLogEntity[] = [];
  ngOnInit(): void {
    this.loadData();
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  loadData() {
    this.dataService.getLogs(this.feature.tablename).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
  convertDate(date) {
    return (
      date.getDate() +
      '/' +
      (date.getMonth() + 1) +
      '/' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds()
    );
  }
  formatStatus(status) {
    if (status === '1') return 'Thành công';
    else return 'Thất bại!';
  }
}
