<nb-card>
  <nb-card-header class="nb-card-header">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-xs-6 col-lg-6">Danh sách dữ liệu {{ this.Name }}</div>
      <div class="col-sm-4 col-md-4 col-xs-4 col-lg-4" style="display: flex;">
        <button (click)="locDieukien()" class="btn btn-primary" data-toggle="tooltip"
          title="Tìm kiếm theo điều kiện lọc"><i class="fas fa-sort-amount-down"></i></button>&nbsp;&nbsp;
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Tìm kiếm theo từ khóa" [(ngModel)]="textSearch"
            [ngModelOptions]="{ standalone: true }" (keyup.enter)="onSearchTableAttribute()" />
          <button class="btn btn-primary" type="button" data-toggle="tooltip" title="Tìm kiếm"
            (click)="onSearchTableAttribute()">
            <i class="fas fa-search fa-sm"></i>&nbsp;
          </button>
        </div>
      </div>
      <div class="col-sm-2 col-md-2 col-xs-2 col-lg-2" style="display: flex; justify-content: end;">
        <!-- <button class="btn btn-secondary mr-2" type="button" (click)="exportData()" data-toggle="tooltip"
          title="Kết xuất dữ liệu">
          <i class="fas fa-download"></i>&nbsp;Kết xuất
        </button>&nbsp;
        <ngx-btn-import-file data-toggle="tooltip" title="Import dữ liệu"
          (click)="importData()"></ngx-btn-import-file>&nbsp;&nbsp;
        <ngx-btn-add data-toggle="tooltip" title="Thêm mới" (click)="openCreate()"></ngx-btn-add>&nbsp;&nbsp; -->
        <ngx-btn-refresh (click)="SearchModel.text = null;SearchModel.status = null; refresh();">
        </ngx-btn-refresh>&nbsp;&nbsp;
        <div class="dropup" style="
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;">
          <button class="btn btn-primary" (click)="hanhdong()" data-toggle="tooltip" title="Hành động">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
              <g clip-path="url(#clip0_1636_16767)">
                <path
                  d="M13.5591 18.3572V14.7145C13.5591 12.8874 12.0779 11.4063 10.2509 11.4063H7.37757V6.03773C7.37757 5.18411 6.68557 4.49213 5.83196 4.49213C4.97834 4.49213 4.28635 5.18411 4.28635 6.03773L4.28634 13.0751L3.77092 13.1721C2.20375 13.467 1.45796 15.2765 2.36215 16.5899L2.57004 16.892L3.57736 18.3572"
                  stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M1.33963 7.34163C1.26072 7.00238 1.21899 6.64886 1.21899 6.28558C1.21899 3.7215 3.29761 1.64288 5.86169 1.64288C8.42579 1.64288 10.5044 3.7215 10.5044 6.28558C10.5044 6.64886 10.4627 7.00238 10.3838 7.34163"
                  stroke="white" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_1636_16767">
                  <rect width="14" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <div *ngIf="buttonHanhDong" class="dropup-content1">
            <a (click)="openCreate()"
              class="k-button k-grid-edit-command k-button-md1 k-rounded-md k-button-solid-base1 k-button-solid ng-star-inserted">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M8 0.799988V14.8" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1 7.75684H15" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
              </svg>&nbsp;Thêm mới dữ liệu
            </a>
            <a (click)="importData()"
              class="k-button k-grid-edit-command k-button-md1 k-rounded-md k-button-solid-base1 k-button-solid ng-star-inserted">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                <g clip-path="url(#clip0_1557_17789)">
                  <path
                    d="M2.5 13.5H1.5C1.23478 13.5 0.98043 13.3946 0.792893 13.2071C0.605357 13.0196 0.5 12.7652 0.5 12.5V4.5H13.5V12.5C13.5 12.7652 13.3946 13.0196 13.2071 13.2071C13.0196 13.3946 12.7652 13.5 12.5 13.5H11.5"
                    stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M4.5 11L7 13.5L9.5 11" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7 13.5V7.5" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M11.29 1C11.2045 0.851738 11.0823 0.727908 10.9353 0.640363C10.7882 0.552818 10.6211 0.504488 10.45 0.5H3.55C3.3789 0.504488 3.21182 0.552818 3.06474 0.640363C2.91766 0.727908 2.79552 0.851738 2.71 1L0.5 4.5H13.5L11.29 1Z"
                    stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7 0.5V4.5" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_1557_17789">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>&nbsp;Import dữ liệu
            </a>
            <a (click)="exportData()"
              class="k-button k-grid-edit-command k-button-md1 k-rounded-md k-button-solid-base1 k-button-solid ng-star-inserted">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M1 11.7898V12.8599C1 13.4275 1.22692 13.9718 1.63085 14.3732C2.03477 14.7745 2.58261 15 3.15385 15H12.8462C13.4174 15 13.9652 14.7745 14.3692 14.3732C14.7731 13.9718 15 13.4275 15 12.8599V11.7898"
                  stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.40015 8.12103L8.00015 11.5987L11.6001 8.12103" stroke="black" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M8 11.4331V1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
              </svg>&nbsp;Kết xuất dữ liệu
            </a>
            <a (click)="exportTemplate()"
              class="k-button k-grid-edit-command k-button-md1 k-rounded-md k-button-solid-base1 k-button-solid ng-star-inserted">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M1 11.7898V12.8599C1 13.4275 1.22692 13.9718 1.63085 14.3732C2.03477 14.7745 2.58261 15 3.15385 15H12.8462C13.4174 15 13.9652 14.7745 14.3692 14.3732C14.7731 13.9718 15 13.4275 15 12.8599V11.7898"
                  stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.40015 8.12103L8.00015 11.5987L11.6001 8.12103" stroke="black" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M8 11.4331V1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
              </svg>&nbsp;Tải biểu mẫu dữ liệu
            </a>
            <a (click)="viewLog()"
              class="k-button k-grid-edit-command k-button-md1 k-rounded-md k-button-solid-base1 k-button-solid ng-star-inserted">
              <i class="fa fa-file"></i>&nbsp;Nhật ký tích hợp</a>
          </div>
        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row" style="padding-bottom: 15px !important" *ngIf="locSearch">
      <div class="col-sm-3 col-md-3 col-xs-3 col-lg-3 d-flex">

      </div>
      <div class="col-sm-6 col-md-6 col-xs-6 col-lg-6 d-flex">
        <ng-select style="width: 200px;" [items]="options" bindValue="value" bindLabel="name" (change)="sortByStatus();"
          [(ngModel)]="status" name="status" placeholder="Lọc theo trạng thái">
        </ng-select>
        &nbsp;
        <ng-select [items]="lstDVHCCH" bindValue="madonvi" bindLabel="ten" [(ngModel)]="maHuyen" name="maHuyen"
          placeholder="Chọn đơn vị hành chính cấp huyện" style="margin: 0 auto;width: 200px"
          (change)="changeHuyenbyXa()">
        </ng-select>
        &nbsp;
        <ng-select [items]="lstDVHCCXFill" bindValue="madonvi" bindLabel="ten" [(ngModel)]="maXa" name="maXa"
          placeholder="Chọn đơn vị hành chính cấp xã" style="margin: 0 auto;width: 200px" (change)="loadHuyenXa()">
        </ng-select>
        &nbsp;
        <ng-select style="width: 200px; margin-right: 15px;" [items]="fields" bindValue="fieldname" bindLabel="name"
          name="status" placeholder="Chọn trường muốn tìm" [(ngModel)]="field"></ng-select>
        &nbsp;
      </div>
      <div class="col-sm-3 col-md-3 col-xs-3 col-lg-3 d-flex">

      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 ">
        <table class="table table-bordered table-hover ">
          <thead>
            <tr>
              <th class="text-center" style="width: 50px">STT</th>
              <th *ngFor="let item of fields; let i = index">{{ item.name }}</th>
              <th class="text-center" style="width: 50px">Hành động</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of target; let i = index">
              <td class="text-center">
                {{ i + (SearchModel.page - 1) * SearchModel.limit + 1 }}
              </td>
              <td *ngFor="let index of fields; let j = index">
                <span
                  *ngIf="index.name.toLowerCase() !== 'trạng thái' && index.name.toLowerCase() !== 'status' && index.fieldname.toLowerCase() !== 'mahuyen' && index.fieldname.toLowerCase() !== 'maxa' && index.name.toLowerCase() !== 'giới tính'">
                  {{item[index.fieldname.toLowerCase()]}}
                </span>
                <span *ngIf="index.fieldname.toLowerCase() === 'mahuyen'">
                  {{ getTenDVH(item[index.fieldname])}}
                </span>
                <span *ngIf="index.fieldname.toLowerCase() === 'maxa'">
                  {{ getTenDVX(item[index.fieldname])}}
                </span>
                <span *ngIf="index.name.toLowerCase() === 'trạng thái' || index.name.toLowerCase() === 'status'">
                  <mat-slide-toggle [color]="'primary'"
                    [checked]="setCheckedForToggle(item[index.fieldname.toLowerCase()])" [disabled]="false"
                    (change)="changeStatus(i)">
                  </mat-slide-toggle>
                </span>
              </td>
              <td class="text-center" style="padding: unset" style="width:95px">
                <button style="padding: 5px !important" title="Xem chi tiết" class="btn btn-sm"
                  (click)="viewFeature(i)">
                  <i class="fa fa-eye"></i>
                </button>
                <button style="padding: 5px !important" title="Sửa thông tin" class="btn btn-sm"
                  (click)="editFeature(i)">
                  <i class="fa fa-edit"></i>
                </button>
                <button style="padding: 5px !important" title="Xóa đối tượng" class="btn btn-sm"
                  (click)="deleteFeature(i)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row col" style="margin-top: 10px;">
        <div *ngIf="targetNew.data.recordsCount > 0">
          <div style="float: left;">
            Tổng số bản ghi: {{targetNew.data.recordsCount}}
          </div>
          <div style="float: right;">
            <ngx-pagination (pageChange)="SearchModel.page = $event;loadDataNew()"
              (pageSize)="SearchModel.limit = $event;loadDataNew()" [currentPage]="SearchModel.page"
              [totalPages]="targetNew.data.totalPage">
            </ngx-pagination>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>