import { DmLienketEntity } from './../../../../@core/data/app-home.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import {
  PAGE_SIZE,
  SUCCESS_RESPONE,
} from './../../../../@core/customs/constants';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DmLienketService } from '../dm-lienket.service';

@Component({
  selector: 'ngx-dm-lienket-create',
  templateUrl: './dm-lienket-create.component.html',
  styleUrls: ['./dm-lienket-create.component.scss'],
})
export class DmLienketCreateComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: DmLienketService,
    private dialogRef: MatDialogRef<DmLienketCreateComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: { lsttype: string }
  ) {}

  target: DmLienketEntity = new DmLienketEntity();

  ngOnInit(): void {}
  create() {
    this.target.loai = 1;
    this.service.insert(this.target).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess('Thêm mới dữ liệu thành công.');
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(res.message);
        this.dialogRef.close(false);
      }
    });
  }
}
