import { Trangthai } from './../../../../@core/data/app-home.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dataCsvIcon } from '@progress/kendo-svg-icons';
import { TintucSukienService } from '../tintuc-sukien.service';
import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';

@Component({
  selector: 'ngx-tintuc-sukien-accept',
  templateUrl: './tintuc-sukien-accept.component.html',
  styleUrls: ['./tintuc-sukien-accept.component.scss']
})
export class TintucSukienAcceptComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private service: TintucSukienService,
    private dialogRef: MatDialogRef<TintucSukienAcceptComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { data: any }
  ) { }
  target: any = {};
  ngOnInit(): void {
    this.target = this.data.data;
    // console.log('this.target', this.target);
  }
  update() {
    const targetUpdate = new Trangthai();
    targetUpdate.timedangtai = this.target.timedangtai ? new Date(this.target.timedangtai) : null;
    targetUpdate.trangthai = this.target.tientrinh;
    this.service.updateStatus(targetUpdate).subscribe(res => {
      if (res.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess('Cập nhật dữ liệu thành công.');
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(res.message);
        this.dialogRef.close(false);
      }
    });
  }

}
