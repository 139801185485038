import { DUPLICATE_RESPONE } from '../../../../@core/customs/constants';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { SysappEntity } from 'app/@core/data/app-qtht.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { FieldInsertOrUpdateModel } from 'app/@core/data/app-gis.service';
import { FileService } from '../file.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'ngx-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  uploadSaveUrl = ''; // should represent an actual API endpoint
  uploadRemoveUrl = ''; // should represent an actual API endpoint
  constructor(
    private fileService: FileService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private folder: { id: number },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FileUploadComponent>
  ) {}

  target: FieldInsertOrUpdateModel = new FieldInsertOrUpdateModel();

  ngOnInit(): void {
    this.uploadSaveUrl = environment.APP_GIS_URL + '/App/Gis/api/File/' + this.folder.id + '/UploadFile';
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  create() {}
  completeUpload(evt) {
    this.dialogRef.close(true);
  }
}
