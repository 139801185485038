import { ConvertDatePipe } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { ExportDataModel } from '../../../../@core/data/app-gis.service';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'ngx-featureclass-export',
  templateUrl: './featureclass-export.component.html',
  styleUrls: ['./featureclass-export.component.scss'],
})
export class FeatureclassExportComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private feature: { id: number; tablename: string },
    public dialogRef: MatDialogRef<FeatureclassExportComponent>,
    private readonly httpClient: HttpClient,
  ) { }

  
  prj: string = '105453';
  exportTye: string = 'shp';
  url: string;
  httpOptions: any;
  ngOnInit(): void {
    this.url = `${environment.APP_GIS_URL}/App/Gis/api/Data/export`;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }


  create(frm: NgForm) {
    const ex = new ExportDataModel();
    ex.featureclassId = this.feature.id;
    ex.tableName = this.feature.tablename;
    ex.fileType = this.exportTye;
    ex.prj = this.prj;
    ex.fields = [];
    ex.filter = ' status = 1';
    const date = Date();
    const CurrDate= new ConvertDatePipe().transform(date);

    const req = new HttpRequest(
      'POST',
      this.url,
      ex,
      { responseType: 'blob' }
    );
    this.httpClient.request<Blob>(req).pipe().subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          let result = event.body;
          if (this.exportTye === 'shp') {
            this.blobToFile(result, "application/zip", CurrDate +'-'+ ex.tableName+".zip");
          } else if (this.exportTye === 'csv') {
            this.blobToFile(result, "text/csv; charset=utf-8",  CurrDate +'-'+ ex.tableName+".csv");
          } else if (this.exportTye === 'excel') {
            this.blobToFile(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",  CurrDate +'-'+ ex.tableName+".xlsx");
          } else if (this.exportTye === 'json') {
            this.blobToFile(result, "application/json",  CurrDate +'-'+ ex.tableName+".json");
          }
          this.dialogRef.close(true);
        }
      },
      (error) => {
        throw error;
      }
    );
   
  }


  blobToFile(data: any, type: string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url; a.download = fileName; a.click();
    window.URL.revokeObjectURL(url);
  }
}



