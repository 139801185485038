import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DmLanguageIndexComponent } from './dm-language-index/dm-language-index.component';

export const routes: Routes = [
  {
    path: '',
    children: [{ path: '', component: DmLanguageIndexComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DmLanguageRoutingModule {}
