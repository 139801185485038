<form #frm="ngForm">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          <label>THÊM MỚI DANH SÁCH LIÊN KẾT</label>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-1" style="margin-top: 10px">
            <h6>Mã</h6>
          </div>
          <div class="col-md-11">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                [disabled]="!isEdit"
                [(ngModel)]="target.ma"
                name="ma"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-1">
            <h6>Tên</h6>
          </div>
          <div class="col-md-11">
            <div class="input-group flex-nowrap">
              <input
                type="text"
                class="form-control"
                [disabled]="!isEdit"
                [(ngModel)]="target.ten"
                name="ten"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="margin-top: 10px">
        <div class="row">
          <div class="col-md-1" style="margin-top: 5px">
            <h6>Linkweb</h6>
          </div>
          <div class="col-md-11">
            <div class="input-group flex-nowrap">
              <input
                type="text"
                class="form-control"
                [disabled]="!isEdit"
                [(ngModel)]="target.linkweb"
                name="linkweb"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="margin-top: 10px">
        <div class="row">
          <div class="col-md-1" style="margin-top: 5px">
            <h6>IMG</h6>
          </div>
          <div class="col-md-11">
            <div class="input-group flex-nowrap">
              <input
                type="text"
                class="form-control"
                [disabled]="!isEdit"
                [(ngModel)]="target.img"
                name="loai"
              />
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-end">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
          <ngx-btn-save *ngIf="isEdit" (click)="Update()"></ngx-btn-save>&nbsp;
          <ngx-btn-close mat-dialog-close></ngx-btn-close>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
