import {
  AttFileattachmentEntity,
  TintucSukienEntity,
  TintucSukienEntityProcessInsertFileModel,
  IFieldInfo,
} from './../../../../@core/data/app-home.service';
// import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TintucSukienService } from '../tintuc-sukien.service';
import { TintucSukienAcceptComponent } from '../tintuc-sukien-accept/tintuc-sukien-accept.component';
import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { FileParameter } from '../../../../@core/data/app-qtht.service';

@Component({
  selector: 'ngx-tintuc-sukien-create',
  templateUrl: './tintuc-sukien-create.component.html',
  styleUrls: ['./tintuc-sukien-create.component.scss'],
})
export class TintucSukienCreateComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: TintucSukienService,
    private dialogRef: MatDialogRef<TintucSukienCreateComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: { lsttype: string }
  ) {}
  currentStep: number = 0;
  ListLoaibv: any = [];
  ListTientrinh: any = [];
  ListTacgia: any = [];
  ListLanguage: any = [];
  listType: string;
  selectedFiles: FileParameter[] = [];
  typeAttach = this.service.typeAttach();
  userName: string;
  tinnoibat: boolean;
  title: string = 'THÊM MỚI ';
  target: TintucSukienEntity = new TintucSukienEntity();
  ngOnInit(): void {
    this.loadDm();
    this.commonService.getUserInfo().subscribe((res1) => {
      this.userName = res1.UserName;
    });
    if ((this.tinnoibat = false)) {
      this.target.tinnoibat === '0';
    }
    this.target.ngayxuatban = new Date();
    if (this.data.lsttype === '01') {
      this.title += 'TIN TỨC - SỰ KIỆN';
    } else if (this.data.lsttype === '02') {
      this.title += 'ALBUM ẢNH/VIDEO';
    } else if (this.data.lsttype === '03') {
      this.title += 'CHUYÊN TRANG “CẢI THIỆN MẠNH MẼ MÔI TRƯỜNG ĐẦU TƯ KINH DOANH”';
    } else if (this.data.lsttype === '04') {
      this.title += 'VĂN HÓA LỊCH SỬ';
    } else if (this.data.lsttype === '05') {
      this.title += 'DANH LAM THẮNG CẢNH';
    } else if (this.data.lsttype === '07') {
      this.title += 'LIÊN KẾT';
    } else if (this.data.lsttype === '08') {
      this.title += 'QUẢNG CÁO';
    }
  }

  loadDm() {
    this.service.getDm().subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        if (this.data.lsttype === '01') {
          this.listType = '01,04,06,07,08,09,10,11,12,16';
        } else if (this.data.lsttype === '02') {
          this.listType = '02,03';
        } else if (this.data.lsttype === '03') {
          this.listType = '13';
        } else if (this.data.lsttype === '04') {
          this.listType = '14';
        } else if (this.data.lsttype === '05') {
          this.listType = '15';
        } else if (this.data.lsttype === '06') {
          this.listType = '20,21,22,23,24,25,26,27,28,29';
        }
        this.ListLoaibv = res.data.dmLoaibaivietVms.filter((x) => this.listType.includes(x.ma));
        this.ListTacgia = res.data.dmTacgiaVms;
        this.ListLanguage = res.data.dmLanguageVms;
        this.ListTientrinh = res.data.dmTientrinhVms.sort((a, b) => a.matientrinh - b.matientrinh);
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  deleteFile(file: any) {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) this.selectedFiles.splice(index, 1);
  }

  // Sự kiện chọn tệp
  chooseFile(files: FileParameter[]) {
    if (files.length === 0) {
      return;
    }
    this.selectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      const fileParameter: FileParameter = { data: files[i], fileName: files[i]['name'] };
      this.selectedFiles.push(fileParameter);
    }
  }

  publish() {
    this.dialog
      .open(TintucSukienAcceptComponent, this.commonService.configDialog('30%', { data: this.target }))
      .afterClosed()
      .subscribe((res) => {
        // this.dialogRef.close(true);
      });
  }

  create() {
    this.commonService.uploadFile('TintucSukien', this.selectedFiles).subscribe((result) => {
      const lstFile = result.data.map(
        (element) =>
          new AttFileattachmentEntity({
            filename: element.fileName,
            recordid: null,
            fileid: element.id,
            id: element.id,
            type: this.typeAttach,
          })
      );

      const targetFileInsert: AttFileattachmentEntity[] = [];
      lstFile?.forEach((m) => {
        const item = new AttFileattachmentEntity();
        item.fileid = m.fileid;
        item.filename = m.filename;
        item.type = this.typeAttach;
        item.isDirty = false;
        item.isNew = true;
        targetFileInsert.push(item);
      });
      this.target.ngayxuatban = new Date(this.target.ngayxuatban) ? new Date(this.target.ngayxuatban) : null;
      this.target.timedangtai = new Date(this.target.timedangtai) ? new Date(this.target.timedangtai) : null;
      // if (this.target.tinnoibat === 'false' || this.target.tinnoibat === null) this.target.tinnoibat = null;
      if (this.tinnoibat) this.target.tinnoibat = '1';
      else this.target.tinnoibat = '0';
      this.target.trangthai = 1;
      this.target.createdate = new Date();
      this.target.createuser = this.userName;
      const model: TintucSukienEntityProcessInsertFileModel = new TintucSukienEntityProcessInsertFileModel({
        entity: this.target,
        lstFileAttachment: targetFileInsert,
      });

      this.service.insertWithFile(model).subscribe((res) => {
        if (res.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess('Thêm mới dữ liệu thành công.');
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrDanger(res.message);
          this.dialogRef.close(false);
        }
      });
    });
  }
}
