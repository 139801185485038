import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmChucvuEntity } from 'app/@core/data/app-home.service';
import { DmChucVuService } from '../dm-chuc-vu.service';

@Component({
  selector: 'ngx-dm-chuc-vu-create',
  templateUrl: './dm-chuc-vu-create.component.html',
  styleUrls: ['./dm-chuc-vu-create.component.scss'],
})
export class DmChucVuCreateComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: DmChucVuService,
    private dialogRef: MatDialogRef<DmChucVuCreateComponent>
  ) {}

  ngOnInit(): void {}

  target: DmChucvuEntity = new DmChucvuEntity();

  create(frm: NgForm) {
    this.target.trangthai = 1;
    this.service.insert(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
