import {
  AppQthtApiService,
  AuthenticateRequest,
  ObjectIEnumerableApiResponse,
  TokenForRefresh,
} from './data/app-qtht.service';
import { CommonService } from './customs/common.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SUCCESS_RESPONE } from './customs/constants';
import { MenuService } from '../@theme/pages/menu/menu.service';

export interface IUserDataToken {
  Id: number;
  FullName: string;
  UnitCode: string;
  UnitName: string;
  PhongBan: string;
  UserName: string;
  RefreshToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl = '';

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError('Tài khoản chưa được phê duyệt');
  }

  private jwtHelper: JwtHelperService;
  private token?: string;
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private commonService: CommonService,
    private appQthtService: AppQthtApiService,
    private menuService: MenuService,
    private router: Router
  ) {
    this.jwtHelper = new JwtHelperService();
    this.jwtHelper.tokenGetter = () => this.token;
    this.loadSavedAuthData();
  }

  login(loginData: any, state: string): Observable<any> {
    this.tokenService.removeToken();

    // set tag for app Đầu tư
    let selectDuAn = null;
    if (state === 'dautu') {
      selectDuAn = this.tokenService.getProjectInvest();
    }

    const userLogin = new AuthenticateRequest({
      username: loginData.username,
      password: loginData.password,
      tag: selectDuAn,
      appId: loginData.appId,
    });
    this.menuService.resetMenuWhenLogin();
    return this.appQthtService.appAuthApiAuthAuthenticate(userLogin).pipe(
      tap((res) => {
        if (res.data?.jwtToken) {
          this.tokenService.saveToken(res.data.jwtToken);
          this.token = res.data.jwtToken;
          const usInfor: IUserDataToken = {
            Id: res.data.id,
            FullName: res.data.fullName,
            UnitCode: res.data.unitCode,
            UserName: res.data.username,
            PhongBan : res.data.phongBan,
            UnitName: res.data.unitName,
            RefreshToken: res.data.refreshToken,
          };
          this.tokenService.setUserInfo(usInfor, this.jwtHelper.getTokenExpirationDate());
          localStorage.setItem('curAppId', loginData.appId);
  
          let stateRouter = '/';
          switch (loginData.appId) {
            case '1':
              stateRouter = '/app-home-admin/tintuc-sukien';
              break;
            case '23':
              stateRouter = '/gis/dataset';
              break;
            case '2':
              stateRouter = '/app-qtht-cb/user-app';
              break;
            default:
              break;
          }
          this.router.navigate([stateRouter]).then(() => {
            this.commonService.toastrSuccess('Đăng nhập thành công !');
          });
        } else {
          this.commonService.toastrDanger(res.message);
        }
        
      }),
      catchError(AuthService.handleError)
    );
  }

  private loadSavedAuthData(): void {
    const tokenString = this.getSavedAuthData();
    this.setAuthData(tokenString);
  }

  private getSavedAuthData() {
    if (!localStorage) return null;
    return this.tokenService.getToken();
  }

  private setAuthData(tokenString: string): void {
    this.token = tokenString;
  }

  getListApplication(): Observable<ObjectIEnumerableApiResponse> {
    return this.appQthtService.appQthtApiSysAppSelectbyStatus();
  }

  async refreshToken(tag: string = null): Promise<any> {
    const body = new TokenForRefresh({
      tag: tag,
    });

    await this.tokenService.getUserInfo().subscribe((result) => {
      body.refreshToken = result.RefreshToken;
    });

    return this.appQthtService.appAuthApiAuthRefreshToken(body).pipe(
      tap((res) => {
        if (res.code === SUCCESS_RESPONE) {
          this.token = res.data.jwtToken;
          const usInfor: IUserDataToken = {
            Id: res.data.id,
            FullName: res.data.fullName,
            UnitCode: res.data.unitCode,
            UserName: res.data.username,
            UnitName: res.data.unitName,
            PhongBan: res.data.phongBan,
            RefreshToken: res.data.refreshToken,
          };
          this.tokenService.saveToken(res.data.jwtToken);
          this.tokenService.setUserInfo(usInfor, this.jwtHelper.getTokenExpirationDate());
        }
      }),
      catchError(AuthService.handleError)
    );
  }

  logout(): void {
    this.token = null;
    this.tokenService.removeToken();
    this.tokenService.removeCookie();
  }

  register(data: any): Observable<any> {
    return null;
  }

  secured(): Observable<any> {
    return null;
  }

  checkTokenExpired(): boolean {
    if (this.jwtHelper.isTokenExpired()) {
      this.router.navigate(['/login']).then((_) => false);
      return true;
    }
    return false;
  }

  routingMach(routing: string): boolean {
    let checkRouting = false;
    this.menuService.getRoutingSaveCheckRole().subscribe((result) => {
      if (result.length === 0) {
        this.menuService.getMenuByUserLogin().subscribe((menuLogin) => {
          if (menuLogin.code === SUCCESS_RESPONE) {
            this.menuService.setRoutingSaveCheckRole(menuLogin.data);
            if (menuLogin.data.findIndex((c) => c.state === routing) !== -1) {
              checkRouting = true;
            }
          }
        });
      } else {
        if (result.findIndex((c) => c.state === routing) !== -1) {
          checkRouting = true;
        }
      }
    });
    return checkRouting;
  }
}
