import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE, SUCCESS_NOTICE, PAGE_SIZE } from '../../../../@core/customs/constants';
import { FileService } from '../file.service';
import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { DatasetService } from '../../dataset/dataset.service';
import { SelectableSettings, FlatBindingDirective } from '@progress/kendo-angular-treelist';
import { FolderCreateComponent } from '../folder-create/folder-create.component';
import { FolderUpdateComponent } from '../folder-update/folder-update.component';
import {
  FolderInsertOrUpdateModel,
  PageModel,
  DatasetDetailModel,
  FileUpdateModel,
} from '../../../../@core/data/app-gis.service';
import { TokenService } from '../../../../@core/token.service';
import { DialogConfirmComponent } from '../../../../@theme/components/template/dialog-confirm/dialog-confirm.component';
import { ShareComponent } from '../../featureclass/Share-component/share.component';

@Component({
  selector: 'ngx-file-index',
  templateUrl: './file-index.component.html',
  styleUrls: ['./file-index.component.scss'],
})
export class FileIndexComponent implements OnInit {
  @Input('directive') directive: FlatBindingDirective;
  constructor(
    private ref: ChangeDetectorRef,
    // private directive: FlatBindingDirective,
    private datasetService: DatasetService,
    private fileService: FileService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private tokenService: TokenService
  ) {}
  public settings: SelectableSettings = {
    enabled: true,
    mode: 'row',
    multiple: false,
    drag: false,
    readonly: false,
  };

  public selected: any[] = [];
  pageModel: PageModel = new PageModel({
    pageSize: PAGE_SIZE,
    condition: null,
    currentPage: 1,
    search: null,
  });
  parentId: string;
  target: any = [];
  folderSelect: any;
  public data: DatasetDetailModel[] = [];
  checkUnit: boolean;
  checkPhongBan: boolean;
  ngOnInit(): void {
    this.tokenService.getUserInfo().subscribe((users: any) => {
      if (users.UnitCode === '9999') {
        this.checkUnit = true;
        this.checkPhongBan = false;
      } else {
        this.checkPhongBan = users.PhongBan ? true : false;
      }
    });
    this.loadFolder();
  }
  share() {
    if (this.selected.length > 0) {
      this.dialog
        .open(ShareComponent, this.commonService.configDialog('40%', { item: this.folderSelect, type: 'Folder' }))
        .afterClosed()
        .subscribe((result) => {
          if (result === true) {
            this.loadFolder();
          }
        });
    } else this.commonService.toastrDanger('Vui lòng chọn thư mục');
  }
  loadFiles(parentId) {
    // if(this.selected.length > 0){
    this.parentId = null;
    this.fileService.PagingFile(parentId, this.pageModel).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.parentId = parentId;
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
    // }else
    //   this.commonService.toastrDanger("Vui lòng chọn thư mục");
  }

  search() {
    this.loadFiles(this.parentId);
  }
  loadFolder() {
    this.selected = [];
    this.target = [];
    this.fileService.getFolder(undefined).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.data = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  setCheckedForToggle(status: number) {
    if (status === 1) {
      return true;
    } else {
      return false;
    }
  }

  searchPage() {
    if (this.selected.length > 0) {
      this.pageModel.search = this.pageModel.search;
      this.loadFiles(this.selected[0].itemKey);
    } else this.commonService.toastrDanger('Vui lòng chọn thư mục');
  }

  resetPage() {
    this.pageModel.currentPage = 1;
    this.pageModel.search = undefined;
    this.searchPage();
  }

  uploadFile() {
    if (this.selected.length > 0) {
      this.dialog
        .open(FileUploadComponent, this.commonService.configDialog('50%', { id: this.selected[0].itemKey }))
        .afterClosed()
        .subscribe((result) => {
          if (result === true) {
            this.loadFiles(this.selected[0].itemKey);
          }
        });
    } else this.commonService.toastrDanger('Vui lòng chọn thư mục');
  }

  copyFolder() {
    if (this.selected.length > 0) {
      if (confirm(`Xác nhận sao chép thư mục ${this.folderSelect.name} ?`)) {
        const folder = new FolderInsertOrUpdateModel();
        folder.name = this.folderSelect.name.trim() + ' - Sao chép';
        folder.parentID = this.folderSelect.parentID;
        this.fileService.createFolder(folder).subscribe((result) => {
          if (result.code === SUCCESS_RESPONE) {
            this.commonService.toastrSuccess();
            this.loadFolder();
          } else {
            this.commonService.toastrDanger(result.message);
          }
        });
      }
    } else this.commonService.toastrDanger('Vui lòng chọn thư mục');
  }

  addFolder() {
    let parentID = null;
    if (this.selected.length > 0) parentID = this.selected[0].itemKey;

    this.dialog
      .open(
        FolderCreateComponent,
        this.commonService.configDialog('40%', { parentID: parentID, checkUnit: this.checkUnit })
      )
      .afterClosed()
      .subscribe((result) => {
        this.loadFolder();
        this.target = [];
      });
  }
  editFolder() {
    if (this.selected.length > 0) {
      this.dialog
        .open(
          FolderUpdateComponent,
          this.commonService.configDialog('40%', { folderSL: this.folderSelect, checkUnit: this.checkUnit })
        )
        .afterClosed()
        .subscribe((result) => {
          this.loadFolder();
        });
    } else this.commonService.toastrDanger('Vui lòng chọn thư mục');
  }
  deleteFolder() {
    if (this.selected.length > 0) {
      this.dialog
        .open(
          DialogConfirmComponent,
          this.commonService.configDialog('30%', {
            title: 'Xác nhận',
            message: `Bạn có chắc chắn xóa <br><b>${this.folderSelect.name}</b> ?`,
            color: 'text-warning',
            type: 1,
            icon: 'exclamation-triangle',
            isAppend: true,
          })
        )
        .afterClosed()
        .subscribe((info) => {
          if (info) {
            this.fileService.deleteFolder(this.folderSelect.id).subscribe((res) => {
              if (res.code === SUCCESS_RESPONE) {
                this.commonService.toastrSuccess(SUCCESS_NOTICE);
                this.loadFolder();
              } else {
                this.commonService.toastrDanger(res.message);
              }
            });
          }
        });
    } else this.commonService.toastrDanger('Vui lòng chọn thư mục');
  }
  changeFolder(evt) {
    this.folderSelect = evt.dataItem;
    this.pageModel.search = null;
    this.pageModel.currentPage = 1;
    this.loadFiles(evt.dataItem.id);
  }
  editItem(id: number, name: string) {
    const q = prompt('Nhập tên tệp để cập nhật', name);

    if (q != null) {
      const modl = new FileUpdateModel();
      modl.name = q;
      this.fileService.updateFile(id, modl).subscribe((result) => {
        if (result.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess();
          this.loadFiles(this.selected[0].itemKey);
        } else {
          this.commonService.toastrDanger(result.message);
        }
      });
    }
  }
  deleteItem(id: number, name: string) {
    if (confirm(`Xác nhận xóa tệp dữ liệu ${name} ?`)) {
      this.fileService.delete(id).subscribe((result) => {
        if (result.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess();
          this.loadFiles(this.selected[0].itemKey);
        } else {
          this.commonService.toastrDanger(result.message);
        }
      });
    }
  }
  byteToSize(bytes) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
