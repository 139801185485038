<form #frm="ngForm" (ngSubmit)="create(frm)">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          Thêm mới chủ đề dữ liệu
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Tên chủ đề</label>
            <input type="text" class="form-control" name="name" [(ngModel)]="target.name" required maxlength="50" />
          </div>
        </div>
        <div class="col-lg-12" *ngIf="checkUnit === true">
          <div class="form-group">
            <label class="col-form-label">Đơn vị quản lý</label>
            <ng-select [items]="lstUnit" bindValue="unitcode" bindLabel="unitname" [(ngModel)]="target.unitcode"
              name="unitcode" placeholder="Chọn đơn vị" appendTo="Body"></ng-select>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label">Mô tả mục đích, phạm vi, nội dung của từng cơ sở dữ liệu</label>
            <textarea type="textarea" class="form-control" name="description" [(ngModel)]="target.description"
              maxlength="250"> </textarea>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label">Cơ chế thu thập, cập nhật, nguồn dữ liệu</label>
            <textarea type="textarea" class="form-control" name="mechanism" [(ngModel)]="target.mechanism"
              maxlength="250">
            </textarea>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label">Hạng mục dữ liệu</label>
            <textarea type="textarea" class="form-control" name="category" [(ngModel)]="target.category"
              maxlength="250">
          </textarea>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label">Ghi chú</label>
            <textarea type="textarea" class="form-control" name="note" [(ngModel)]="target.note"
              maxlength="250"></textarea>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button type="submit" class="btn btn-primary" [disabled]="frm.invalid">
            Lưu
          </button>
          &nbsp;
          <button type="button" class="btn btn-outline-secondary" mat-dialog-close>
            Đóng
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>