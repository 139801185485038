import { Component, OnInit } from '@angular/core';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { PAGE_SIZE, SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CountDataModel, DataSearchModel, DataSetModel } from '../../../../@core/data/app-gis.service';
import { DatasetService } from '../../dataset/dataset.service';
import { FeatureclassService } from '../../featureclass/featureclass.service';
import { DataService } from '../../services/data.service';
import { Workbook } from 'exceljs';
import { DatePipe } from '@angular/common';
import * as fs from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { ViewDataThongKeComponent } from '../view-data-thong-ke/view-data-thong-ke.component';
import { FieldService } from '../../featureclass/field.service';
import { TokenService } from '../../../../@core/token.service';
@Component({
  selector: 'ngx-data-index',
  templateUrl: './data-index.component.html',
  styleUrls: ['./data-index.component.scss'],
})
export class DataIndexComponent implements OnInit {
  constructor(
    private datasetService: DatasetService,
    private commonService: CommonService,
    private dataService: DataService,
    private featureclassService: FeatureclassService,
    private dialog: MatDialog,
    private fieldService: FieldService,
    private tokenService: TokenService,
  ) { }

  datasets = [];
  target: any = [];
  toggleTable: boolean = false;
  firstYear = 2013;
  month = 0;
  Length: number;
  CurYear: number;
  CurMonth: number;
  dataSetId: number;
  lstDataSetId: any[] = [];
  totalCount: number;
  dateStart: any;
  dateEnd: any;
  textSearch: string;
  lstDVHCCH: any[] = [];
  lstDataSession: any[] = [];
  lstDataSetSession: any[] = [];
  lstDVHCCX: any[] = [];
  lstXa: any[] = [];
  lstField: any[] = [];
  MDVHCCX: string;
  field: string;
  MDVHCCH: string;
  lstTable: any[] = [];
  lstLabel: any[] = [];
  featureClassID: any[] = [];
  show: boolean = false;
  dataModel: CountDataModel = new CountDataModel({
    listDataSetId: null,
    maDVHCCH: null,
    year: null,
    month: null,
    maDVHCCX: null,
  });
  SearchModel: DataSearchModel = new DataSearchModel({
    tableName: null,
    text: null,
    page: 1,
    limit: 999999999,
    status: null,
    fieldSelect: null,
    dvhcch: null,
    dvhccx: null,
    lstId: null
  });
  ngOnInit(): void {
    this.loadData();
    this.loadDVHC();
  }
  loadDVHC() {
    this.dataService.getDataByTableName('dvhccx').subscribe((result) => {
      this.lstDVHCCX = JSON.parse(result.data);
    });
    this.dataService.getDataByTableName('dvhcch').subscribe((res) => {
      this.lstDVHCCH = JSON.parse(res.data);
    });
  }
  loadData() {
    this.datasetService.getDataset().subscribe((ds) => {
      if (ds.code === SUCCESS_RESPONE) {
        this.tokenService.getUserInfo().subscribe((users: any) => {
          if (users.UnitCode !== '9999') {
            ds.data = ds.data.filter(x => x.unitcode === users.UnitCode);
          } 
          this.datasets = this.dataSetTree(ds.data, null);
          this.lstDataSetSession = ds.data;
        });
        
      } else {
        this.commonService.toastrDanger(ds.message);
      }
    });
  }
  filterByDataset(item) {
    this.dataSetId = item.id;
    if (this.dataSetId !== null) {
      this.featureClassID = [];
      this.featureclassService.getFeatureByParentId(this.dataSetId).subscribe((ds) => {
        if (ds.code === SUCCESS_RESPONE) {
          this.lstTable = ds.data;
        }
      });
    } else {
      this.lstTable = [];
      this.featureClassID = [];
      this.dataSetId = undefined;
    }
  }

  changeFeature() {
    if (this.featureClassID.length === 1) {
      this.show = true;
      this.getField();
    } else {
      this.textSearch = null;
      this.show = false;
    }
  }
  getField() {
    this.dataService.getFieldsByTableName(this.featureClassID[0]).subscribe((fields) => {
      if (fields.code === SUCCESS_RESPONE) {
        this.lstField = fields.data;
      }
    })
  }
  getTenDVHCCX(value: string) {
    if (value === null || value === undefined) return value;
    const name = this.lstDVHCCX.find((x) => x.madonvi === value).ten
      ? this.lstDVHCCX.find((x) => x.madonvi === value).ten
      : value;
    return name;
  }
  getTenDVHCCH(value: string) {
    if (value === null || value === undefined) return value;
    const name = this.lstDVHCCH.find((x) => x.madonvi === value).ten
      ? this.lstDVHCCH.find((x) => x.madonvi === value).ten
      : value;
    return name;
  }
  locDieukien() {
    this.toggleTable = !this.toggleTable;
  }
  changeDataSet() {
    if (this.dataSetId !== null) {
      this.featureClassID = [];
      this.featureclassService.getFeatureByParentId(this.dataSetId).subscribe((ds) => {
        if (ds.code === SUCCESS_RESPONE) {
          this.lstTable = ds.data;
        }
      });
    } else {
      this.lstTable = [];
      this.featureClassID = [];
      this.dataSetId = undefined;
    }
  }
  refresh() {
    this.lstTable = [];
    this.featureClassID = [];
    this.lstDataSetId = [];
    this.MDVHCCH = null;
    this.MDVHCCX = null;
    this.dataSetId = undefined;
    this.lstLabel = [];
    this.target = [];
    this.dateStart = null;
    this.dateEnd = null;
    this.toggleTable = false;
    this.textSearch = null;
    this.field = null;
  }
  countDataSet() {
    const lst: Array<DataSetModel> = [];
    if (this.lstDataSetId.length > 0) {
      this.lstDataSetId.forEach(element => {
        this.featureclassService.getFeatureByParentId(element).subscribe((ds) => {
          ds.data.forEach((e) => {
            const datasetModel = new DataSetModel();
            datasetModel.tableName = e.tableName;
            datasetModel.name = e.name;
            datasetModel.dataSetName = this.datasets.filter((x) => x.id === element)[0]?.name;
            lst.push(datasetModel);
          });
          this.ApiCountDataSet(lst);
        });
      });
    } else {
      this.datasets.forEach(element => {
        this.featureclassService.getFeatureByParentId(element.id).subscribe((ds) => {
          ds.data.forEach((e) => {
            const datasetModel = new DataSetModel();
            datasetModel.tableName = e.tableName;
            datasetModel.name = e.name;
            datasetModel.dataSetName = this.datasets.filter((x) => x.id === element.id)[0]?.name;
            lst.push(datasetModel);
          });
          this.ApiCountDataSet(lst);
        });
      });
    }
  }
  count() {
    const lst: Array<DataSetModel> = [];
    if (this.featureClassID.length > 0) {
      this.featureClassID.forEach((elm) => {
        const feature = this.lstTable.filter((x) => x.tableName === elm)[0];
        const datasetModel = new DataSetModel();
        datasetModel.tableName = feature.tableName;
        datasetModel.name = feature.name;
        datasetModel.dataSetName = this.datasets.filter((x) => x.id === this.dataSetId)[0]?.name;
        lst.push(datasetModel);
      });
      this.ApiCount(lst);
    } else {
      this.featureclassService.getFeatureByParentId(this.dataSetId).subscribe((ds) => {
        ds.data.forEach((element) => {
          const datasetModel = new DataSetModel();
          datasetModel.tableName = element.tableName;
          datasetModel.name = element.name;
          datasetModel.dataSetName = this.datasets.filter((x) => x.id === this.dataSetId)[0]?.name;
          lst.push(datasetModel);
        });
        this.ApiCount(lst);
      });
    }
  }
  exportExcelData() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Dữ liệu', {
      pageSetup: { paperSize: 50 },
    });

    worksheet.getCell('A1', 'E1').style = {
      font: { bold: true, name: 'Times New Roman', size: 16 },
      alignment: { vertical: 'middle', horizontal: 'center' },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    };

    worksheet.getCell('A1', 'E1').value = 'Bảng thống kê dữ liệu';
    worksheet.getRow(1).height = 40;
    worksheet.columns = [
      { header: ['Bảng thống kê dữ liệu', 'STT'], key: 'stt', width: 7 },
      { header: ['Bảng thống kê dữ liệu', 'Chủ đề dữ liệu'], key: 'chude', width: 40 },
      { header: ['Bảng thống kê dữ liệu', 'Tên huyện'], key: 'tenhuyen', width: 40 },
      { header: ['Bảng thống kê dữ liệu', 'Tên xã'], key: 'tenxa', width: 40 },
    ];
    let endCol = 4 + this.lstLabel.length;
    let colNum = 0;
    const dateTime =
      new ConvertDatePipe().transformCustom(this.dateStart) + ' - ' + new ConvertDatePipe().transformCustom(this.dateEnd);
    if (this.dateStart) {
      colNum = endCol + 1;
      worksheet.getCell(2, endCol + 1).style = {
        font: { bold: true, name: 'Times New Roman', size: 12 },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
      };
      worksheet.getCell(2, endCol + 2).style = {
        font: { bold: true, name: 'Times New Roman', size: 12 },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
      };

      worksheet.getCell(2, colNum).value = 'Thời gian';
      worksheet.getCell(2, colNum + 1).value = 'Tổng dữ liệu';
      worksheet.mergeCells(1, 1, 1, endCol + 2);
    } else {
      colNum = endCol + 1;
      worksheet.getCell(2, endCol + 1).style = {
        font: { bold: true, name: 'Times New Roman', size: 12 },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
      };
      worksheet.getCell(2, colNum).value = 'Tổng dữ liệu';
      worksheet.mergeCells(1, 1, 1, endCol + 1);
    }
    for (let i = 1; i < 5; i++) {
      worksheet.getCell(2, i).style = {
        font: { bold: true, name: 'Times New Roman', size: 12 },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
      };
    }
    if (this.lstLabel?.length > 0) {
      for (let index = 0; index < this.lstLabel.length; index++) {
        const name = this.lstDataSession.filter((x) => x.tableName === this.lstLabel[index].tablename)[0]?.name;
        if (name) {
          let colNumber = index + 5;
          worksheet.getCell(2, colNumber).style = {
            font: { bold: true, name: 'Times New Roman', size: 12 },
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
          };
          worksheet.getCell(2, colNumber).value = name;
        }
      }
    }

    if (this.target.length > 0) {
      this.target.forEach((element, index) => {
        let rowNumber = index + 3;
        worksheet.getCell(rowNumber, 1).style = {
          font: { bold: false, name: 'Times New Roman', size: 10 },
          border: {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          },
          alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
        };
        worksheet.getCell(rowNumber, 2).style = {
          font: { bold: false, name: 'Times New Roman', size: 10 },
          border: {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          },
          alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
        };
        worksheet.getCell(rowNumber, 3).style = {
          font: { bold: false, name: 'Times New Roman', size: 10 },
          border: {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          },
          alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
        };
        worksheet.getCell(rowNumber, 4).style = {
          font: { bold: false, name: 'Times New Roman', size: 10 },
          border: {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          },
          alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
        };
        worksheet.getCell(rowNumber, 1).value = index + 1;
        worksheet.getCell(rowNumber, 2).value = this.target[index].dataSetName;
        worksheet.getCell(rowNumber, 3).value = this.getTenDVHCCH(this.target[index].mahuyen);
        worksheet.getCell(rowNumber, 4).value = this.getTenDVHCCX(this.target[index].maxa);
        this.lstLabel.forEach((e, i) => {
          let colNumber = i + 5;
          worksheet.getCell(rowNumber, colNumber).style = {
            font: { bold: false, name: 'Times New Roman', size: 10 },
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
          };
          worksheet.getCell(rowNumber, colNumber).value = element[e.tablename];
        });
        worksheet.getCell(rowNumber, colNum).value = element.totalData;
        if (this.dateStart) {
          worksheet.getCell(rowNumber, colNum).value = dateTime;
          worksheet.getCell(rowNumber, colNum + 1).value = element.totalData;
          worksheet.getCell(rowNumber, colNum).style = {
            font: { bold: false, name: 'Times New Roman', size: 10 },
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
          };
          worksheet.getCell(rowNumber, colNum + 1).style = {
            font: { bold: false, name: 'Times New Roman', size: 10 },
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
          };
        } else {
          worksheet.getCell(rowNumber, colNum).value = element.totalData;
          worksheet.getCell(rowNumber, colNum).style = {
            font: { bold: false, name: 'Times New Roman', size: 10 },
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
          };
        }
      });
    }
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        maxLength = Math.max(columnLength, maxLength);
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

    worksheet.getRow(2).height = 20;

    const datePipe = new DatePipe('en-US');
    const dateNow = datePipe.transform(new Date(), 'dd-MM-YYYY');
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Bang-Thong-Ke-Du-Lieu-Den-Ngay-' + dateNow + '.xlsx');
    });
  }
  selectAllD(item) {
    if (item === 'feature') {
      this.featureClassID = this.lstTable.map((x) => x.tableName);
    } else {
      this.lstDataSetId = this.datasets.map((x) => x.id);
    }
  }
  unselectAll(item) {
    if (item === 'feature') {
      this.featureClassID = [];
    } else {
      this.lstDataSetId = [];
    }
  }
  changeHuyen() {
    if (this.MDVHCCH) {
      this.lstXa = this.lstDVHCCX.filter((x) => x.macha === this.MDVHCCH);
    } else {
      this.lstXa = [];
      this.MDVHCCX = null;
    }
  }
  ApiCountDataSet(lst) {
    this.dataModel.listDataSetId = lst;
    this.dataModel.month = this.dateStart;
    this.dataModel.year = this.dateEnd;
    this.dataModel.maDVHCCX = this.MDVHCCX;
    this.dataModel.maDVHCCH = this.MDVHCCH;
    this.dataModel.field = this.field;
    this.dataModel.search = this.textSearch;
    this.datasetService.countDatas(this.dataModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.lstDataSession = res.data;
        const resultObject = {};
        res.data.forEach((item) => {
          const key = `${item.dataSetName}_${item.dvhcch}_${item.dvhccx}`;
          resultObject[key] = resultObject[key] || {
            dataSetName: item.dataSetName,
            name: item.name,
            mahuyen: item.dvhcch,
            maxa: item.dvhccx,
          };

          resultObject[key][item.tableName] = item.totalCount;
          const count = resultObject[key].totalData ? resultObject[key].totalData : 0;
          resultObject[key].totalData = count + item.totalCount;
        });
        this.target = Object.values(resultObject);
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
  viewData(tableName, lstId) {
    this.dialog
      .open(ViewDataThongKeComponent, this.commonService.configDialog('80%',
        {
          tableName: tableName,
          lstId: lstId,
          field: this.field,
          textSearch: this.textSearch,
          month: this.dateStart,
          year: this.dateEnd,
          maDVHCCX: this.MDVHCCX,
          maDVHCCH: this.MDVHCCH
        }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  targetNew: any = {
    data: [],
  };
  fields: any = [];
  async ExportData(tableName: string, listId: string) {
    this.SearchModel.tableName = tableName;
    this.SearchModel.dvhcch = this.MDVHCCH;
    this.SearchModel.dvhccx = this.MDVHCCX;
    this.SearchModel.fieldSelect = this.field;
    this.SearchModel.text = this.textSearch;
    this.SearchModel.startDate = this.dateStart;
    this.SearchModel.endDate = this.dateEnd;
    const name = await this.fieldService.getName(tableName).toPromise();
    const field = await this.fieldService.getAllByTableName(tableName).toPromise();
    this.fields = field?.data;
    this.targetNew = await this.dataService.PaingData(this.SearchModel).toPromise();
    const DataExcel = JSON.parse(this.targetNew.data.data);
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Dữ liệu', {
      pageSetup: { paperSize: 50 },
    });
    let tieude1 = ['DANH SÁCH THÔNG TIN CHI TIẾT THỐNG KÊ'];
    worksheet.addRow(tieude1);
    worksheet.addRow(['', 'Lớp dữ liệu', name]);
    worksheet.addRow(['', 'Huyện', this.getTenDVHCCH(this.MDVHCCH)]);
    worksheet.addRow(['', 'Xã', this.getTenDVHCCX(this.MDVHCCX)]);
    worksheet.addRow(['', 'Thời gian từ', this.dateStart]);
    worksheet.addRow(['', 'Thời gian đến', this.dateEnd]);
    const datePipe = new DatePipe('en-US');
    let isFirstRow = true;
    DataExcel.forEach((item, index) => {
      const dataRow = [index + 1];
      this.fields.forEach(header => {
        if (item[header.fieldname.toLowerCase()] == null || item[header.fieldname.toLowerCase()] == undefined) {
          dataRow.push("");
        } else if (header.fieldname == 'CREATEDATE') {
          dataRow.push(datePipe.transform(item[header.fieldname.toLowerCase()], 'dd-MM-YYYY'));
        } else {
          dataRow.push(item[header.fieldname.toLowerCase()]);
        }
      });
      if (isFirstRow) {
        const headerRow = ["STT"];
        this.fields.forEach(header => {
          headerRow.push(header.name);
        });
        worksheet.addRow(headerRow);
        isFirstRow = false;
      }
      worksheet.addRow(dataRow);
    });

    worksheet.eachRow(function (row, rowNumber) {
      row.eachCell((cell) => {
        cell.font = { size: 13, name: "Times New Roman" };
        cell.alignment = { vertical: 'middle' }
        cell.border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
      })
    });

    const columnLetters = [];
    for (let i = 0; i < this.fields.length + 1; i++) {
      columnLetters.push(String.fromCharCode(65 + i)); // A=65, B=66, ..., Z=90
    }
    columnLetters.forEach(x => {
      worksheet.getCell(x + '7').style = {
        font: { bold: true, size: 14 },
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
        border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
      };
      if (x !== 'A') {
        worksheet.getColumn(x).width = 35;
      }
    });
    worksheet.getCell('A1').style = {
      font: { bold: true, size: 18 }, alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
    };
    worksheet.getCell('B2').style = {
      font: {
        bold: true,
      },
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
    };
    worksheet.getCell('B3').style = {
      font: {
        bold: true,
      },
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
    };
    worksheet.getCell('B4').style = {
      font: {
        bold: true,
      },
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
    };
    worksheet.getCell('B5').style = {
      font: {
        bold: true,
      },
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
    };
    worksheet.getCell('B6').style = {
      font: {
        bold: true,
      },
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
    };
    worksheet.mergeCells('A1', 'J1');

    const dateNow = datePipe.transform(new Date(), 'dd-MM-YYYY');
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Bang-Thong-Ke-Du-Lieu-Den-Ngay-' + dateNow + '.xlsx');
    });
  };
  ApiCount(lst) {
    this.dataModel.listDataSetId = lst;
    this.dataModel.month = this.dateStart;
    this.dataModel.year = this.dateEnd;
    this.dataModel.maDVHCCX = this.MDVHCCX;
    this.dataModel.maDVHCCH = this.MDVHCCH;
    this.dataModel.field = this.field;
    this.dataModel.search = this.textSearch;
    if (this.featureClassID.length === 1) {
      this.dataModel.featureClassId = this.lstTable.filter(x => x.tableName === this.featureClassID[0])[0].id;
    }
    this.datasetService.countDatas(this.dataModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.lstDataSession = res.data;
        const uniqueNamesMap = [];
        res.data.forEach((obj) => {
          if (uniqueNamesMap.filter((x) => x.name === obj.tableName).length <= 0) {
            uniqueNamesMap.push({ name: obj.name, tablename: obj.tableName });
          }
        });
        this.lstLabel = uniqueNamesMap;
        const resultObject = {};
        res.data.forEach((item) => {
          // if (item.dataSetName == 'Chuyên đề văn hoá thể thao và du lịch') {
          //   item.dataSetName = null;
          // }
          const key = `${item.dataSetName}_${item.dvhcch}_${item.dvhccx}`;
          resultObject[key] = resultObject[key] || {
            dataSetName: this.datasets.filter(x => x.id === this.dataSetId)[0].name,
            name: item.name,
            mahuyen: item.dvhcch,
            maxa: item.dvhccx,
            lstId: item.listId,
          };

          resultObject[key][item.tableName] = item.totalCount;
          const count = resultObject[key].totalData ? resultObject[key].totalData : 0;
          resultObject[key].totalData = count + item.totalCount;
        });
        this.target = Object.values(resultObject);
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
  dataSetTree(data, parentid) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].parentid === parentid) {
        result.push({
          name: data[i].name,
          id: data[i].id,
          items: this.dataSetTree(data, data[i].id),
        });
      } 
    }
    return result;
  }
}
function convertDate(arg0: any): any {
  throw new Error('Function not implemented.');
}

