import { DUPLICATE_RESPONE } from '../../../../@core/customs/constants';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { MapAdminService } from '../mapadmin.service';
import { ApiResponse, MapInsertOrUpdateModel, SysmapEntity } from '../../../../@core/data/app-gis.service';
import { DatasetService } from '../../dataset/dataset.service';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'ngx-mapadmin-create',
  templateUrl: './mapadmin-create.component.html',
  styleUrls: ['./mapadmin-create.component.scss'],
})
export class MapAdminCreateComponent implements OnInit {
  constructor(
    private mapadminService: MapAdminService,
    private commonService: CommonService,
    private datasetService: DatasetService,
    @Inject(MAT_DIALOG_DATA) private param: { checkUnit: boolean },
    public dialogRef: MatDialogRef<MapAdminCreateComponent>,
    private readonly httpClient: HttpClient
  ) {}
  fileToUpload: FileList | null = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files;
  }
  target: SysmapEntity = new SysmapEntity();
  checkUnit: boolean;
  lstUnit: any[] = [];
  ngOnInit(): void {
    this.checkUnit = this.param.checkUnit;
    this.getUnit();
  }
  getUnit(){
    this.datasetService.getAllUnit().subscribe(res => {
        this.lstUnit = res.data;
    });
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  create(frm: NgForm) {
    let _body = new MapInsertOrUpdateModel();
     _body.name =  this.target.name.trim();;
     _body.config = this.target.config;
     _body.status = this.target.status;
     _body.unitCode = this.target.unitcode;
    const formData = new FormData();
    if(this.fileToUpload){
      for (let i = 0; i < this.fileToUpload.length; i++) {
        formData.append('Files', this.fileToUpload[i]);
      }
    }
    formData.append('model', JSON.stringify(_body));
    const _headers: HttpHeaders = new HttpHeaders({
      //TODO: must contain boundary
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
      // "Accept": "application/json"
      'Accept': '*/*',
    });
    const req = new HttpRequest(
      'POST',
      `${environment.APP_GIS_URL}/App/Gis/api/Map/InsertDataWithFiles`,
      formData,
      { headers: _headers }
    );

    this.httpClient.request<ApiResponse>(req).pipe().subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          let result = event.body;
          if (result.code === SUCCESS_RESPONE) {
            this.commonService.toastrSuccess();
            this.dialogRef.close(true);
          } else {
            this.commonService.toastrDanger(result.message);
          }
        }
      },
      (error) => {
        throw error;
      }
    );
    // this.target.name = this.target.name.trim();
    // this.mapadminService.create(this.target).subscribe((result) => {
    //   if (result.code === SUCCESS_RESPONE) {
    //     this.commonService.toastrSuccess();
    //     this.dialogRef.close(true);
    //   } else {
    //     this.commonService.toastrDanger(result.message);
    //   }
    // });
  }
}
