import { MatDialog } from '@angular/material/dialog';
import { TintucSukienService } from './../tintuc-sukien.service';
import { CommonService } from 'app/@core/customs/common.service';
import { PageModel } from './../../../../@core/data/app-qtht.service';
import { PAGE_SIZE, SUCCESS_RESPONE } from './../../../../@core/customs/constants';
import { Component, OnInit } from '@angular/core';
import { TintucSukienCreateComponent } from '../tintuc-sukien-create/tintuc-sukien-create.component';
import { TintucSukienUpdateComponent } from '../tintuc-sukien-update/tintuc-sukien-update.component';
import { TintucSukienDeleteComponent } from '../tintuc-sukien-delete/tintuc-sukien-delete.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-tintuc-sukien-index',
  templateUrl: './tintuc-sukien-index.component.html',
  styleUrls: ['./tintuc-sukien-index.component.scss'],
})
export class TintucSukienIndexComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: TintucSukienService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
    language: null,
  });

  target: any = {
    data: [],
  };
  ListLoaibv: any = [];
  ListTientrinh: any = [];
  ListTacgia: any = [];
  ListLanguage: any = [];
  type: string;
  title: string;
  typeAttach = this.service.typeAttach();

  ngOnInit(): void {
    // this.loadData();
    this.loadDm();
    this.route.data.subscribe((data) => {
      this.type = data?.type;
      if (this.type === '01') {
        this.searchModel.childType = '1,2,3,4,5,6,7,8,9,10';
        this.title = 'TIN TỨC - SỰ KIỆN';
      } else if (this.type === '02') {
        this.searchModel.childType = '02,03';
        this.title = 'ALBUM ẢNH/VIDEO';
      } else if (this.type === '03') {
        this.searchModel.childType = '13';
        this.title = 'CHUYÊN TRANG “CẢI THIỆN MẠNH MẼ MÔI TRƯỜNG ĐẦU TƯ KINH DOANH”';
      } else if (this.type === '04') {
        this.searchModel.childType = '14';
        this.title = 'VĂN HÓA LỊCH SỬ';
      } else if (this.type === '05') {
        this.searchModel.childType = '15';
        this.title = 'DANH LAM THẮNG CẢNH';
      } else if (this.type === '06') {
        this.searchModel.childType = '20,21,22,23,24,25,26,27,28,29';
        this.title = 'HỆ THỐNG CHÍNH TRỊ';
      } else if (this.type === '07') {
        this.searchModel.childType = '';
        this.title = 'LIÊN KẾT';
      } else if (this.type === '08') {
        this.searchModel.childType = '';
        this.title = 'QUẢNG CÁO';
      } else if (this.type === '09') {
        this.searchModel.childType = '';
        this.title = 'DANH MỤC LOẠI BÀI VIẾT';
      }
      this.loadData();
      // this.title = this.getTitle();
    });
  }

  loadData() {
    this.service.paging(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
  loadDm() {
    this.service.getDm().subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.ListLoaibv = res.data.dmLoaibaivietVms;
        this.ListTacgia = res.data.dmTacgiaVms;
        this.ListLanguage = res.data.dmLanguageVms;
        this.ListTientrinh = res.data.dmTientrinhVms.sort((a, b) => a.matientrinh - b.matientrinh);
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  getTenloaibaiviet(value: string) {
    if (value === null) return null;
    const name = this.ListLoaibv.find((x) => x.ma === value).ten
      ? this.ListLoaibv.find((x) => x.ma === value).ten
      : null;
    return name;
  }

  getTacgia(value: string) {
    if (value === null) return null;
    const name = this.ListTacgia.find((x) => x.matacgia === value).tentacgia
      ? this.ListTacgia.find((x) => x.matacgia === value).tentacgia
      : null;
    return name;
  }

  getTientrinh(value: string) {
    if (value === null) return null;
    const name = this.ListTientrinh.find((x) => x.matientrinh === value).tentientrinh
      ? this.ListTientrinh.find((x) => x.matientrinh === value).tentientrinh
      : null;
    return name;
  }

  search() {
    this.searchModel.currentPage = 1;
    this.loadData();
  }

  openCreate() {
    this.dialog
      .open(TintucSukienCreateComponent, this.commonService.configDialog('100%', { lsttype: this.type }))
      .afterClosed()
      .subscribe((res) => {
        if (res) this.search();
      });
  }
  updateData(id: number, isEdit: boolean) {
    this.dialog
      .open(
        TintucSukienUpdateComponent,
        this.commonService.configDialog('80%', { id: id, type: this.typeAttach, isEdit: isEdit, lsttype: this.type })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) this.loadData();
      });
  }

  deleteData(id: number, title: string) {
    this.dialog
      .open(TintucSukienDeleteComponent, this.commonService.configDialog('30%', { id: id, title: title }))
      .afterClosed()
      .subscribe((res) => {
        if (res) this.loadData();
      });
  }
}
