import { CommonService } from 'app/@core/customs/common.service';
import { Router } from '@angular/router';
import { AuthService } from './../../../@core/auth.service';
import { TokenService } from './../../../@core/token.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserInfoComponent } from 'app/@theme/pages/user-info/user-info.component';
import { ChangePasswordComponent } from 'app/@theme/pages/change-password/change-password.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  showHeader = true;

  userMenu = [{ title: 'Thông tin tài khoản' }, { title: 'Đổi mật khẩu' }, { title: 'Đăng xuất' }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private tokenService: TokenService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.tokenService.getUserInfo().subscribe((users: any) => {
      this.user = users;
      if (this.user === null || this.user === undefined) {
        this.showHeader = false;
        // this.dangXuat();
        document.getElementById('mn-sidebar').style.display = 'none';
      }
    });
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  selectToolbar() {
    const menuService = new NbMenuService();
    let status = true;
    menuService.onItemClick().subscribe((menu) => {
      switch (menu.item.title) {
        case 'Đăng xuất':
          if (status) {
            this.authService.logout();
            this.router.navigate(['/login']);
            status = false;
          }
          break;
        case 'Thông tin tài khoản':
          if (status) {
            const data = {
              userId: this.user.Id,
            };
            this.dialog
              .open(UserInfoComponent, this.commonService.configDialog('40%', { data: data }))
              .afterClosed()
              .subscribe(() => {});
            status = false;
          }
          break;
        case 'Đổi mật khẩu':
          if (status) {
            this.dialog
              .open(ChangePasswordComponent, this.commonService.configDialog('40%', {}))
              .afterClosed()
              .subscribe(() => {});
            status = false;
          }
          break;
      }
    });
  }

  dangXuat() {
    let status = true;
    if (status) {
      this.authService.logout();
      this.router.navigate(['/login']);
      localStorage.removeItem('curAppId');
      status = false;
    }
  }

  navigateHome() {
    this.router.navigate['/gis/map'];
  }
}
