<ngx-spinner size="large"></ngx-spinner>
<nb-layout>
  <nb-layout-column>
    <div class="gradientBG">
      <div id="particles-js"></div>
      <div class="container-fluid top-hd"
        style="height: 0; background-color: #fcfcfc;font-family:'sans-serif', sans-serif, serif;font-style: normal; position: fixed; top: 0; left: -15px; width: 100%;">
        <div class="limiter" style="font-family:'sans-serif', sans-serif, serif;font-style: normal;">
          <div class="container-login100">
            <div class="wrap-login100" style="padding-bottom: 20px; padding-top: 20px  ">
              <table style="width:100%; height: 100%;">
                <tr style="text-align:center;">
                  <td colspan="2"
                    style="font-weight: bold;font-size: xx-large;padding-top: 15px;padding-bottom: 20px;color:#14646b;">
                    QUẢN TRỊ HỆ THỐNG CSDL CHUYÊN NGÀNH</td>
                </tr>
                <tr style="text-align:center;">
                  <td style="width:50%;">
                    <img style="width: 380px; margin-left: 10%;" src="../../../../assets/images/logo-BT.png" alt="IMG">
                  </td>
                  <td style="width:50%;">
                    <form class="login100-form validate-form" keyup.enter="login()" style="margin-left: 16%;">
                      <div data-validate="Enter Username">
                        <input class="form-control" type="text" style="border-radius: 5px;" name="username"
                          placeholder="Tên đăng nhập" [(ngModel)]="user.username" focus-me="focusUsername"
                          keyup.enter="login()" autocomplete="false" />
                      </div>
                      <div data-validate="Enter Password" style="padding-top: 8px;">
                        <input class="form-control" type="password" style="border-radius: 5px;" name="pass"
                          placeholder="Mật khẩu" [(ngModel)]="user.password" keyup.enter="login()"
                          autocomplete="false" />
                      </div>
                      <div style="padding-top: 8px;">
                        <select class="form-control" [(ngModel)]="user.appId" name="chooseAppId">
                          <option *ngFor="let item of lstApplication" value="{{item.appid}}">{{item.tenapp}}</option>
                        </select>
                      </div>
                      <div class="container-login100-form-btn">
                        <button prevent-default class="btn btn-success" (click)="login()" style="width: 100%;"
                          [disabled]="!user.appId">
                          <i class="fa fa-sign-in" aria-hidden="true"></i> Đăng nhập
                        </button>
                      </div>
                      <!-- <div class="container-login100-SSO-form-btn">
                                        <button prevent-default class="btn btn-success" (click)="loginSSO()" style="width: 100%;">
                                            <i class="fa fa-podcast" aria-hidden="true"></i> Đăng nhập SSO
                                        </button>
                                    </div>
                                    <div class="container-login10-form-btn">
                                        <button prevent-default class="btn btn-warning" (click)="createUser()" style="width: 100%;">
                                            <i class="fa fa-address-card-o" aria-hidden="true"></i> Đăng ký độc giả
                                        </button>
                                    </div> -->
                    </form>
                  </td>
                </tr>
                <tr style="text-align:center;">
                  <td colspan="2">B&T HighTech Solution &copy; Copyright 2021</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
