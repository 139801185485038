import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  NbDirectionality,
  NbTreeGridDataService,
  NbTreeGridDataSourceBuilder,
  NbTreeGridFilterService,
  NbTreeGridService,
  NbTreeGridSortService,
} from '@nebular/theme';
import { AppGISRoutingModule } from './app-gis-routing.module';
import { DatasetModule } from './dataset/dataset.module';
import { PermissionComponent } from './permission/permission.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
@NgModule({
  imports: [
    AppGISRoutingModule,
    CommonModule,
    DatasetModule,
    GridModule,
    TreeViewModule,
    UploadsModule,
    InputsModule,
    InputsModule,
    ButtonsModule,
    FormsModule,
    
  ],
  providers: [
    NbTreeGridDataSourceBuilder,
    NbTreeGridFilterService,
    NbTreeGridSortService,
    NbTreeGridService,
    NbTreeGridDataService,
    NbDirectionality,
  ],
  declarations: [PermissionComponent],
})
export class AppGISModule { }
