<form #frm="ngForm" (ngSubmit)="update(frm)">
    <nb-card>
        <nb-card-header class="nb-card-header">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    Chi tiết cấu hình
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
          <div class="row">
              <div class="col-lg-12">
                  <div class="form-group">
                      <label class="col-form-label required">Mã cấu hình</label>
                      <input type="text" class="form-control" name="code" [(ngModel)]="target.code" required maxlength="50" disabled>
                  </div>
              </div>
              <div class="col-lg-12">
                  <div class="form-group">
                      <label class="col-form-label required">Tên cấu hình</label>
                      <input type="text" class="form-control" name="name" [(ngModel)]="target.name" required maxlength="250">
                  </div>
              </div>
              <div class="col-lg-12">
                  <div class="form-group">
                      <label class="col-form-label">Cấu hình JSON</label>
                      <textarea type="text" class="form-control" name="config" [(ngModel)]="target.config" required></textarea>
                  </div>
              </div>
          </div>
      </nb-card-body>
        <nb-card-footer class="text-right">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
                    <button type="submit" class="btn btn-primary" [disabled]="frm.invalid">Lưu</button>
                    &nbsp;
                    <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
                </div>
            </div>
        </nb-card-footer>
    </nb-card>
</form>
