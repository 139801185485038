import { Sort } from './sort';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/* tslint:disable-next-line */
@Directive({ selector: '[appSort]' })
export class SortDirective {
    constructor(private targetElem: ElementRef) {}
    @Input()  appSort: Array<any>;
    @HostListener('click')
    sortData() {
        const sort = new Sort();
        const elem = this.targetElem.nativeElement;
        const order = elem.getAttribute('data-order');
        const type = elem.getAttribute('data-type');
        const property = elem.getAttribute('data-name');
        if (order === 'desc') {
            this.appSort.sort(sort.startSort(property, order, type));
            elem.setAttribute('data-order', 'asc');
        } else {
            this.appSort.sort(sort.startSort(property, order, type));
            elem.setAttribute('data-order', 'desc');
        }
    }
}
