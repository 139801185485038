<form #frm="ngForm" >
  <nb-card>
      <nb-card-header class="nb-card-header">
          <div class="row">
              <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                 CHI TIẾT DANH MỤC TIẾN TRÌNH
              </div>
          </div>
      </nb-card-header>
      <nb-card-body>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
              <div class="form-group">
                  <label class="control-label">Mã tiến trình</label>
                  <div class="input-group">
                    <input  type="text" class="form-control" name="matientrinh" maxlength="500" [(ngModel)]="target.matientrinh" #matientrinh="ngModel"  readonly />
                </div>
              </div>
          </div>
          <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
              <div class="form-group">
                  <label class="control-label">Tên tiến trình</label>
                  <div class="input-group">
                    <input  type="text" class="form-control" name="tentientrinh" maxlength="500" [(ngModel)]="target.tentientrinh" #tentientrinh="ngModel" readonly  />
                </div>
              </div>
          </div>
          <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
            <div class="form-group">
                <label class="control-label">Chức vụ xử lý</label>
                <div class="input-group">
                  <input  type="text" class="form-control" name="chucvuxuly" maxlength="500" [(ngModel)]="target.chucvuxuly" #chucvuxuly="ngModel"  readonly />
              </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
          <div class="form-group">
              <label class="control-label">Icon</label>
              <div class="input-group">
                <input  type="text" class="form-control" name="icon" maxlength="500" [(ngModel)]="target.icon" #icon="ngModel"  readonly />
            </div>
          </div>
      </div>
      <div class="col-sm-3 col-md-6 col-xs-3 col-lg-5">
        <div class="form-group" style="margin-top:10px">
          <label class="control-label">Trạng thái</label>
          <div class="input-group">
            <input  type="radio" style="margin-top: auto;margin-bottom: auto; width: 20px; height: 20px;" [value]="1"  [(ngModel)]="target.trangthai" id="trangthai1" name="trangthai">&nbsp;
            <label for="trangthai1" style="margin: 10px 10px 10px 0px;">Sử dụng </label>
            <br/>
            <input  type="radio" style="margin-top: auto;margin-bottom: auto; width: 20px; height: 20px;" [value]="0" [(ngModel)]="target.trangthai" id="trangthai2" name="trangthai">&nbsp;
            <label for="trangthai2" style="margin: 10px 10px 10px 0px;">Không sử dụng </label>
        </div>
      </div>
      </div>
          </div>
      </nb-card-body>
      <nb-card-footer class="text-end">
          <div class="row">
              <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
                  <!-- <button class="btn btn-success" type="button" [disabled]="frm.invalid" (click)="publish();"><i class="fa-solid fa-upload"></i>&nbsp;Lưu và Xuất bản</button>&nbsp; -->
                  <ngx-btn-close mat-dialog-close></ngx-btn-close>
              </div>
          </div>
      </nb-card-footer>
  </nb-card>
</form>
