<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="" (click)="navigateHome()">BT-<span>Admin</span></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
  <!-- <ngx-layout-direction-switcher class="direction-switcher"></ngx-layout-direction-switcher> -->
</div>
<div class="header-container">
  <ng-select name="lstDuAn" style="text-align: right;width: 400px;max-width: 400px;" placeholder="Lựa chọn dự án" [items]="lstDuAn" bindValue="maduan"
                            bindLabel="tenduan" [multiple]="false" [closeOnSelect]="true"
                            [(ngModel)]="selectDuAn" [searchFn]="search.searchItem"
                            [clearOnBackspace]="false" appendTo="body" [clearSearchOnAdd]="true"
                            (change)="selectProjectInvest(selectDuAn)">
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              {{item.tenduan}}
                            </ng-template>
                    </ng-select>
  <nb-actions size="small">
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.FullName"
               [picture]="user?.picture"
               (click)="selectToolbar()"
               >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
