import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE, SUCCESS_NOTICE } from '../../../../@core/customs/constants';
import { SettingService } from '../setting.service';
import { Component, OnInit } from '@angular/core';
import { PAGE_SIZE } from 'app/@core/customs/constants';
import { MatDialog } from '@angular/material/dialog';
import { SettingUpdateComponent } from '../setting-update/setting-update.component';
import { PageModel, SyssettingEntity } from 'app/@core/data/app-gis.service';

@Component({
  selector: 'ngx-setting-index',
  templateUrl: './setting-index.component.html',
  styleUrls: ['./setting-index.component.scss'],
})
export class SettingIndexComponent implements OnInit {
  constructor(
    private settingService: SettingService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}

  pageModel: PageModel = new PageModel({
    pageSize: PAGE_SIZE,
    condition: null,
    currentPage: 1,
    search: null,
  });

  target: any = {
    data: [],
  };

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.settingService.paging(this.pageModel).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  editItem(id: number) {
    this.dialog
      .open(SettingUpdateComponent, this.commonService.configDialog('100%', { id: id }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
}
