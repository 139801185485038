import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  AppGISApiService,
  CreateOrUpdateDataModel,
  DataSearchModel,
  DataUpdateModel,
  ExportDataModel,
  FeatureclassDetailModelIEnumerableApiResponse,
  FieldModelIEnumerableApiResponse,
  FileParameter,
  ImportDataModel,
  Int32ApiResponse,
  ItemIListApiResponse,
  MapDetailModelIEnumerableApiResponse,
  ResponseModelListApiResponse,
  StringApiResponse,
  StringPageModelViewApiResponse,
  SysFileManagerModelApiResponse,
  SysFileModelListApiResponse,
  SysimportLogEntityIEnumerableApiResponse,
} from '../../../@core/data/app-gis.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private api: AppGISApiService) { }

  importData(tablename: string, body: ImportDataModel | undefined): Observable<ApiResponse> {
    return this.api.appGisApiDataImport(tablename, body);
  }

  PaingData(
    body: DataSearchModel | undefined
  ): Observable<StringPageModelViewApiResponse> {
    return this.api.appGisApiDataPagingData(body);
  }
  getLogs(table: string | undefined): Observable<SysimportLogEntityIEnumerableApiResponse> {
    return this.api.appGisApiDataImportLogs(table);
  }
  deleteData(tablename: string, key: string): Observable<ApiResponse> {
    return this.api.appGisApiDataDeleteData(tablename, key);
  }
  updateData(tablename: string, key: string, body: DataUpdateModel[] | undefined): Observable<ApiResponse> {
    return this.api.appGisApiDataUpdateData(tablename, key, body);
  }
  exportData(body: ExportDataModel | undefined): Observable<Blob> {
    return this.api.appGisApiDataExport(body);
  }

  getDataById(tablename: string, key: number): Observable<string> {
    return this.api.appGisApiDataGetDataById(tablename, key);
  }
  getDataByTableName(tablename: string): Observable<StringApiResponse> {
    return this.api.appGisApiDataGetDataByTable(tablename);
  }
  getMap(): Observable<MapDetailModelIEnumerableApiResponse> {
    return this.api.appGisApiMapGetMapByAdmin();
  }
  getFieldsByTableName(tablename: string): Observable<FieldModelIEnumerableApiResponse> {
    return this.api.appGisApiDataGetFields(tablename);
  }
  getFeatureClass(): Observable<FeatureclassDetailModelIEnumerableApiResponse> {
    return this.api.appGisApiFeatureclassGetFeatureclasssByMap();
  }

  ChangeStatus(tablename: string, status: number | undefined, id: number): Observable<ApiResponse> {
    return this.api.appGisApiDataChangeStatusByTableName(tablename, status, id);
  }
  getFiles(tableName: string, IdData: number): Observable<SysFileManagerModelApiResponse> {
    return this.api.appGisApiSysFileGetFileById(tableName, IdData);
  }
  getFile(IdData: number): Observable<SysFileModelListApiResponse> {
    return this.api.appGisApiDataGetFilesByIdData(IdData);
  }

  total(): Observable<number> {
    return this.api.appGisApiDataCountAllTable();
  }

  deleteFile(IdData: string): Observable<ApiResponse> {
    return this.api.appGisApiSysFileDeleteFile(IdData);
  }
  uploadFile(files: FileParameter[], idData: number, tableName: string, folder: string, type: string): Observable<ResponseModelListApiResponse> {
    return this.api.appGisApiSysFileUploadFile(files, idData, tableName, folder, type);
  }
  createCustom(target: CreateOrUpdateDataModel): Observable<Int32ApiResponse> {
    return this.api.appGisApiDataInsertDataCustom(target);
  }
  updateCustom(target: CreateOrUpdateDataModel): Observable<Int32ApiResponse> {
    return this.api.appGisApiDataUpdateDataCustom(target);
  }
  // minioUpload(files: FileParameter[]): Observable<ApiResponse> {
  //   return this.api.appGisApiMinioUploadFile(files);
  // }
  getMinioObjects(): Observable<ItemIListApiResponse> {
    return this.api.appGisApiMinioGetObjects();
  }
  getMinioObject(): Observable<StringApiResponse> {
    return this.api.appGisApiMinioGetObject();
  }
} 
