import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { PAGE_SIZE, SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { PageModel } from 'app/@core/data/app-home.service';
import { DmTacGiaCreateComponent } from '../dm-tac-gia-create/dm-tac-gia-create.component';
import { DmTacGiaDeleteComponent } from '../dm-tac-gia-delete/dm-tac-gia-delete.component';
import { DmTacGiaDetailComponent } from '../dm-tac-gia-detail/dm-tac-gia-detail.component';
import { DmTacGiaUpdateComponent } from '../dm-tac-gia-update/dm-tac-gia-update.component';
import { DmTacGiaService } from '../dm-tac-gia.service';

@Component({
  selector: 'ngx-dm-tac-gia-index',
  templateUrl: './dm-tac-gia-index.component.html',
  styleUrls: ['./dm-tac-gia-index.component.scss'],
})
export class DmTacGiaIndexComponent implements OnInit {
  constructor(
    private service: DmTacGiaService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
  });
  target: any = {
    data: [],
  };
  type: string;
  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.service.paging(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  openForm() {
    this.dialog
      .open(
        DmTacGiaCreateComponent,
        this.commonService.configDialog('50%', { lsttype: this.type })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) this.search();
      });
  }

  search() {
    this.searchModel.currentPage = 1;
    this.loadData();
  }

  updateData(id: number) {
    this.dialog
      .open(
        DmTacGiaUpdateComponent,
        this.commonService.configDialog('40%', { id: id })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }

  detailData(id: number) {
    this.dialog
      .open(
        DmTacGiaDetailComponent,
        this.commonService.configDialog('40%', { id: id })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }

  deleteData(id: number, ten: string) {
    this.dialog
      .open(
        DmTacGiaDeleteComponent,
        this.commonService.configDialog('30%', { id: id, ten: ten })
      )
      .afterClosed()
      .subscribe((result) => {
        this.loadData();
      });
  }
}
