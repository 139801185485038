import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { PAGE_SIZE, SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { PageModel } from 'app/@core/data/app-qtht.service';
import { DmLienHeDeleteComponent } from '../dm-lien-he-delete/dm-lien-he-delete.component';
import { DmLienHeDetailComponent } from '../dm-lien-he-detail/dm-lien-he-detail.component';
import { DmLienHeService } from '../dm-lien-he.service';

@Component({
  selector: 'ngx-dm-lien-he-index',
  templateUrl: './dm-lien-he-index.component.html',
  styleUrls: ['./dm-lien-he-index.component.scss'],
})
export class DmLienHeIndexComponent implements OnInit {
  constructor(private commonService: CommonService, private service: DmLienHeService, private dialog: MatDialog) {}
  typeAttach = this.service.typeAttach();
  type: string;
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
  });
  ngOnInit(): void {
    this.loadData();
  }

  target: any = {
    data: [],
  };

  loadData() {
    this.service.paging(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
  search() {
    this.searchModel.currentPage = 1;
    this.loadData();
  }

  detailData(id: number) {
    this.dialog
      .open(DmLienHeDetailComponent, this.commonService.configDialog('60%', { id: id }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }

  // openCreate() {
  //   this.dialog
  //     .open(
  //       DmLoaiBaiVietCreateComponent,
  //       this.commonService.configDialog('100%', { lsttype: this.type })
  //     )
  //     .afterClosed()
  //     .subscribe((res) => {
  //       if (res) this.search();
  //     });
  // }

  // updateData(id: number) {
  //   this.dialog
  //     .open(
  //       DmLoaiBaiVietUpdateComponent,
  //       this.commonService.configDialog('40%', { id: id })
  //     )
  //     .afterClosed()
  //     .subscribe((result) => {
  //       if (result === true) {
  //         this.loadData();
  //       }
  //     });
  // }

  deleteData(id: number, tieude: string) {
    this.dialog
      .open(
        DmLienHeDeleteComponent,
        this.commonService.configDialog('30%', { id: id, tieude: tieude })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
}
