import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { SettingService } from '../setting.service';
import { SysappEntity } from 'app/@core/data/app-qtht.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { SyssettingEntity } from 'app/@core/data/app-gis.service';

@Component({
  selector: 'ngx-setting-update',
  templateUrl: './setting-update.component.html',
  styleUrls: ['./setting-update.component.scss'],
})
export class SettingUpdateComponent implements OnInit {
  constructor(
    private settingService: SettingService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private SettingItem: { id: number },
    public dialogRef: MatDialogRef<SettingUpdateComponent>
  ) {}

  target: SyssettingEntity = new SyssettingEntity();

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.settingService.selectOne(this.SettingItem.id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  update(frm: NgForm) {
    this.target.fields = null;
    this.settingService.update(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
