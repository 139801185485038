import { PAGE_SIZE, SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MapAdminService } from '../mapadmin.service';
import {
  ObjectIEnumerablePageModelView,
  ObjectIEnumerablePageModelViewApiResponse,
  PageModel,
  SysappEntity,
} from 'app/@core/data/app-qtht.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { MapUserModel, SysmapEntity } from 'app/@core/data/app-gis.service';
import { UserAppService } from '../../services/user-app.service';
import { MapAdminPermissionAddUserComponent } from '../mapadmin-permission-addusers/mapadmin-permission-addusers.component';

@Component({
  selector: 'ngx-mapadmin-permission',
  templateUrl: './mapadmin-permission.component.html',
  styleUrls: ['./mapadmin-permission.component.scss'],
})
export class MapAdminPermissionComponent implements OnInit {
  constructor(
    private userAppService: UserAppService,
    private mapService: MapAdminService,
    private commonService: CommonService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private MapAdminItem: { id: number },
    public dialogRef: MatDialogRef<MapAdminPermissionComponent>
  ) {}

  target: MapUserModel[] = [];
  lstUserName: any[] = [];
  pageModel: PageModel = new PageModel({
    pageSize: PAGE_SIZE,
    condition: null,
    currentPage: 1,
    search: null,
  });

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    // <<<<<<< HEAD
    //     this.mapService.selectOne(this.MapAdminItem.id).subscribe((result) => {
    //       if (result.code === SUCCESS_RESPONE) {
    //         this.target = result.data;
    this.mapService.getPermission(this.MapAdminItem.id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
        this.lstUserName = this.target;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
  onThemNguoiDung() {
    this.dialog
      .open(
        MapAdminPermissionAddUserComponent,
        this.commonService.configDialog('100%', { id: this.MapAdminItem.id, lstUserName: this.lstUserName })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  deletePermission(id, name) {
    if (confirm(`Xác nhận xóa quyền người dùng ${name} ?`)) {
      this.mapService.deletePermission(id).subscribe((result) => {
        if (result.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess();
          this.loadData();
        } else {
          this.commonService.toastrDanger(result.message);
        }
      });
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  capNhat() {
    this.dialogRef.close(true);
  }
}
