<nb-card>
  <nb-card-header class="nb-card-header">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
        <div class="input-group">
          <label style="flex: auto">Danh sách lớp dữ liệu</label>
          <ngx-btn-add type="button" (click)="addFeatureClass()"></ngx-btn-add>&nbsp;
          <ngx-btn-refresh (click)="searchModel.search = '';searchModel.status = null; search();">
          </ngx-btn-refresh>
        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row d-flex">
      <div class="col-md-8 col-sm-8 col-xs-8 col-lg-8 d-flex">
        <label style="padding-top: 7px;">Lọc theo chủ đề dữ liệu: </label>
        <kendo-dropdowntree placeholder="Chọn chủ đề" kendoDropDownTreeExpandable
          [kendoDropDownTreeHierarchyBinding]="datasets" textField="name" valueField="id" childrenField="items"
          class="furniture" (valueChange)="filterByDataset($event)" [filterable]="true">
        </kendo-dropdowntree>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4 col-lg-4 d-flex">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Nhập nội dung tìm kiếm" style="height: auto;"
            [(ngModel)]="searchModel.search" (keydown.enter)="search();" name="seach">
          <button class="btn btn-primary" type="button" data-toggle="tooltip" title="Tìm kiếm" (click)="search()">
            <i class="fas fa-search fa-sm"></i>&nbsp;
          </button>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th class="text-center" style="width: 50px;">STT</th>
              <th>Chủ đề dữ liệu</th>
              <th>Tên lớp</th>
              <th>Tên bảng dữ liệu</th>
              <th>Hệ quy chiếu</th>
              <th>Trạng Thái</th>
              <th class="text-center" style="width: 270px;">Hành động</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of target.data; let i = index">
              <td class="text-center">
                {{ i + (searchModel.currentPage - 1) * searchModel.pageSize + 1 }}
              </td>
              <td>{{ getDataSetName(item.datasetId) }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.tableName }}</td>
              <td>{{ item.prj }}</td>
              <td>{{item.status ? "Sử dụng" : "Không sử dụng"}}</td>
              <td class="text-center" style="padding: unset;">
                <button style="padding:5px !important;" title="Trường dữ liệu" class="btn btn-sm"
                  (click)="addField(item.id)"><i class="fa fa-list"></i></button>
                <button style="padding:5px !important;" title="Sửa thông tin" class="btn btn-sm"
                  (click)="editItem(item.id)"><i class="fa fa-edit"></i></button>
                <button style="padding:5px !important;" title="Chia sẻ cho phòng ban" *ngIf="!checkPhongBan"
                  class="btn btn-sm" (click)="share(item)"><i class="fa-solid fa-share"></i></button>
                <button style="padding:5px !important;" title="Xóa lớp dữ liệu" class="btn btn-sm"
                  (click)="deleteItem(item.id,item.name)"><i class="fa fa-trash"></i></button>
                <button style="padding:5px !important;" title="Nhập dữ liệu" class="btn btn-sm"
                  (click)="importData(item.id,item.tableName)"><i class="fa fa-upload"></i></button>
                <button style="padding:5px !important;" title="Xuất dữ liệu" class="btn btn-sm"
                  (click)="exportData(item.id,item.tableName)"><i class="fa fa-download"></i></button>
                <button style="padding:5px !important;" title="Tải biểu mẫu dữ liệu" class="btn btn-sm"
                  (click)="exportTemplate(item.id,item.tableName)">
                  <i class="fas fa-file-download"></i>
                </button>
                <button style="padding:5px !important;" title="Xem dữ liệu" class="btn btn-sm"
                  (click)="viewData(item.id,item.tableName,item.name)"><i class="fa fa-eye"></i></button>
                <button style="padding:5px !important;" title="Xem nhật ký tích hợp dữ liệu" class="btn btn-sm"
                  (click)="viewLog(item.id,item.tableName)"><i class="fa fa-file"></i></button>
                <button style="padding:5px !important;" title="Xóa toàn bộ dữ liệu" class="btn btn-sm"
                  (click)="truncateData(item.id,item.name)"><i class="fa fa-ban"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="target?.recordsCount > 0" class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
        <div style="float: left;">
          Tổng số bản ghi: {{target?.recordsCount}}
        </div>
        <div style="float: right;">
          <ngx-pagination (pageChange)="searchModel.currentPage = $event;
                loadData()" (pageSize)="searchModel.pageSize = $event;
                loadData()" [currentPage]="searchModel.currentPage" [totalPages]="target.totalPage">
          </ngx-pagination>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>