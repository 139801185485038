<div class="header-danhmuc">
  <div class="row">
    <div class="col-sm-10 col-md-10 col-xs-10 col-lg-10">Danh sách phòng ban được chia sẻ</div>
    <div style="text-align: -webkit-right" class="col-sm-2 col-md-2 col-xs-2 col-lg-2 text-right">
      <button
      type="button"
      class="btn btn-outline-secondary"
      mat-dialog-close
    >
      Đóng
    </button>
    </div>
  </div>
</div>
<hr />
<div class="row">
  
  <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12" style="height: 500px; overflow: auto">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th class="text-center" style="width: 50px">STT</th>
          <th>Tên phòng ban</th>
          <th>Chia sẻ</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data; let i = index">
          <td>{{ i + 1}}</td>
          <td>
            {{ item.ten }}
          </td>
          <td>
            <div style="margin-left: 42%; font-size: 20px" class="form-check form-switch">
              <input class="form-check-input" [checked]="setCheckedForToggle(item)" [disabled]="false"
                (change)="checked(item)" type="checkbox" id="flexSwitchCheckChecked" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row" style="margin-top: 20px; text-align: end;">
  <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
    <button id="btn" type="submit" class="btn btn-secondary" (click)="saveShare()">
      <i class="fas fa-save fa-sm"></i>
      Lưu
    </button>

  </div>
</div>