import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FieldService } from '../field.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'ngx-featureclass-viewdetail-data',
  templateUrl: './featureclass-viewdetail-data.component.html',
  styleUrls: ['./featureclass-viewdetail-data.component.scss'],
})
export class FeatureclassViewDetailDataComponent implements OnInit {
  constructor(
    private fieldService: FieldService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) private feature: any,
    public dialogRef: MatDialogRef<FeatureclassViewDetailDataComponent>
  ) {}
  fields: any[] = [];
  field: any[] = [];
  fieldsName: any[] = [];
  lstAll: any[] = [];
  lstFile: any[] = [];
  ngOnInit(): void {
     this.getField();
     this.getFiles();
  }
  getFiles(){
    this.dataService.getFile(this.feature.data.id).subscribe((result) => {
        if(result.code = SUCCESS_RESPONE){
          this.lstFile = result.data;
        }
    });
  }
  getField(){
    this.fieldService.GetFieldsForInsertOrUpdate(this.feature.tablename).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.lstAll = [];
        const fields = result.data;
        this.field = fields.map(function (e) {
          return e.name;
        });
        this.fieldsName = fields.map(function (e) {
          return e.fieldname;
        });
        for (let i = 0; i < this.fieldsName.length; i++) {
          const x = this.fieldsName[i];
          const y = this.field[i];
          for (const attr in this.feature.data) {
            if (attr !== 'id' && attr !== 'shape' && x.toLowerCase() === attr && x.toLowerCase() !== 'status')
              this.fields.push({
                fieldName: x,
                label: y,
                value: this.feature.data[attr],
              });
          }
        }
      }
    });
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}
