<form #frm="ngForm" (ngSubmit)="update(frm)">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div style="display: flex; align-items: center; justify-content: space-between">
        <div class="">Chi tiết bản đồ</div>
        <div style="display: flex; justify-content: flex-end; gap: 10px">
          <a id="btn" class="btn btn-primary" (click)="openAddSourceDialog()">Add Source</a>
          <a id="btn" class="btn btn-primary" (click)="openAddLayerDialog()">Add Layer</a>
          <a id="btn" class="btn btn-primary" (click)="openAddGroupsDialog()">Add Groups</a>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row" style="height: 600px;">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Tên bản đồ</label>
            <input type="text" class="form-control" name="name" [(ngModel)]="target.name" required maxlength="250" />
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Trạng thái chia sẻ</label>
            <select class="form-control" aria-label="Trạng thái chia sẻ" [ngModelOptions]="{standalone: true}" ư
              [(ngModel)]="target.status">
              <option value="public">Mọi người dùng</option>
              <!-- <option value='protected'>Người dùng đăng nhập</option> -->
              <option value="private">Không chia sẻ</option>
            </select>
          </div>
        </div>
        <div class="col-lg-12" style="margin-top: 10px;">
          <div class="form-group">
            <input type="file" id="file" multiple (change)="handleFileInput($event.target.files)" class="form-control"
              accept="image/png">
          </div>
        </div>
        <div class="col-lg-12" *ngIf="checkUnit === true">
          <div class="form-group">
            <label class="col-form-label">Đơn vị quản lý</label>
            <ng-select [items]="lstUnit" bindValue="unitcode" bindLabel="unitname" 
              [(ngModel)]="target.unitcode" name="unitcode" placeholder="Chọn đơn vị" appendTo="Body"></ng-select>
          </div>
        </div>
        <div class="col-lg-12" style="margin-top: 20px;">
          <!-- <div class="form-group">
              <label class="col-form-label">Cấu hình JSON</label>
              <ngx-json-viewer *ngIf="jsonParser(target.config)" [json]="jsonParser(target.config)"></ngx-json-viewer>
              
            </div> -->
          <label style="flex: auto;margin: 20px;">Danh sách Source</label>
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="text-center" style="width: 50px;">STT</th>
                <th>Tên source</th>
                <th>Cấu hình</th>
                <th class="text-center" style="width: 150px;">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of sources; let i = index">
                <td class="text-center">
                  {{ i + 1 }}
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.value.data }}</td>
                <td class="text-center" style="padding: unset;">
                  <a style="padding:5px !important;" title="Xoá" class="btn btn-sm" (click)="deleteSource(item.name)"><i
                      class="fa fa-trash"></i></a>
                  <a style="padding:5px !important;" title="Sửa" class="btn btn-sm" (click)="updateSource(item)"><i
                      class="fa fa-edit"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-lg-12" style="margin-top: 20px;">
          <!-- <div class="form-group">
              <label class="col-form-label">Cấu hình JSON</label>
              <ngx-json-viewer *ngIf="jsonParser(target.config)" [json]="jsonParser(target.config)"></ngx-json-viewer>
              
            </div> -->
          <label style="flex: auto;margin: 20px;">Danh sách lớp</label>
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="text-center" style="width: 50px;">STT</th>
                <th>Tên lớp</th>
                <th>Tên Lớp</th>
                <th>Tên Source</th>
                <th>Tên Nhóm</th>
                <th>Kiểu hiển thị</th>
                <th class="text-center" style="width: 150px;">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of Layers; let i = index">
                <td class="text-center">
                  {{ i + 1 }}
                </td>
                <td>{{ item.id }}</td>
                <td>{{ item.metadata.name }}</td>
                <td>{{ item.metadata.tablename }}</td>
                <td>{{ getGroupName(item.metadata.groupId) }}</td>
                <td>{{ item.type }}</td>
                <td class="text-center" style="padding: unset;">
                  <a style="padding:5px !important;" title="Trường dữ liệu" class="btn btn-sm"
                    (click)="deleteLayer(item.featureClassId)"><i class="fa fa-trash"></i></a>
                  <a style="padding:5px !important;" title="Trường dữ liệu" class="btn btn-sm"
                    (click)="updateLayer(item)"><i class="fa fa-edit"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-lg-12" style="margin-top: 20px;">
          <label style="flex: auto;margin: 20px;">Danh sách Nhóm</label>
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="text-center" style="width: 50px;">STT</th>
                <th>Tên Nhóm</th>
                <th class="text-center" style="width: 150px;">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of Groups; let i = index">
                <td class="text-center">
                  {{ i + 1 }}
                </td>
                <td>{{ item.name }}</td>
                <td class="text-center" style="padding: unset;">
                  <a style="padding:5px !important;" title="Xoá" class="btn btn-sm" (click)="deleteGroup(item)"><i
                      class="fa fa-trash"></i></a>
                  <a style="padding:5px !important;" title="Sửa" class="btn btn-sm" (click)="updateGroup(item)"><i
                      class="fa fa-edit"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row" style="margin-top: 20px;">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button id="btn" type="submit" class="btn btn-primary" [disabled]="frm.invalid">
            <i class="fas fa-save fa-sm"></i>
            Lưu
          </button>
          &nbsp;
          <button id="btn-close" type="button" class="btn btn-light" mat-dialog-close>
            <i class="fas fa-times fa-sm"></i>
            Đóng
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>