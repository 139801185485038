import { Injectable } from '@angular/core';
import {
  ApiResponse,
  AppHomeApiService,
  DmChucvuEntity,
  DmChucvuEntityApiResponse,
  MasterModelIEnumerablePageModelViewApiResponse,
  PageModel,
} from 'app/@core/data/app-home.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class DmChucVuService {
  constructor(private api: AppHomeApiService) {}

  typeAttach(): number {
    return 1;
  }

  paging(
    body: PageModel | undefined
  ): Observable<MasterModelIEnumerablePageModelViewApiResponse> {
    return this.api.appAdminApiDmChucVuPaging(body);
  }

  insert(body: DmChucvuEntity | undefined): Observable<ApiResponse> {
    return this.api.appAdminApiDmChucVuInsert(body);
  }

  selectOne(key: number): Observable<DmChucvuEntityApiResponse> {
    return this.api.appAdminApiDmChucVuSelectOne(key);
  }

  update(entity: DmChucvuEntity): Observable<ApiResponse> {
    return this.api.appAdminApiDmChucVuUpdate(entity);
  }

  delete(id: number): Observable<ApiResponse> {
    return this.api.appAdminApiDmChucVuDelete(id);
  }
}
