<nb-card>
  <nb-card-header class="nb-card-header">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
        Danh mục chủ đề
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
        <div class="input-group">
          <button
            class="btn btn-primary"
            type="button"
            (click)="openCreateDialog()"
          >
            <i class="fas fa-plus fa-sm"></i>&nbsp;Thêm mới
          </button>
          &nbsp;&nbsp;
          <input
            type="text"
            class="form-control"
            placeholder="Nhập nội dung tìm kiếm"
            aria-label="Nhập nội dung tìm kiếm"
            aria-describedby="basic-addon2"
            [(ngModel)]="pageModel.search"
          />
          <div class="input-group-append">
            <button class="btn btn-info" type="button" (click)="searchPage()">
              <i class="fas fa-search fa-sm"></i>&nbsp;Tìm kiếm
            </button>
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="resetPage()"
            >
              <i class="fas fa-sync-alt fa-sm"></i>&nbsp;Làm mới
            </button>
            <button class="btn btn-outline-secondary" type="button">
              <i class="fas fa-reply fa-sm"></i>&nbsp;Quay lại
            </button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th class="text-center" style="width: 50px;">STT</th>
              <th>Tên chức năng</th>
              <th>Mô tả</th>
              <th class="text-center" style="width: 140px;">Trạng thái</th>
              <th class="text-center" style="width: 115px;">Hành động</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of target.data; let i = index">
              <td class="text-center">
                {{ pageModel.pageSize * (pageModel.currentPage - 1) + i + 1 }}
              </td>
              <td>{{ item.tenapp }}</td>
              <td>{{ item.mota }}</td>
              <td class="text-center">
                <mat-slide-toggle
                    [color]="'primary'"
                    [checked]="setCheckedForToggle(item.trangthai)"
                    [disabled]="false"
                    (change)="changeStatus(item)">
                </mat-slide-toggle>
              </td>
              <td class="text-center">
                <ngx-icon-info (click)="detailItem(item.appid)"></ngx-icon-info>
                &nbsp;&nbsp;
                <ngx-icon-edit (click)="editItem(item.appid)"></ngx-icon-edit>
                &nbsp;&nbsp;
                <ngx-icon-delete (click)="deleteItem(item.appid, item.tenapp)"></ngx-icon-delete>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" style="float: right">
      <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
        <ngx-pagination
          (pageChange)="pageModel.currentPage = $event; loadData()"
          (pageSize)="pageModel.pageSize = $event; loadData()"
          [currentPage]="pageModel.currentPage"
          [totalPages]="target.totalPage"
        >
        </ngx-pagination>
      </div>
    </div>
  </nb-card-body>
</nb-card>
