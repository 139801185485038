import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Workbook } from 'exceljs';
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';
import { saveAs } from "file-saver";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun,
  Table, TableRow, TableCell, WidthType
} from "docx";

@Component({
  selector: 'ngx-dataset-export',
  templateUrl: './dataset-export.component.html',
  styleUrls: ['./dataset-export.component.scss'],
})
export class DataSetExportComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private dataset: any,
    public dialogRef: MatDialogRef<DataSetExportComponent>,
    private readonly httpClient: HttpClient,
  ) { }
  exportTye: any;
  ngOnInit(): void {

  }

  closeDialog() {
    this.dialogRef.close(false);
  }


  create(frm: NgForm) {
    if (this.exportTye === 'word') {
      this.exportToWord();
    } else {
      this.exportExcel();
    }
  }

  dataExport: any[] = [];
  exportToWord() {
    this.dataExport = this.dataset.data;
    let targetExport: any[] = this.dataExport;
    // let targetExport: any[] = this.dataExport.filter((x) => x.id === id);
    // targetExport = this.dataExport.filter((x) => x.parentId === id)
    // Check if there's any data to export
    if (targetExport.length === 0) {
      console.log('No data to export');
      return;
    }
    const document = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              text: "Danh sách chủ đề dữ liệu",
              heading: HeadingLevel.TITLE
            }),
            this.createInstitutionTable(targetExport)
          ]
        }
      ]
    });
    Packer.toBlob(document).then(blob => {
      console.log(blob);
      saveAs(blob, "Danh sách chủ đề dữ liệu.docx");
      console.log("Document created successfully");
    });
  }

  public createInstitutionTable(datasetDetails: any[]): Table {
    const columnWidths = [500, 3000, 3000, 3000, 3000, 3000];
    const headerRow = new TableRow({
      children: [
        new TableCell({
          width: {
            size: columnWidths[0],
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'STT',
                  bold: true
                }),
              ]
            }),
          ]
        }),
        new TableCell({
          width: {
            size: columnWidths[1],
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'Tên cơ sở dữ liệu',
                  bold: true
                }),
              ]
            }),
          ]
        }),
        new TableCell({
          width: {
            size: columnWidths[2],
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'Mô tả mục đích, phạm vi, nội dung của từng cơ sở dữ liệu',
                  bold: true
                }),
              ]
            }),
          ]
        }),
        new TableCell({
          width: {
            size: columnWidths[3],
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'Cơ chế thu thập, cập nhật, nguồn dữ liệu',
                  bold: true
                }),
              ]
            }),
          ]
        }),
        new TableCell({
          width: {
            size: columnWidths[4],
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'Hạng mục dữ liệu',
                  bold: true
                }),
              ]
            }),
          ]
        }),
        new TableCell({
          width: {
            size: columnWidths[5],
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'Ghi chú',
                  bold: true
                }),
              ]
            }),
          ]
        }),
        // Add more TableCell elements for additional fields
      ]
    });

    const dataRows = datasetDetails.map((item, index) => {
      const institutionName = item.name;
      const description = item.description;
      const category = item.category;
      const mechanism = item.mechanism;
      const note = item.note;

      return new TableRow({
        children: [
          new TableCell({
            width: {
              size: columnWidths[0],
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${index + 1}`,
                  }),
                ]
              }),
            ],
          }),
          new TableCell({
            width: {
              size: columnWidths[0],
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                text: institutionName,
              }),
            ]
          }),
          new TableCell({
            width: {
              size: columnWidths[1],
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                text: description,
              }),
            ]
          }),
          new TableCell({
            width: {
              size: columnWidths[2],
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                text: category,
              }),
            ]
          }),
          new TableCell({
            width: {
              size: columnWidths[3],
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                text: mechanism,
              }),
            ]
          }),
          new TableCell({
            width: {
              size: columnWidths[4],
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                text: note,
              }),
            ]
          }),
          // Add more TableCell elements for additional fields
        ]
      });
    });

    return new Table({ rows: [headerRow, ...dataRows] });
  }

  exportExcel() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Danh sách chủ đề dữ liệu'.replace(/[\/\\:*?\[\]]/g, ''), {
      pageSetup: { paperSize: 9 }
    });
    worksheet.pageSetup = { showGridLines: false };

    worksheet.columns = [
      { header: ['Danh sách chủ đề dữ liệu', 'STT'], key: 'index', width: 8 },
      { header: ['Danh sách chủ đề dữ liệu', 'Tên cơ sở dữ liệu'], key: 'name', width: 40 },
      { header: ['Danh sách chủ đề dữ liệu', 'Mô tả mục đích, phạm vi, nội dung của từng cơ sở dữ liệu'], key: 'description', width: 30 },
      { header: ['Danh sách chủ đề dữ liệu', 'Cơ chế thu thập, cập nhật, nguồn dữ liệu'], key: 'mechanism', width: 30 },
      { header: ['Danh sách chủ đề dữ liệu', 'Hạng mục dữ liệu'], key: 'category', width: 30 },
      { header: ['Danh sách chủ đề dữ liệu', 'Ghi chú'], key: 'note', width: 50 },
    ];

    worksheet.mergeCells('A1', 'F1');
    worksheet.getCell('A1', 'F1').style = {
      font: { bold: true, size: 17, name: 'Times New Roman' },
      alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }
    };
    worksheet.getRow(1).height = 40;
    this.dataExport = this.dataset.data;
    // let targetExport: any[] = this.dataExport.filter((x) => x.id === id);
    // targetExport = this.dataExport.filter((x) => x.parentId === id)
    let index = 0;
    this.dataExport?.forEach((e) => {
      index++;
      worksheet.addRow(
        {
          index: index,
          id: e.id,
          name: e.name,
          description: e.description,
          mechanism: e.mechanism,
          category: e.category,
          note: e.note,
        },
        'n'
      );
    });
    worksheet.eachRow((row, rowNumber) => {
      for (let colNumber = 1; colNumber < 7; colNumber++) {
        if (rowNumber > 2) {
          if (colNumber === 1 || colNumber === 3 || colNumber === 7) {
            worksheet.getCell(rowNumber, colNumber).style = {
              border: {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' }
              },
              font: { size: 12, name: 'Times New Roman' },
              alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' }
            };
          } else {
            worksheet.getCell(rowNumber, colNumber).style = {
              border: {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' }
              },
              font: { size: 12, name: 'Times New Roman' },
              alignment: { wrapText: true, vertical: 'middle' }
            };
          }
        }
        if (rowNumber === 2) {
          worksheet.getCell(rowNumber, colNumber).style = {
            border: {
              top: { style: 'thin' },
              bottom: { style: 'thin' },
              left: { style: 'thin' },
              right: { style: 'thin' }
            },
            font: { bold: true, size: 12, name: 'Times New Roman' },
            alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' }
          };
          row.height = 30;
        }
      }
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Danh sách chủ đề dữ liệu' + '.xlsx');
    });
  }
}



