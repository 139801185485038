import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { DatasetService } from '../dataset.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { DatasetDetailModel } from '../../../../@core/data/app-gis.service';
import { TokenService } from '../../../../@core/token.service';

@Component({
  selector: 'ngx-dataset-update',
  templateUrl: './dataset-update.component.html',
  styleUrls: ['./dataset-update.component.scss'],
})
export class DatasetUpdateComponent implements OnInit {
  constructor(
    private datasetService: DatasetService,
    private commonService: CommonService,
    private tokenService: TokenService,
    @Inject(MAT_DIALOG_DATA) private DatasetItem: { id: number, checkUnit: boolean; },
    public dialogRef: MatDialogRef<DatasetUpdateComponent>
  ) { }

  datasets = [];
  selectedItem: any;
  parentId: any;
  target: DatasetDetailModel = new DatasetDetailModel();
  lstUnit: any[] = [];
  checkUnit: boolean = false;
  ngOnInit(): void {
    this.checkUnit = this.DatasetItem.checkUnit;
    this.getUnit();
    this.loadData();
  }
  getUnit() {
    this.datasetService.getAllUnit().subscribe(res => {
      this.lstUnit = res.data;
    });
  }
  loadData() {
    this.datasetService.selectOne(this.DatasetItem.id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
        this.parentId = this.target.parentId;
      } else {
        this.commonService.toastrDanger(result.message);
      }

      this.datasetService.getAll().subscribe((ds) => {
        if (ds.code === SUCCESS_RESPONE) {
          this.datasets = this.dataSetTree(ds.data, null);
        }
      });
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  update(frm: NgForm) {
    if (this.selectedItem && this.selectedItem.id === this.target.id) {
      this.commonService.toastrDanger('Không cập nhật được theo chủ đề trùng nhau');
      return;
    }
    if (this.selectedItem !== undefined) {
      this.target.parentId = this.selectedItem.id;
    } else {
      this.target.parentId = null;
    }
    this.datasetService.update(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
  dataSetTree(data, parentid) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      const obj = {
        name: data[i].name,
        id: data[i].id,
      };
      if (data[i].parentId === parentid) {
        result.push({
          name: data[i].name,
          id: data[i].id,
          items: this.dataSetTree(data, data[i].id),
        });
      }
      if (data[i].id === this.parentId) this.selectedItem = obj;
    }
    return result;
  }
}
