import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { AppQthtApiService, ListMenuModel, ListMenuModelListApiResponse } from './../../../@core/data/app-qtht.service';

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NbMenuItem } from '@nebular/theme';
import { AppDauTuApiService, ObjectIEnumerableApiResponse } from 'app/@core/data/app-dautu.service';
import { AppGISApiService, DatasetDetailModelIEnumerableApiResponse, FeatureclassDetailModelIEnumerableApiResponse } from '../../../@core/data/app-gis.service';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private menuItemsChangedCallback: BehaviorSubject<NbMenuItem[]>;
    private routingChangedCallback: BehaviorSubject<ListMenuModelListApiResponse[]>;
    private lstDanhSachDuAnCallback: BehaviorSubject<ObjectIEnumerableApiResponse>;
    constructor(
        private api: AppQthtApiService,
        private apiDauTu: AppDauTuApiService,
        private apiGis: AppGISApiService
        // private logger: LoggerService
    ) {
        this.menuItemsChangedCallback = new BehaviorSubject<NbMenuItem[]>([]);
        this.routingChangedCallback = new BehaviorSubject<ListMenuModelListApiResponse[]>([]);
        this.lstDanhSachDuAnCallback = new BehaviorSubject<ObjectIEnumerableApiResponse>(null);
    }

    getNbMenuItem(): Observable<NbMenuItem[]> {
        return this.menuItemsChangedCallback;
    }

    setNbMenuItem(lstMenu: NbMenuItem[]) {
        this.menuItemsChangedCallback.next(lstMenu);
    }

    getRoutingSaveCheckRole(): BehaviorSubject<ListMenuModel[]> {
        return this.routingChangedCallback;
    }

    setRoutingSaveCheckRole(lst) {
        this.routingChangedCallback.next(lst);
    }

    getMenuByUserLogin(): Observable<ListMenuModelListApiResponse> {
        // this.logger.log(`BookQuotaService: get book quota detail`);
        // return this.api.token(1);
        return this.api.appQthtApiMenuGetMenuByUserLogin();
    }

    resetMenuWhenLogin() {
        this.menuItemsChangedCallback = new BehaviorSubject<NbMenuItem[]>([]);
    }

    getDanhSachDuAn(): Observable<ObjectIEnumerableApiResponse> {
        return this.lstDanhSachDuAnCallback;
    }

    setDanhSachDuAn(target: ObjectIEnumerableApiResponse) {
        this.lstDanhSachDuAnCallback.next(target);
    }

    selectAllFeatureclass(datasetId: number): Observable<FeatureclassDetailModelIEnumerableApiResponse> {
        return this.apiGis.appGisApiFeatureclassGetFeatureclasss(datasetId);
    }

    selectAllDataset(): Observable<DatasetDetailModelIEnumerableApiResponse> {
        return this.apiGis.appGisApiDatasetGetDatasets();
    }

    selectAllDanhSachDuAn(): Observable<ObjectIEnumerableApiResponse> {
        return this.apiDauTu.appDautuApiDanhsachduanSelectall();
    }

    // create(target: BookQuotaCreateModel) {
    //     this.logger.log(`BookQuotaService: create BookQuota by ${target}`);
    //     return this.api.apiMdMdBookQuotaCreateBookQuotaPost(target);
    // }

    // update(target: BookQuotaCreateModel): Observable<ApiResponse> {
    //     this.logger.log(`BookQuotaService: update book quota by ${target}`);
    //     return this.api.apiMdMdBookQuotaUpdateBookQuotaPost(target);
    // }

    // delete(code: string): Observable<ApiResponse> {
    //     this.logger.log(`BookQuotaService: update book quota by ${code}`);
    //     return this.api.apiMdMdBookQuotaDeleteBookQuotaPost(code);
    // }
}
