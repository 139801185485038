import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmChucvuEntity } from 'app/@core/data/app-home.service';
import { DmChucVuService } from '../dm-chuc-vu.service';

@Component({
  selector: 'ngx-dm-chuc-vu-update',
  templateUrl: './dm-chuc-vu-update.component.html',
  styleUrls: ['./dm-chuc-vu-update.component.scss'],
})
export class DmChucVuUpdateComponent implements OnInit {
  constructor(
    private service: DmChucVuService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number },
    public dialogRef: MatDialogRef<DmChucVuUpdateComponent>
  ) {}

  ngOnInit(): void {
    this.loadData(this.data.id);
  }

  target: DmChucvuEntity = new DmChucvuEntity();
  update(frm: NgForm) {
    this.service.update(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  loadData(id: number) {
    this.service.selectOne(id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
