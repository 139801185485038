<form (ngSubmit)="update()">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div style="display: flex; align-items: center; justify-content: space-between">
        <div class="">Nguồn bản đồ</div>
        <div style="display: flex; justify-content: flex-end; gap: 10px"></div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Chọn bản đồ</label>
            <ng-select
              style="width: 300px"
              [items]="target"
              bindValue="tableName"
              bindLabel="name"
              [(ngModel)]="tableName"
              name="tableName"
              placeholder="Chọn Map"
            ></ng-select>
          </div>
          <div class="form-group mt-2">
            <label class="col-form-label required">Chọn nguồn dữ liệu</label>
            <div class="input-group">
              <input
                type="radio"
                style="margin-top: auto; margin-bottom: auto; width: 20px; height: 20px"
                [value]="'api'"
                [(ngModel)]="sourceMap"
                id="status1"
                name="sourceMap"
              />
              &nbsp;
              <label for="status1" style="margin: 10px 10px 10px 0px">API</label>
              <br />
              <input
                type="radio"
                style="margin-top: auto; margin-bottom: auto; width: 20px; height: 20px"
                [value]="'json'"
                [(ngModel)]="sourceMap"
                id="status2"
                name="sourceMap"
              />
              &nbsp;
              <label for="status2" style="margin: 10px 10px 10px 0px">File Json</label>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button type="submit" class="btn btn-primary">Lưu</button>
          &nbsp;
          <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
