<div class="main">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 col-xl-12">
         DANH MỤC TÁC GIẢ
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 col-xl-12">
            <div class="input-group">
                <ngx-btn-add data-toggle="tooltip" title="thêm mới" (click)="openForm()"></ngx-btn-add>&nbsp;
                <input  type="text" class="form-control" placeholder="Nhập nội dung cần tìm kiếm" style="height: auto;" [(ngModel)]="searchModel.search" (keydown.enter)="search()" name="seach"/>&nbsp;
                <ngx-btn-search (click)="search();"></ngx-btn-search>&nbsp;
                <ngx-btn-refresh (click)="searchModel.search = '';searchModel.status = null; search();">
                </ngx-btn-refresh>
            </div>
        </div>
    </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 col-xl-12">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th class="text-center align-middle" style="width:2%">STT</th>
                    <th class="text-center align-middle"style="width:10%;">Mã tác giả</th>
                    <th class="text-center align-middle" >Tên tác giả</th>
                    <th class="text-center align-middle"style="width:10%">Trạng thái</th>
                    <th class="text-center align-middle" style="width:13%">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of target.data; let i = index;">
                    <td class="text-center align-middle" >{{i+1}}</td>
                    <td class="text-left align-middle" style="padding-left:20px">{{item.matacgia}}</td>
                    <td class="text-left align-middle" style="padding-left:20px">{{item.tentacgia}}</td>
                    <td class="text-center align-middle">{{item.trangthai}}</td>
                    <td class="text-center align-middle">
                      <ngx-icon-info class="icon" data-toggle="tooltip" title="Xem chi tiết" (click)="detailData(item.id);">
                      </ngx-icon-info>
                      <ng-container>
                          <ngx-icon-edit class="icon" data-toggle="tooltip" title="Cập nhật" (click)="updateData(item.id);">
                          </ngx-icon-edit>
                          <ngx-icon-delete class="icon" data-toggle="tooltip" title="Xoá" (click)="deleteData(item.id, item.tentacgia);">
                          </ngx-icon-delete>
                      </ng-container>
                      <ngx-icon-reply class="icon" data-toggle="tooltip" title="Thu hồi" *ngIf="item.status === 2" (click)="recoverData(item);">
                      </ngx-icon-reply>
                      <ngx-icon-ellipsis class="icon" *ngIf="item.status === 2 && item.isGovernment" (click)="viewListShare(item.lstShareUnit);"></ngx-icon-ellipsis>
                  </td>
                  </tr>

                </tbody>
              </table>
        </div>
      </div>

    </nb-card-body>
  </nb-card>
</div>
