import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  AppGISApiService,
  SysdatasetEntity,
  ObjectIEnumerablePageModelViewApiResponse,
  PageModel,
  DatasetDetailModelIEnumerableApiResponse,
  DatasetDetailModelApiResponse,
  CountDataModel,
  ResultDataModelIEnumerableApiResponse,
  ObjectIEnumerableApiResponse,
} from '../../../@core/data/app-gis.service';
import { AppQthtApiService, UnitSelectModelIEnumerableApiResponse } from '../../../@core/data/app-qtht.service';

@Injectable({
  providedIn: 'root',
})
export class DatasetService {
  constructor(
    private api: AppGISApiService, // private logger: LoggerService
    private apiQTHT: AppQthtApiService
  ) { }

  paging(page: PageModel): Observable<ObjectIEnumerablePageModelViewApiResponse> {
    return this.api.appGisApiDatasetPaging(page);
  }

  create(target: SysdatasetEntity): Observable<ApiResponse> {
    return this.api.appGisApiDatasetInsertCustom(target);
  }

  update(entity: SysdatasetEntity): Observable<ApiResponse> {
    return this.api.appGisApiDatasetUpdateCustom(entity);
  }

  delete(key: number): Observable<ApiResponse> {
    return this.api.appGisApiDatasetDeleteCustom(key);
  }

  selectOne(key: number): Observable<DatasetDetailModelApiResponse> {
    return this.api.appGisApiDatasetSelecOneCustom(key);
  }

  getAll(): Observable<DatasetDetailModelIEnumerableApiResponse> {
    return this.api.appGisApiDatasetGetDatasets();
  }
  getDataset(): Observable<ObjectIEnumerableApiResponse> {
    return this.api.appGisApiDatasetSelectAll();
  }
  getAllUnit(): Observable<UnitSelectModelIEnumerableApiResponse> {
    return this.apiQTHT.appQthtApiSysUnitGetSysUnitList();
  }
  countData(key: number, year: number, month: number): Observable<number> {
    return this.api.appGisApiDataCountData(key, year, month);
  }
  countDatas(body: CountDataModel): Observable<ResultDataModelIEnumerableApiResponse> {
    return this.api.appGisApiDataCountDatas(body);
  }
}
