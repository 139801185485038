<form #frm="ngForm">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">CHI TIẾT</div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
          <div class="form-group">
            <label class="control-label">Mã tác giả</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                name="matacgia"
                maxlength="500"
                [(ngModel)]="target.matacgia"
                #matacgia="ngModel"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
          <div class="form-group">
            <label class="control-label">Tên tác giả</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                name="tentacgia"
                maxlength="500"
                [(ngModel)]="target.tentacgia"
                #tentacgia="ngModel"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
          <div class="form-group" style="margin-top: 10px">
            <label class="control-label">Trạng thái</label>
            <div class="input-group">
              <input
                type="radio"
                style="margin-top: auto; margin-bottom: auto; width: 20px; height: 20px"
                [value]="1"
                [(ngModel)]="target.trangthai"
                id="trangthai1"
                name="trangthai"
              />&nbsp;
              <label for="trangthai1" style="margin: 10px 10px 10px 0px">Sử dụng </label>
              <br />
              <input
                type="radio"
                style="margin-top: auto; margin-bottom: auto; width: 20px; height: 20px"
                [value]="0"
                [(ngModel)]="target.trangthai"
                id="trangthai2"
                name="trangthai"
              />&nbsp;
              <label for="trangthai2" style="margin: 10px 10px 10px 0px">Không sử dụng </label>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-end">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
          <!-- <button class="btn btn-success" type="button" [disabled]="frm.invalid" (click)="publish();"><i class="fa-solid fa-upload"></i>&nbsp;Lưu và Xuất bản</button>&nbsp; -->
          <ngx-btn-close mat-dialog-close></ngx-btn-close>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
