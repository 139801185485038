<nb-card>
    <nb-card-header class="nb-card-header">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                Thông tin người dùng
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                <div class="form-group">
                    <label class="col-form-label">Tên người dùng</label>
                    <input autocomplete="off" type="text" class="form-control" name="username" [(ngModel)]="target.username" maxlength="50" #username="ngModel" disabled>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                <div class="form-group">
                    <label class="col-form-label">Unitcode</label>
                    <input autocomplete="off" type="text" class="form-control" name="unitcode" [(ngModel)]="target.unitcode" maxlength="50" #unitcode="ngModel" disabled>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                <div class="form-group">
                    <label class="col-form-label">Họ và tên</label>
                    <input autocomplete="off" type="text" class="form-control" name="fullname" [(ngModel)]="target.fullname" maxlength="50" #fullname="ngModel" disabled>
                </div>
            </div>
            <!-- <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                <div class="form-group">
                    <label class="col-form-label">Số giấy phép kinh doanh</label>
                    <input autocomplete="off" type="text" class="form-control" name="chucvu" [(ngModel)]="target.chucvu" maxlength="50" #chucvu="ngModel" disabled>
                </div>
            </div> -->
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                <div class="form-group">
                    <label class="col-form-label">Địa chỉ</label>
                    <input autocomplete="off" type="text" class="form-control" name="diachi" [(ngModel)]="target.diachi" maxlength="200" #diachi="ngModel" disabled>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                <div class="form-group">
                    <label class="col-form-label">Số điện thoại</label>
                    <input autocomplete="off" type="text" class="form-control" name="phone" [(ngModel)]="target.phone" maxlength="11" #phone="ngModel" disabled>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                <div class="form-group">
                    <label class="col-form-label">Email</label>
                    <input autocomplete="off" type="text" class="form-control" name="email" [(ngModel)]="target.email" maxlength="150" #email="ngModel" disabled>
                </div>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
                <ngx-btn-close mat-dialog-close></ngx-btn-close>
            </div>
        </div>
    </nb-card-footer>
</nb-card>