import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE, SUCCESS_NOTICE, PAGE_SIZE } from '../../../../@core/customs/constants';
import { FeatureclassService } from '../featureclass.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';;
import { DataSearchModel, ExportDataModel } from '../../../../@core/data/app-gis.service';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { FieldService } from '../field.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { FeatureclassViewDetailDataComponent } from '../featureclass-viewdetail-data/featureclass-viewdetail-data.component';
import { FeatureclassUpdateDataComponent } from '../featureclass-updatedata/featureclass-updatedata.component';
import { FeatureclassCreatedataComponent } from '../featureclass-createdata/featureclass-createdata.component';
import { DialogConfirmComponent } from '../../../../@theme/components/template/dialog-confirm/dialog-confirm.component';
import { FeatureclassExportComponent } from '../featureclass-export/featureclass-export.component';
import { FeatureclassImportComponent } from '../featureclass-import/featureclass-import.component';
import { FeatureclassViewLogComponent } from '../featureclass-viewlog/featureclass-viewlog.component';
@Component({
  selector: 'ngx-lopdulieu-index',
  templateUrl: './lopdulieu-index.component.html',
  styles: ['.furniture { width: 285px; }'],
  styleUrls: ['./lopdulieu-index.component.scss'],
})
export class LopDuLieuIndexComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private fieldService: FieldService,
    private featureclassService: FeatureclassService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private readonly httpClient: HttpClient,
    private route: ActivatedRoute,
  ) { }
  fields: any[] = [];
  fieldsName: any[] = [];
  target: any[] = [];
  textSearch: string;
  lstObject: any[] = [];
  options: any[] = [];
  status: any;
  lst: any[] = [];
  currentPage = 1;
  totalPage: number;
  lstDVHC: any[] = [];
  targetNew: any = {
    data: [],
  };
  lstDVHCCH: any[] = [];
  lstDVHCCX: any[] = [];
  SearchModel: DataSearchModel = new DataSearchModel({
    tableName: null,
    text: null,
    page: 1,
    limit: PAGE_SIZE,
    status: null,
    fieldSelect: null,
    dvhcch: null,
    dvhccx: null,
  });
  field: string;
  idDataset: any;
  tableName: any;
  Name: any;
  IdtableName: any;
  ngOnInit(): void {
    this.idDataset = Number(this.route.snapshot.paramMap.get('id'));
    this.tableName = this.route.snapshot.paramMap.get('tablename');
    this.IdtableName = this.route.snapshot.paramMap.get('idtablename');
    this.featureclassService.GetName(this.tableName).subscribe((res) => {
      this.Name = res;
    });
    this.options = [
      {
        name: 'Sử dụng',
        value: 1
      },
      {
        name: 'Không sử dụng',
        value: 0
      }
    ];
    this.loadDataNew();
    this.fieldService.getAllByTableName(this.tableName).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.fields = result.data;
      }
    });
    this.loadDVHC();

  }
  locSearch: boolean = false;
  locDieukien() {
    this.locSearch = !this.locSearch;
  }

  buttonHanhDong: boolean = false;
  hanhdong() {
    this.buttonHanhDong = !this.buttonHanhDong;
  }
  lstDVHCCXFill: any[] = [];
  maHuyen: any;
  maXa: any;
  changeHuyenbyXa() {
    this.SearchModel.dvhcch = this.maHuyen;
    this.SearchModel.dvhccx = this.maXa;
    this.dataService.getDataByTableName('dvhccx').subscribe((result) => {
      this.lstDVHCCXFill = JSON.parse(result.data);
      this.lstDVHCCXFill = this.lstDVHCCXFill.filter((x) => x.macha === this.maHuyen)
    });
    this.loadDataNew();
  }

  loadHuyenXa() {
    this.SearchModel.dvhcch = this.maHuyen;
    this.SearchModel.dvhccx = this.maXa;
    this.loadDataNew();
  }

  loadDVHC() {
    this.dataService.getDataByTableName('dvhccx').subscribe((result) => {
      this.lstDVHCCX = JSON.parse(result.data);
    });
    this.dataService.getDataByTableName('dvhcch').subscribe((res) => {
      this.lstDVHCCH = JSON.parse(res.data);
    })
  }
  getTenDVX(unit) {
    const name = this.lstDVHCCX.filter(x => x.madonvi === unit);
    return name.length > 0 ? name[0].ten : unit
  }
  getTenDVH(unit) {
    const name = this.lstDVHCCH.filter(x => x.madonvi === unit);
    return name.length > 0 ? name[0].ten : unit
  }
  changeStatus(item: any) {
    const tableName = this.tableName;
    const id = this.target[item].id;
    const status = this.target[item].status;
    this.dataService.ChangeStatus(tableName, status, id).subscribe(res => {
      if (res.code === SUCCESS_RESPONE) {
        this.exportGeoJson();
        this.loadDataNew();
      }
    });
  }
  setCheckedForToggle(status: number) {
    if (status === 1) {
      return true;
    } else {
      return false;
    }
  }
  exportGeoJson() {
    const ex = new ExportDataModel();
    ex.featureclassId = this.IdtableName;
    ex.tableName = this.tableName;
    ex.fileType = 'json';
    ex.prj = '105453';
    ex.fields = [];
    ex.filter = "status = 1";
    const req = new HttpRequest(
      'POST',
      `${environment.APP_GIS_URL}/App/Gis/api/Data/export`,
      ex,
      { responseType: 'blob' }
    );
    this.httpClient.request<Blob>(req).pipe().subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          let result = event.body;
        }
      },
      (error) => {
        throw error;
      }
    );
  }

  exportTemplate() {
    const ex = new ExportDataModel();
    ex.featureclassId = this.IdtableName;
    ex.tableName = this.tableName;
    const date = Date();
    const CurrDate = new ConvertDatePipe().transform(date);

    const req = new HttpRequest('POST', `${environment.APP_GIS_URL}/App/gis/api/Data/ExportTemplate`, ex, {
      responseType: 'blob',
    });
    this.httpClient
      .request<Blob>(req)
      .pipe()
      .subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            let result = event.body;
            this.blobToFile(
              result,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              CurrDate + '-' + ex.tableName + '.xlsx'
            );
          }
        },
        (error) => {
          throw error;
        }
      );
  }

  blobToFile(data: any, type: string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getTen(value: number) {
    let name: string;
    if (value === 0) name = 'Nữ';
    else if (value === 1) name = 'Nam';
    else name = 'Khác'
    return name;
  }
  sortByStatus() {
    this.SearchModel.text = null;
    this.SearchModel.status = this.status;
    this.SearchModel.page = 1;
    this.loadDataNew();
  }
  onSearchTableAttribute() {
    this.SearchModel.dvhcch = this.maHuyen;
    this.SearchModel.dvhccx = this.maXa;
    this.SearchModel.fieldSelect = this.field;
    this.SearchModel.page = 1;
    this.SearchModel.text = this.textSearch;
    this.loadDataNew();
  }
  refresh() {
    this.maHuyen = null;
    this.maXa = null;
    this.SearchModel.dvhcch = null;
    this.SearchModel.dvhccx = null;
    this.textSearch = '';
    this.SearchModel.page = 1;
    this.loadDataNew();
  }

  loadDataNew() {
    this.SearchModel.tableName = this.tableName;
    this.dataService.PaingData(this.SearchModel).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.targetNew = result;
        this.target = JSON.parse(this.targetNew.data.data);
      } else {
        this.commonService.toastrDanger(result.message);
      }

    });

  }

  viewLog() {
    this.dialog
      .open(FeatureclassViewLogComponent, this.commonService.configDialog('100%', { id: this.IdtableName, tablename: this.tableName }))
      .afterClosed()
      .subscribe((result) => { });
  }
  exportData() {
    this.dialog
      .open(FeatureclassExportComponent, this.commonService.configDialog('50%', { id: this.IdtableName, tablename: this.tableName }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadDataNew();
        }
      });
  }
  importData() {
    this.dialog
      .open(FeatureclassImportComponent, this.commonService.configDialog('80%', { id: this.IdtableName, tablename: this.tableName }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadDataNew();
        }
      });
  }
  editFeature(index) {
    const data = this.target[index];
    this.dialog
      .open(
        FeatureclassUpdateDataComponent,
        this.commonService.configDialog('60%', { data: data, tablename: this.tableName, DVHCCH: this.lstDVHCCH, DVHCCX: this.lstDVHCCX })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadDataNew();
        }
      });
  }
  viewFeature(index) {
    const data = this.target[index];
    this.dialog
      .open(
        FeatureclassViewDetailDataComponent,
        this.commonService.configDialog('60%', { data: data, tablename: this.tableName })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadDataNew();
        }
      });
  }
  openCreate() {
    this.dialog
      .open(
        FeatureclassCreatedataComponent,
        this.commonService.configDialog('60%', {
          data: this.fields,
          dataName: this.fieldsName,
          tablename: this.tableName,
          DVHCCH: this.lstDVHCCH, DVHCCX: this.lstDVHCCX
        })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadDataNew();
        }
      });
  }

  deleteFeature(index) {
    const id = this.target[index].id;
    this.dialog.open(DialogConfirmComponent, this.commonService.configDialog('30%', {
      title: 'Xác nhận',
      message: `Bạn có chắc chắn xóa ?`,
      color: 'text-warning',
      type: 1,
      icon: 'exclamation-triangle',
      isAppend: true
    })).afterClosed().subscribe(info => {
      if (info) {
        this.dataService.deleteData(this.tableName, id).subscribe(res => {
          if (res.code === SUCCESS_RESPONE) {
            this.commonService.toastrSuccess(SUCCESS_NOTICE);
            this.loadDataNew();
            this.exportGeoJson();
          } else {
            this.commonService.toastrDanger(res.message);
          }
        });
      }
    });
  }
}
