import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { DUPLICATE_RESPONE, SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmLoaibaivietEntity } from 'app/@core/data/app-home.service';
import { DmLoaiBaiVietService } from '../dm-loai-bai-viet.service';

@Component({
  selector: 'ngx-dm-loai-bai-viet-create',
  templateUrl: './dm-loai-bai-viet-create.component.html',
  styleUrls: ['./dm-loai-bai-viet-create.component.scss'],
})
export class DmLoaiBaiVietCreateComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: DmLoaiBaiVietService,
    private dialogRef: MatDialogRef<DmLoaiBaiVietCreateComponent>
  ) {}

  target: DmLoaibaivietEntity = new DmLoaibaivietEntity();
  error: string;
  ngOnInit(): void {}

  create(frm: NgForm) {
    this.target.trangthai = 1;
    this.service.insertLBV(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else if (result.code === DUPLICATE_RESPONE) {
        this.error = 'Tên danh mục đã tồn tại';
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
