import { ConvertDatePipe } from 'app/@core/customs/common.service';
import { SortDirective } from 'app/@core/utils/sort.directive';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import {
  NbActionsModule, NbButtonModule, NbCardModule, NbContextMenuModule, NbIconModule,
  NbLayoutModule, NbListModule, NbMenuModule, NbRadioModule, NbSearchModule, NbSelectModule,
  NbSidebarModule, NbThemeModule, NbUserModule
} from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  FooterComponent, HeaderComponent, LayoutDirectionSwitcherComponent, SearchInputComponent,
  SwitcherComponent
} from './components';
import { HeaderDauTuComponent } from './components/header-dau-tu/header-dau-tu.component';
import { MdPaginationComponent } from './components/pagination/pagination.component';
import { BtnAddSyncComponent } from './components/template/button/add-sync/btn-add-sync.component';
import { BtnAddComponent } from './components/template/button/add/btn-add.component';
import { BtnBackComponent } from './components/template/button/back/btn-back.component';
import { BtnCancelComponent } from './components/template/button/cancel/btn-cancel.component';
import { BtnCloseComponent } from './components/template/button/close/btn-close.component';
import { BtnConfirmComponent } from './components/template/button/confirm/btn-confirm.component';
import { BtnDeleteComponent } from './components/template/button/delete/btn-delete.component';
import {
  BtnDownloadTemplateComponent
} from './components/template/button/download-template/btn-download-template.component';
import { BtnFileComponent } from './components/template/button/file/btn-file.component';
import {
  BtnImportFileComponent
} from './components/template/button/import-file/btn-import-file.component';
import { BtnRefreshComponent } from './components/template/button/refresh/btn-refresh.component';
import { BtnRefuseComponent } from './components/template/button/refuse/btn-refuse.component';
import { BtnSaveComponent } from './components/template/button/save/btn-save.component';
import { BtnSearchComponent } from './components/template/button/search/btn-search.component';
import { BtnSubmitComponent } from './components/template/button/submit/btn-submit.component';
import { BtnSyncComponent } from './components/template/button/sync/btn-sync.component';
import {
  BtnUpdateSyncComponent
} from './components/template/button/update-sync/btn-update-sync.component';
import { IconApproveComponent } from './components/template/icon/approve/icon-approve.component';
import { IconDeclineComponent } from './components/template/icon/decline/icon-decline.component';
import { IconDeleteComponent } from './components/template/icon/delete/icon-delete.component';
import { IconEditComponent } from './components/template/icon/edit/icon-edit.component';
import { IconInfoComponent } from './components/template/icon/info/icon-info.component';
import { IconReplyComponent } from './components/template/icon/reply/icon-reply.component';
import { IconShareComponent } from './components/template/icon/share/icon-share.component';
import { IconSiteMapComponent } from './components/template/icon/site-map/icon-site-map.component';
import {
  OneColumnLayoutComponent, ThreeColumnsLayoutComponent, TwoColumnsLayoutComponent
} from './layouts';
import {
  OneColumnDauTuLayoutComponent
} from './layouts/one-column-dau-tu/one-column-dau-tu.layout';
import { CapitalizePipe, NumberWithCommasPipe, PluralPipe, RoundPipe, TimingPipe, NumberWithDotPipe } from './pipes';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { DARK_THEME } from './styles/theme.dark';
import { DEFAULT_THEME } from './styles/theme.default';
import { BtnAddRowComponent } from './components/template/button/add-row/btn-add-row.component';
import { BtnDeleteRowComponent } from './components/template/button/delete-row/btn-delete-row.component';
import { MapComponent } from '../modules/app-gis/map/map-index/map.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { DialogConfirmComponent } from './components/template/dialog-confirm/dialog-confirm.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
const BASE_MODULES = [CommonModule, FormsModule, NgSelectModule, ReactiveFormsModule];

const MAT_MODULES = [MatDialogModule, MatTabsModule, MatStepperModule];

const TELERIK_MODULES = [DialogsModule, ButtonsModule, InputsModule];

const NB_MODULES = [
  NbListModule,
  NbRadioModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  TreeViewModule,
  IvyCarouselModule,
  ChartsModule
];
const COMPONENTS = [
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  HeaderComponent,
  FooterComponent,
  HeaderDauTuComponent,
  SearchInputComponent,
  OneColumnLayoutComponent,
  OneColumnDauTuLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  MdPaginationComponent,
  BtnSubmitComponent,
  BtnAddComponent,
  BtnBackComponent,
  BtnCloseComponent,
  BtnRefreshComponent,
  BtnSaveComponent,
  BtnSearchComponent,
  BtnSyncComponent,
  BtnFileComponent,
  BtnDeleteComponent,
  BtnAddSyncComponent,
  BtnUpdateSyncComponent,
  BtnConfirmComponent,
  BtnRefuseComponent,
  BtnImportFileComponent,
  BtnDownloadTemplateComponent,
  BtnAddRowComponent,
  BtnDeleteRowComponent,
  IconInfoComponent,
  IconEditComponent,
  IconDeleteComponent,
  SortDirective,
  IconApproveComponent,
  IconDeclineComponent,
  IconShareComponent,
  IconReplyComponent,
  IconSiteMapComponent,
  BtnCancelComponent,
  MapComponent,
  DialogConfirmComponent
];
const PIPES = [CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, NumberWithCommasPipe, ConvertDatePipe, NumberWithDotPipe];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, ...MAT_MODULES, ...TELERIK_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...MAT_MODULES, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]
        ).providers,
      ],
    };
  }
}
