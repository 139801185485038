<kendo-treeview
      [nodes]="data"
      textField="text"
      kendoTreeViewHierarchyBinding
      childrenField="items"
      [filterable]="true"
      kendoTreeViewExpandable
      [expandedKeys]="expandedKeys"
      kendoTreeViewCheckable
      [(checkedKeys)]="checkedKeys"
    >
    </kendo-treeview>