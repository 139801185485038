import { Routes } from '@angular/router';
import { FeatureclassIndexComponent } from './featureclass-index/featureclass-index.component';
import { LopDuLieuIndexComponent } from './lopdulieu-index/lopdulieu-index.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: FeatureclassIndexComponent,
      },
      {
        path: 'lopdulieu/:id/:idtablename/:tablename',
        component: LopDuLieuIndexComponent,
      },
    ]
  }
];
