import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FieldService } from '../field.service';
import { FieldInsertOrUpdateModel } from '../../../../@core/data/app-gis.service';

@Component({
  selector: 'ngx-field-create',
  templateUrl: './field-create.component.html',
  styleUrls: ['./field-create.component.scss'],
})
export class FieldCreateComponent implements OnInit {
  constructor(
    private fieldService: FieldService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private feature: { featureClass: any },
    public dialogRef: MatDialogRef<FieldCreateComponent>
  ) {}

  target: FieldInsertOrUpdateModel = new FieldInsertOrUpdateModel();

  ngOnInit(): void {
    this.target.datalength = '1';
    this.target.show = '1';
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  create() {
    this.target.name = this.target.name.trim();
    this.target.featureclassId = this.feature.featureClass.featureid;
    // this.target.datatype = 'nvarchar2'
    // this.target.show = '1';
    // this.target.datalength = '255';
    this.target.fieldname = this.target.fieldname.toLowerCase();
    this.fieldService.create(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
