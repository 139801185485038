<form #frm="ngForm" (ngSubmit)="update(frm)">
    <nb-card>
        <nb-card-header class="nb-card-header">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    Cập nhật tên thư mục
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
            
          <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="col-form-label">Thư mục cha</label>
                    <kendo-dropdowntree
                        placeholder="Chọn thư mục cha" 
                        kendoDropDownTreeExpandable
                        [kendoDropDownTreeHierarchyBinding]="folders"
                        textField="name"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="selectedItem"
                        valueField="id"
                        childrenField="items"
                        class="furniture"
                        >
                    </kendo-dropdowntree>
                </div>
            </div>
            <div class="col-lg-12" *ngIf="checkUnit === true">
                <div class="form-group">
                  <label class="col-form-label">Đơn vị quản lý</label>
                  <ng-select [items]="lstUnit" bindValue="unitcode" bindLabel="unitname" 
                    [(ngModel)]="target.unitCode" name="unitcode" placeholder="Chọn đơn vị" appendTo="Body"></ng-select>
                </div>
              </div>
              <div class="col-lg-12">
                  <div class="form-group">
                      <label class="col-form-label required">Tên thư mục</label>
                      <input type="text" class="form-control" name="name" [(ngModel)]="target.name" required maxlength="50">
                  </div>
              </div>
          </div>
      </nb-card-body>
        <nb-card-footer class="text-right">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
                    <button type="submit" class="btn btn-primary" [disabled]="frm.invalid">Lưu</button>
                    &nbsp;
                    <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
                </div>
            </div>
        </nb-card-footer>
    </nb-card>
</form>
