<form type="multipart/form-data">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 d-flex">
          <div class="input-group" style="margin-bottom: 20px;">
            <label style="flex: auto">Tạo địa điểm tại ví trí được đánh dấu</label>
            <button type="submit" class="btn btn-primary" (click)="create()">Lưu</button>&nbsp;
            <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <!-- <input type="file" id="file" multiple (change)="handleFileInput($event.target.files)" class="form-control"> -->
            <ngx-btn-file (change)="chooseFile($event.target.files)" [multiple]="true" name="file" [accept]="'image/jpg, image/jpeg, image/png, video/mp4'">
            </ngx-btn-file>
            &nbsp;&nbsp;
            <div>
              <span *ngIf="selectedFiles && selectedFiles.length > 0" class="files">
                Danh sách file :
                <span class="file-x" *ngFor="let file of selectedFiles">
                  {{file.fileName}}&nbsp;<ngx-icon-delete (click)="deleteFile(file)"></ngx-icon-delete>&nbsp;
                </span>
              </span>
              <span *ngIf="selectedFiles.length === 0"><i>Không có file đính kèm</i></span>
            </div>
          </div>
        </div>
      </div>
   
      <div class="row" *ngFor="let t of lstAll">
        <div class="col-lg-12">
          <div class="form-group">
            <div
              *ngIf="t.x.toLowerCase() !== 'status' && t.x.toLowerCase() !== 'maxa' && t.x.toLowerCase() !== 'mahuyen' && t.x.toLowerCase() !== 'matinh' && t.x.toLowerCase() !== 'createddate' && t.x.toLowerCase() !== 'gioitinh'">
              <label class="col-form-label">{{ t.y }}</label>
              <input type="text" id="{{ t.x }}-create" class="form-control" />
            </div>
            <div *ngIf="t.x.toLowerCase() === 'matinh'">
              <label class="col-form-label">Tên tỉnh</label>
              <input type="text" id="{{ t.x }}-create" class="form-control" value="Tỉnh Cao Bằng" readonly="true" [(ngModel)]="MDVHCT" name="matinh"/>
            </div>
            <div *ngIf="t.x.toLowerCase() === 'mahuyen'">
              <label class="col-form-label">Tên đơn vị hành chính cấp huyện</label>
              <ng-select [items]="lstDVHCCH" bindValue="madonvi" [(ngModel)]="MDVHCCH" bindLabel="ten"  name="MDVHCCH" placeholder="Chọn đơn vị hành chính cấp huyện" appendTo="body"></ng-select>
            </div>
            <div *ngIf="t.x.toLowerCase() === 'maxa'">
              <label class="col-form-label">Tên đơn vị hành chính cấp xã</label>
              <ng-select [items]="lstDVHCCX" bindValue="madonvi" [(ngModel)]="MDVHCCX" bindLabel="ten"  name="MDVHCCX" placeholder="Chọn đơn vị hành chính cấp xã" appendTo="body"></ng-select>
            </div>
            <div class="form-group" *ngIf=" t.x.toLowerCase() === 'gioitinh'">
              <label class="col-form-label">Giới tính</label>
              <ng-select [items]="options" bindValue="value" [(ngModel)]="gender" bindLabel="name" name="gender"
                placeholder="Chọn giới tính" appendTo="body"></ng-select>
            </div>
          </div>
        </div>
      </div>
</form>