<form type="multipart/form-data">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 d-flex">
      <div class="input-group" style="margin-bottom: 20px;">
        <label style="flex: auto">Thêm ảnh & video</label>
        <button type="submit" class="btn btn-primary" (click)="create()">Lưu</button>&nbsp;
        <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="form-group">
        <!-- <input type="file" id="file" multiple (change)="handleFileInput($event.target.files)" class="form-control"> -->
        <ngx-btn-file (change)="chooseFile($event.target.files)" [multiple]="true" name="file" [accept]="'image/jpg, image/jpeg, image/png, video/mp4'">
        </ngx-btn-file>
        &nbsp;&nbsp;
        <div>
          <span *ngIf="selectedFiles && selectedFiles.length > 0" class="files">
            Danh sách file :
            <span class="file-x" *ngFor="let file of selectedFiles">
              {{file.fileName}}&nbsp;<ngx-icon-delete (click)="deleteFile(file)"></ngx-icon-delete>&nbsp;
            </span>
          </span>
          <span *ngIf="selectedFiles.length === 0"><i>Không có file đính kèm</i></span>
        </div>
      </div>
    </div>
  </div>
</form>