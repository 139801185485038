import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ApiResponse,
    AppQthtApiService,
    SysappEntity,
    SysappEntityApiResponse,
    ObjectIEnumerablePageModelViewApiResponse,
    PageModel
} from 'app/@core/data/app-qtht.service';

@Injectable({
    providedIn: 'root',
})
export class SysAppService {
    constructor(
        private api: AppQthtApiService,
        // private logger: LoggerService
    ) { }

    paging(page: PageModel): Observable<ObjectIEnumerablePageModelViewApiResponse>  {
        return this.api.appQthtApiSysAppPaging(page);
    }

    create(target: SysappEntity): Observable<ApiResponse> {
        return this.api.appQthtApiSysAppInsert(target);
    }

    update(entity: SysappEntity): Observable<ApiResponse> {
        return this.api.appQthtApiSysAppUpdate(entity);
    }

    delete(key: number): Observable<ApiResponse> {
        return this.api.appQthtApiSysAppDelete(key);
    }

    selectOne(key: number):  Observable<SysappEntityApiResponse> {
        return this.api.appQthtApiSysAppSelectOne(key);
    }
}
