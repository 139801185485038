import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmTientrinhEntity } from 'app/@core/data/app-home.service';
import { DmTienTrinhService } from '../dm-tien-trinh.service';

@Component({
  selector: 'ngx-dm-tien-trinh-create',
  templateUrl: './dm-tien-trinh-create.component.html',
  styleUrls: ['./dm-tien-trinh-create.component.scss'],
})
export class DmTienTrinhCreateComponent implements OnInit {
  constructor(
    private service: DmTienTrinhService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<DmTienTrinhCreateComponent>
  ) {}

  target: DmTientrinhEntity = new DmTientrinhEntity();
  ngOnInit(): void {}

  create(frm: NgForm) {
    this.target.trangthai = 1;
    this.service.insert(this.target).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess('Thêm mới thành công');
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
}
