import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { WebAdminRoutingModule } from './web-admin-routing.module';
import { EditorModule } from '@progress/kendo-angular-editor';
import { DmLienHeIndexComponent } from './dm-Lien-he/dm-lien-he-index/dm-lien-he-index.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    WebAdminRoutingModule,
    NbSidebarModule.forRoot(),
    NbWindowModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbToastrModule.forRoot(),
    EditorModule,
  ],
})
export class WebAdminModule {}
