import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeatureclassService } from '../featureclass.service';
import { TokenService } from '../../../../@core/token.service';
import { DatasetService } from '../../dataset/dataset.service';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { FileService } from '../../file/file.service';
import { MapAdminService } from '../../mapadmin/mapadmin.service';
import { ApiResponse, MapInsertOrUpdateModel } from '../../../../@core/data/app-gis.service';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'ngx-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  constructor(
    
    private commonService: CommonService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public feature: { item : any, type : any },
    public dialogRef: MatDialogRef<ShareComponent>,
    private featureclassService: FeatureclassService,
    private datasetService: DatasetService,
    private tokenService: TokenService,
    private folderService: FileService,
    private mapService: MapAdminService,
    private readonly httpClient: HttpClient,
  ) {}
  fileToUpload: FileList | null = null;
  data : any[] = [];
  lstShare : any[] = [];
  unitcode: string
  ngOnInit(): void {
    this.tokenService.getUserInfo().subscribe((users: any) => {
      this.unitcode = users.UnitCode;
    });
    if (this.feature.item.shareFor) {
      this.lstShare = this.feature.item.shareFor.split(";");
    }
    this.loadPhongBan();
  }
  loadPhongBan() {
    this.featureclassService.getAllPhongBan().subscribe(feature => {
      if (this.unitcode !== '9999') {
        this.data = feature.data.filter((i) => i.unitcode === this.unitcode);
      } else {
        this.data = feature.data;
      }
    })
  }
  saveShare() {
    const item = this.feature.item;
    item.shareFor = this.lstShare.join(";")
    if (this.feature.type === 'FeatureClass') {
      this.featureclassService.update(item).subscribe((res) => {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      });
    } else if (this.feature.type === 'Dataset') {
      this.datasetService.update(item).subscribe((result) => {
        if (result.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess();
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrDanger(result.message);
        }
      });
    } else if (this.feature.type === 'Folder') {
      this.folderService.updateFolder(item).subscribe((result) => {
        if (result.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess();
          this.dialogRef.close(result);
        } else {
          this.commonService.toastrDanger(result.message);
        }
      });
    } else {
      this.updateMap(item);
    }
  }
  updateMap(item) {
    let _body = new MapInsertOrUpdateModel();
    _body.name = item.name.trim();
    _body.id = item.id;
    _body.config = item.config;
    _body.status = item.status;
    _body.unitCode = item.unitcode;
    _body.shareFor = item.shareFor;
    const formData = new FormData();
    if (this.fileToUpload) {
      for (let i = 0; i < this.fileToUpload.length; i++) {
        formData.append('Files', this.fileToUpload[i]);
      }
    }
    formData.append('model', JSON.stringify(_body));
    const _headers: HttpHeaders = new HttpHeaders({
      //TODO: must contain boundary
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
      // "Accept": "application/json"
      'Accept': '*/*',
    });
    const req = new HttpRequest(
      'POST',
      `${environment.APP_GIS_URL}/App/Gis/api/Map/UpdateCustom`,
      formData,
      { headers: _headers }
    );

    this.httpClient.request<ApiResponse>(req).pipe().subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          let result = event.body;
          if (result.code === SUCCESS_RESPONE) {
            this.commonService.toastrSuccess();
            this.dialogRef.close(true);
          } else {
            this.commonService.toastrDanger(result.message);
          }
        }
      },
      (error) => {
        throw error;
      }
    );
  }
  checked(item) {
    const checkExist = this.lstShare.filter(x => x === item.id.toString());
    if (checkExist.length > 0) {
      this.lstShare = this.lstShare.filter(x => x !== item.id.toString());
    } else {
      this.lstShare.push(item.id.toString());
    }
  }
  setCheckedForToggle(item) {
    const check = this.lstShare.filter(x => x === item.id.toString());
    if (check.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
