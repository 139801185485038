import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { PAGE_SIZE, SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { DmLoaiBaiVietCreateComponent } from '../dm-loai-bai-viet-create/dm-loai-bai-viet-create.component';
import { DmLoaiBaiVietDeleteComponent } from '../dm-loai-bai-viet-delete/dm-loai-bai-viet-delete.component';
import { DmLoaiBaiVietDetailComponent } from '../dm-loai-bai-viet-detail/dm-loai-bai-viet-detail.component';
import { DmLoaiBaiVietUpdateComponent } from '../dm-loai-bai-viet-update/dm-loai-bai-viet-update.component';
import { DmLoaiBaiVietService } from '../dm-loai-bai-viet.service';
import { PageModel } from './../../../../@core/data/app-qtht.service';

@Component({
  selector: 'ngx-dm-loai-bai-viet-index',
  templateUrl: './dm-loai-bai-viet-index.component.html',
  styleUrls: ['./dm-loai-bai-viet-index.component.scss'],
})
export class DmLoaiBaiVietIndexComponent implements OnInit {
  constructor(private commonService: CommonService, private service: DmLoaiBaiVietService, private dialog: MatDialog) {}
  typeAttach = this.service.typeAttach();
  type: string;
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
  });
  ngOnInit(): void {
    this.loadData();
  }

  target: any = {
    data: [],
  };

  loadData() {
    this.service.paging(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  openCreate() {
    this.dialog
      .open(DmLoaiBaiVietCreateComponent, this.commonService.configDialog('30%', { lsttype: this.type }))
      .afterClosed()
      .subscribe((res) => {
        if (res) this.search();
      });
  }

  search() {
    this.searchModel.currentPage = 1;
    this.loadData();
  }

  updateData(id: number) {
    this.dialog
      .open(DmLoaiBaiVietUpdateComponent, this.commonService.configDialog('40%', { id: id }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }

  detailData(id: number) {
    this.dialog
      .open(DmLoaiBaiVietDetailComponent, this.commonService.configDialog('40%', { id: id }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  deleteData(id: number, ten: string) {
    this.dialog
      .open(DmLoaiBaiVietDeleteComponent, this.commonService.configDialog('30%', { id: id, ten: ten }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
}
