import { AppQthtApiService, FileParameter, FileResponse,  } from '../data/app-qtht.service';
import { TokenService } from './../token.service';
import { DatePipe } from '@angular/common';
import { Component, Injectable, Pipe, PipeTransform } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { HEIGHT_DIALOG, TITLE_NOTICE, WIDTH_DIALOG, SUCCESS_NOTICE, SUCCESS_RESPONE } from './constants';
import { Observable } from 'rxjs';
import { ResponseModelListApiResponse } from '../data/app-qtht.service';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private toastrService: NbToastrService,
    private appQthtApiService: AppQthtApiService,
    private spinner: NgxSpinnerService,
    private tokenService: TokenService
  ) { }

  getDuAnDauTu() {
    return this.tokenService.getProjectInvest();
  }

  getUserInfo() {
    return this.tokenService.getUserInfo();
  }

  uploadFile(folder: string, files: FileParameter[]): Observable<ResponseModelListApiResponse> {
    return this.appQthtApiService.appQthtApiFileUploadFile(folder, files);
  }

  downloadFile(fileId: number): void {
    this.appQthtApiService.appQthtApiFileDownloadFile(fileId).subscribe(response => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.download = b64DecodeUnicode(response.headers['x-file-name']);
      a.href = URL.createObjectURL(blob);
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
      if (response.status !== 200) {
        this.toastrDanger('Lỗi khi thực hiện tác vụ download.');
      }
    });

    function b64DecodeUnicode(str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(atob(str).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }
  }

  getFile(fileId: number): Observable<FileResponse> {
    return this.appQthtApiService.appQthtApiFileDownloadFile(fileId);
  }

  toastrSuccess(message?: string, title?: string) {
    this.toastrService.success(message ?? SUCCESS_NOTICE, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrPrimary(message: string, title?: string) {
    this.toastrService.primary(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrWarning(message: string, title?: string) {
    this.toastrService.warning(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrDanger(message: string, title?: string) {
    this.toastrService.danger(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrInfo(message: string, title?: string) {
    this.toastrService.info(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrShow(message: string, title?: string) {
    this.toastrService.show(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrDefault(message: string, title?: string) {
    this.toastrService.default(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  configDialog(
    widthDialog: string,
    data: any,
  ): MatDialogConfig {
    const DIALOG_CONFIG = new MatDialogConfig();
    // DIALOG_CONFIG.disableClose = true;
    DIALOG_CONFIG.width = widthDialog;
    DIALOG_CONFIG.height = 'auto';
    DIALOG_CONFIG.maxWidth = WIDTH_DIALOG;
    DIALOG_CONFIG.maxHeight = HEIGHT_DIALOG;
    DIALOG_CONFIG.data = data;
    return DIALOG_CONFIG;
  }
}

@Component({
  template: ''
})
// tslint:disable-next-line: component-class-suffix
export class VitualScrollNgSelect {
  numberOfItemsFromEndBeforeFetchingMore = 10;
  loading = false;
  vitualData = [];
  lstVitualScroll = [];
  bufferSize = 10;

  constructor() { }

  setDataForVitualScroll(lst: any[]) {
    this.vitualData = lst;
    this.lstVitualScroll = this.vitualData.slice(0, this.bufferSize);
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll({ end }) {
    if (this.loading || this.vitualData.length <= this.lstVitualScroll.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.lstVitualScroll.length) {
      this.fetchMore();
    }
  }
  private fetchMore() {
    const len = this.lstVitualScroll.length;
    const more = this.vitualData.slice(len, this.bufferSize + len);
    this.loading = true;
    // using timeout here to simulate backend API delay
    setTimeout(() => {
      this.loading = false;
      this.lstVitualScroll = this.lstVitualScroll.concat(more);
    }, 200);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor() { }
  filter(array, string) {
    function convertDate(params: string, str: string) {
      if ((params !== null || params !== undefined) && params.length === 8) {
        try {
          const year = params.substr(0, 4);
          const month = params.substr(4, 2);
          const day = params.substr(6, 2);
          if (`${day}/${month}/${year}`.includes(str)) {
            return true;
          }
        } catch (error) {
          return false;
        }
      }
      return false;
    }
    function checkData(params: string, str: string) {
      return params.toLowerCase().includes(str.toLowerCase())
        || convertDate(params, str) ? true : false;
    }
    if ((array !== null || array !== undefined) && (string !== null || string !== undefined) && string !== '') {
      return array.filter(o =>
        Object.keys(o).some(
          k => typeof (o[k]) === 'string'
            ? checkData(o[k], string)
            : typeof (o[k]) === 'number'
              ? o[k].toString().includes(string.toLowerCase())
              : false
        ));
    }
    return array;
  }

  searchItem(string: string, obj: any) {
    function convertDate(params: string, str: string) {
      if ((params !== null || params !== undefined) && params.length === 8) {
        try {
          const year = params.substr(0, 4);
          const month = params.substr(4, 2);
          const day = params.substr(6, 2);
          if (`${day}/${month}/${year}`.includes(str)) {
            return true;
          }
        } catch (error) {
          return false;
        }
      }
      return false;
    }

    function checkData(params: string, str: string) {
      return params.toLowerCase().includes(str.toLowerCase())
        || convertDate(params, str) ? true : false;
    }

    return Object.keys(obj).some(
      k => typeof (obj[k]) === 'string'
        ? checkData(obj[k], string)
        : typeof (obj[k]) === 'number'
          ? obj[k].toString().includes(string.toLowerCase())
          : false
    );
  }
}

@Pipe({
  name: 'pipeDate',
})
export class ConvertDatePipe implements PipeTransform {
  //#region CONVERT_DATE

  transform(value: any) {
    const datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'yyyy-MM-dd');
    return value;
  }
  transformCustom(value: any) {
    const datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'dd/MM/yyyy');
    return value;
  }
  convertDateToString(target: Date): string {
    if (target) {
      return this.transform(target.toString());
    }
    return null;
  }
  convertStringToDateString(target: string): string {
    if (target) {
      if (target.length === 8) {
        const year = target.substr(0, 4);
        const month = target.substr(4, 2);
        const day = target.substr(6, 2);
        return day + '/' + month + '/' + year;
      }
      return target;
    }
  }
  convertStringToDate(target: string): Date {
    if (target && target.length === 8) {
      const year = +target.substr(0, 4);
      const month = +target.substr(4, 2);
      const day = +target.substr(6, 2);
      return new Date(year, month, day);
    }
    return null;
  }

  convertStringVnToDate(target: string): Date {
    if (target && target.length === 10) {
      const year = + target.substr(6, 4);
      const month = + target.substr(3, 2);
      const day = + target.substr(0, 2);
      return new Date(year, month, day);
    }
    return null;
  }

  convertStringToFormatInput(target: string): string {
    if (target && target.length === 8) {
      const year = target.substr(0, 4);
      const month = target.substr(4, 2);
      const day = target.substr(6, 2);
      return year + '-' + month + '-' + day;
    }
    return target;
  }

  convertStringddMMyyyyToFormatTextVN(target: string) {
    if (target && target.length === 8) {
      const year = target.substr(0, 4);
      const month = target.substr(4, 2);
      const day = target.substr(6, 2);
      return 'Ngày ' + day + ' tháng ' + month + ' năm ' + year;
    }
    return target;
  }

  convertStringDisplayToDateString(target: string) {
    if (target && target.length === 10) {
      const year = target.substr(0, 4);
      const month = target.substr(5, 2);
      const day = target.substr(8, 2);
      return year + month + day;
    }
    return target;
  }
  //#endregion
}
