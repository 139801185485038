<form #frm="ngForm" (ngSubmit)="update(frm)">
    <nb-card>
        <nb-card-header class="nb-card-header">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    Chỉnh sửa trường dữ liệu
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
          <div class="row">
              <div class="col-lg-12">
                  <div class="form-group">
                      <label class="col-form-label required">Tên trường dữ liệu</label>
                      <input type="text" class="form-control" name="fieldname" [(ngModel)]="target.fieldname" disabled>
                  </div>
              </div>
              <div class="col-lg-12">
                  <div class="form-group">
                      <label class="col-form-label required">Nhãn hiển thị</label>
                      <input type="text" class="form-control" name="name" [(ngModel)]="target.name" required maxlength="250">
                  </div>
              </div>
              <div class="col-lg-12">
                  <div class="form-group">
                      <label class="col-form-label required">Thứ tự hiển thị</label>
                      <input type="number"
                      placeholder="Thứ tự hiển thị, giá trị 0 nếu không hiển thị"
                       class="form-control" name="show" [(ngModel)]="target.show" required maxlength="250">
                  </div>
              </div>
          </div>
      </nb-card-body>
        <nb-card-footer class="text-right">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
                    <button type="submit" class="btn btn-primary" [disabled]="frm.invalid">Lưu</button>
                    &nbsp;
                    <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
                </div>
            </div>
        </nb-card-footer>
    </nb-card>
</form>
