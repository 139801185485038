import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FilesManagerIndexComponent } from './files-manager-index/files-manager-index.component';
export const routes: Routes = [
  {
    path: '',
    children: [{ path: '', component: FilesManagerIndexComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FilesManagerRoutingModule {}
