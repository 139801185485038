import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { ApiResponse, CreateOrUpdateDataModel, DataUpdateModel, ExportDataModel, FileParameter } from '../../../../@core/data/app-gis.service';
import { DataService } from '../../services/data.service';
import { FieldService } from '../field.service';
@Component({
  selector: 'ngx-featureclass-createdata',
  templateUrl: './featureclass-createdata.component.html',
  styleUrls: ['./featureclass-createdata.component.scss'],
})
export class FeatureclassCreatedataComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private commonService: CommonService,
    private fieldService: FieldService,
    @Inject(MAT_DIALOG_DATA) private tableName: any,
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(MAT_DIALOG_DATA) private dataName: any,
    @Inject(MAT_DIALOG_DATA) private DVHCCX: any,
    @Inject(MAT_DIALOG_DATA) private DVHCCH: any,
    public dialogRef: MatDialogRef<FeatureclassCreatedataComponent>,
    private readonly httpClient: HttpClient

  ) { }
  fields: any[] = [];
  fieldsName: any[] = [];
  target: any[] = [];
  textSearch: string;
  lstId: any[] = [];
  lstObject: any[] = [];
  lstAll: any[] = [];
  fileToUpload: FileList | null = null;
  MDVHCCX: string;
  MDVHCCH: string;
  MDVHCT: string;
  lstDVHCCH: any[] = [];
  lstDVHCCX: any[] = [];
  gender: string;
  options: any[] = [];
  selectedFiles: FileParameter[] = [];
  lstFileIdDelete: any[] = [];
  handleFileInput(files: FileList) {
    this.fileToUpload = files;
  }

  ngOnInit(): void {
    this.fields = this.data.data;
    this.fieldsName = this.dataName.dataName;
    this.getField();
    this.options = [
      {
        name: 'Nam',
        value: 1
      },
      {
        name: 'Nữ',
        value: 0
      },
      {
        name: 'khác',
        value: 2
      }
    ];
    this.loadDVHC();
    this.MDVHCT = 'Tỉnh Cao Bằng'
  }
  getField() {
    this.fieldService.GetFieldsForInsertOrUpdate(this.tableName.tablename).subscribe((result) => {

      if (result.code === SUCCESS_RESPONE) {
        this.lstAll = [];
        const fields = result.data;
        this.fields = fields.map(function (e) {
          return e.name;
        });
        this.fieldsName = fields.map(function (e) {
          return e.fieldname;
        });
        for (let i = 0; i < this.fieldsName.length; i++) {
          const x = this.fieldsName[i];
          const y = this.fields[i];
          this.lstAll.push({ x, y });
        }
      }
    });
  }
  loadDVHC() {
    this.lstDVHCCH = this.DVHCCH.DVHCCH;
  }
  ChangeHuyen() {
    this.lstDVHCCX = this.DVHCCX.DVHCCX.filter(x => x.macha === this.MDVHCCH);
  }
  deleteFile(file: any) {
    if (this.selectedFiles.indexOf(file) !== -1) {
      this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
    }
    if (file.data.id !== undefined) {
      this.lstFileIdDelete.push(file.data.id);
    }
  }

  chooseFile(files: FileParameter[]) {
    if (files.length === 0) {
      return;
    }
    this.selectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      const fileParameter: FileParameter = {
        data: files[i],
        fileName: files[i]["name"],
      };
      this.selectedFiles.push(fileParameter);
    }
  }

  create() {
    const lst: Array<DataUpdateModel> = [];

    this.fieldsName.forEach((element) => {
      if (element !== 'id' && element !== 'shape' && element.toLowerCase() !== 'createddate' && element.toLowerCase() !== 'status'
        && element.toLowerCase() !== 'maxa' && element.toLowerCase() !== 'mahuyen' && element.toLowerCase() !== 'matinh' && element.toLowerCase() !== 'gioitinh') {
        const value = (<HTMLInputElement>document.getElementById(element + '-create')).value;
        const model = new DataUpdateModel();
        model.key = element;
        model.value = value;
        lst.push(model);
      }
    });
    if (this.MDVHCCX) {
      const model = new DataUpdateModel();
      model.key = 'maxa';
      model.value = this.MDVHCCX;
      lst.push(model);
    }
    if (this.MDVHCCH) {
      const model = new DataUpdateModel();
      model.key = 'mahuyen';
      model.value = this.MDVHCCH;
      lst.push(model);
    }
    if (this.MDVHCT) {
      const model = new DataUpdateModel();
      model.key = 'matinh';
      model.value = '04';
      lst.push(model);
    }
    if (this.gender !== null && this.gender !== undefined) {
      const model = new DataUpdateModel();
      model.key = 'GIOITINH';
      model.value = this.gender;
      lst.push(model);
    }
    let _body = new CreateOrUpdateDataModel();
    _body.listData = lst;
    _body.tableName = this.tableName.tablename;
    this.dataService.createCustom(_body).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {

        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
        if (this.selectedFiles.length > 0) {
          this.dataService.uploadFile(this.selectedFiles, res.data, this.tableName.tablename, this.tableName.tablename, 'image').subscribe(res1 => {

          });
        }
      } else {
        this.commonService.toastrDanger(res.message);
      }
    })

  }
}
