import { DUPLICATE_RESPONE } from '../../../../@core/customs/constants';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { DatasetService } from '../dataset.service';
import { SysdatasetEntity } from '../../../../@core/data/app-gis.service';
import { TokenService } from '../../../../@core/token.service';

@Component({
  selector: 'ngx-dataset-create',
  templateUrl: './dataset-create.component.html',
  styleUrls: ['./dataset-create.component.scss'],
})
export class DatasetCreateComponent implements OnInit {
  constructor(
    private datasetService: DatasetService,
    private commonService: CommonService,
    
    @Inject(MAT_DIALOG_DATA) private testData: { parentId: number,checkUnit: boolean },
    public dialogRef: MatDialogRef<DatasetCreateComponent>
  ) {}

  target: SysdatasetEntity = new SysdatasetEntity();
  lstUnit: any[] = [];
  checkUnit: boolean;
  ngOnInit(): void {
    this.getUnit();
    this.checkUnit = this.testData.checkUnit;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  getUnit(){
    this.datasetService.getAllUnit().subscribe(res => {
        this.lstUnit = res.data;
    });
  }
  create(frm: NgForm) {
    this.target.name = this.target.name.trim();
    this.target.parentid = this.testData.parentId;
    this.datasetService.create(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
