<nb-card>
    <nb-card-header class="nb-card-header">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12" style="text-transform: none;">
                Xác nhận
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                <div class="form-group">
                    <!-- <label class="control-label">Cài đặt bình luận</label> -->
                    <div class="input-group">
                        <input type="radio" style="margin-top: auto;margin-bottom: auto; width: 20px; height: 20px;" value="1" [(ngModel)]="target.tientrinh" id="tientrinh1" name="tientrinh">&nbsp;
                        <label for="tientrinh1" style="margin: 10px 10px 10px 0px;">Xuất bản ngay</label>
                        <br/>
                        <input type="radio" style="margin-top: auto;margin-bottom: auto; width: 20px; height: 20px;" value="0" [(ngModel)]="target.tientrinh" id="tientrinh2" name="tientrinh">&nbsp;
                        <label for="tientrinh2" style="margin: 10px 10px 10px 0px;">Hẹn giờ xuất bản</label>
                        <input type="datetime-local" class="form-control" [disabled]="target.tientrinh === 1" [(ngModel)]="target.timedangtai" name="timedangtai">
                    </div>
                </div>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer class="text-end">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
                <ngx-btn-delete (click)="update()"></ngx-btn-delete>&nbsp;
                <ngx-btn-close mat-dialog-close></ngx-btn-close>
            </div>
        </div>
    </nb-card-footer>
</nb-card>