import {
  DmLienketEntity,
  TintucSukienEntityProcessUpdateFileModel,
} from './../../../../@core/data/app-home.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CommonService,
  ConvertDatePipe,
} from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmQuangcaoService } from '../dm-quangcao.service';

@Component({
  selector: 'ngx-dm-quangcao-update',
  templateUrl: './dm-quangcao-update.component.html',
  styleUrls: ['./dm-quangcao-update.component.scss'],
})
export class DmQuangcaoUpdateComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: DmQuangcaoService,
    private dialogRef: MatDialogRef<DmQuangcaoUpdateComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { id: number; type: number; isEdit: boolean; lsttype: string }
  ) {}
  isEdit: boolean;
  userName: string;
  listType: string;
  title: string;
  target: DmLienketEntity = new DmLienketEntity();
  ngOnInit(): void {
    this.isEdit = this.data.isEdit;
    this.loadData();
    this.commonService.getUserInfo().subscribe((res1) => {
      this.userName = res1.UserName;
    });
    if (this.data.isEdit) {
      this.title = 'CHỈNH SỬA ';
    } else {
      this.title = 'CHI TIẾT ';
    }
  }
  loadData() {
    this.service.SelectOne(this.data.id).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
  Update() {
    this.service.update(this.target).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess('Cập nhật dữ liệu thành công.');
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(res.message);
        this.dialogRef.close(false);
      }
    });
  }
}
