<nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          Danh sách cấu hình
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="text-center" style="width: 50px;">STT</th>
                <th>Mã cấu hình</th>
                <th>Tên cấu hình</th>
                <th>Cấu hình</th>
                <th class="text-center" style="width: 115px;">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of target.data; let i = index">
                <td class="text-center">
                  {{ i + 1 }}
                </td>
                <td>{{ item.code }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.config }}</td>
                <td class="text-center">
                  <ngx-icon-edit (click)="editItem(item.id)"></ngx-icon-edit>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </nb-card-body>
  </nb-card>
  