import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import {
  PAGE_SIZE,
  SUCCESS_RESPONE,
} from './../../../../@core/customs/constants';
import { Component, OnInit } from '@angular/core';
// import { TintucSukienCreateComponent } from '../tintuc-sukien-create/tintuc-sukien-create.component';
// import { dmQuangcaoUpdateComponent } from '../tintuc-sukien-update/tintuc-sukien-update.component';
// import { TintucSukienDeleteComponent } from '../tintuc-sukien-delete/tintuc-sukien-delete.component';
import { ActivatedRoute } from '@angular/router';
import { DmQuangcaoService } from '../dm-quangcao.service';
import { DmQuangcaoCreateComponent } from '../dm-quangcao-create/dm-quangcao-create.component';
import { DmQuangcaoUpdateComponent } from '../dm-quangcao-update/dm-quangcao-update.component';
import { DmQuangcaoDeleteComponent } from '../dm-quangcao-delete/dm-quangcao-delete.component';
import { PageModel } from 'app/@core/data/app-qtht.service';
import { PageModelLienKet } from 'app/@core/data/app-home.service';

@Component({
  selector: 'ngx-dm-quangcao-index',
  templateUrl: './dm-quangcao-index.component.html',
  styleUrls: ['./dm-quangcao-index.component.scss'],
})
export class DmQuangcaoIndexComponent implements OnInit {
  type: any;

  constructor(
    private commonService: CommonService,
    private service: DmQuangcaoService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  searchModel: PageModelLienKet = new PageModelLienKet({
    search: null,
    currentPage: 1,
    pageSize: 10,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
    loai: 2,
  });

  target: any = {
    data: [],
  };

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.service.pagingLienket(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  openCreate() {
    this.dialog
      .open(
        DmQuangcaoCreateComponent,
        this.commonService.configDialog('60%', { lsttype: 1 })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) this.loadData();
      });
  }

  updateData(id: number, isEdit: boolean) {
    this.dialog
      .open(
        DmQuangcaoUpdateComponent,
        this.commonService.configDialog('60%', {
          id: id,
          isEdit: isEdit,
          lsttype: 1,
        })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) this.loadData();
      });
  }

  deleteData(id: number, title: string) {
    this.dialog
      .open(
        DmQuangcaoDeleteComponent,
        this.commonService.configDialog('30%', { id: id, title: title })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) this.loadData();
      });
  }
}
