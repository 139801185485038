import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE, PAGE_SIZE, SUCCESS_NOTICE } from '../../../../@core/customs/constants';
import { MapAdminService } from '../mapadmin.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MapAdminUpdateComponent } from '../mapadmin-update/mapadmin-update.component';
import { MapAdminCreateComponent } from '../mapadmin-create/mapadmin-create.component';
import { MapAdminPermissionComponent } from '../mapadmin-permission/mapadmin-permission.component';
import { PageModel } from '../../../../@core/data/app-gis.service';
import { TokenService } from '../../../../@core/token.service';
import { DialogConfirmComponent } from '../../../../@theme/components/template/dialog-confirm/dialog-confirm.component';
import { ShareComponent } from '../../featureclass/Share-component/share.component';

@Component({
  selector: 'ngx-mapadmin-index',
  templateUrl: './mapadmin-index.component.html',
  styleUrls: ['./mapadmin-index.component.scss'],
})
export class MapAdminIndexComponent implements OnInit {
  constructor(
    private mapadminService: MapAdminService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private tokenService: TokenService
  ) {}

  pageModel: PageModel = new PageModel({
    pageSize: PAGE_SIZE,
    condition: null,
    currentPage: 1,
    search: null,
  });

  target: any = [];

  checkUnit: boolean;
  checkPhongBan: boolean;
  ngOnInit(): void {
    this.tokenService.getUserInfo().subscribe((users: any) => {
      if (users.UnitCode === '9999') {
        this.checkUnit = true;
        this.checkPhongBan = false;
      } else {
        this.checkPhongBan = users.PhongBan ? true : false;
      }
    });
    this.loadData();
  }
  share(item) {
    this.dialog
      .open(ShareComponent, this.commonService.configDialog('40%', { item : item, type : 'Map' }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  loadData() {
    this.mapadminService.getAll().subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
  openCreateDialog(frm) {
    this.dialog
      .open(MapAdminCreateComponent, this.commonService.configDialog('80%', {checkUnit: this.checkUnit}))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  editItem(id: number) {
    this.dialog
      .open(MapAdminUpdateComponent, this.commonService.configDialog('100%', { id: id ,checkUnit: this.checkUnit}))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  deleteItem(id: number, map: string) {
    this.dialog.open(DialogConfirmComponent, this.commonService.configDialog('30%', {
      title: 'Xác nhận',
      message: `Bạn có chắc chắn xóa <br><b>${map}</b> ?`,
      color: 'text-warning',
      type: 1,
      icon: 'exclamation-triangle',
      isAppend: true
    })).afterClosed().subscribe(info => {
      if (info) {
        this.mapadminService.delete(id).subscribe(res => {
          if (res.code === SUCCESS_RESPONE) {
            this.commonService.toastrSuccess(SUCCESS_NOTICE);
            this.loadData();
          } else {
            this.commonService.toastrDanger(res.message);
          }
        });
      }
    });
  }
  shareItem(id: number) {
    this.dialog
      .open(MapAdminPermissionComponent, this.commonService.configDialog('100%', { id: id }))
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  getConfig(config) {
    return config.substr(0, 200) + '...';
  }
}
