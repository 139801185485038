import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FeatureclassService } from '../featureclass.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { SysfeatureclassEntity } from '../../../../@core/data/app-gis.service';
import { DatasetService } from '../../dataset/dataset.service';
import { TokenService } from '../../../../@core/token.service';

@Component({
  selector: 'ngx-featureclass-update',
  templateUrl: './featureclass-update.component.html',
  styleUrls: ['./featureclass-update.component.scss'],
})
export class FeatureclassUpdateComponent implements OnInit {
  constructor(
    private featureclassService: FeatureclassService,
    private commonService: CommonService,
    private datasetService: DatasetService,
    private tokenService: TokenService,
    @Inject(MAT_DIALOG_DATA) private FeatureclassItem: { id: number,checkUnit:boolean },
    public dialogRef: MatDialogRef<FeatureclassUpdateComponent>
  ) {}

  target: SysfeatureclassEntity = new SysfeatureclassEntity();
  datasets = [];
  lstUnit: any[] = [];
  checkUnit: boolean = false;
  selectedItem: any;
  parentId: any;
  ngOnInit(): void {
    this.checkUnit = this.FeatureclassItem.checkUnit;
    this.getUnit();
    this.loadData();
  }
  getUnit(){
    this.datasetService.getAllUnit().subscribe(res => {
        this.lstUnit = res.data;
    });
  }
  
  dataSetTree(data, parentid) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      const obj = {
        name: data[i].name,
        id: data[i].id,
      };
      if (data[i].parentid === parentid) {
        result.push({
          name: data[i].name,
          id: data[i].id,
          items: this.dataSetTree(data, data[i].id),
        });
      }
      if (data[i].id === this.parentId) this.selectedItem = obj;
    }
    return result;
  }
  loadData() {
    this.featureclassService.selectOne(this.FeatureclassItem.id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.parentId = result.data['datasetId'];
        this.target = result.data;
          this.datasetService.getDataset().subscribe((ds) => {
            if (ds.code === SUCCESS_RESPONE) {
              this.datasets = this.dataSetTree(ds.data, null);
            }
          });
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  update(frmFeature: NgForm) {
    this.target.fields = null;
    this.target['datasetId'] = this.selectedItem.id;
    this.featureclassService.update(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
