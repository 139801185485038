import {
  MasterModelIEnumerablePageModelViewApiResponse,
  TintucSukienEntity,
} from './../../../@core/data/app-home.service';
import { Injectable } from '@angular/core';
import {
  AppHomeApiService,
  EntityWithFileModelApiResponse,
  TintucSukienEntityProcessInsertFileModel,
  TintucSukienEntityProcessUpdateFileModel,
  VmDanhmucApiResponse,
} from 'app/@core/data/app-home.service';
import { ApiResponse, ObjectIEnumerableApiResponse, PageModel } from 'app/@core/data/app-qtht.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TintucSukienService {
  constructor(private api: AppHomeApiService) {}

  typeAttach(): number {
    return 1;
  }

  paging(body: PageModel | undefined): Observable<MasterModelIEnumerablePageModelViewApiResponse> {
    return this.api.appHomeApiTintucSukienPagingCustom(body);
  }

  selectAll(): Observable<ObjectIEnumerableApiResponse> {
    return this.api.appHomeApiTintucSukienSelectAll();
  }

  selectOneWithFile(type: number, id: number): Observable<EntityWithFileModelApiResponse> {
    return this.api.appHomeApiTintucSukienSelectOneWithFile(type, id);
  }

  insertWithFile(body: TintucSukienEntityProcessInsertFileModel | undefined): Observable<ApiResponse> {
    return this.api.appHomeApiTintucSukienInsertWithFile(body);
  }

  updateWithFile(body: TintucSukienEntityProcessUpdateFileModel | undefined): Observable<ApiResponse> {
    return this.api.appHomeApiTintucSukienUpdateWithFile(body);
  }

  updateStatus(body: TintucSukienEntity | undefined): Observable<ApiResponse> {
    return this.api.appHomeApiTintucSukienUpdate(body);
  }

  deleteWithFile(id: number): Observable<ApiResponse> {
    return this.api.appHomeApiTintucSukienDeleteWithFile(id);
  }

  getDm(): Observable<VmDanhmucApiResponse> {
    return this.api.appHomeApiTintucSukienGetdm();
  }
  getDmLBV(): Observable<ObjectIEnumerableApiResponse> {
    return this.api.appAdminApiDmloaibaivietSelectAll();
  }
}
