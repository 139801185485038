import { Injectable } from '@angular/core';
import {
  AppHomeApiService,
  DmTientrinhEntity,
  DmTientrinhEntityApiResponse,
  MasterModelIEnumerablePageModelViewApiResponse,
} from 'app/@core/data/app-home.service';
import { ApiResponse, PageModel } from 'app/@core/data/app-qtht.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DmTienTrinhService {
  constructor(private api: AppHomeApiService) {}

  paging(
    body: PageModel | undefined
  ): Observable<MasterModelIEnumerablePageModelViewApiResponse> {
    return this.api.appAdminApiDmTientrinhPaging(body);
  }

  insert(entity: DmTientrinhEntity | undefined): Observable<ApiResponse> {
    return this.api.appAdminApiDmTientrinhInsert(entity);
  }

  selectOne(id: number): Observable<DmTientrinhEntityApiResponse> {
    return this.api.appAdminApiDmTientrinhSelectOne(id);
  }

  update(entity: DmTientrinhEntity): Observable<ApiResponse> {
    return this.api.appAdminApiDmTientrinhUpdate(entity);
  }

  delete(id: number): Observable<ApiResponse> {
    return this.api.appAdminApiDmTientrinhDelete(id);
  }
}
