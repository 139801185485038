<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <h5 class="mb-0">Danh sách tệp</h5>
            </div>
          </div>
          <div class="row mb-3">
                <label class="col-md-3">Lọc theo thư mục:</label>
                <div class="col-md-9">
                  <kendo-dropdowntree
                  placeholder="Chọn thư mục" 
                    kendoDropDownTreeExpandable
                    [kendoDropDownTreeHierarchyBinding]="folder"
                    textField="name"
                    valueField="id"
                    childrenField="items"
                    class="furniture"
                    (valueChange)="filterByFolder($event)"
                  >
                </kendo-dropdowntree>
              </div>
          </div>
          <div class="row">
            
            <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
              <!-- <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Tìm kiếm tên tệp"
                  aria-label="Tìm kiếm tên tệp"
                  aria-describedby="basic-addon2"
                  [(ngModel)]="pageModel.search"
                />
                <div class="input-group-append">
                  <button class="btn btn-info" type="button" (click)="searchPage()">
                    <i class="fas fa-search fa-sm"></i>&nbsp;Tìm kiếm
                  </button>
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    (click)="resetPage()"
                  >
                    <i class="fas fa-sync-alt fa-sm"></i>&nbsp;Làm mới
                  </button>
                </div>
              </div> -->
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th class="text-center" style="width: 50px;">STT</th>
                    <th>Tên tệp</th>
                    <th>Kích thước</th>
                    <!-- <th>Thời gian</th> -->
                    <th class="text-center" style="width: 115px;">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of target.data; let i = index">
                    <td class="text-center">
                      {{ i +1 }}
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ byteToSize(item.length) }}</td>
                    <!-- <td>{{ item.createdate }}</td> -->
                    <td class="text-center">
                      <button title="Chọn tệp dữ liệu" 
                      class="btn btn-sm" (click)="selectFile(item.id, item.name)"><i class="fa fa-check"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="target?.recordsCount > 0" class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                    <div style="float: left;">
                        Tổng số bản ghi: {{target?.recordsCount}}
                    </div>
                    <div style="float: right;">
                        <ngx-pagination (pageChange)="pageModel.currentPage = $event;loadFiles(this.parentId)" (pageSize)="pageModel.pageSize = $event;loadFiles(this.parentId)" [currentPage]="pageModel.currentPage" [totalPages]="target.totalPage">
                        </ngx-pagination>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>