import { PAGE_SIZE, SUCCESS_NOTICE, SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { FieldService } from '../field.service';
import { FeatureclassUpdateDataComponent } from '../featureclass-updatedata/featureclass-updatedata.component';
import { FeatureclassCreatedataComponent } from '../featureclass-createdata/featureclass-createdata.component';
import { DataSearchModel, ExportDataModel } from '../../../../@core/data/app-gis.service';
import { FeatureclassViewDetailDataComponent } from '../featureclass-viewdetail-data/featureclass-viewdetail-data.component';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { DialogConfirmComponent } from '../../../../@theme/components/template/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'ngx-featureclass-viewdata',
  templateUrl: './featureclass-viewdata.component.html',
  styleUrls: ['./featureclass-viewdata.component.scss'],
})
export class FeatureclassViewDataComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private fieldService: FieldService,
    private commonService: CommonService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private feature: { id: number; tablename: string, name: string },
    public dialogRef: MatDialogRef<FeatureclassViewDataComponent>,
    private readonly httpClient: HttpClient,
  ) { }
  fields: any[] = [];
  fieldsName: any[] = [];
  target: any[] = [];
  textSearch: string;
  lstObject: any[] = [];
  options: any[] = [];
  status: any;
  lst: any[] = [];
  currentPage = 1;
  totalPage: number;
  lstDVHC: any[] = [];
  featureclassName: string;
  targetNew: any = {
    data: [],
  };
  lstDVHCCH: any[] = [];
  lstDVHCCX: any[] = [];
  SearchModel: DataSearchModel = new DataSearchModel({
    tableName: null,
    text: null,
    page: 1,
    limit: PAGE_SIZE,
    status: null,
    fieldSelect: null,
    dvhcch: null,
    dvhccx: null,
  });
  field: string;
  stt: any;
  ngOnInit(): void {
    this.options = [
      {
        name: 'Sử dụng',
        value: 1
      },
      {
        name: 'Không sử dụng',
        value: 0
      }
    ];
    this.featureclassName = this.feature.name;
    this.loadDataNew();
    this.fieldService.getAllByTableName(this.feature.tablename).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.fields = result.data;
      }
    });
    this.loadDVHC();

  }
  lstDVHCCXFill: any[] = [];
  maHuyen: any;
  maXa: any;
  changeHuyenbyXa() {
    this.SearchModel.dvhcch = this.maHuyen;
    this.SearchModel.dvhccx = this.maXa;
    this.dataService.getDataByTableName('dvhccx').subscribe((result) => {
      this.lstDVHCCXFill = JSON.parse(result.data);
      this.lstDVHCCXFill = this.lstDVHCCXFill.filter((x) => x.macha === this.maHuyen)
    });
    this.loadDataNew();
  }

  loadHuyenXa() {
    this.SearchModel.dvhcch = this.maHuyen;
    this.SearchModel.dvhccx = this.maXa;
    this.loadDataNew();
  }

  loadDVHC() {
    this.dataService.getDataByTableName('dvhccx').subscribe((result) => {
      this.lstDVHCCX = JSON.parse(result.data);
    });
    this.dataService.getDataByTableName('dvhcch').subscribe((res) => {
      this.lstDVHCCH = JSON.parse(res.data);
    })
  }
  getTenDVX(unit) {
    const name = this.lstDVHCCX.filter(x => x.madonvi === unit);
    return name.length > 0 ? name[0].ten : unit
  }
  getTenDVH(unit) {
    const name = this.lstDVHCCH.filter(x => x.madonvi === unit);
    return name.length > 0 ? name[0].ten : unit
  }
  changeStatus(item: any) {
    const tableName = this.feature.tablename;
    const id = this.target[item].id;
    const status = this.target[item].status;
    this.dataService.ChangeStatus(tableName, status, id).subscribe(res => {
      if (res.code === SUCCESS_RESPONE) {
        this.exportGeoJson();
        this.loadDataNew();
      }
    });
  }
  setCheckedForToggle(status: number) {
    if (status === 1) {
      return true;
    } else {
      return false;
    }
  }
  exportGeoJson() {
    const ex = new ExportDataModel();
    ex.featureclassId = this.feature.id;
    ex.tableName = this.feature.tablename;
    ex.fileType = 'json';
    ex.prj = '105453';
    ex.fields = [];
    ex.filter = "status = 1";
    const req = new HttpRequest(
      'POST',
      `${environment.APP_GIS_URL}/App/Gis/api/Data/export`,
      ex,
      { responseType: 'blob' }
    );
    this.httpClient.request<Blob>(req).pipe().subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          let result = event.body;
        }
      },
      (error) => {
        throw error;
      }
    );
  }
  getTen(value: number) {
    let name: string;
    if (value === 0) name = 'Nữ';
    else if (value === 1) name = 'Nam';
    else name = 'Khác'
    return name;
  }
  sortByStatus() {
    this.SearchModel.text = null;
    this.SearchModel.status = this.status;
    this.SearchModel.page = 1;
    this.loadDataNew();
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  onSearchTableAttribute() {
    this.SearchModel.dvhcch = this.maHuyen;
    this.SearchModel.dvhccx = this.maXa;
    this.SearchModel.fieldSelect = this.field;
    this.SearchModel.page = 1;
    this.SearchModel.text = this.textSearch;
    this.loadDataNew();
  }
  refresh() {
    this.maHuyen = null;
    this.maXa = null;
    this.SearchModel.dvhcch = null;
    this.SearchModel.dvhccx = null;
    this.textSearch = '';
    this.SearchModel.page = 1;
    this.loadDataNew();
  }

  loadDataNew() {
    this.SearchModel.tableName = this.feature.tablename;
    this.dataService.PaingData(this.SearchModel).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.targetNew = result;
        this.target = JSON.parse(this.targetNew.data.data);
        this.stt = (this.SearchModel.page - 1) * this.SearchModel.limit;
      } else {
        this.commonService.toastrDanger(result.message);
      }

    });

  }
  editFeature(index) {
    const data = this.target[index];
    this.dialog
      .open(
        FeatureclassUpdateDataComponent,
        this.commonService.configDialog('60%', { data: data, tablename: this.feature.tablename, DVHCCH: this.lstDVHCCH, DVHCCX: this.lstDVHCCX })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadDataNew();
        }
      });
  }
  viewFeature(index) {
    const data = this.target[index];
    this.dialog
      .open(
        FeatureclassViewDetailDataComponent,
        this.commonService.configDialog('60%', { data: data, tablename: this.feature.tablename })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadDataNew();
        }
      });
  }
  openCreate() {
    this.dialog
      .open(
        FeatureclassCreatedataComponent,
        this.commonService.configDialog('60%', {
          data: this.fields,
          dataName: this.fieldsName,
          tablename: this.feature.tablename,
          DVHCCH: this.lstDVHCCH, DVHCCX: this.lstDVHCCX
        })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadDataNew();
        }
      });
  }

  deleteFeature(index) {
    const id = this.target[index].id;
    this.dialog.open(DialogConfirmComponent, this.commonService.configDialog('30%', {
      title: 'Xác nhận',
      message: `Bạn có chắc chắn xóa ?`,
      color: 'text-warning',
      type: 1,
      icon: 'exclamation-triangle',
      isAppend: true
    })).afterClosed().subscribe(info => {
      if (info) {
        this.dataService.deleteData(this.feature.tablename, id).subscribe(res => {
          if (res.code === SUCCESS_RESPONE) {
            this.commonService.toastrSuccess(SUCCESS_NOTICE);
            this.loadDataNew();
            this.exportGeoJson();
          } else {
            this.commonService.toastrDanger(res.message);
          }
        });
      }
    });
  }

}
