import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../../@theme/theme.module';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NbTreeGridModule } from '@nebular/theme';
import { DatasetDetailComponent } from './dataset-detail/dataset-detail.component';
import { DatasetUpdateComponent } from './dataset-update/dataset-update.component';
import { DatasetIndexComponent } from './dataset-index/dataset-index.component';
import { DatasetCreateComponent } from './dataset-create/dataset-create.component';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DataSetExportComponent } from './dataset-export/dataset-export.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ThemeModule,
        MatSelectModule,
        MatSlideToggleModule,
        NbTreeGridModule,
        TreeListModule,
        ButtonsModule,
        DropDownsModule,
    ],
    declarations: [DatasetDetailComponent, DatasetUpdateComponent, DatasetIndexComponent, DatasetCreateComponent, DataSetExportComponent]
})
export class DatasetModule { }
