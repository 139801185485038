<form>
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 d-flex">
          <div class="input-group">
            <label style="flex: auto">Xem chi tiết dữ liệu</label>
            <!-- <button class="btn btn-primary" type="button" (click)="update()">
              <i class="fas fa-plus fa-sm"></i>&nbsp;Cập nhật
            </button> -->
            <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
          </div>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row" >
        <div class="col-lg-12">
          <div style="padding-top: 8px;">
            <span *ngIf="lstFile.length > 0" class="files">
              <span class="file-x" *ngFor="let item of lstFile">
                &nbsp;Danh sách file :  {{item.filename}}&nbsp;
              </span>
            </span>
          </div>
          
        </div>
        <div class="col-lg-6" *ngFor="let t of fields">
          <div class="form-group">
            <label class="col-form-label">{{ t.label }}</label>
            <input type="text" id="{{ t.fieldName.toLowerCase() }}-update" class="form-control" value="{{ t.value }}" readonly="true"/>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</form>