<nb-card>
  <nb-card-header class="nb-card-header">
      <div class="row">
          <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12" style="text-transform: none;">
              Xác nhận
          </div>
      </div>
  </nb-card-header>
  <nb-card-body>
      <div class="row">
          <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12" style="text-transform: none;">
              <i class="fas fa-question"></i>&nbsp;&nbsp;Bạn có muốn xoá <span class="title">{{name}}</span> ?
          </div>
      </div>
  </nb-card-body>
  <nb-card-footer class="text-end">
      <div class="row">
          <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
              <ngx-btn-delete (click)="delete()"></ngx-btn-delete>&nbsp;
              <ngx-btn-close mat-dialog-close></ngx-btn-close>
          </div>
      </div>
  </nb-card-footer>
</nb-card>
