import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/@core/customs/common.service';
import { PAGE_SIZE, SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { PageModel } from 'app/@core/data/app-home.service';
import { DmChucVuCreateComponent } from '../dm-chuc-vu-create/dm-chuc-vu-create.component';
import { DmChucVuDeleteComponent } from '../dm-chuc-vu-delete/dm-chuc-vu-delete.component';
import { DmChucVuDetailComponent } from '../dm-chuc-vu-detail/dm-chuc-vu-detail.component';
import { DmChucVuUpdateComponent } from '../dm-chuc-vu-update/dm-chuc-vu-update.component';
import { DmChucVuService } from '../dm-chuc-vu.service';

@Component({
  selector: 'ngx-dm-chuc-vu-index',
  templateUrl: './dm-chuc-vu-index.component.html',
  styleUrls: ['./dm-chuc-vu-index.component.scss'],
})
export class DmChucVuIndexComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: DmChucVuService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadData();
  }
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
  });
  type: string;
  target: any = {
    data: [],
  };

  loadData() {
    this.service.paging(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  openCreate() {
    this.dialog
      .open(
        DmChucVuCreateComponent,
        this.commonService.configDialog('50%', { lsttype: this.type })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) this.search();
      });
  }

  search() {
    this.searchModel.currentPage = 1;
    this.loadData();
  }

  updateData(id: number) {
    this.dialog
      .open(
        DmChucVuUpdateComponent,
        this.commonService.configDialog('40%', { id: id })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }

  detailData(id: number) {
    this.dialog
      .open(
        DmChucVuDetailComponent,
        this.commonService.configDialog('40%', { id: id })
      )
      .afterClosed()
      .subscribe((result) => {
        this.loadData();
      });
  }

  deleteData(id: number, ten: string) {
    this.dialog
      .open(
        DmChucVuDeleteComponent,
        this.commonService.configDialog('30%', { id: id, ten: ten })
      )
      .afterClosed()
      .subscribe((result) => {
        this.loadData();
      });
  }
}
