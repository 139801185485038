<form #frm="ngForm" (ngSubmit)="create(frm)">
    <nb-card>
        <nb-card-header class="nb-card-header">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12" >
                    THÊM MỚI LOẠI BÀI VIẾT
                </div>

            </div>
        </nb-card-header>
        <nb-card-body>
            <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                <div class="form-group">
                    <label class="control-label">Tên bài viết</label>
                    <div class="input-group">
                      <input type="text"  class="form-control inputStyle" name="ten" maxlength="500" [(ngModel)]="target.ten" #ten="ngModel"  required placeholder="Nhập tên bài viết" required />
                  </div>
                  <p style="color:red; margin:5px 0px 0px 5px">{{error}}</p>
                  <ng-container *ngIf="ten.errors && (ten.dirty || ten.touched)">
                    <div [hidden]="!ten.errors.required">
                        <label class="text-danger">Loại bài viết bắt buộc chọn</label>
                    </div>
                </ng-container>
                </div>
            </div>
            </div>
        </nb-card-body>
        <nb-card-footer class="text-end">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
                    <ngx-btn-submit  [disabled]="frm.invalid"></ngx-btn-submit>&nbsp;
                    <!-- <button class="btn btn-success" type="button" [disabled]="frm.invalid" (click)="publish();"><i class="fa-solid fa-upload"></i>&nbsp;Lưu và Xuất bản</button>&nbsp; -->
                    <ngx-btn-close mat-dialog-close></ngx-btn-close>
                </div>
            </div>
        </nb-card-footer>
    </nb-card>
</form>


