<form #frm="ngForm">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">CHI TIẾT LIÊN HỆ</div>
      </div>
    </nb-card-header>
    <nb-card-body class="cardBody">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
          <div class="form-group">
            <label class="control-label">Tên người gửi</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                name="tennguoigui"
                maxlength="500"
                [(ngModel)]="target.tennguoigui"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
          <div class="form-group">
            <label class="control-label">Tiêu dề</label>
            <div class="input-group">
              <input type="text" class="form-control" name="tieude" maxlength="500" [(ngModel)]="target.tieude" />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-12">
          <div class="form-group">
            <label class="control-label">Nội dung</label>
            <kendo-editor name="noidung" [(ngModel)]="target.noidung" style="min-height: 300px">
              <kendo-toolbar>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                  <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
              </kendo-toolbar>
            </kendo-editor>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
          <div class="form-group">
            <label class="control-label">Email</label>
            <div class="input-group">
              <input type="text" class="form-control" name="email" maxlength="500" [(ngModel)]="target.email" />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
          <div class="form-group">
            <label class="control-label">Điện thoại</label>
            <div class="input-group">
              <input type="text" class="form-control" name="dienthoai" maxlength="500" [(ngModel)]="target.dienthoai" />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
          <div class="form-group">
            <label class="control-label">File đính kèm</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                name="filedinhkem"
                maxlength="500"
                [(ngModel)]="target.filedinhkem"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
          <div class="form-group">
            <label class="control-label">Ngày gửi</label>
            <div class="input-group">
              <input type="date" class="form-control" name="time" [(ngModel)]="target.time" />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xs-12 col-lg-6">
          <div class="form-group">
            <label class="control-label" style="margin-top: 10px">Công khai</label>
            <div class="input-group">
              <div>
                <input
                  type="radio"
                  style="margin-top: auto; margin-bottom: auto; width: 20px; height: 20px"
                  [value]="1"
                  [(ngModel)]="target.congkhai"
                  id="trangthai1"
                  name="congkhai"
                />&nbsp;
                <label for="trangthai1" style="margin: 10px 10px 10px 0px">Công khai </label>
                <input
                  type="radio"
                  style="margin-top: auto; margin-bottom: auto; width: 20px; height: 20px"
                  [value]="0"
                  id="trangthai2"
                  [(ngModel)]="target.congkhai"
                  name="congkhai"
                />&nbsp;
                <label for="trangthai2" style="margin: 10px 10px 10px 0px">Không Công khai </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-end">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-end">
          <!-- <button class="btn btn-success" type="button" [disabled]="frm.invalid" (click)="publish();"><i class="fa-solid fa-upload"></i>&nbsp;Lưu và Xuất bản</button>&nbsp; -->
          <ngx-btn-close mat-dialog-close></ngx-btn-close>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
