import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { PAGE_SIZE, SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { PageModel } from 'app/@core/data/app-home.service';
import { DmTienTrinhCreateComponent } from '../dm-tien-trinh-create/dm-tien-trinh-create.component';
import { DmTienTrinhDeleteComponent } from '../dm-tien-trinh-delete/dm-tien-trinh-delete.component';
import { DmTienTrinhDetailComponent } from '../dm-tien-trinh-detail/dm-tien-trinh-detail.component';
import { DmTienTrinhUpdateComponent } from '../dm-tien-trinh-update/dm-tien-trinh-update.component';
import { DmTienTrinhService } from '../dm-tien-trinh.service';

@Component({
  selector: 'ngx-dm-tien-trinh-index',
  templateUrl: './dm-tien-trinh-index.component.html',
  styleUrls: ['./dm-tien-trinh-index.component.scss'],
})
export class DmTienTrinhIndexComponent implements OnInit {
  constructor(
    private service: DmTienTrinhService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadData();
  }
  searchModel: PageModel = new PageModel({
    search: null,
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: null,
    sortColumn: null,
    sortExpression: null,
    status: null,
    columnName: null,
    childType: null,
  });
  type: string;
  target: any = {
    data: [],
  };

  loadData() {
    this.service.paging(this.searchModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  openCreate() {
    this.dialog
      .open(
        DmTienTrinhCreateComponent,
        this.commonService.configDialog('50%', { lsttype: this.type })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res) this.search();
      });
  }
  search() {
    this.searchModel.currentPage = 1;
    this.loadData();
  }
  updateData(id: number) {
    this.dialog
      .open(
        DmTienTrinhUpdateComponent,
        this.commonService.configDialog('50%', { id: id })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  detailData(id: number) {
    this.dialog
      .open(
        DmTienTrinhDetailComponent,
        this.commonService.configDialog('50%', { id: id })
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.loadData();
        }
      });
  }
  deleteData(id: number, name: string) {
    this.dialog
      .open(
        DmTienTrinhDeleteComponent,
        this.commonService.configDialog('50%', { id: id, name: name })
      )
      .afterClosed()
      .subscribe((res) => {
        if (res === true) {
          this.loadData();
        }
      });
  }
}
