import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginLayoutComponent } from '../@theme/pages/login-layout/login-layout.component';

export const routes: Routes = [
  { path: '', redirectTo: 'app-home', pathMatch: 'full' },
  {
    path: 'gis',
    loadChildren: () => import('./app-gis/app-gis.module').then((m) => m.AppGISModule),
  },
  {
    path: 'app-home',
    loadChildren: () => import('./app-home/web-home.module').then((m) => m.WebHomeModule),
  },
  {
    path: 'app-home-admin',
    loadChildren: () => import('./app-home-admin/web-admin.module').then((m) => m.WebAdminModule),
  },
  {
    path: 'app-qtht-cb',
    loadChildren: () => import('./app-qtht-cb/app-qtht-cb.module').then((m) => m.AppQTHTCBModule),
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginLayoutComponent,
      },
    ],
  },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
