import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { MenuService } from './menu.service';
import { TokenService } from '../../../@core/token.service';
import { AuthService } from '../../../@core/auth.service';
import { SUCCESS_RESPONE } from '../../../@core/customs/constants';
import { ListMenuModel } from '../../../@core/data/app-qtht.service';

@Component({
  selector: 'ngx-menu',
  styleUrls: ['menu.component.scss'],
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  menu: NbMenuItem[] = [];


  constructor(private menuService: MenuService,
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router,
    private nbMenuService: NbMenuService) {
  }
  searchQuery: any;
  lstApplication: any[] = [];
  isAuthenticated: boolean = false;
  user: {
    username: string;
    password: string;
    appId: string;
  };
  ngOnInit(): void {
    this.LoadData();
    this.tokenService.getUserInfo().subscribe(result => {
      if (result.Id !== null) { this.isAuthenticated = true; }
    });
  }

  dataMenu: any[] = [];
  dataMenuset: any[] = [];
  lstdata: any[] = [];
  async LoadData() {
    let checkLoadMenu = false;
    const dataset = await this.menuService.selectAllDataset().toPromise();
    const feature = await this.menuService.selectAllFeatureclass(undefined).toPromise();
    this.dataMenuset = dataset.data;
    this.dataMenu = feature.data;
    this.menuService.getNbMenuItem().subscribe((result) => {
      if (result.length !== 0 || checkLoadMenu) {
        this.menu = result;
      } else {
        this.menuService.getMenuByUserLogin().subscribe(menu => {
          if (menu.code === SUCCESS_RESPONE) {
            if (this.isAuthenticated) {
              const appId = localStorage.getItem('curAppId');
              if (appId !== '23') {
                this.lstdata = [...menu.data];
              } else {
                const datafetu = this.dataMenu.map(item => {
                  return {
                    cssClass: "",
                    iconClass: "",
                    id: item.id.toString(),
                    parent: item.datasetId.toString(),
                    state: `/gis/featureclass/lopdulieu/${item.datasetId}/${item.id}/${item.tableName}`,
                    text: item.name,
                    value: item.id.toString(),
                  };
                });
                const newDataObject = {
                  cssClass: "",
                  iconClass: "",
                  id: "quantrilopdulieu",
                  parentId: null,
                  state: "/gis/dataset/quantrilopdulieu",
                  name: "Quản trị lớp dữ liệu",
                  value: "quantrilopdulieu",
                  unitCode: "9999.02"
                };
                // Cập nhật parent của các đối tượng trong mảng dataMenuset
                const updatedDataMenuset = this.dataMenuset.map(item => {
                  if (item.parentId === null || item.parentId === undefined) {
                    item.parentId = newDataObject.id;
                  }
                  return item;
                });

                // Thêm newDataObject vào mảng
                updatedDataMenuset.push(newDataObject);

                // Chuyển đổi mảng updatedDataMenuset thành dataset
                const dataset = updatedDataMenuset.map(item => {
                  return {
                    cssClass: "",
                    iconClass: "",
                    id: item.id.toString(),
                    parent: item.parentId === null ? null : item.parentId?.toString(),
                    state: `/gis/dataset/${item.id}`,
                    text: item.name,
                    value: item.id.toString(),
                  };
                });
                this.lstdata = [...menu.data, ...dataset, ...datafetu];
              }
            }
            this.loadMenu(this.lstdata);
            this.menuService.setRoutingSaveCheckRole(menu.data);
            checkLoadMenu = true;
          }
        },
          (() => {
            this.authService.logout();
            this.router.navigate(['/login']);
          }));
      }
    });
  }

  clickE() {
    this.nbMenuService.onItemClick().subscribe((data) => {
      const regex = /^\/gis\/featureclass\/lopdulieu\/(\d+)\/(\d+)\/([^/]+)$/;
      const matches = regex.exec(data.item.link);
      if (matches) {
        const idparent = matches[1];
        const idtablename = matches[2];
        const tablename = matches[3];
        switch (tablename) {
          case tablename:
            location.href = `#/gis/featureclass/lopdulieu/${idparent}/${idtablename}/${tablename}`;
            window.location.reload();
            break;
          default:
          // code block
        }
      }
    });
  };

  loadMenu(lst) {
    lst
      .filter((c) => c.parent === null || c.parent === '')
      .forEach((element) => {
        const itemMenu = new NbMenuItem();
        itemMenu.title = element.text;
        itemMenu.link = element.state;
        if (lst.findIndex((c) => c.parent === element.value) !== -1) {
          itemMenu.children = this.loadMenuChildrent(element, lst);
        }
        this.menu.push(itemMenu);
      });
    this.menuService.setNbMenuItem(this.menu);
  }

  loadMenuChildrent(item: ListMenuModel, lst: ListMenuModel[]) {
    const result = [];
    if (!item || !lst) {
      return result;
    }
    lst
      .filter((c) => c.parent === item.value)
      .forEach((element) => {
        const itemChilrent = new NbMenuItem();
        itemChilrent.title = element.text;
        itemChilrent.link = element.state;
        if (lst.findIndex((t) => t.parent === element.value) !== -1) {
          if (item.value !== element.value) {
            itemChilrent.children = this.loadMenuChildrent(element, lst);
          }
        }
        result.push(itemChilrent);
      });
    return result;
  }

  // menu = MENU_ITEMS;

  // NbMenuItem[] = [
  //   {
  //     title: 'Dashboard',
  //     icon: 'home-outline',
  //     link: '/pages/dashboard',
  //     home: true,
  //   },
  //   {
  //     title: 'FEATURES',
  //     group: true,
  //   },
  //   {
  //     title: 'Auth',
  //     icon: 'lock-outline',
  //     children: [
  //       {
  //         title: 'Login',
  //         link: '/auth/login',
  //       },
  //       {
  //         title: 'Register',
  //         link: '/auth/register',
  //       },
  //       {
  //         title: 'Request Password',
  //         link: '/auth/request-password',
  //       },
  //       {
  //         title: 'Reset Password',
  //         link: '/auth/reset-password',
  //       },
  //     ],
  //   },
}
