import { Injectable } from '@angular/core';
import { AppQthtApiService, SysuserEntityApiResponse } from 'app/@core/data/app-qtht.service';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class UserInfoService {
    constructor (
        private api: AppQthtApiService
    ) {}

    seletOne(key: number): Observable<SysuserEntityApiResponse> {
        return this.api.appQthtApiSysUserSelectOne(key);
    }
}
