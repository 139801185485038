import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, AppGISApiService, FileParameter, IconModelIEnumerableApiResponse, MapDetailModelApiResponse, MapDetailModelIEnumerableApiResponse, MapUserModel, MapUserModelIEnumerableApiResponse, ResponseModelListApiResponse, SysDmbieudoEntityIEnumerableApiResponse, SysmapEntity } from '../../../@core/data/app-gis.service';


@Injectable({
  providedIn: 'root',
})
export class MapAdminService {
  constructor(
    private api: AppGISApiService // private logger: LoggerService
  ) { }

  getAll(): Observable<MapDetailModelIEnumerableApiResponse> {
    return this.api.appGisApiMapGetMapByAdmin();
  }
  create(target: SysmapEntity): Observable<ApiResponse> {
    return this.api.appGisApiMapInsertCustom(target);
  }

  getIcon(tablename: string): Observable<IconModelIEnumerableApiResponse> {
    return this.api.appGisApiDataGetIconByTable(tablename);
  }

  updateCustomMap(entity: SysmapEntity): Observable<ApiResponse> {
    return this.api.appGisApiMapUpdateCustomMap(entity);
  }

  updateCustom(entity: SysmapEntity): Observable<ApiResponse> {
    return this.api.appGisApiMapUpdateCustom(entity);
  }
  update(body: SysmapEntity): Observable<ApiResponse> {
    return this.api.appGisApiMapUpdate(body);
  }
  delete(key: number): Observable<ApiResponse> {
    return this.api.appGisApiMapDeleteCustom(key);
  }
  selectOne(key: number): Observable<MapDetailModelApiResponse> {
    return this.api.appGisApiMapSelecOneCustom(key);
  }
  addPermission(body: MapUserModel | undefined): Observable<ApiResponse> {
    return this.api.appGisApiMapAddPermission(body);
  }
  deletePermission(key: number): Observable<ApiResponse> {
    return this.api.appGisApiMapDeletePermission(key);
  }
  getPermission(mapid: number): Observable<MapUserModelIEnumerableApiResponse> {
    return this.api.appGisApiMapGetPermission(mapid);
  }
  selectMap(): Observable<MapDetailModelApiResponse> {
    return this.api.appGisApiMapSelecOneMap();
  }
  uploadFile(files: FileParameter[], idData: number, tableName: string, folder: string, type: string): Observable<ResponseModelListApiResponse> {
    return this.api.appGisApiSysFileUploadFile(files, idData,tableName, folder, type);
  }
  SelectAllDmBieuDo(): Observable<SysDmbieudoEntityIEnumerableApiResponse> {
    return this.api.appGisApiDataSelectAllDmBieuDo();
  }
}
