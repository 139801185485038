import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { FeatureclassService } from '../../featureclass/featureclass.service';
import { MapAdminService } from '../mapadmin.service';

@Component({
  selector: 'ngx-groups-manager',
  templateUrl: './groups-manager.component.html',
  // styleUrls: ['./groups-manager.component.scss'],
})
export class groupsmanagerComponent implements OnInit {
  constructor(
    private mapService: MapAdminService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<groupsmanagerComponent>,
    private featureclassService: FeatureclassService
  ) { }

  name: string;
  parentId: string;
  type: string;
  baseUrl = environment.APP_GIS_URL;
  check: number = 1;
  target: any[] = [];
  ngOnInit(): void {
    if (this.data.isEdit) {
      this.name = this.data.data.name;
    }
    this.mapService.selectOne(this.data.id).subscribe((res) => {
      const mapModal = res.data;
      const config = JSON.parse(mapModal.config);
      this.target = config.groups;
    });
  }

  checkDuplicate(groups) {
    if (this.data.data === null) {
      const name = groups.filter((x) => x.name === this.name).length > 0 ? true : false;
      return name;
    } else {
      if (this.data.data.name !== this.name) {
        const name = groups.filter((x) => x.name === this.name).length > 0 ? true : false;
        return name;
      }
    }
    return false;
  }
  update() {
    this.mapService.selectOne(this.data.id).subscribe((res) => {
      const mapModal = res.data;
      const config = JSON.parse(mapModal.config);
      const groups = config.groups;
      if (this.checkDuplicate(groups) === false) {
        if (groups.length > 0) {
          let i = 1;
          let id = groups[groups.length - i].id;
          while (id <= 0) {
            i++;
            id = groups[groups.length - i].id;
          }
          this.check === 1 ? this.check = (id + 1) : this.check = 0
          if (this.data.isEdit) {
            let index = groups.indexOf(this.data.data);
            groups.splice(index, 1);
            groups.push({
              parentId: this.parentId == null ? null : this.parentId,
              name: this.name,
              id: this.check,
            });
            config.groups = groups;
          } else {
            groups.push({
              parentId: this.parentId == null ? null : this.parentId,
              name: this.name,
              id: this.check,
            });
          }
        } else {
          groups.push({
            parentId: this.parentId == null ? null : this.parentId,
            name: this.name,
            id: this.check,
          });
        }
        mapModal.config = JSON.stringify(config);
        this.mapService.updateCustomMap(mapModal).subscribe((res) => {
          if (res.code === SUCCESS_RESPONE) {
            this.commonService.toastrSuccess('Thành công!');
            this.dialogRef.close(true);
          } else {
            this.commonService.toastrDanger(res.message);
          }
        });
      } else {
        this.commonService.toastrWarning('Tên nhóm đã tồn tại');
      }
    });
  }
}
