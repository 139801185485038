import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FieldService } from '../field.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { SysfieldEntity } from '../../../../@core/data/app-gis.service';

@Component({
  selector: 'ngx-field-update',
  templateUrl: './field-update.component.html',
  styleUrls: ['./field-update.component.scss'],
})
export class FieldUpdateComponent implements OnInit {
  constructor(
    private featureclassService: FieldService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private FieldItem: { id: number },
    public dialogRef: MatDialogRef<FieldUpdateComponent>
  ) {}

  target: SysfieldEntity = new SysfieldEntity();

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.featureclassService.selectOne(this.FieldItem.id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  update(frm: NgForm) {
    this.target.fields = null;
    this.target.show = this.target.show.toString();
    this.featureclassService.update(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
