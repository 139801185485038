import { DUPLICATE_RESPONE } from '../../../../@core/customs/constants';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { SysAppService } from '../config-app.service';
import { SysappEntity } from 'app/@core/data/app-qtht.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'ngx-config-app-update',
  templateUrl: './config-app-update.component.html',
  styleUrls: ['./config-app-update.component.scss']
})
export class SysAppUpdateComponent implements OnInit {

  constructor(
    private sysAppService: SysAppService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private SysAppItem: {id: number},
    public dialogRef: MatDialogRef<SysAppUpdateComponent>
  ) {
  }

  target: SysappEntity = new SysappEntity();

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.sysAppService.selectOne(this.SysAppItem.id).subscribe(result => {
      if (result.code === SUCCESS_RESPONE) {
         this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  update(frm: NgForm) {
    this.target.fields = null;
    this.sysAppService.update(this.target).subscribe(result => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
