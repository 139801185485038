import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { ApiResponse, CreateOrUpdateDataModel, DataUpdateModel, FileParameter } from '../../../../@core/data/app-gis.service';
import { FieldService } from '../../featureclass/field.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'ngx-map-add',
  templateUrl: './map-add.component.html',
  styleUrls: ['./map-add.component.scss']
})
export class MapAddComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private fieldService: FieldService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private area: any,
    @Inject(MAT_DIALOG_DATA) private tableName: any,
    public dialogRef: MatDialogRef<MapAddComponent>,
    private readonly httpClient: HttpClient
  ) { }

  fields: any[] = [];
  fieldsName: any[] = [];
  lstAll: any[] = [];
  lnglat: any;
  fileToUpload: FileList | null = null;
  MDVHCCX: string;
  MDVHCCH: string;
  MDVHCT: string;
  lstDVHCCH: any[] = [];
  lstDVHCCX: any[] = [];
  gender: string;
  options: any[] = [];
  selectedFiles: FileParameter[] = [];
  lstFileIdDelete: any[] = [];
  handleFileInput(files: FileList) {
    this.fileToUpload = files;
  }
  ngOnInit(): void {
    this.getFields();
    this.loadDVHC();
    this.options = [
      {
        name : 'Nam',
        value : 1
      },
      {
        name : 'Nữ',
        value : 0
      },
      {
        name : 'khác',
        value : 2
      }
    ]
  }
 
  loadDVHC() {
    this.lstDVHCCH = this.area.DVHCCH;
    this.lstDVHCCX = this.area.DVHCCX;
  }
  deleteFile(file: any) {
    if (this.selectedFiles.indexOf(file) !== -1) {
      this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
    }
    if (file.data.id !== undefined) {
      this.lstFileIdDelete.push(file.data.id);
    }
  }
  chooseFile(files: FileParameter[]) {
    if (files.length === 0) {
      return;
    }
    this.selectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      const fileParameter: FileParameter = {
        data: files[i],
        fileName: files[i]["name"],
      };
      this.selectedFiles.push(fileParameter);
    }
  }
  getFields(){
    if(this.tableName.tableName === null){
      this.lstAll = [];
    } else {
      this.fieldService.GetFieldsForInsertOrUpdate(this.tableName.tableName).subscribe((result) => {
        if (result.code === SUCCESS_RESPONE) {
          this.lstAll = [];
          const fields = result.data;
          this.fields = fields.map(function (e) {
            return e.name;
          });
          this.fieldsName = fields.map(function (e) {
            return e.fieldname;
          });
          for (let i = 0; i < this.fieldsName.length; i++) {
            const x = this.fieldsName[i];
            const y = this.fields[i];
            this.lstAll.push({ x, y });
          }
        }
      });
    }
  }
  create() {
    // this.lnglat = 'POINT ('+this.area.area.lng +' '+ this.area.area.lat+')';
    const lst: Array<DataUpdateModel> = [];
    this.fieldsName.forEach((element) => {
      if (element !== 'id' && element !== 'shape' && element.toLowerCase() !== 'status' && element.toLowerCase() !== 'status' 
      && element.toLowerCase() !== 'maxa' && element.toLowerCase() !== 'mahuyen' && element.toLowerCase() !== 'matinh'
      && element.toLowerCase() !== 'createddate') {
        const value = (<HTMLInputElement>document.getElementById(element + '-create')).value;
        const model = new DataUpdateModel();
        model.key = element;
        model.value = value;
        lst.push(model);
      }
    });
    if(this.MDVHCCX){
      const model = new DataUpdateModel();
      model.key = 'maxa';
      model.value = this.MDVHCCX;
      lst.push(model);
    }
    if(this.MDVHCCH){
      const model = new DataUpdateModel();
      model.key = 'mahuyen';
      model.value = this.MDVHCCH;
      lst.push(model);
    }
    if(this.MDVHCT){
      const model = new DataUpdateModel();
      model.key = 'matinh';
      model.value = '04';
      lst.push(model);
    }
    if(this.gender !== null && this.gender !== undefined){
      const model = new DataUpdateModel();
      model.key = 'gioitinh';
      model.value = this.gender;
      lst.push(model);
    }
    let _body = new CreateOrUpdateDataModel();
    _body.listData = lst;
    _body.tableName = this.tableName.tableName;
    // _body.lngLat = this.lnglat;
    this.dataService.createCustom(_body).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.dialogRef.close(true);
        this.commonService.toastrSuccess();
        if (this.selectedFiles.length > 0) {
          this.dataService.uploadFile(this.selectedFiles,res.data,this.tableName,this.tableName, 'image').subscribe(res1 => {
          });
        }
      }
    });
    
  }
}
