import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmTientrinhEntity } from 'app/@core/data/app-home.service';
import { DmTienTrinhService } from '../dm-tien-trinh.service';

@Component({
  selector: 'ngx-dm-tien-trinh-detail',
  templateUrl: './dm-tien-trinh-detail.component.html',
  styleUrls: ['./dm-tien-trinh-detail.component.scss'],
})
export class DmTienTrinhDetailComponent implements OnInit {
  constructor(
    private service: DmTienTrinhService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number }
  ) {}

  ngOnInit(): void {
    this.loadData(this.data.id);
  }

  target: DmTientrinhEntity = new DmTientrinhEntity();

  loadData(id: number) {
    this.service.selectOne(id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
