import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmLanguageEntity } from 'app/@core/data/app-home.service';
import { DmLanguageService } from '../dm-language.service';

@Component({
  selector: 'ngx-dm-language-detail',
  templateUrl: './dm-language-detail.component.html',
  styleUrls: ['./dm-language-detail.component.scss'],
})
export class DmLanguageDetailComponent implements OnInit {
  constructor(
    private service: DmLanguageService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number }
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  target: DmLanguageEntity = new DmLanguageEntity();

  loadData() {
    this.service.selectOne(this.data.id).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
}
