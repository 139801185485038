import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmTacGiaService } from '../dm-tac-gia.service';

@Component({
  selector: 'ngx-dm-tac-gia-delete',
  templateUrl: './dm-tac-gia-delete.component.html',
  styleUrls: ['./dm-tac-gia-delete.component.scss'],
})
export class DmTacGiaDeleteComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: DmTacGiaService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number; ten: string },
    private dialogRef: MatDialogRef<DmTacGiaDeleteComponent>
  ) {}

  tentacgia: string;
  ngOnInit(): void {
    this.tentacgia = this.data.ten;
  }

  delete() {
    this.service.delete(this.data.id).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess('Xoá dữ liệu thành công.');
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(res.message);
        this.dialogRef.close(false);
      }
    });
  }
}
