import { Router } from '@angular/router';
import { CommonService } from './../../../@core/customs/common.service';
import { AuthService } from './../../../@core/auth.service';
import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../../@core/token.service';
import { SUCCESS_RESPONE } from '../../../@core/customs/constants';

@Component({
  selector: 'ngx-login-layout',
  styleUrls: ['login-layout.component.scss'],
  templateUrl: './login-layout.component.html',
})
export class LoginLayoutComponent implements OnInit {
  user: {
    username: string;
    password: string;
    appId: string;
  };
  lstApplication: any[] = [];
  isAuthenticated: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private tokenService: TokenService
  ) { }

  ngOnInit() {

    this.user = {
      username: '',
      appId: '1',
      password: '',
    };

    this.tokenService.getUserInfo().subscribe(result => {
      if (result) {
        if (result.Id !== null) { this.isAuthenticated = true; }
      }
    });

    if (this.isAuthenticated) {
      this.user.appId = localStorage.getItem('curAppId');
      let stateRouter = '/';
      switch (this.user.appId) {
        case '1':
          stateRouter = '/app-home-admin/tintuc-sukien';
          break;
        case '23':
          stateRouter = '/gis/dataset';
          break;
        case '2':
          stateRouter = '/app-qtht-cb/user-app';
          break;
        default:
          break;
      }
      this.router.navigate([stateRouter]);

    }



    this.authService.getListApplication().subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.lstApplication = result.data;
        // this.user.appId = this.lstApplication[0].appid;
      }
    });
  }

  async isUserEmpty() {
    this.tokenService.getUserInfo().subscribe((_users: any) => {
      if (this.user === null || this.user === undefined) {
        return true;
      }
    });
    return false;
  }

  login() {
    const appSelect = this.lstApplication.find((c) => c.appid === Number(this.user.appId)) ?? null;
    this.authService.login(this.user, appSelect.state).subscribe(
      () => { },
      (error) => {
        this.commonService.toastrDanger('Đăng nhập thất bại: ' + error);
      }
    );
  }
}
