import { Injectable } from '@angular/core';
import {
  AppHomeApiService,
  DmTacgiumEntity,
  DmTacgiumEntityApiResponse,
  MasterModelIEnumerablePageModelViewApiResponse,
  PageModel,
} from 'app/@core/data/app-home.service';
import { ApiResponse } from 'app/@core/data/app-qtht.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DmTacGiaService {
  constructor(private api: AppHomeApiService) {}

  paging(
    body: PageModel | undefined
  ): Observable<MasterModelIEnumerablePageModelViewApiResponse> {
    return this.api.appAdminApiDmTacgiaPaging(body);
  }

  insert(entity: DmTacgiumEntity | undefined): Observable<ApiResponse> {
    return this.api.appAdminApiDmTacgiaInsert(entity);
  }

  update(entity: DmTacgiumEntity | undefined): Observable<ApiResponse> {
    return this.api.appAdminApiDmTacgiaUpdate(entity);
  }

  selectOne(id: number): Observable<DmTacgiumEntityApiResponse> {
    return this.api.appAdminApiDmTacgiaSelectOne(id);
  }

  delete(id: number): Observable<ApiResponse> {
    return this.api.appAdminApiDmTacgiaDelete(id);
  }
}
