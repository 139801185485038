<form #frm="ngForm" (ngSubmit)="update()">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div style="display: flex; align-items: center; justify-content: space-between">
        <div class="">Thêm layer</div>
        <div style="display: flex; justify-content: flex-end; gap: 10px"></div>
      </div>
    </nb-card-header>
    <nb-card-body [style.padding-bottom]="dataType === undefined ? '170px' : '70px'">
      <div class="row">
        <div class="form-group"
          style="display: flex; justify-content: space-between; align-items: center; margin-top: 7px">
          <label style="display: block; min-width: 200px;" class="col-form-label required">Chọn bản source</label>
          <ng-select style="width: 100%;" [items]="target" bindValue="id" bindLabel="name"
            [(ngModel)]="layerData.idFeatureClass" name="tableName" placeholder="Chọn Map"
            (change)="loadFeatureClass(layerData.idFeatureClass, $event)"></ng-select>
        </div>

        <div class="col-lg-12">
          <div class="form-group mt-2"
            style="display: flex; justify-content: space-between; align-items: center; margin-top: 7px">
            <label style="display: block; min-width: 200px;" class="col-form-label required">Chọn kiểu hiển thị</label>
            <ng-select style="width: 100%" [items]="dataTypes" bindValue="name" bindLabel="label"
              [(ngModel)]="layerData.type" name="dataType" placeholder="Chọn kiểu dữ liệu"
              (change)="typeChange(layerData.type)" required></ng-select>
          </div>
          <div class="form-group mt-2"
            style="display: flex; justify-content: space-between; align-items: center; margin-top: 7px">
            <label style="display: block; min-width: 200px;" class="col-form-label required">Chọn Nhóm</label>
            <ng-select style="width: 100%" [items]="lstGroup" bindValue="id" bindLabel="name"
              [(ngModel)]="groupId" name="groupId" placeholder="Chọn nhóm"
              required></ng-select>
          </div>
          <div *ngIf="dataType">
            <div class="row" *ngFor="let item of dataType.paint">
              <div class="form-group"
                *ngIf="item.type !== 'color' && !item.type.startsWith('combobox') && item.type !== 'file'"
                style="display: flex; justify-content: space-between; align-items: center; margin-top: 7px">
                <label style="display: block; min-width: 200px;" class="col-form-label">{{ item.label }}</label>
                <input type="{{ item.type }}" name="{{ item.name }}" class="form-control" required
                  [(ngModel)]="fieldsInput[item.name]" (change)="fileChange($event, item.name)" />
              </div>

              <div class="form-group" *ngIf="item.type === 'file'"
                style="display: flex; justify-content: space-between; align-items: center; margin-top: 7px">
                <label style="display: block; min-width: 200px;" class="col-form-label">{{ item.label }}</label>
                <input type="file" id="file" multiple (change)="handleFileInput($event.target.files)"
                  class="form-control" accept="image/png">
              </div>

              <div class="form-group" *ngIf="item.type === 'color'"
                style="display: flex; justify-content: space-between; align-items: center; margin-top: 7px">
                <label class="col-form-label">{{ item.label }}</label>
                <input type="color" class="form-control" name="{{ item.name }}" [(ngModel)]="fieldsInput[item.name]"
                  style="width: 35px; height: 20px; padding: 2px; border-radius: 0px" value="#ff0000" />
              </div>

              <div class="form-group" *ngIf="item.type.startsWith('combobox')"
                style="display: flex; justify-content: space-between; align-items: center; margin-top: 7px">
                <label style="display: block; min-width: 200px;" class="col-form-label required">{{ item.label
                  }}</label>
                <ng-select style="width: 100%;" [items]="getDataCombobox(item.type)" bindValue="fieldname"
                  [(ngModel)]="fieldsInput[item.name]" bindLabel="name" name="dataType" placeholder="Chọn nhãn hiển thị"
                  required></ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button type="submit" class="btn btn-primary" [disabled]="frm.invalid">Lưu</button>
          &nbsp;
          <button type="button" class="btn btn-outline-secondary" mat-dialog-close>Đóng</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>