import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  AppGISApiService,
  SysfeatureclassEntity,
  SysfeatureclassEntityApiResponse,
  ObjectIEnumerablePageModelViewApiResponse,
  PageModel,
  FeatureclassDetailModelIEnumerableApiResponse,
  FeatureclassDetailModelIEnumerablePageModelViewApiResponse,
  ExportDataModel,
} from '../../../@core/data/app-gis.service';
import { AppQthtApiService, ObjectIEnumerableApiResponse } from '../../../@core/data/app-qtht.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureclassService {
  constructor(
    private api: AppGISApiService, // private logger: LoggerService
    private qthtapi: AppQthtApiService 
  ) { }
  getAllPhongBan(): Observable<ObjectIEnumerableApiResponse> {
    return this.qthtapi.appQthtApiDmPhongBanSelectAll();
  }
  paging(page: PageModel): Observable<ObjectIEnumerablePageModelViewApiResponse> {
    return this.api.appGisApiFeatureclassPaging(page);
  }
  getAll(datasetId: number | undefined): Observable<FeatureclassDetailModelIEnumerableApiResponse> {
    return this.api.appGisApiFeatureclassGetFeatureclasss(datasetId);
  }
  getFeatureclasssByMap(): Observable<FeatureclassDetailModelIEnumerableApiResponse> {
    return this.api.appGisApiFeatureclassGetFeatureclasssByMap();
  }
  getFeatureByParentId(datasetId: number | undefined): Observable<FeatureclassDetailModelIEnumerableApiResponse> {
    return this.api.appGisApiFeatureclassGetFeatureclasssByParentId(datasetId);
  }
  create(target: SysfeatureclassEntity): Observable<ApiResponse> {
    return this.api.appGisApiFeatureclassInsertCustom(target);
  }

  update(entity: SysfeatureclassEntity): Observable<ApiResponse> {
    return this.api.appGisApiFeatureclassUpdateCustom(entity);
  }

  delete(key: number): Observable<ApiResponse> {
    return this.api.appGisApiFeatureclassDeleteCustom(key);
  }
  truncate(key: number): Observable<ApiResponse> {
    return this.api.appGisApiFeatureclassDeleteData(key);
  }
  selectOne(key: number): Observable<SysfeatureclassEntityApiResponse> {
    return this.api.appGisApiFeatureclassSelecOneCustom(key);
  }
  pagingF(page: PageModel, datasetId: number): Observable<FeatureclassDetailModelIEnumerablePageModelViewApiResponse> {
    return this.api.appGisApiFeatureclassPagingFeature(datasetId, page);
  }
  GetName(tablename: string): Observable<string> {
    return this.api.appGisApiDataGetName(tablename);
  }

}
