import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmLoaiBaiVietService } from '../dm-loai-bai-viet.service';

@Component({
  selector: 'ngx-dm-loai-bai-viet-delete',
  templateUrl: './dm-loai-bai-viet-delete.component.html',
  styleUrls: ['./dm-loai-bai-viet-delete.component.scss'],
})
export class DmLoaiBaiVietDeleteComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: DmLoaiBaiVietService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number; ten: string },
    private dialogRef: MatDialogRef<DmLoaiBaiVietDeleteComponent>
  ) {}

  ten: string;
  ngOnInit(): void {
    this.ten = this.data.ten;
  }

  delete() {
    this.service.delete(this.data.id).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess('Xoá dữ liệu thành công.');
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(res.message);
        this.dialogRef.close(false);
      }
    });
  }
}
