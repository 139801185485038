<form #frm="ngForm" (ngSubmit)="create(frm)">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          Xuất dữ liệu
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Định dạng dữ liệu</label>
            <select class="form-control" aria-label="Định dạng dữ liệu" placeholder="Định dạng dữ liệu"
              [(ngModel)]="exportTye" [ngModelOptions]="{standalone: true}">
              <option value="word">Word</option>
              <option value="excel">Excel</option>
            </select>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button type="submit" class="btn btn-primary" [disabled]="frm.invalid">
            Xuất dữ liệu
          </button>
          &nbsp;
          <button type="button" class="btn btn-outline-secondary" mat-dialog-close>
            Đóng
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>