import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import {} from '@nebular/auth';

import { MenuComponent } from '../../@theme/pages/menu/menu.component';
import { routes as datasetRoutes } from './dataset/dataset-routing.module';
import { routes as featureRoutes } from './featureclass/featureclass-routing.module';
import { routes as settingsRoutes } from './setting/setting-routing.module';
import { routes as mapsRoutes } from './mapadmin/mapadmin-routing.module';
import { routes as fileRoutes } from './file/file-routing.module';
import { MapComponent } from './map/map-index/map.component';
import { PermissionComponent } from './permission/permission.component';
import { routes as DataRoutes } from './datastatistics/data-routing.module';

export const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      { path: 'dataset', children: datasetRoutes },
      { path: 'featureclass', children: featureRoutes },
      { path: 'map', component: MapComponent },
      { path: 'permission', component: PermissionComponent },
      { path: 'file', children: fileRoutes },
      { path: 'settings', children: settingsRoutes },
      { path: 'mapadmin', children: mapsRoutes },
      { path: 'data', children: DataRoutes },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppGISRoutingModule {}
