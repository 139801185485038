import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { FileSelectImportComponent } from './fileselect-import.component';
import { DataService } from '../../services/data.service';
import { FieldService } from '../field.service';
import { FileService } from '../../file/file.service';
import {  FieldMappingModel, ImportDataModel } from '../../../../@core/data/app-gis.service';

@Component({
  selector: 'ngx-featureclass-import',
  templateUrl: './featureclass-import.component.html',
  styleUrls: ['./featureclass-import.component.scss'],
})
export class FeatureclassImportComponent implements OnInit {
  constructor(
    private featureclassService: FieldService,
    private fileService: FileService,
    private dataService: DataService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private feature: { id: number; tablename: string },
    public dialogRef: MatDialogRef<FeatureclassImportComponent>,
    private dialog: MatDialog,
  ) {}

  target: ImportDataModel = new ImportDataModel();
  fields: any[] = [];
  fieldsFile: any[] = [];
  exportTye: string = 'shp';
  placeHolder: string = 'Chọn tệp dữ liệu (định dạng .shp)';

  ngOnInit(): void {
    this.target.prj = '105453';
    this.loadFields();
  }


  loadFields() {
    this.featureclassService.getAll(this.feature.id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        result.data.forEach((element) => {
          if (element.fieldname !== 'SHAPE' && element.fieldname !== 'ID') {
            this.fields.push({
              fieldDestination: element.fieldname,
            });
          }
        });
      }
    });
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  
  chonTepDuLieu() {
    let fileType = '.shp';
    if (this.exportTye === 'excel') fileType = '.xls';
    else if (this.exportTye === 'csv') fileType = '.csv';
    this.dialog
      .open(FileSelectImportComponent, this.commonService.configDialog('100%', { fileType: fileType }))
      .afterClosed()
      .subscribe((result) => {
        this.target.pathFile = result.name;
        this.target.fileId = result.id;
        this.fileService.getColumns(result.id).subscribe((res) => {
          this.fieldsFile = ['--Không chọn---'];
          this.fieldsFile = this.fieldsFile.concat(res.data);
        });
      });
  }
  onChonNguonDuLieu(value) {
    this.target.pathFile = '';
    this.target.fileId = 0;
    switch (value) {
      case 'shp':
        this.placeHolder = 'Chọn tệp dữ liệu (định dạng .shp)';
        break;
      case 'excel':
        this.placeHolder = 'Chọn tệp dữ liệu (định dạng .xlsx hoặc .xls)';
        break;
      case 'csv':
        this.placeHolder = 'Chọn tệp dữ liệu (định dạng .csv)';
        break;
    }
  }
  create(frm: NgForm) {
    const model = new ImportDataModel();
    model.pathFile = this.target.pathFile;
    model.fileId = this.target.fileId;
    model.fileType = 'shp';
    model.featureclassId = this.feature.id;
    model.tableName = this.feature.tablename;


      model.fields = [];
      model.prj = this.target.prj;
      if (this.target.fileId < 1) {
        this.commonService.toastrDanger('Chọn tệp dữ liệu');
        return;
      }
      this.fields.forEach((element) => {
        const el = document.getElementById('field-import-' + element.fieldDestination) as any;
        if (el && el.value !== '--Không chọn---') {

          const mapping = new FieldMappingModel();
          mapping.fieldDestination = element.fieldDestination;
          mapping.fieldSource = el.value;
          model.fields.push(mapping);
        }
      });
      this.dataService.importData(this.feature.tablename, model).subscribe((res) => {
        if (res.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess();
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrDanger(res.message);
        }
      });
  }
}
