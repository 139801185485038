<form #frm="ngForm" (ngSubmit)="create(frm)">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          Thêm mới lớp dữ liệu
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Tên lớp dữ liệu</label>
            <input
              type="text"
              class="form-control"
              name="name"
              [(ngModel)]="target.name"
              required
              maxlength="255"
            />
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Tên bảng</label>
            <input
              type="text"
              class="form-control"
              name="tablename"
              [(ngModel)]="target.tablename"
              required
              maxlength="20"
            />
          </div>
        </div>
        <div class="col-lg-12" *ngIf="checkUnit === true">
          <div class="form-group">
            <label class="col-form-label">Đơn vị quản lý</label>
            <ng-select [items]="lstUnit" bindValue="unitcode" bindLabel="unitname" 
              [(ngModel)]="target.unitcode" name="unitcode" placeholder="Chọn đơn vị" appendTo="Body"></ng-select>
          </div>
        </div>
        <!-- <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Kiểu hình học</label>
            <select class="form-control" aria-label="Kiểu hình học">
              <option value="POINT">Điểm</option>
              <option value="LINESTRING">Đường</option>
              <option value="POLYGON">Vùng</option>
            </select>
          </div>
        </div> -->
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label required">Hệ quy chiếu</label>
            <select class="form-control" aria-label="Hệ quy chiếu" [(ngModel)]="target.prj"
            [ngModelOptions]="{standalone: true}">
              <option value='4326'>WGS84</option>
              <option value='103003'>VN2000, 103°0, 3</option>
              <option value='103006'>VN2000, 103°0, 6</option>
              <option value='104003'>VN2000, 104°0, 3</option>
              <option value='104006'>VN2000, 104°0, 6</option>
              <option value='104303'>VN2000, 104°30, 3</option>
              <option value='104306'>VN2000, 104°30, 6</option>
              <option value='104453'>VN2000, 104°45, 3</option>
              <option value='104456'>VN2000, 104°45, 6</option>
              <option value='105003'>VN2000, 105°0, 3</option>
              <option value='105006'>VN2000, 105°0, 6</option>
              <option value='105303'>VN2000, 105°30, 3</option>
              <option value='105306'>VN2000, 105°30, 6</option>
              <option value='105453'>VN2000, 105°45, 3</option>
              <option value='105456'>VN2000, 105°45, 6</option>
              <option value='106003'>VN2000, 106°0, 3</option>
              <option value='106006'>VN2000, 106°0, 6</option>
              <option value='106153'>VN2000, 106°15, 3</option>
              <option value='106156'>VN2000, 106°15, 6</option>
              <option value='106303'>VN2000, 106°30, 3</option>
              <option value='106306'>VN2000, 106°30, 6</option>
              <option value='107003'>VN2000, 107°0, 3</option>
              <option value='107006'>VN2000, 107°0, 6</option>
              <option value='107153'>VN2000, 107°15, 3</option>
              <option value='107156'>VN2000, 107°15, 6</option>
              <option value='107303'>VN2000, 107°30, 3</option>
              <option value='107306'>VN2000, 107°30, 6</option>
              <option value='107453'>VN2000, 107°45, 3</option>
              <option value='107456'>VN2000, 107°45, 6</option>
              <option value='108003'>VN2000, 108°0, 3</option>
              <option value='108006'>VN2000, 108°0, 6</option>
              <option value='108153'>VN2000, 108°15, 3</option>
              <option value='108156'>VN2000, 108°15, 6</option>
              <option value='108303'>VN2000, 108°30, 3</option>
              <option value='108306'>VN2000, 108°30, 6</option>
            </select>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="col-form-label">Mô tả</label>
            <textarea
              type="textarea"
              class="form-control"
              name="description"
              [(ngModel)]="target.description"
            ></textarea>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group" style="margin-top:10px">
              <label class="control-label">Trạng thái</label>
              <div class="input-group">
                  <input  type="radio" style="margin-top: auto;margin-bottom: auto; width: 20px; height: 20px;" [value]="1"  [(ngModel)]="target.status" id="status1" name="status">&nbsp;
                  <label for="status1" style="margin: 10px 10px 10px 0px;">Sử dụng </label>
                  <br/>
                  <input  type="radio" style="margin-top: auto;margin-bottom: auto; width: 20px; height: 20px;" [value]="0" [(ngModel)]="target.status" id="status2" name="status">&nbsp;
                  <label for="status2" style="margin: 10px 10px 10px 0px;">Không sử dụng </label>
              </div>
          </div>
      </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="frm.invalid"
          >
            Lưu
          </button>
          &nbsp;
          <button
            type="button"
            class="btn btn-outline-secondary"
            mat-dialog-close
          >
            Đóng
          </button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
