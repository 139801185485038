<nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="input-group">
          <label style="flex: auto">Danh sách bản đồ</label>
          <button
          class="btn btn-primary"
          type="button"
          (click)="openCreateDialog(null)"
        >
          <i class="fas fa-plus fa-sm"></i>&nbsp;Thêm mới
        </button>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>

      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="text-center" style="width: 50px;">STT</th>
                <th>Mã bản đồ</th>
                <th>Tên bản đồ</th>
                <th>Cấu hình</th>
                <th>Trạng thái chia sẻ</th>
                <th class="text-center" style="width: 180px;">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of target; let i = index">
                <td class="text-center">
                  {{ i + 1 }}
                </td>
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ getConfig(item.config) }}</td>
                <td>{{ item.status === 'public' ? 'Mọi người dùng' : 'Không chia sẻ' }}</td>
                <td class="text-center">
                  <button title="Sửa thông tin" class="btn" (click)="editItem(item.id)"><i class="fa fa-edit"></i></button>
                  <button title="Phân quyền dữ liệu" class="btn" (click)="shareItem(item.id)"><i class="fa fa-users"></i></button>
                  <button title="Xóa bản đồ" class="btn" (click)="deleteItem(item.id, item.name)"><i class="fa fa-trash"></i></button>
                  <button title="Chia sẻ cho phòng ban" class="btn" *ngIf="!checkPhongBan" (click)="share(item)"><i class="fa-solid fa-share"></i></button>
                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
