import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DataService } from '../../services/data.service';
import { FieldService } from '../field.service';
import { ApiResponse, CreateOrUpdateDataModel, DataUpdateModel, ExportDataModel, FileParameter } from '../../../../@core/data/app-gis.service';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'ngx-featureclass-updatedata',
  templateUrl: './featureclass-updatedata.component.html',
  styleUrls: ['./featureclass-updatedata.component.scss'],
})
export class FeatureclassUpdateDataComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private fieldService: FieldService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private feature: any,
    public dialogRef: MatDialogRef<FeatureclassUpdateDataComponent>,
    private readonly httpClient: HttpClient
  ) {

  }
  fields: any[] = [];
  field: any[] = [];
  fieldsName: any[] = [];
  textSearch: string;
  lstAll: any[] = [];
  lstFile: any[] = [];
  fileToUpload: FileList | null = null;
  lstObject: any[] = [];
  MDVHC: string;
  gender: string;
  options: any[] = [];
  handleFileInput(files: FileList) {
    this.fileToUpload = files;
  }
  MDVHCCX: string;
  MDVHCCH: string;
  MDVHCT: string;
  lstDVHCCH: any[] = [];
  lstDVHCCX: any[] = [];
  selectedFiles: FileParameter[] = [];
  lstFileIdDelete: any[] = [];
  ngOnInit(): void {
    this.loadDVHC();
    this.options = [
      {
        name: 'Nam',
        value: 1
      },
      {
        name: 'Nữ',
        value: 0
      },
      {
        name: 'khác',
        value: 2
      }
    ]
    this.getField();
    this.getFiles();
    this.MDVHCT = 'Tỉnh Cao Bằng'
  }
  loadDVHC() {
    this.lstDVHCCH = this.feature.DVHCCH;
  }
  ChangeHuyen() {
    this.lstDVHCCX = this.feature.DVHCCX.filter(x => x.macha === this.MDVHCCH);
  }
  getField() {
    this.fieldService.GetFieldsForInsertOrUpdate(this.feature.tablename).subscribe((result) => {

      if (result.code === SUCCESS_RESPONE) {
        this.lstAll = [];
        const fields = result.data;
        this.field = fields.map(function (e) {
          return e.name;
        });
        this.fieldsName = fields.map(function (e) {
          return e.fieldname;
        });
        for (let i = 0; i < this.fieldsName.length; i++) {
          const x = this.fieldsName[i];
          const y = this.field[i];
          for (const attr in this.feature.data) {
            if (attr === 'maxa') {
              this.MDVHCCX = this.feature.data[attr];
            }
            if (attr === 'mahuyen') {
              this.MDVHCCH = this.feature.data[attr];
            }
            if (attr === 'gioitinh') {
              this.gender = this.feature.data[attr];
            }
            if (attr !== 'id' && attr !== 'shape' && x.toLowerCase() !== 'createddate' && x.toLowerCase() !== 'status' && x.toLowerCase() === attr) {
              this.fields.push({
                fieldName: x,
                label: y,
                value: this.feature.data[attr],
              });
            }
          }
        }
      }
    });

  }

  getFiles() {
    this.dataService.getFile(this.feature.data.id).subscribe((result) => {
      if (result.code = SUCCESS_RESPONE) {
        result.data.forEach(element => {
          const fileParameter: FileParameter = {
            data: element,
            fileName: element.filename,
          };
          this.selectedFiles.push(fileParameter);
        });
      }
    });
  }


  closeDialog() {
    this.dialogRef.close(false);
  }
  deleteFile(file: any) {
    if (this.selectedFiles.indexOf(file) !== -1) {
      this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
    }
    if (file.data.id !== undefined) {
      this.lstFileIdDelete.push(file.data.id);
    }
  }
  chooseFile(files: FileParameter[]) {
    if (files.length === 0) {
      return;
    }
    this.selectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      const fileParameter: FileParameter = {
        data: files[i],
        fileName: files[i]["name"],
      };
      this.selectedFiles.push(fileParameter);
    }
  }
  update() {
    const lst: Array<DataUpdateModel> = [];
    this.fieldsName.forEach((element) => {
      if (element !== 'id' && element !== 'shape' && element.toLowerCase() !== 'createddate' && element.toLowerCase() !== 'status'
        && element.toLowerCase() !== 'gioitinh' && element.toLowerCase() !== 'maxa' && element.toLowerCase() !== 'mahuyen'
        && element.toLowerCase() !== 'matinh') {
        const value = (<HTMLInputElement>document.getElementById(element + '-update')).value;
        const model = new DataUpdateModel();
        model.key = element;
        model.value = value;
        lst.push(model);
      }
    });
    if (this.MDVHCCX) {
      const model = new DataUpdateModel();
      model.key = 'maxa';
      model.value = this.MDVHCCX;
      lst.push(model);
    }
    if (this.MDVHCCH) {
      const model = new DataUpdateModel();
      model.key = 'mahuyen';
      model.value = this.MDVHCCH;
      lst.push(model);
    }
    if (this.MDVHCT) {
      const model = new DataUpdateModel();
      model.key = 'matinh';
      model.value = '04';
      lst.push(model);
    }
    if (this.gender !== null && this.gender !== undefined) {
      const model = new DataUpdateModel();
      model.key = 'GIOITINH';
      model.value = this.gender;
      lst.push(model);
    }
    let _body = new CreateOrUpdateDataModel();
    _body.listData = lst;
    _body.id = this.feature.data.id;
    _body.tableName = this.feature.tablename;
    _body.fileDelete = this.lstFileIdDelete;
    this.dataService.updateCustom(_body).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess();
        this.dialogRef.close(true);
        if (this.selectedFiles.length > 0) {
          this.dataService.uploadFile(this.selectedFiles, res.data, this.feature.tablename, this.feature.tablename, 'image').subscribe(res1 => {
          });
        }
      } else {
        this.commonService.toastrDanger(res.message);
      }
    })
  }
}
