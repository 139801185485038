import { AttFileattachmentEntity, TintucSukienEntity, TintucSukienEntityProcessUpdateFileModel } from './../../../../@core/data/app-home.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TintucSukienService } from '../tintuc-sukien.service';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { FileParameter } from '../../../../@core/data/app-qtht.service';

@Component({
  selector: 'ngx-tintuc-sukien-update',
  templateUrl: './tintuc-sukien-update.component.html',
  styleUrls: ['./tintuc-sukien-update.component.scss']
})
export class TintucSukienUpdateComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private service: TintucSukienService,
    private dialogRef: MatDialogRef<TintucSukienUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { id: number, type: number, isEdit: boolean, lsttype: string }
  ) { }
  currentStep: number = 0;
  ListLoaibv: any = [];
  ListTientrinh: any = [];
  ListTacgia: any = [];
  ListLanguage: any = [];
  selectedFiles: FileParameter[] = [];
  typeAttach = this.service.typeAttach();
  isEdit: boolean;
  lstFileIdDelete: number[] = [];
  fileList: any[] = [];
  userName: string;
  listType: string;
  title: string;
  target: TintucSukienEntity = new TintucSukienEntity();
  tinnoibat: boolean;
  ngOnInit(): void {
    this.isEdit = this.data.isEdit;
    this.loadDm();
    this.loadData();
    this.commonService.getUserInfo().subscribe(res1 => {
      this.userName = res1.UserName;
    });
    if (this.data.isEdit) {
      this.title = 'CHỈNH SỬA ';
    } else {
      this.title = 'CHI TIẾT ';
    }
    if (this.data.lsttype === '01') {
      this.title += 'TIN TỨC - SỰ KIỆN';
    } else if (this.data.lsttype === '02') {
      this.title += 'ALBUM ẢNH/VIDEO';
    } else if (this.data.lsttype === '03') {
      this.title += 'CHUYÊN TRANG “CẢI THIỆN MẠNH MẼ MÔI TRƯỜNG ĐẦU TƯ KINH DOANH”';
    } else if (this.data.lsttype === '04') {
      this.title += 'VĂN HÓA LỊCH SỬ';
    } else if (this.data.lsttype === '05') {
      this.title += 'DANH LAM THẮNG CẢNH';
    }
    // this.target.tinnoibat = 'false';
  }
  loadData() {
    this.service.selectOneWithFile(this.data.type, this.data.id).subscribe(res => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data.entity;
        this.target.ngayxuatban = new ConvertDatePipe().transform(this.target.ngayxuatban);
        // if (this.target.tinnoibat === 'false') this.target.tinnoibat = null;
        if (this.target.tinnoibat === '1') this.tinnoibat = true;
        else this.tinnoibat = false;
        this.currentStep = this.target.tientrinh;
        this.fileList = [];
        const data: any[] = res.data.listFile;
        const blobPart: BlobPart[] = data;
        const blob = new Blob(blobPart);
        res.data.listFile.forEach(m => {
          const item: any = { data: m, fileName: m.fileName, isExist: true, fileId: m.fileId };
          this.fileList.push(item);
        });
      } else {
        this.commonService.toastrDanger(res.message);
        this.dialogRef.close(false);
      }
    });
  }

  loadDm() {
    this.service.getDm().subscribe(res => {
      if (res.code === SUCCESS_RESPONE) {
        if (this.data.lsttype === '01') {
          this.listType = '01,04,06,09,10,11,12,16';
        } else if (this.data.lsttype === '02') {
          this.listType = '02,03';
        } else if (this.data.lsttype === '03') {
          this.listType = '13';
        } else if (this.data.lsttype === '04') {
          this.listType = '14';
        } else if (this.data.lsttype === '05') {
          this.listType = '15';
        } else if (this.data.lsttype === '06') {
          this.listType = '20,21,22,23,24,25,26,27,28,29';
        }
        this.ListLoaibv = res.data.dmLoaibaivietVms.filter(x => this.listType.includes(x.ma));
        this.ListTacgia = res.data.dmTacgiaVms;
        this.ListLanguage = res.data.dmLanguageVms;
        this.ListTientrinh = res.data.dmTientrinhVms.sort((a, b) => a.matientrinh - b.matientrinh);
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  deleteFile(file: any) {
    if (this.fileList.indexOf(file) !== -1) {
      this.fileList.splice(this.fileList.indexOf(file), 1);
    }
    if (file.isExist) {
      this.lstFileIdDelete.push(file.fileId);
    }
  }

  // Sự kiện chọn tệp
  chooseFile(files: FileParameter[]) {
    this.selectedFiles = files;
    if (files.length === 0) {
      return;
    }
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const fileParameter: any = { data: this.selectedFiles[i], fileName: this.selectedFiles[i]['name'] };
      this.fileList.push({
        fileName: fileParameter.fileName ? fileParameter.fileName : '',
        fileId: null,
        isExist: false,
        data: fileParameter.data
      });
    }
  }

  update() {
    this.fileList = this.fileList.filter(x => x.isExist === false);
    // Save file upload
    this.commonService.uploadFile('TintucSukien', this.fileList).subscribe(result => {
      const lstFile = result.data.map(element => new AttFileattachmentEntity({
        filename: element.fileName,
        recordid: this.target.id,
        fileid: element.id,
        id: element.id,
        type: this.typeAttach
      }));

      const targetFileInsert: AttFileattachmentEntity[] = [];
      lstFile?.forEach(m => {
        const item = new AttFileattachmentEntity();
        item.fileid = m.fileid;
        item.filename = m.filename;
        item.type = this.typeAttach;
        item.isDirty = false;
        item.isNew = true;
        targetFileInsert.push(item);
      });
      if (this.tinnoibat) this.target.tinnoibat = '1';
      else this.target.tinnoibat = '0';
      const targetUpdate = new TintucSukienEntity();
      targetUpdate.id = this.target.id;
      targetUpdate.binhluan = this.target.binhluan;
      targetUpdate.loaibaiviet = this.target.loaibaiviet;
      targetUpdate.ngaynoibat = this.target.ngaynoibat;
      targetUpdate.ngayxuatban = this.target.ngayxuatban ? new Date(this.target.ngayxuatban) : null;
      targetUpdate.noidung = this.target.noidung;
      targetUpdate.noidungtomtat = this.target.noidungtomtat;
      targetUpdate.tacgia = this.target.tacgia;
      targetUpdate.tientrinh = this.target.tientrinh;
      targetUpdate.tieude = this.target.tieude;
      targetUpdate.timedangtai = this.target.timedangtai ? new Date(this.target.timedangtai) : null;
      targetUpdate.tinnoibat = this.target.tinnoibat;
      targetUpdate.trangthai = this.target.trangthai;
      targetUpdate.isNew = false;
      targetUpdate.isDirty = true;
      targetUpdate.editdate = new Date();
      targetUpdate.edituser = this.userName;

      const targetUpdateAll: TintucSukienEntityProcessUpdateFileModel = new TintucSukienEntityProcessUpdateFileModel({
        entity: targetUpdate,
        lstFileAttachment: lstFile,
        typeDelete: this.typeAttach,
        lstFileIdDelete: this.lstFileIdDelete
      });

      this.service.updateWithFile(targetUpdateAll).subscribe(res => {
        if (res.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess('Cập nhật dữ liệu thành công.');
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrDanger(res.message);
          this.dialogRef.close(false);
        }
      });
    });
  }
}
