import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CommonService } from 'app/@core/customs/common.service';
import { SUCCESS_RESPONE } from 'app/@core/customs/constants';
import { DmChucvuEntity } from 'app/@core/data/app-home.service';
import { DmChucVuService } from '../dm-chuc-vu.service';

@Component({
  selector: 'ngx-dm-chuc-vu-detail',
  templateUrl: './dm-chuc-vu-detail.component.html',
  styleUrls: ['./dm-chuc-vu-detail.component.scss'],
})
export class DmChucVuDetailComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: DmChucVuService,
    @Inject(MAT_DIALOG_DATA) private data: { id: number }
  ) {}

  ngOnInit(): void {
    this.loadData(this.data.id);
  }
  target: DmChucvuEntity = new DmChucvuEntity();
  loadData(id: number) {
    this.service.selectOne(id).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.target = result.data;
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }
}
