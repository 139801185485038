import { SUCCESS_RESPONE } from '../../../../@core/customs/constants';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MapAdminService } from '../mapadmin.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureclassService } from '../../featureclass/featureclass.service';
import { FieldService } from '../../featureclass/field.service';
import { environment } from '../../../../../environments/environment';
import { FeatureclassDetailModel, FileParameter, SysfeatureclassEntity } from '../../../../@core/data/app-gis.service';

class LayerType {
  metadata: any;
  id: string;
  type: string;
  source: string;
  featureClassId: string;

  public constructor(mapModal: any, layerData: any, groupId: any) {
    this.metadata = {
      name: mapModal.name,
      tablename: layerData.tablename,
      groupId: groupId
    };

    this.featureClassId = layerData.idFeatureClass;
    this.id = layerData.tablename;
    this.type = layerData.type;
    this.source = layerData.tablename;
  }
}

class Fill extends LayerType {
  paint: {
    'fill-opacity': number;
    'fill-color': string;
    'fill-outline-color': string;
  };

  public constructor(mapModal: any, layerData: any, fieldsInput: any, groupId: any) {
    super(mapModal, layerData, groupId);

    this.paint = {
      'fill-opacity': Number(fieldsInput['fill-opacity']) ?? 1,
      'fill-color': fieldsInput['fill-color'] ?? '#000000',
      'fill-outline-color': fieldsInput['fill-outline-color'] ?? '#000000',
    };
  }
}

class Line extends LayerType {
  paint: {
    'line-color': string;
    'line-width': number;
  };

  public constructor(mapModal: any, layerData: any, fieldsInput: any, groupId: any) {
    super(mapModal, layerData, groupId);
    this.paint = {
      'line-color': fieldsInput['line-color'] ?? '#000000',
      'line-width': Number(fieldsInput['line-width']) ?? 1,
    };
  }
}

class Circle extends LayerType {
  paint: {
    'circle-radius': number;
    'circle-stroke-width': number;
    'circle-color': string;
    'circle-stroke-color': string;
  };

  public constructor(mapModal: any, layerData: any, fieldsInput: any, groupId: any) {
    super(mapModal, layerData, groupId);
    this.paint = {
      'circle-radius': 7,
      'circle-stroke-width': 1,
      'circle-color': fieldsInput['circle-color'] ?? '#000000',
      'circle-stroke-color': fieldsInput['circle-stroke-color'] ?? '#000000',
    };
  }
}

class Symbol extends LayerType {
  paint: {
    'icon-color': string;
  };
  layout: {
    'icon-image': any;
    'icon-size': number;
    'text-field': string;
    'text-anchor': string;
    'text-radial-offset': number;
    'text-justify': string;
    'text-size': number;
    'text-padding': number;
  };

  public constructor(mapModal: any, layerData: any, fieldsInput: any, groupId) {
    super(mapModal, layerData, groupId);
    this.paint = {
      ['icon-color']: fieldsInput['icon-color'] ?? '#000000',
    };

    this.layout = {
      'icon-image': ['get', 'icon'],
      'icon-size': 0.06,
      'text-field': fieldsInput['text-field'],
      'text-anchor': 'bottom',
      'text-radial-offset': 1,
      'text-justify': 'center',
      'text-size': 1,
      'text-padding': 1,
    };
  }
}

@Component({
  selector: 'ngx-mapadmin-update',
  templateUrl: './layer-update.component.html',
  styleUrls: ['./layer-update.component.scss'],
})
export class LayerUpdateComponent implements OnInit {
  constructor(
    private mapService: MapAdminService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<LayerUpdateComponent>,
    private featureclassService: FeatureclassService,
    private fieldService: FieldService
  ) { }
  lstGroup: any[] = [];
  groupId: any
  target: FeatureclassDetailModel[];
  sourceMap: string;
  layerName: string;
  baseUrl = environment.APP_GIS_URL;
  dataTypes = [
    {
      label: 'Fill',
      name: 'fill',
      paint: [
        {
          label: 'Fill Color',
          name: 'fill-color',
          type: 'color',
        },
        {
          label: 'Fill Outline Color',
          name: 'fill-outline-color',
          type: 'color',
        },
        {
          label: 'Fill Opacity',
          name: 'fill-opacity',
          type: 'number',
        },
      ],
    },
    {
      label: 'Line',
      name: 'line',
      paint: [
        {
          label: 'Line Color',
          name: 'line-color',
          type: 'color',
        },
        {
          label: 'Line Width',
          name: 'line-width',
          type: 'number',
        },
      ],
    },
    {
      label: 'Circle',
      name: 'circle',
      paint: [
        {
          label: 'Circle Color',
          name: 'circle-color',
          type: 'color',
        },
        {
          label: 'Circle Stroke Color',
          name: 'circle-stroke-color',
          type: 'color',
        },
        // {
        //   label: 'Circle Radius',
        //   name: 'circle-radius',
        //   type: 'number',
        // },
        // {
        //   label: 'Circle Stroke Width',
        //   name: 'circle-stroke-width',
        //   type: 'number',
        // },
      ],
    },
    {
      label: 'Symbol',
      name: 'symbol',
      paint: [
        {
          label: 'Icon Color',
          name: 'icon-color',
          type: 'color',
        },
        {
          label: 'Icon Image',
          name: 'icon-image',
          type: 'file',
        },
        // {
        //   label: 'Icon Size',
        //   name: 'icon-size',
        //   type: 'number',
        // },
        {
          label: 'Text Field',
          name: 'text-field',
          type: 'combobox:featureClass',
        },
        // {
        //   label: 'Text Anchor',
        //   name: 'text-anchor',
        //   type: 'text',
        // },
        // {
        //   label: 'Text Radial Offset',
        //   name: 'text-radial-offset',
        //   type: 'text',
        // },
        // {
        //   label: 'Text Justify',
        //   name: 'text-justify',
        //   type: 'text',
        // },
        // {
        //   label: 'Text Size',
        //   name: 'text-size',
        //   type: 'number',
        // },
        // {
        //   label: 'Text Padding',
        //   name: 'text-padding',
        //   type: 'number',
        // },
      ],
    },
  ];

  dataType: any;
  fieldsInput: any = {};
  layerData: any = {
  };
  ngOnInit(): void {
    if (this.data.isEdit === true) {
      this.dataType = this.dataTypes.find((d) => d.name === this.data.item.type);
      this.layerData.type = this.data.item.type;
      this.layerData.idFeatureClass = this.data.item.featureClassId;
      this.groupId = this.data.item.metadata.groupId;
      for (const key in this.data.item.paint) {
        this.fieldsInput[key] = this.data.item.paint[key];
      }
      for (const key in this.data.item.layout) {
        this.fieldsInput[key] = this.data.item.layout[key];
      }
    }
    this.loadata();
  }

  loadata() {
    this.featureclassService.getFeatureclasssByMap().subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data;
        this.layerData.idFeatureClass = this.data.isEdit ? this.data.item.featureClassId : this.target[0].id;
        this.loadFeatureClass(this.layerData.idFeatureClass);
        this.layerData.tablename = this.data.isEdit ? this.data.item.id : this.target[0].tableName;
        this.layerName = this.data.isEdit ? this.data.item.metadata.name : this.target[0].name;
        this.lstGroup = JSON.parse(this.data.data.config).groups;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  fileChange(event: any, name: string) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: ProgressEvent) => {
        this.layerData[name] = (<FileReader>event.target).result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  loadFeatureClass(idFeatureClass, event?: any) {
    if (event) {
      this.layerData.tablename = event.tableName;
      this.layerName = event.name;
    }
    this.fieldService.getAll(idFeatureClass).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.layerData.featureClass = res.data;
        this.layerData.textField = res.data[0].fieldname;
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  getDataCombobox(type) {
    //example type: "combobox:featureClass" =>> key = "featureClass"
    const key = (type ?? '').split(':')[1];
    return this.layerData[key];
  }

  typeChange(name) {
    this.fieldsInput = {};
    this.dataType = this.dataTypes.find((d) => d.name === name);
  }
  ;
  update() {
    const mapModal = this.data.data;
    const modelLayer = { name: this.layerName };
    const config = JSON.parse(mapModal.config);
    config.layers = Array.isArray(config.layers) ? config.layers : [];

    if (this.data.isEdit) {
      config.layers = config.layers.filter((layer) => layer.id !== this.data.item.id);
    }

    if (config.layers.some((layer) => layer.id === this.layerData.tablename)) {
      this.commonService.toastrWarning('Layer ' + modelLayer.name + ' đã tồn tại');
      return;
    }

    switch (this.layerData.type) {
      case 'fill':
        config.layers.push(new Fill(modelLayer, this.layerData, this.fieldsInput, this.groupId));
        break;
      case 'line':
        config.layers.push(new Line(modelLayer, this.layerData, this.fieldsInput, this.groupId));
        break;
      case 'circle':
        config.layers.push(new Circle(modelLayer, this.layerData, this.fieldsInput, this.groupId));
        break;
      case 'symbol':
        config.layers.push(new Symbol(modelLayer, this.layerData, this.fieldsInput, this.groupId));
        break;
    }

    mapModal.config = JSON.stringify(config);

    this.mapService.updateCustomMap(mapModal).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        if (this.selectedFiles.length > 0) {
          this.mapService
            .uploadFile(this.selectedFiles, this.layerData.idFeatureClass, this.layerData.tablename, 'Icon', 'Icon')
            .subscribe((res) => {
              if (res.code === SUCCESS_RESPONE) {
                this.loadata();
              } else {
                this.commonService.toastrDanger(res.message);
              }
            });
        }
        this.commonService.toastrSuccess('Thành công!');
        this.loadata();
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }
  selectedFiles: FileParameter[] = [];
  handleFileInput(files: FileParameter[]) {
    if (files.length === 0) {
      return;
    }
    this.selectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      const fileParameter: FileParameter = {
        data: files[i],
        fileName: files[i]['name'],
      };
      this.selectedFiles.push(fileParameter);
    }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
