import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DmLienketIndexComponent } from './dm-lienket-index/dm-lienket-index.component';

export const routes: Routes = [
  {
      path: '',
      children: [
          { path: '', component: DmLienketIndexComponent }
      ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DmLienketRoutingModule { }
