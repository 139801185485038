export const SUCCESS_RESPONE = 'Success';
export const DUPLICATE_RESPONE = 'Duplicate';
export const NOTFOUND_RESPONE = 'NotFound';
export const SUCCESS_NOTICE = 'Thành công';
export const TITLE_NOTICE = 'Thông báo';

export const PAGE_SIZE = 10;
export const PAGE_SIZE_CUSTOM = 12;
export const WIDTH_DIALOG = '80%';
export const HEIGHT_DIALOG = '100%';
export const SEARCH_LABEL = 'Tìm kiếm';
export const REFRESH_LABEL = 'Làm mới';
export const ADDNEW_LABEL = 'Thêm mới';
export const EDIT_LABEL = 'Sửa';
export const DETAIL_LABEL = 'Xem';
export const DELETE_LABEL = 'Xóa';

export const LBL_DISPLAY = {
    syncNgsp: 'Đồng bộ NGSP',
    add: 'Thêm mới',
    edit: 'Sửa',
    update: 'Cập nhập',
    delete: 'Xóa',
    view: 'Xem',
    search: 'Tìm kiếm',
    save: 'Lưu',
    close: 'Đóng',
    back: 'Quay lại',
    refresh: 'Làm mới',
    file: 'Chọn file',
    confirm: 'Xác nhận',
    cancel: 'Hủy',
    importFile: 'Import',
    downloadTemplate: 'Tải template',
    sync: 'Đồng bộ'
};


export const LOAIDM = [
    {
        value: 1,
        text: 'Diện tích rừng trồng mới tập trung phân theo loại rừng',
        img: 'default-2.png'
    },
    {
        value: 2,
        text: 'Sản lượng gỗ phân theo loại hình kinh tế',
        img: 'Covid-19_1598014946761.png'
    },
    {
        value: 3,
        text: 'Diện tích các loại cây trồng phân theo nhóm cây',
        img: 'Vănhóa-Thểthao-Dulịch_1597992715213.png'
    },
    {
        value: 4,
        text: 'Diện tích gieo trồng một số cây hàng năm',
        img: 'Giáodục-Đàotạo_1597992652964.png'
    },
    {
        value: 5,
        text: 'Năng suất một số cây hàng năm',
        img: 'Khoahọc-Côngnghệ_1597992666205.png'
    },
    {
        value: 6,
        text: 'Sản lượng một số cây hàng năm',
        img: 'Tàinguyên-Môitrường_1597992676727.png'
    },
    {
        value: 7,
        text: 'Diện tích và sản lượng lương thực có hạt',
        img: 'Nông-Lâm-Ngưnghiệp_1598021457832.png'
    },
    {
        value: 8,
        text: 'Diện tích và sản lượng lúa cả năm',
        img: 'Thôngtin-Truyềnthông_1597992726426.png'
    },
    {
        value: 9,
        text: 'Sản lượng sản phẩm chăn nuôi chủ yếu',
        img: 'Giaothôngvậntải_1597992741175.png'
    },
    {
        value: 10,
        text: 'Diện tích mặt nước nuôi trồng thủy sản',
        img: 'default-2.png'
    },
    {
        value: 11,
        text: 'Số lượng thủy sản',
        img: 'default-2.png'
    },
    {
        value: 12,
        text: 'Sản lượng thủy sản khai thác phân theo ngành hoạt động',
        img: 'default-2.png'
    },
    {
        value: 13,
        text: 'Sản lượng thủy sản nuôi trồng',
        img: 'default-2.png'
    },
];

export const LOAIDM1 = {
    DTRUNG: 1,
    SANLUONGGO: 2,
    DTCAYTRONG: 3,
    DTGIEOTRONG: 4,
    NANGSUATGIEOTRONG: 5,
    SANLUONGGIEOTRONG: 6,
    DTVASLLUONGTHUC: 7,
    DTVASLLUA: 8,
    SLSPCHANNUOI: 9,
    DTMATNUOC: 10,
    SLTHUYSAN: 11,
    SLTHUYSANKT: 12,
    SLTHUYSANNT: 13,
};

export const DSTUDIEN = {
    FIELDNAMEDVKC: 'DVKC,',
    FIELDNAMEKY: 'KY,',
    FIELDNAMECHANNUOI: 'DONGVAT,',
    FIELDNAMETRONGLUONG: 'DVTL,',
    FIELDNAMEHAISAN: 'HAISAN,',
    FIELDNAMECAYTRONG: 'CAYTRONG,'
};

export const LSTSORT = [
    {
        value: 'Tieudebaiviet|Ascending',
        text: 'Sắp xếp từ A đến Z'
    },
    {
        value: 'Tieudebaiviet|Descending',
        text: 'Sắp xếp từ Z đến A'
    },
    {
        value: 'Ngaycapnhat|Ascending',
        text: 'Sắp xếp thời gian cập nhật sớm nhất'
    },
    {
        value: 'Ngaycapnhat|Descending',
        text: 'Sắp xếp thời gian cập nhật muộn nhất'
    }
];

export const DTRUNG = 1;
export const SANLUONGGO = 2;
export const DTCAYTRONG = 3;
export const DTGIEOTRONG = 4;
export const NANGSUATGIEOTRONG = 5;
export const SANLUONGGIEOTRONG = 6;
export const DTVASLLUONGTHUC = 7;
export const DTVASLLUA = 8;
export const SLSPCHANNUOI = 9;
export const DTMATNUOC = 10;
export const SLTHUYSAN = 11;
export const SLTHUYSANKT = 12;
export const SLTHUYSANNT = 13;
export const FIELDNAMEDVKC = 'DVKC,';
export const FIELDNAMEKY = 'KY,';
export const FIELDNAMECHANNUOI = 'DONGVAT,';
export const FIELDNAMETRONGLUONG = 'DVTL,';
export const FIELDNAMEHAISAN = 'HAISAN,';
export const FIELDNAMECAYTRONG = 'CAYTRONG,';

export const LOAITALE = {
    DIENTICHCAYTRONG: 'DIENTICHCAYTRONG',
    DIENTICHGIEOTRONG: 'DIENTICHGIEOTRONG',
    DIENTICHMATNUOC: 'DIENTICHMATNUOC',
    DIENTICHRUNG: 'DIENTICHRUNG',
    SANLUONGCHANNUOI: 'SANLUONGCHANNUOI',
    SANLUONGGO: 'SANLUONGGO',
    SANLUONGTHUYSANKT: 'SANLUONGTHUYSANKT',
    SANLUONGTHUYSANNT: 'SANLUONGTHUYSANNT',
    SOLUONGTHUYSAN: 'SOLUONGTHUYSAN',
    LUONGTHUCCOHAT: 'LUONGTHUCCOHAT',
    NANGSUATCAYHANGNAM: 'NANGSUATCAYHANGNAM',
    SANLUONGCAYHANGNNAM: 'SANLUONGCAYHANGNNAM',
    SANLUONGLUA: 'SANLUONGLUA'
};

export const LOAIQUYETTOAN = {
    QTDA01: 'QTDA_01',
    QTDA02: 'QTDA_02',
    QTDA03: 'QTDA_03',
    QTDA04: 'QTDA_04',
    QTDA05: 'QTDA_05',
    QTDA06: 'QTDA_06',
    QTDA07: 'QTDA_07',
    QTDA08: 'QTDA_08',
};
