<nb-card >
    <nb-card-header class="nb-card-header">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 col-xl-12">
                {{title}}
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 col-xl-12">
                <div class="input-group">
                    <ngx-btn-add data-toggle="tooltip" title="Thêm mới" (click)="openCreate();"></ngx-btn-add>&nbsp;
                    <ng-select style="width: 300px;" [items]="ListLoaibv" bindValue="ma" bindLabel="ten" [(ngModel)]="searchModel.childType" (change)="search();" #childType="ngModel" name="childType" placeholder="Chọn Loại bài viết">
                    </ng-select>&nbsp;
                    <ng-select style="width: 300px;" [items]="ListLanguage" bindValue="ma" bindLabel="ten" [(ngModel)]="searchModel.language" (change)="search();" #language="ngModel" name="language" placeholder="Chọn ngôn ngữ">
                    </ng-select>&nbsp;
                    <input type="text" class="form-control" placeholder="Nhập nội dung tìm kiếm" style="height: auto;" [(ngModel)]="searchModel.search" (keydown.enter)="search();" name="seach">&nbsp;
                    <ngx-btn-search (click)="search();"></ngx-btn-search>&nbsp;
                    <ngx-btn-refresh (click)="searchModel.search = '';searchModel.status = null; search();">
                    </ngx-btn-refresh>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12" style="overflow: auto;">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th class="text-center align-middle" style="width: 60px;">STT</th>
                            <th class="text-center align-middle" style="min-width: 150px;">Loại bài viết</th>
                            <th class="text-center align-middle">Ngôn ngữ</th>
                            <th class="text-center align-middle" style="min-width: 300px;">Tiêu đề</th>
                            <th class="text-center align-middle" style="width: 200px">Tác giả</th>
                            <th class="text-center align-middle">Ngày xuất bản</th>
                            <th class="text-center align-middle col-status" style="width: 100px">Trạng thái</th>
                            <th class="text-center align-middle col-action" style="width: 150px">Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of target.data;let i = index;">
                            <td class="text-center align-middle">{{(searchModel.currentPage - 1) * searchModel.pageSize + i + 1}}</td>
                            <td class="text-left align-middle">{{getTenloaibaiviet(item.loaibaiviet)}}</td>
                            <td class="text-left align-middle">{{item.language}}</td>
                            <td class="text-left align-middle">{{item.tieude}}</td>
                            <td class="text-left align-middle">{{getTacgia(item.tacgia)}}</td>
                            <td class="text-center align-middle">{{item.ngayxuatban | date: 'dd/MM/yyyy'}}</td>
                            <td class="text-center align-middle">{{getTientrinh(item.tientrinh)}}</td>
                            <td class="text-center align-middle">
                                <ngx-icon-info class="icon" data-toggle="tooltip" title="Xem chi tiết" (click)="updateData(item.id, false);">
                                </ngx-icon-info>
                                <ng-container>
                                    <ngx-icon-edit class="icon" data-toggle="tooltip" title="Cập nhật" (click)="updateData(item.id, true);">
                                    </ngx-icon-edit>
                                    <ngx-icon-delete class="icon" data-toggle="tooltip" title="Xoá" (click)="deleteData(item.id, item.tieude);">
                                    </ngx-icon-delete>
                                    <ngx-icon-share class="icon" data-toggle="tooltip" title="Chia sẻ" (click)="shareData(item.id);">
                                    </ngx-icon-share>
                                </ng-container>
                                <ngx-icon-reply class="icon" data-toggle="tooltip" title="Thu hồi" *ngIf="item.status === 2" (click)="recoverData(item);">
                                </ngx-icon-reply>
                                <ngx-icon-ellipsis class="icon" *ngIf="item.status === 2 && item.isGovernment" (click)="viewListShare(item.lstShareUnit);"></ngx-icon-ellipsis>
                            </td>
                        </tr>
                        <tr *ngIf="target?.data.length === 0">
                            <td colspan="7">Không có dữ liệu.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="target?.recordsCount > 0" class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                <div style="float: left;">
                    Tổng số bản ghi: {{target?.recordsCount}}
                </div>
                <div style="float: right;">
                    <ngx-pagination (pageChange)="searchModel.currentPage = $event;loadData()" (pageSize)="searchModel.pageSize = $event;loadData()" [currentPage]="searchModel.currentPage" [totalPages]="target.totalPage">
                    </ngx-pagination>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>
