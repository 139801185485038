<nb-card>
  <nb-card-header class="nb-card-header">
    <h3 style="padding: 10px">Thống kê dữ liệu</h3>
  </nb-card-header>
  <nb-card-body>
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <span (click)="refresh()">Thống kê theo chủ đề</span>
        </ng-template>
        <div class="row" style="width: 100%;">
          <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12" style="display: flex; padding-bottom: 15px">
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 d-flex" style="display: flex"></div>
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 d-flex endjun">
              <button id="btn" class="btn btn-secondary" type="button" (click)="locDieukien()">
                <i class="fa fa-filter"></i>&nbsp; Bộ lọc</button>&nbsp;&nbsp;
              <button id="btn" class="btn btn-primary" type="button" (click)="exportExcelData()">
                <i class="fa fa-download"></i>&nbsp; Kết xuất file
              </button>
              &nbsp;&nbsp;
              <ngx-btn-refresh (click)="refresh()"></ngx-btn-refresh>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 tableFilter" *ngIf="toggleTable">
          <div class="row" style="width: 100%">
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 dataset">
              <label class="col-form-label required">Chủ đề dữ liệu</label>
              <ng-select [items]="datasets" bindValue="id" bindLabel="name" appendTo="body"
                placeholder="Chọn chủ đề thống kê" (change)="changeDataSet()" name="dataset" [(ngModel)]="lstDataSetId"
                multiple="true" [closeOnSelect]="false" [clearSearchOnAdd]="true">
                <ng-template ng-header-tmp>
                  <button (click)="selectAllD('dataset')" class="btn btn-sm btn-secondary">Chọn tất
                    cả</button>&nbsp;&nbsp;
                  <button (click)="unselectAll('dataset')" class="btn btn-sm btn-secondary">Bỏ chọn tất cả</button>
                </ng-template>
              </ng-select>

            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3 diaban">
              <label class="col-form-label">Huyện</label>
              <ng-select [items]="lstDVHCCH" bindValue="madonvi" bindLabel="ten" placeholder="Chọn huyện"
                [(ngModel)]="MDVHCCH" name="mahuyen" (change)="changeHuyen()">
              </ng-select>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3 diaban">
              <label class="col-form-label">Xã</label>
              <ng-select [items]="lstXa" bindValue="madonvi" bindLabel="ten" placeholder="Chọn xã" name="maxa"
                [(ngModel)]="MDVHCCX">
              </ng-select>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3 diaban">
              <label class="col-form-label">Thời gian từ</label>
              <br />
              <input type="date" class="form-control" name="dateStart" [(ngModel)]="dateStart" />
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3 diaban">
              <label class="col-form-label">Đến</label>
              <br />
              <input type="date" class="form-control" name="endStart" [(ngModel)]="dateEnd" />
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12" style="margin: 10px 0px 10px 0px">
              <ngx-btn-search data-toggle="tooltip" title="Tìm kiếm" (click)="countDataSet()"
                style="float: right"></ngx-btn-search>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span (click)="refresh()">Thống kê theo lớp dữ liệu</span>
        </ng-template>
        <div class="row" style="width: 100%;">
          <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12" style="display: flex; padding-bottom: 15px">
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 d-flex" style="display: flex"></div>
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 d-flex endjun">
              <button id="btn" class="btn btn-secondary" type="button" (click)="locDieukien()">
                <i class="fa fa-filter"></i>&nbsp; Bộ lọc</button>&nbsp;&nbsp;
              <button id="btn" class="btn btn-primary" type="button" (click)="exportExcelData()">
                <i class="fa fa-download"></i>&nbsp; Kết xuất file
              </button>
              &nbsp;&nbsp;
              <ngx-btn-refresh (click)="refresh()"></ngx-btn-refresh>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 tableFilter" *ngIf="toggleTable">
          <div class="row" style="width: 100%">
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 dataset">
              <label class="col-form-label required">Chủ đề dữ liệu</label>
              <ng-select [items]="datasets" bindValue="id" bindLabel="name" appendTo="body"
                placeholder="Chọn chủ đề thống kê" (change)="changeDataSet()" name="dataset" [(ngModel)]="dataSetId">
              </ng-select>
            </div>

            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 feature">
              <label class="col-form-label">Lớp dữ liệu</label>
              <ng-select [items]="lstTable" bindValue="tableName" bindLabel="name" placeholder="Chọn bảng thống kê"
                [(ngModel)]="featureClassID" name="name" multiple="true" [closeOnSelect]="false"
                [clearSearchOnAdd]="true" (change)="changeFeature($event)">
                <ng-template ng-header-tmp>
                  <button (click)="selectAllD('feature')" class="btn btn-sm btn-secondary">Chọn tất
                    cả</button>&nbsp;&nbsp;
                  <button (click)="unselectAll('feature')" class="btn btn-sm btn-secondary">Bỏ chọn tất cả</button>
                </ng-template>
              </ng-select>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3 diaban">
              <label class="col-form-label">Huyện</label>
              <ng-select [items]="lstDVHCCH" bindValue="madonvi" bindLabel="ten" placeholder="Chọn huyện"
                [(ngModel)]="MDVHCCH" name="mahuyen" (change)="changeHuyen()">
              </ng-select>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3 diaban">
              <label class="col-form-label">Xã</label>
              <ng-select [items]="lstXa" bindValue="madonvi" bindLabel="ten" placeholder="Chọn xã" name="maxa"
                [(ngModel)]="MDVHCCX">
              </ng-select>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3 diaban">
              <label class="col-form-label">Thời gian từ</label>
              <br />
              <input type="date" class="form-control" name="dateStart" [(ngModel)]="dateStart" />
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 col-lg-3 diaban">
              <label class="col-form-label">Đến</label>
              <br />
              <input type="date" class="form-control" name="endStart" [(ngModel)]="dateEnd" />
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6" *ngIf="show">
              <label class="col-form-label">Từ khóa tìm kiếm</label>
              <br />
              <input type="text" class="form-control" name="textSearch" [(ngModel)]="textSearch" />
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6" *ngIf="show">
              <label class="col-form-label">Danh sách trường dữ liệu</label>
              <br />
              <ng-select [items]="lstField" bindValue="fieldname" bindLabel="name" placeholder="Chọn trường dữ liệu"
                name="field" [(ngModel)]="field">
              </ng-select>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12" style="margin: 10px 0px 10px 0px">
              <ngx-btn-search data-toggle="tooltip" title="Tìm kiếm" (click)="count()"
                style="float: right"></ngx-btn-search>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <br />
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th class="text-center" style="width: 50px">STT</th>
              <th>Chủ đề dữ liệu</th>
              <ng-container *ngIf="lstLabel.length >= 0">
                <th *ngFor="let item of lstLabel" style="min-width: 130px;">
                  {{ item.name }}
                </th>
              </ng-container>
              <th>Huyện</th>
              <th>Xã</th>
              <th>Tổng dữ liệu</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of target; let i = index">
              <td class="text-center">
                {{ i + 1 }}
              </td>
              <td>{{ item.dataSetName }}</td>
              <td *ngFor="let items of lstLabel">
                {{ item[items.tablename] }}
                <button id="buttonIcon" style="float: right;" (click)="viewData(items.tablename,item.lstId)"
                  class="btn btn-light" type="button" data-toggle="tooltip" title="Xem danh sách dữ liệu">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18"
                    viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                    <path
                      d="M572.5 241.4C518.3 135.6 410.9 64 288 64S57.7 135.6 3.5 241.4a32.4 32.4 0 0 0 0 29.2C57.7 376.4 165.1 448 288 448s230.3-71.6 284.5-177.4a32.4 32.4 0 0 0 0-29.2zM288 400a144 144 0 1 1 144-144 143.9 143.9 0 0 1 -144 144zm0-240a95.3 95.3 0 0 0 -25.3 3.8 47.9 47.9 0 0 1 -66.9 66.9A95.8 95.8 0 1 0 288 160z" />
                  </svg>
                </button>
                <button id="buttonIcon" style="float: right;" (click)="ExportData(items.tablename,item.lstId)"
                  class="btn btn-light" type="button" data-toggle="tooltip" title="Kết xuất dữ liệu">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M1 11.7898V12.8599C1 13.4275 1.22692 13.9718 1.63085 14.3732C2.03477 14.7745 2.58261 15 3.15385 15H12.8462C13.4174 15 13.9652 14.7745 14.3692 14.3732C14.7731 13.9718 15 13.4275 15 12.8599V11.7898"
                      stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4.40015 8.12103L8.00015 11.5987L11.6001 8.12103" stroke="black" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M8 11.4331V1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
              </td>
              <td>{{ getTenDVHCCH(item.mahuyen) }}</td>
              <td>{{ getTenDVHCCX(item.maxa) }}</td>
              <td>{{ item.totalData }}</td>
            </tr>
            <tr *ngIf="target?.length === 0">
              <td colspan="7">Không có dữ liệu.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </nb-card-body>
</nb-card>